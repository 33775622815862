import { storyblokEditable } from '@storyblok/react';
import { Box, List, ListIcon, ListItem } from '@terminal/design-system';
import { CheckIcon } from '@components/Icons/Check';
import { RichText } from './RichText';
import type { CustomListStoryblok, Storyblok } from '../types';

const posibleIcons = {
  checkIcon: {
    as: CheckIcon,
    color: 'green.500',
  },
};

export function CustomList({ blok, page }: Storyblok<CustomListStoryblok>) {
  return (
    <List spacing={2} mb={8} aria-label={`${blok.ariaLabel} list`} {...storyblokEditable(blok)}>
      {blok.items?.content?.map((item, i) => {
        return (
          <ListItem key={`${blok._uid}-item-${i}`} display="flex" alignItems="center">
            <ListIcon {...posibleIcons[blok.icon || 'checkIcon']} w={4} h={4} mr={3} />
            <Box
              sx={{
                '&>div': {
                  margin: 0,
                  padding: 0,
                },
              }}
            >
              <RichText
                blok={{
                  ...blok,
                  component: 'richText',
                  content: {
                    type: 'doc',
                    content: [item],
                  },
                }}
                page={page}
              />
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}
