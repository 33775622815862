/**
 * Return the site path with the current environment
 *
 * @description  Had to move this function out of sitePath.ts because tests would fail not being
 * able to read getConfig()
 */
export function sitePath_withEnv({
  path,
  lang,
  siteEnv,
}: {
  path: string;
  lang?: string;
  siteEnv: string;
}) {
  const langString = lang ? `/${lang}` : '';

  if (siteEnv === 'dev') {
    return `https://dev.terminal.io${langString + path}`;
  } else {
    return `https://www.terminal.io${langString + path}`;
  }
}
