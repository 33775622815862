import { textTheme } from '../Text/Text.theme';

export const menuTheme = {
  parts: ['item', 'command', 'list', 'button', 'groupTitle', 'divider'],
  baseStyle: {
    list: {
      bg: 'ui.inverse.primary',
      boxShadow: 'md',
      minW: 64,
      py: 0,
      zIndex: 'dropdown',
      borderRadius: 'base',
      borderWidth: 0,
    },
    item: {
      py: 4,
      px: 6,
      transition: 'background 50ms ease-in 0s',
      ...textTheme.variants.caption,
      color: 'text.primary',
      _hover: {
        bg: 'bg.tertiary',
        p: {
          color: 'text.primary',
        },
      },
      _focus: {
        bg: 'bg.tertiary',
      },
      _active: {
        bg: 'accent.main',
        p: {
          color: 'text.inverse',
        },
      },
      _activeLink: {
        bg: 'accent.main',
        p: {
          color: 'text.inverse',
        },
      },
      _expanded: {
        bg: 'ui.inverse.secondary',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
    groupTitle: {
      mt: 0,
      mx: 6,
      mb: 1,
      pt: 2,
      fontWeight: 'bold',
      fontSize: 'xs',
      color: 'text.secondary',
    },
    command: {
      opacity: 0.6,
    },
    divider: {
      border: '1px',
      borderColor: 'ui.inverse.secondary',
      my: 0,
    },
  },
  variants: {
    default: {},
    secondary: {
      item: {
        color: 'text.primary',
        py: 3,
        px: 2,
        borderRadius: 'base',
        mb: 2,
        _hover: {
          color: 'text.primary',
          bg: 'accent.lighter',
        },
        _focus: {
          color: 'text.primary',
          bg: 'accent.lighter',
        },
        _active: {
          color: 'text.primary',
          bg: 'accent.lighter',
          p: {
            color: 'text.primary',
            fontWeight: 'bold',
          },
        },
        _activeLink: {
          color: 'text.primary',
          bg: 'accent.lighter',
          p: {
            color: 'text.primary',
            fontWeight: 'bold',
          },
        },
        _expanded: {
          color: 'text.primary',
          bg: 'accent.lighter',
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
