import { useEffect, useState } from 'react';
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader';
import { loadPreferences } from '@segment/consent-manager';

import { requiresConsent as segmentRequiresConsent } from '../segment/SegmentConsent';

export function IntercomPerformanceOptimized({ type }: { type: string }) {
  const segmentPrefs = loadPreferences();
  const INTERCOM_API = 'vbx1d7ae';
  const intercomColor = 'rgb(49, 130, 206)';
  const [isIntercomVisible, setIsIntercomVisible] = useState(false);
  const userType = type === 'businesses' ? 'businesses' : 'engineers';

  useEffect(() => {
    if (
      !isIntercomVisible ||
      typeof window === 'undefined' ||
      !(typeof (window as any).Intercom === 'function')
    )
      return;

    if (localStorage?.getItem('userType') !== userType) {
      (window as any).Intercom('shutdown');
      localStorage?.setItem('userType', userType);
    }

    (window as any).Intercom('boot', {
      app_id: INTERCOM_API,
      alignment: 'right',
    });
  }, [INTERCOM_API, isIntercomVisible, userType]);

  useEffect(() => {
    segmentRequiresConsent()
      .then((consentRequired) => {
        if (!consentRequired || segmentPrefs.customPreferences?.functional) {
          setIsIntercomVisible(true);
        }
      })
      .catch(console.log);
  }, [segmentPrefs.customPreferences?.functional]);

  // TODO: not sure what is the best way of doing this, I need to test in env to see if it works
  return (
    <LiveChatLoaderProvider provider="intercom" providerKey={INTERCOM_API}>
      {isIntercomVisible && <Intercom color={intercomColor} />}
    </LiveChatLoaderProvider>
  );
}
