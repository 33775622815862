import { Text } from '@terminal/design-system';
import { Box } from '@chakra-ui/react';
import ImageShimmer from '../ImageShimmer/ImageShimmer';

export default function Testimonial({
  testimonial,
  ...props
}: {
  testimonial: {
    bgDark?: boolean;
    image: {
      sourceUrl: string;
      altText: string;
    };
    text: string;
    author: {
      name: string;
      jobTitle: string;
    };
  };
  [x: string]: any;
}) {
  return (
    <Box
      w="100%"
      bgColor={testimonial.bgDark ? 'grey.900' : 'blue.100'}
      color={testimonial.bgDark ? 'white' : 'grey.900'}
      py={{ base: '12' }}
      pl={{ base: '12', lg: '12', xl: '14' }}
      pr={{ base: '12', lg: '10' }}
      position="relative"
      {...props}
    >
      {!!testimonial.image.sourceUrl && (
        <Box position="relative" overflow="hidden" w="5.5rem" h="5.5rem" borderRadius="50%">
          <ImageShimmer
            src={testimonial.image.sourceUrl}
            alt={testimonial.image.altText}
            style={{ objectFit: 'cover' }}
            fill
          />
        </Box>
      )}
      <Text
        mt="6"
        fontSize={{ base: '2xl', lg: '1.75rem' }}
        lineHeight={{ base: '7', lg: '2.125rem' }}
        fontWeight="semibold"
        color="inherit"
      >
        {testimonial.text}
      </Text>
      {!!testimonial.author.name && (
        <Text color="inherit" mt="8" fontSize="lg" lineHeight="6" fontWeight="bold">
          {testimonial.author.name}
        </Text>
      )}
      {!!testimonial.author.jobTitle && (
        <Text color="inherit" fontSize="lg" lineHeight="6">
          {testimonial.author.jobTitle}
        </Text>
      )}
    </Box>
  );
}
