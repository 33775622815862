import { Box, Grid, GridItem } from '@chakra-ui/react';

export default function ColoredSquares() {
  return (
    <Grid w="10" gridTemplateColumns="repeat(3, auto)" gridGap="2">
      {['blue.500', 'yellow.400', 'green.500'].map((dot) => (
        <GridItem key={dot}>
          <Box w="2" h="2" bgColor={dot} />
        </GridItem>
      ))}
    </Grid>
  );
}
