import { useState, useRef, useEffect, MouseEvent, ReactNode } from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Flex,
  Container,
  Link,
  Button,
  List,
  ListItem,
  useDisclosure,
  MenuIcon,
} from '@terminal/design-system';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';

import { PrimaryButton } from '../UI/Button';
import TerminalLogo from '../UI/TerminalLogo';
import ChevronRight from '../Icons/ChevronRight';
import ArrowRight from '../Icons/ArrowRight';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl, loginUrl, signUpUrl } = publicRuntimeConfig;

function LangSwitch({
  href,
  children,
  ...props
}: {
  href: string;
  children: ReactNode;
  [x: string]: any;
}) {
  const router = useRouter();

  const changeTo = router.locale === 'en' ? 'es' : 'en';

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    router.push(href, undefined, { locale: changeTo });
  };

  return (
    <Link
      href={href}
      title={`Change language to ${changeTo === 'es' ? 'Spanish' : 'English'}`}
      aria-label={`Change language to ${changeTo === 'es' ? 'Spanish' : 'English'}`}
      onClick={handleClick}
      fontSize={{ base: 'lg', xl: 'xs' }}
      lineHeight="1"
      _hover={{ textDecoration: 'none' }}
      role="group"
      color="text.primary"
      {...props}
    >
      <Box
        position="relative"
        _before={{
          content: "''",
          position: 'absolute',
          bottom: '-2',
          w: '100%',
          h: '0.5',
          transition: 'background-color 0.2s ease-in-out',
          bgColor: 'transparent',
        }}
        _groupHover={{
          _before: {
            bgColor: 'green.500',
          },
        }}
      >
        {children}
      </Box>
    </Link>
  );
}

export default function Header({
  type,
  getStartedLink,
  hideBanner = false,
}: {
  type: string;
  getStartedLink: string;
  hideBanner?: boolean;
}) {
  const customGetStartedLink = () => {
    if (type === 'businesses') {
      return getStartedLink ?? talentHubOnboardingUrl;
    } else {
      return getStartedLink ?? signUpUrl;
    }
  };

  const { isOpen, onToggle } = useDisclosure();

  const { t } = useTranslation('header');
  const { t: common } = useTranslation('common');

  useEffect(() => {
    if (isOpen) {
      document.body.parentElement.classList.add('mobile-expanded');
      document.body.classList.add('mobile-expanded');
    } else {
      document.body.parentElement.classList.remove('mobile-expanded');
      document.body.classList.remove('mobile-expanded');
    }
  }, [isOpen]);

  interface NavItemsProps {
    label: string;
    href: string;
    children?: {
      label: string;
      href: string;
    }[];
  }

  // TODO: refactor this assertion because is causing an issue
  const NavItemsBusinesses = t('businesses.menu') as NavItemsProps[];
  const NavItemsEngineers = t('engineers.menu') as NavItemsProps[];
  const NavBusinessesSwitch = t('businesses.typeSwitch') as NavItemsProps[];
  const NavEngineersSwitch = t('engineers.typeSwitch') as NavItemsProps[];

  function MobileAccordion(props) {
    const [expand, setExpand] = useState(false);
    const expandContainer = useRef(null);

    const handleExpand = () => {
      if (!props.childItems?.length) {
        onToggle();
      } else {
        setExpand(!expand);
      }
    };

    const AccordionButton = (props) => {
      const AccordionItem = props.showArrow ? Button : Link;

      return (
        <AccordionItem
          display="flex"
          p="0"
          w="full"
          h="auto"
          color="grey.900"
          alignItems="center"
          justifyContent="space-between"
          fontSize={{ base: 'xl', md: '2xl' }}
          lineHeight={{ base: '5', md: '7' }}
          fontWeight="bold"
          _hover={{ textDecoration: 'none' }}
          onClick={handleExpand}
          bgColor="transparent"
          _active={{ bgColor: 'transparent' }}
          href={props.showArrow ? undefined : props.href}
        >
          {props.children}
          {props.showArrow && (
            <ChevronRight
              transform={{ base: expand ? 'rotate(90deg)' : 'rotate(0)' }}
              transition="transform .3s ease-in-out"
            />
          )}
        </AccordionItem>
      );
    };

    return (
      <>
        <AccordionButton href={props.href} showArrow={!!props.childItems?.length}>
          {props.children}
        </AccordionButton>
        {props.childItems?.length && (
          <Box
            mt="0 !important"
            maxHeight={{
              base: expand ? expandContainer.current.scrollHeight : '0',
            }}
            transition="max-height .3s ease-in-out"
            overflow="hidden"
            ref={expandContainer}
          >
            <List
              textAlign="left"
              spacing={{ base: '6', md: '8' }}
              lineHeight="7"
              mt={{ base: '1.5rem !important', md: '2rem !important' }}
            >
              {props.childItems.map((childItem) => (
                <ListItem key={childItem.label}>
                  <Link
                    href={childItem.href}
                    color="grey.900"
                    fontSize={{ base: 'xl', md: '2xl' }}
                    lineHeight={{ base: '6', md: '7' }}
                    fontWeight="normal"
                    _hover={{ textDecoration: 'none' }}
                    onClick={onToggle}
                  >
                    {childItem.label}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </>
    );
  }

  const MobileLinks = ({ navItems }: { navItems: NavItemsProps[] }) => {
    if (!Array.isArray(navItems)) {
      return null;
    }

    return (
      <List textAlign="left" spacing={{ base: '6', md: '8' }}>
        {navItems.map((navItem) => (
          <ListItem key={navItem.label}>
            <MobileAccordion href={navItem.href} childItems={navItem.children}>
              {navItem.label}
            </MobileAccordion>
          </ListItem>
        ))}
      </List>
    );
  };

  const DesktopDropdown = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();
    return (
      <Box position="relative">
        <Link
          // TODO: once Gabi and Greg create the index page, we can remove this validation
          href={props.href === '/solutions' ? '#' : props.href}
          display="block"
          p="4"
          fontSize={props.size === 'sm' ? 'xs' : 'sm'}
          lineHeight="1"
          fontWeight={props.size === 'sm' ? 'semibold' : 'normal'}
          color="grey.900"
          _hover={{ textDecoration: 'none' }}
          bgColor={isOpen && props.childItems?.length ? 'blue.100' : 'transparent'}
          role="group"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          onFocus={onOpen}
          onBlur={onClose}
        >
          <Box
            position="relative"
            _before={{
              content: "''",
              position: 'absolute',
              bottom: '-2',
              w: '100%',
              h: '0.5',
              transition: 'background-color 0.2s ease-in-out',
              bgColor: router.asPath.includes(props.href) ? 'grey.900' : 'transparent',
            }}
            whiteSpace="nowrap"
            _groupHover={{
              _before: {
                bgColor: 'green.500',
              },
            }}
          >
            {props.children}
          </Box>
        </Link>
        {props.childItems?.length > 0 && (
          <List
            minW="max-content"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            bgColor="blue.100"
            position="absolute"
            left="0"
            boxShadow="md"
            opacity={isOpen ? '1' : '0'}
            visibility={isOpen ? 'visible' : 'hidden'}
            transition="opacity 0.2s ease-in-out, visibility 0.2s ease-in-out"
          >
            {props.childItems.map((childItem) => (
              <ListItem
                as="div"
                key={childItem.label}
                p="0"
                position="relative"
                _before={{
                  md: {
                    content: '""',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    margin: '0 auto',
                    bottom: '0',
                    width: 'calc(100% - 1.5rem)',
                    height: 'px',
                    bgColor: 'blue.200',
                  },
                }}
                _last={{
                  _before: {
                    display: 'none',
                  },
                }}
              >
                <Link
                  href={childItem.href}
                  display="block"
                  w="100%"
                  py="6"
                  px="3.5"
                  fontSize={props.size === 'sm' ? 'xs' : 'sm'}
                  lineHeight="4"
                  fontWeight="normal"
                  color="grey.900"
                  _hover={{ textDecoration: 'none', bgColor: 'blue.200' }}
                  tabIndex={0}
                  onFocus={onOpen}
                  onBlur={onClose}
                >
                  {childItem.label}
                </Link>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  };

  const DesktopLinks = ({ navItems, size }: { navItems: NavItemsProps[]; size?: string }) => {
    if (!Array.isArray(navItems)) {
      return null;
    }

    return (
      <List textAlign="left" display="grid" gridAutoFlow="column" gridGap="2">
        {navItems?.map((navItem, index) => (
          <ListItem key={navItem.label}>
            <DesktopDropdown
              href={navItem.href}
              childItems={navItem.children}
              id={`${index}`}
              size={size}
            >
              {navItem.label}
            </DesktopDropdown>
          </ListItem>
        ))}
      </List>
    );
  };

  const MobileMenu = () => {
    return (
      <>
        <Box pl={{ base: '4', md: '10' }} pr={{ base: '4', md: '10' }}>
          <Flex
            py="5"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth="0.0625rem"
            borderBottomStyle="solid"
            borderColor="grey.300"
          >
            <Link href="/" display="flex" alignItems="center" aria-label="Terminal logo">
              <TerminalLogo mt="-2" width="8.75rem" height="2.5625rem" />
            </Link>
            <Flex justifyContent="flex-end">
              {type === 'engineers' && (
                <Flex alignItems="center" justifyContent="right" w="40">
                  <Link
                    href={loginUrl}
                    color="grey.900"
                    fontSize="lg"
                    _hover={{ textDecoration: 'none' }}
                  >
                    Log In
                  </Link>
                </Flex>
              )}
              <Button
                display="flex"
                alignItems="center"
                justifyContent="center"
                minW="6"
                h="6"
                ml={{ base: '5' }}
                p="0"
                bgColor="transparent"
                borderRadius="0"
                _hover={{
                  backgroundColor: 'transparent',
                }}
                color="grey.900"
                fontSize="5xl"
                onClick={onToggle}
                aria-label="Close menu"
              >
                &times;
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Box
          pt="6"
          pb="5"
          pl={{ base: '4', md: '10' }}
          pr={{ base: '4', md: '10' }}
          overflowY="scroll"
          maxH="calc(100vh - 4.875rem)"
        >
          <Link
            href="/"
            color="blue.700"
            fontSize="2xl"
            textTransform="capitalize"
            fontWeight="bold"
            lineHeight="7"
            mb={{ base: '6', md: '8' }}
            _hover={{ textDecoration: 'none' }}
            onClick={onToggle}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {t('typeMobile.businesses')}{' '}
            {type !== 'businesses' && (
              <ArrowRight w="4" mr="0.375rem" aria-label="Arrow pointing to the right" />
            )}
          </Link>
          {type === 'businesses' && <MobileLinks navItems={NavItemsBusinesses} />}
          <Link
            href="/engineers"
            color="blue.700"
            fontSize="2xl"
            textTransform="capitalize"
            fontWeight="bold"
            lineHeight="7"
            mt={{ base: '6', md: '8' }}
            mb={{ base: type === 'engineers' && '6' }}
            _hover={{ textDecoration: 'none' }}
            onClick={onToggle}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {t('typeMobile.engineers')}{' '}
            {type !== 'engineers' && (
              <ArrowRight w="4" mr="0.375rem" aria-label="Arrow pointing to the right" />
            )}
          </Link>
          {type === 'engineers' && <MobileLinks navItems={NavItemsEngineers} />}
          <PrimaryButton
            mt={{ base: '10' }}
            minW={{ base: '100%' }}
            maxW={{ base: '21.625rem' }}
            href={customGetStartedLink()}
            h={12}
            w={40}
            onClick={() =>
              trackCtaClicked({
                name: type === 'engineers' ? common('engineers.getStarted') : 'Browse Talent',
                type,
                context: 'Header',
                link: getStartedLink,
              })
            }
          >
            {type === 'engineers' ? common('engineers.getStarted') : 'Browse Talent'}
          </PrimaryButton>
        </Box>
      </>
    );
  };

  const DesktopMenu = () => {
    return (
      <Box ml="6" position="relative">
        {type === 'businesses' && <DesktopLinks navItems={NavItemsBusinesses} />}
        {type === 'engineers' && <DesktopLinks navItems={NavItemsEngineers} />}
      </Box>
    );
  };

  return (
    <>
      <style global jsx>{`
        .mobile-expanded {
          overflow: hidden;
        }
        @media (min-width: 100px) {
          .mobile-expanded {
            overflow: auto;
          }
        }
      `}</style>
      <Box
        as="header"
        position="relative"
        zIndex="dropdown"
        minH="4.4444444444rem"
        borderBottom={{ base: '0.0625rem solid #D9E3E7' }}
      >
        <Box bgColor="white" zIndex="999">
          <Container zIndex="1" position="relative" bgColor="white">
            <Box
              display={{ base: 'flex' }}
              alignItems={{ md: 'center' }}
              justifyContent={{ base: 'space-between' }}
              pt={{ base: '5', xl: '7' }}
              pb={{ base: '3', xl: '7' }}
              height="80px"
            >
              <Flex alignItems="center">
                <Link
                  href={type === 'engineers' ? '/engineers' : '/'}
                  display="flex"
                  alignItems="center"
                  mr={{ xl: '6' }}
                  aria-label="Home Page - Terminal logo"
                >
                  <TerminalLogo mt="-2" width="8.05555555556rem" height="2.2777777778rem" />
                </Link>
                <Box display={{ base: 'none', xl: 'block' }}>
                  <DesktopMenu />
                </Box>
              </Flex>
              <Flex alignItems="center">
                <Box display={{ base: 'none', xl: 'flex' }} mr="2">
                  <DesktopLinks
                    navItems={type === 'businesses' ? NavBusinessesSwitch : NavEngineersSwitch}
                    size="sm"
                  />
                </Box>
                {type === 'engineers' && (
                  <Link
                    href={loginUrl}
                    display={{ base: 'none', xl: 'block' }}
                    ml="2"
                    mr="2"
                    p="4"
                    color="grey.900"
                    fontSize="sm"
                    lineHeight="1"
                    _hover={{ textDecoration: 'none' }}
                    position="relative"
                    _before={{
                      content: '""',
                      position: 'absolute',
                      bottom: '0',
                      top: '0',
                      left: '-2',
                      margin: 'auto',
                      width: '0.0625rem',
                      height: '2rem',
                      backgroundColor: 'blue.200',
                    }}
                    role="group"
                  >
                    <Box
                      position="relative"
                      _before={{
                        content: '""',
                        position: 'absolute',
                        bottom: '-2',
                        w: '100%',
                        h: '0.5',
                        transition: 'background-color 0.2s ease-in-out',
                        bgColor: 'transparent',
                      }}
                      _groupHover={{
                        _before: {
                          bgColor: 'green.500',
                        },
                      }}
                    >
                      Log In
                    </Box>
                  </Link>
                )}

                <Button
                  display={{ base: 'flex', xl: 'none' }}
                  _hover={{ bg: 'inherit', opacity: 0.8 }}
                  mr={-3}
                  variant="ghost"
                  onClick={onToggle}
                  w={12}
                  h={12}
                >
                  <MenuIcon color="ui.primary" fontSize="2xl" aria-label="Menu hamburger icon" />
                </Button>
                <PrimaryButton
                  display={{ base: 'none', xl: 'flex' }}
                  href={customGetStartedLink()}
                  onClick={() =>
                    trackCtaClicked({
                      type,
                      name: type === 'engineers' ? common('engineers.getStarted') : 'Browse Talent',
                      context: 'Header',
                      link: getStartedLink,
                    })
                  }
                  h={12}
                  w={40}
                >
                  {type === 'engineers' ? common('engineers.getStarted') : 'Browse Talent'}
                </PrimaryButton>
              </Flex>
            </Box>
          </Container>
          <Box
            display={{ xl: 'none' }}
            position="fixed"
            top="0"
            right="0"
            w={{ base: '100%', md: '36rem' }}
            h="100%"
            zIndex="999"
            bgColor="blue.100"
            transition="transform .3s ease-in-out"
            transform={{ base: isOpen ? 'translateX(0)' : 'translateX(100%)' }}
          >
            <MobileMenu />
          </Box>
        </Box>
      </Box>
      <Box></Box>
    </>
  );
}
