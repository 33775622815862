import type { ImageStoryblok, Storyblok } from '../types';
import Image from 'next/image';
import { forceExtractDimensionsFormURL, selectBlokStyles } from '@helpers/storyblok.utils';
import { Box, Text } from '@terminal/design-system';
import { storyblokEditable } from '@storyblok/react';

export function StoryblokImage({ blok }: Storyblok<ImageStoryblok>) {
  const { width, height } = forceExtractDimensionsFormURL(blok.image?.filename, {
    width: 2000,
    height: 800,
  });

  const { marginStyles, sizesStyle } = selectBlokStyles(blok);

  const displaySizing = {
    width:
      Array.isArray(sizesStyle?.width) && sizesStyle?.width.length
        ? (sizesStyle?.width[0] as string)
        : (sizesStyle?.width as string),
    height:
      Array.isArray(sizesStyle?.height) && sizesStyle?.height.length
        ? (sizesStyle?.height[0] as string)
        : (sizesStyle?.height as string),
  };

  const styleProps = {
    stretch: {
      width,
      height,
      style: {
        objectFit: 'contain',
        display: 'block',
        width: '100%',
        position: 'relative',
        marginLeft: 0,
        marginRight: 0,
      },
    },
    default: {
      width,
      height,
      style: {
        ...displaySizing,
      },
    },
    left: {
      width,
      height,
      style: {
        ...displaySizing,
        float: 'left',
        marginLeft: 0,
      },
    },
    right: {
      width,
      height,
      style: {
        ...displaySizing,
        float: 'right',
        marginRight: 0,
      },
    },
    center: {
      width,
      height,
      style: {
        ...displaySizing,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  } as const;

  const selectedStyle = styleProps[blok.laupoutMode || 'default'];

  if (!blok.caption) {
    return (
      <Box {...marginStyles}>
        <Image
          src={blok.image?.filename}
          alt={blok.alt || blok.image?.alt}
          title={blok?.title || blok?.image?.title}
          priority={blok.priority || false}
          {...selectedStyle}
          {...storyblokEditable(blok)}
        />
      </Box>
    );
  }

  const stylePropsWithCaption = {
    stretch: {
      container: {
        ...marginStyles,
      },
      image: {
        width,
        height,
        style: {
          objectFit: 'contain',
          display: 'block',
          width: '100%',
          position: 'relative',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    default: {
      container: {
        ...marginStyles,
      },
      image: styleProps.default,
    },
    left: {
      container: {
        ...marginStyles,
        float: 'left',
        marginLeft: 0,
      },
      image: {
        width,
        height,
        style: {
          ...displaySizing,
        },
      },
    },
    right: {
      container: {
        ...marginStyles,
        float: 'right',
        marginRight: 0,
      },
      image: {
        width,
        height,
        style: {
          ...displaySizing,
        },
      },
    },
    center: {
      container: {
        ...marginStyles,
        display: 'flex',
        justifyContent: 'center',
      },
      image: {
        width,
        height,
        style: {
          ...displaySizing,
        },
      },
    },
  } as const;

  const selectedStyleWithCaption = stylePropsWithCaption[blok.laupoutMode || 'default'];

  return (
    <Box width="auto" {...selectedStyleWithCaption.container} {...storyblokEditable(blok)}>
      <Box>
        <Image
          src={blok.image?.filename}
          alt={blok.alt || blok.image.alt}
          title={blok?.title || blok?.image?.title}
          priority={blok.priority || false}
          {...selectedStyleWithCaption.image}
        />
        <Text variant="caption" color="text.secondary" mt={2}>
          {blok.caption}
        </Text>
      </Box>
    </Box>
  );
}
