import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const fiveCompaniesLogos = [
  {
    name: 'Hims & Hers',
    imageSrc: '/images/light-lp/companies/hims-hers.svg',
    imageSize: {
      w: 141,
      h: 24,
    },
  },
  {
    name: 'Calendly',
    imageSrc: '/images/light-lp/companies/calendly.svg',
    imageSize: {
      w: 120,
      h: 30,
    },
  },
  {
    name: 'Grindr',
    imageSrc: '/images/light-lp/companies/grindr.svg',
    imageSize: {
      w: 95,
      h: 30,
    },
  },
  {
    name: 'Nextdoor',
    imageSrc: '/images/light-lp/companies/nextdoor.svg',
    imageSize: {
      w: 146,
      h: 21,
    },
  },
  {
    name: 'GoPro',
    imageSrc: '/images/light-lp/companies/gopro.svg',
    imageSize: {
      w: 100,
      h: 30,
    },
  },
  {
    name: 'Transfix',
    imageSrc: '/images/light-lp/companies/transfix.svg',
    imageSize: {
      w: 130,
      h: 28,
    },
  },
];

export const testimonialsSlider = [
  {
    type: 'case-study',
    caseStudy: {
      title: 'Jaris Scales Hiring + Boosts Engineering Productivity with Terminal',
      logo: {
        src: '/images/success-stories/jaris-logo.png',
        alt: 'Jaris',
        width: 90,
        height: 50,
      },
      link: '/success-stories/jaris',
    },
  },
  {
    type: 'testimonial',
    testimonial: {
      text: 'Terminal changed my life. The dynamic with full-time is different. You become part of a company.',
      author: {
        image: '/images/testimonials/jesus-estrada.jpg',
        name: 'Jesús Estrada',
        job: 'Tech Lead, Handshake via Terminal',
      },
      video: '1Y99klPwLtc',
    },
  },
  {
    type: 'case-study',
    caseStudy: {
      title: 'SESO Labor Scales Hiring + Boosts Engineering Productivity with Terminal',
      logo: {
        src: '/images/success-stories/seso-logo.svg',
        alt: 'Seso',
        width: 90,
        height: 40,
      },
      link: '/success-stories/seso',
    },
  },
  {
    type: 'testimonial',
    testimonial: {
      text: 'With Terminal I have a clear career path. I work with people that are growing along with me.',
      author: {
        image: '/images/testimonials/ana-barrios.jpg',
        name: 'Ana Barrios',
        job: 'Team Lead, Software Engineering, Everly Health',
      },
      video: '-ymOHAZToXk',
    },
  },
  {
    type: 'case-study',
    caseStudy: {
      title: 'How PresenceLearning scaled their team by 150% in 3 months with Terminal',
      logo: {
        src: '/images/success-stories/presencelearning-logo.svg',
        alt: 'PresenceLearning',
        width: 154,
        height: 25,
      },
      link: '/success-stories/presencelearning',
    },
  },
  {
    type: 'testimonial',
    testimonial: {
      text: 'Thanks to Terminal, I was able to find a balance between my professional and personal life.',
      author: {
        image: '/images/testimonials/mayra-rodriguez.jpg',
        name: 'Mayra Rodriguez',
        job: 'Full Stack Engineer, Tile via Terminal',
      },
      video: '7LHF4hdFbnw',
    },
  },
  {
    type: 'case-study',
    caseStudy: {
      title:
        'A multi-market approach: How Armory uses Terminal to build engineering teams 3x faster',
      logo: {
        src: '/images/success-stories/armory.svg',
        alt: 'Armory',
        width: 150,
        height: 40,
      },
      link: '/success-stories/armory',
    },
  },
  {
    type: 'testimonial',
    testimonial: {
      text: 'In less than one year, I was able to move from Senior Developer to a Tech Lead position.',
      author: {
        image: '/images/testimonials/cesar-charria.jpg',
        name: 'Cesar Charria',
        job: 'Full-time Software Engineer, PresenceLearning via Terminal',
      },
      video: '15F-AIuE-xU',
    },
  },
];

export const siteUrl =
  publicRuntimeConfig.siteEnv === 'dev' ? 'https://www.dev.terminal.io' : 'https://www.terminal.io';
