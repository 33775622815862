import { getContext } from './SegmentContext';

export const trackGetStarted = ({
  type,
  context,
  link,
}: {
  type?: string;
  context: string;
  link?: string;
}) => {
  if (type && type !== 'businesses' && !link) {
    window &&
      (window as any).analytics.track(
        'Get Started Clicked',
        {
          context: context ?? null,
        },
        getContext(),
      );
  }
};
