import {
  Box,
  Flex,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import { Formik, Form, Field, FormikErrors, FormikTouched } from 'formik';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { PrimaryButton } from './Button';
import ChevronDown from '../Icons/ChevronDown';
import ColoredSquares from '../UI/ColoredSquares';

const OnboardingRolesFormSchema = {
  role: Yup.string().required('Role is required'),
  experience: Yup.string().required('Years of professional experience is required'),
};

export default function OnboardingRolesForm({
  onboardingRoles,
  onboardingYears,
  signUpUrl,
  variant,
}: {
  onboardingRoles: {
    icon: string;
    title: string;
    query: string;
  }[];
  onboardingYears: {
    title: string;
    value: string;
  }[];
  signUpUrl: string;
  variant?: string;
}) {
  const FormSchema = Yup.object().shape(OnboardingRolesFormSchema);
  const router = useRouter();
  const [roleValue, setRoleValue] = useState<string>('');
  const [expValue, setExpValue] = useState<string>('');

  const [utmParams, setUtmParams] = useState('');

  useEffect(() => {
    window.location.search && setUtmParams('&' + window.location.search.substring(1));
  }, []);
  return (
    <Box
      bgColor={{ lg: variant === 'hero' ? 'bg.secondary' : 'white' }}
      p={{
        lg: '3rem 2.5rem',
        xl: variant === 'hero' ? '2.75rem 2rem' : '4rem 2.5rem',
      }}
      border={{ lg: variant !== 'hero' && '0.0625rem solid #D5DADC' }}
    >
      <Formik
        initialValues={{
          role: '',
          experience: '',
        }}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          router.push(`${signUpUrl}?role=${values.role}&years=${values.experience}${utmParams}`);
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
        }: {
          errors: FormikErrors<{
            role: string;
            experience: string;
          }>;
          touched: FormikTouched<{
            role: string;
            experience: string;
          }>;
          isSubmitting: boolean;
        }) => (
          <Form>
            {variant === 'hero' && (
              <Box display={{ base: 'none', lg: 'block' }} mb="6" maxWidth="10">
                <ColoredSquares />
              </Box>
            )}
            <Box
              mt={{ base: '6', lg: '0' }}
              mb="4"
              fontWeight="bold"
              fontSize={{ base: 'md', lg: '2xl' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              What role are you looking for?
            </Box>
            <Menu flip={false} gutter={4} matchWidth>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={Button}
                    h="12"
                    rightIcon={
                      <ChevronDown
                        transform={{
                          base: isOpen ? 'rotate(180deg)' : 'rotate(0)',
                        }}
                        transition="transform .3s ease-in-out"
                        color="grey.900"
                      />
                    }
                    bgColor="white"
                    borderRadius="0.25rem"
                    borderWidth="0.0625rem"
                    boxShadow={errors.role && touched.role && '0 0 0 1px #e53e3e'}
                    borderColor={errors.role && touched.role ? 'red' : 'grey.400'}
                    color={!roleValue && 'grey.500'}
                    fontWeight="normal"
                    w="100%"
                    textAlign="left"
                    _hover={{ bg: 'grey.200' }}
                    _focus={{ bg: 'white' }}
                    _active={{ bg: 'grey.200' }}
                    _expanded={{ bg: 'white' }}
                  >
                    {onboardingRoles.filter((role) => role.query === roleValue)[0]?.title ??
                      'Select a role'}
                  </MenuButton>
                  <RadioGroup
                    as={MenuList}
                    onChange={setRoleValue}
                    value={roleValue}
                    boxShadow="0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
                    borderRadius="0.25rem"
                    minWidth="100%"
                    py="1rem"
                    px="1rem"
                  >
                    {onboardingRoles.map((role) => (
                      <MenuItem
                        as="div"
                        key={role.title}
                        p="0"
                        sx={{
                          '.chakra-radio__control': {
                            display: 'none',
                          },
                        }}
                      >
                        <Field
                          name="role"
                          as={Radio}
                          value={role.query}
                          w="100%"
                          p="0.625rem"
                          _hover={{
                            bgColor: 'grey.200',
                          }}
                        >
                          <Flex alignItems="center" w="100%">
                            <Box
                              w="6"
                              mr="0.5rem"
                              dangerouslySetInnerHTML={{
                                __html: role.icon,
                              }}
                            />
                            {role.title}
                          </Flex>
                        </Field>
                      </MenuItem>
                    ))}
                  </RadioGroup>
                </>
              )}
            </Menu>
            <FormControl isInvalid={errors.role && touched.role}>
              <FormErrorMessage mt="1">{errors.role}</FormErrorMessage>
            </FormControl>
            <Box
              my="4"
              fontWeight="bold"
              fontSize={{ base: 'md', lg: '2xl' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              How many years of professional experience do you have?
            </Box>
            <RadioGroup onChange={setExpValue} value={expValue}>
              <Flex justifyContent={{ base: 'space-between' }} flexWrap="wrap">
                {onboardingYears.map((option) => (
                  <Box
                    key={option.title}
                    h="3.75rem"
                    border="1px solid #C2C2C2"
                    borderColor={errors.experience && touched.experience ? 'red' : 'grey.400'}
                    borderRadius="0.25rem"
                    bgColor={expValue === option.value ? 'grey.200' : 'white'}
                    _hover={{
                      textDecoration: 'none',
                      bgColor: 'grey.200',
                    }}
                    fontWeight={expValue === option.value ? 'bold' : 'normal'}
                    w={{
                      base: 'calc(50% - 0.375rem)',
                      lg: 'calc(50% - 0.75rem)',
                    }}
                    mb={{ base: '3', lg: '6' }}
                    sx={{
                      '.chakra-radio__control': {
                        display: 'none',
                      },
                      '.chakra-radio__label': {
                        ml: '0',
                      },
                    }}
                  >
                    <Field
                      name="experience"
                      as={Radio}
                      value={option.value}
                      w="100%"
                      h="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {option.title}
                    </Field>
                  </Box>
                ))}
              </Flex>
            </RadioGroup>
            <FormControl isInvalid={errors.experience && touched.experience}>
              <FormErrorMessage mt={{ base: '-2', lg: '-5' }} mb="1">
                {errors.experience}
              </FormErrorMessage>
            </FormControl>
            <PrimaryButton
              w="100%"
              mt={{ base: '1', lg: '0' }}
              type="submit"
              disabled={isSubmitting}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {variant === 'hero' ? 'Unlock My Job Matches' : 'Next'}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
