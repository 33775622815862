import { storyblokEditable } from '@storyblok/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Text, Link } from '@terminal/design-system';
import Faq from '@components/LightLP/Faq';
import { FaqSectionStoryblok, Storyblok } from '../types';
import { RichText } from '../primitive';

export function FAQSection({ blok, page }: Storyblok<FaqSectionStoryblok>) {
  const title = blok.title?.replace('%STORYNAME%', blok.storyName);
  const footerMessage =
    blok.footerMessage?.replace('%STORYNAME%', blok.storyName).split(/\[([^\]]*)\]/) || [];

  const faqs = blok.faqs?.map((faq) => {
    return {
      q: faq.q,
      a:
        typeof faq.a === 'string' ? (
          faq.a
        ) : (
          <RichText
            blok={{
              ...blok,
              component: 'richText',
              content: faq.a,
            }}
            page={page}
            key={faq._uid}
          />
        ),
    };
  });

  if (!blok.faqs.length) return null;

  return (
    <Faq
      {...storyblokEditable(blok)}
      title={title}
      faq={faqs}
      {...(blok.showSidebar
        ? { showSidebar: true }
        : {
            parentBoxProps: {
              pt: { base: 4, lg: 0 },
              pb: { base: 12, lg: 20 },
            },
            customStyles: {
              heading: {
                textAlign: 'center',
              },
              questionHeading: {
                fontSize: '2xl',
              },
              content: {
                fontSize: 'lg',
                whiteSpace: 'break-spaces',
              },
            },
          })}
    >
      {footerMessage?.length === 3 ? (
        <Text variant="body" mt={2} mb={6}>
          {footerMessage[0]}
          <Link
            {...(blok.footerLink || {})}
            href={blok.footerLink?.url || '/faq'}
            color="blue.500"
            textDecor="underline"
            ml={1}
            display="inline-block"
            _hover={{ textDecoration: 'none' }}
          >
            {footerMessage[1]} <ExternalLinkIcon ml={1} w={4} h={4} />
          </Link>
          {footerMessage[2]}
        </Text>
      ) : null}
    </Faq>
  );
}
