import {
  Box,
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import type { BackgroundProps, StyleProps } from '@chakra-ui/react';
import type { HeadingProps } from '../Heading';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { Divider } from '../Divider';
import { Button } from '../Button';

export const Accordion = ({
  accordions,
  headingVariant = 'heading-3',
  arrowColor,
  ...styleProps
}: {
  accordions: {
    /**
     * Title for accordion
     */
    title?: string;
    /**
     * Text inside accordion
     */
    description?: string;
    /**
     * Component inside the accordion
     */
    children?: React.ReactNode;
    /**
     * Component outside the accordion
     */
    parent?: React.ReactNode;
  }[];
  arrowColor?: BackgroundProps['bgColor'];
  headingVariant?: HeadingProps['variant'];
} & StyleProps) => {
  return (
    <ChakraAccordion w="full" allowToggle allowMultiple>
      {accordions.map(({ title, description, children, parent }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <AccordionItem>
            <AccordionButton
              {...styleProps}
              _hover={{ backgroundColor: styleProps.backgroundColor }}
            >
              <Box flex="1" textAlign="left" w="100%">
                {title && <Heading variant={headingVariant}>{title}</Heading>}
                {parent && parent}
              </Box>
              <Button variant="ghost" w={8} h={8}>
                <AccordionIcon color={arrowColor} />
              </Button>
            </AccordionButton>

            <AccordionPanel pt={description ? 2 : 0}>
              {description && (
                <Text variant="body" color="text.secondary" mt={2}>
                  {description}
                </Text>
              )}
              {children && children}
            </AccordionPanel>
          </AccordionItem>
          {/* We want to show the divider in all accordions except the last one */}
          {index !== accordions.length - 1 && <Divider mt={8} mb={6} />}
        </div>
      ))}
    </ChakraAccordion>
  );
};
