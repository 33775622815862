import { getContext } from './SegmentContext';

export const trackCtaClicked = ({
  type,
  context,
  link,
  name,
}: {
  type?: string;
  context: string;
  link?: string;
  name?: string;
}) => {
  // @ts-ignore reason: analytics is a variable defined and added to Window by Segment in run time
  trackEvent('Cta Clicked', {
    context,
    type,
    link,
    name,
  });
};

export const trackEvent = (eventName: string, properties?: object, context?: object) => {
  if ((window as any).analytics) {
    (window as any).analytics.track(eventName, properties, {
      ...getContext(),
      ...context,
    });
  }
};
