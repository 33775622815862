import type { SpaceProps, BackgroundProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { designTokens } from '../../theme';

export const VerticalSteps = ({
  steps,
  timelineColor = 'accent.lightest',
  ...spacingProps
}: {
  steps: React.ReactNode[] | undefined;
  timelineColor?: BackgroundProps['backgroundColor'];
} & SpaceProps) => {
  const circleSize = '14px';

  return (
    <Box
      as="ul"
      position="relative"
      listStyleType="none"
      sx={{
        'li[role="listitem"]:first-child:before': {
          top: 1,
        },
        'li[role="listitem"]:last-child:before': {
          height: 10,
          top: -8,
        },
        'li[role="listitem"]:not(:last-child)': {
          paddingBottom: [4, 4, 6],
        },
        'li[role="listitem"]:before': {
          display: steps && steps?.length > 1 ? 'inherit' : 'none',
        },
      }}
      {...spacingProps}
    >
      {steps &&
        steps.map((step, index) => (
          <Box
            as="li"
            role="listitem"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position="relative"
            _before={{
              display: 'inline-block',
              content: '""',
              position: 'absolute',
              top: 0,
              left: `calc(-${circleSize} + ((${circleSize} / 2) - 1px) - ${designTokens.space[3]})`,
              height: '100%',
              borderLeft: '2px solid',
              borderColor: timelineColor,
            }}
            _after={{
              content: '""',
              display: 'inline-block',
              position: 'absolute',
              top: 1,
              left: `calc(-${circleSize} - ${designTokens.space[3]})`,
              width: circleSize,
              height: circleSize,
              border: `2px solid ${timelineColor}`,
              borderRadius: '50%',
              backgroundColor: timelineColor,
            }}
          >
            {step}
          </Box>
        ))}
    </Box>
  );
};
