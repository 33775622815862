import { Container, Box, Grid, GridItem, Heading, Text, Flex } from '@chakra-ui/react';
import ImageShimmer from '@components/ImageShimmer/ImageShimmer';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import type { HeroWithAnimatedTextStoryblok, Storyblok } from '../types';

export function HeroWithAnimatedText({ blok, page }: Storyblok<HeroWithAnimatedTextStoryblok>) {
  const {
    longText,
    smallText,
    withAnimation,
    mainTitle,
    heroTextAnimation,
    text,
    button,
    outlineButton,
    heroTextMinHeightBase,
    heroTextMinHeightSmall,
    heroTextMinHeightLarge,
    heroTextMinHeightXL,
    type,
    color,
    image,
    cta,
  } = blok;

  const hero = {
    color: color?.value || 'brand.main',
    longText,
    smallText,
    withAnimation,
    cta,
    title: {
      main: mainTitle,
      secondary: '',
    },
    text,
    button,
    outlineButton,
    heroTextAnimation,
    heroTextMinHeight: {
      base: `${heroTextMinHeightBase}px`,
      sm: `${heroTextMinHeightSmall}px`,
      lg: `${heroTextMinHeightLarge}px`,
      xl: `${heroTextMinHeightXL}px`,
    },
    type,
    image: {
      sourceUrl: image?.filename,
      altText: image?.alt,
      size: {
        width: image?.width,
        height: image?.height,
      },
    },
  };

  return (
    <Box py={{ base: 8, lg: 16 }} as="section" {...storyblokEditable(blok)}>
      <Container>
        <Grid
          gridTemplateColumns={{
            lg: '1fr 1fr',
            xl: hero.longText ? '6.1fr 3.9fr' : '1.05fr 1fr',
          }}
          gap={{ base: '10', xl: hero.smallText ? '24' : '9' }}
        >
          {hero.image?.sourceUrl && (
            <GridItem order={{ lg: 1 }}>
              <ImageShimmer
                src={hero.image.sourceUrl}
                alt={hero.image.altText}
                width={hero.image.size?.width ? hero.image.size?.width : 620}
                height={hero.image.size?.height ? hero.image.size?.height : 430}
                style={{ objectFit: 'contain' }}
                layout="intrinsic"
                priority={true}
              />
            </GridItem>
          )}

          <GridItem display={{ lg: 'flex' }} alignItems="center">
            <Box>
              <Heading
                as="h1"
                mb={4}
                fontSize={{
                  base: hero.smallText ? '3xl' : '2.25rem',
                  lg: hero.smallText ? '4xl' : '2.25rem',
                }}
                lineHeight="short"
              >
                <Box minH={hero.heroTextMinHeight}>
                  <Box as="span" dangerouslySetInnerHTML={{ __html: hero.title.main }} />
                  <Box
                    as="span"
                    dangerouslySetInnerHTML={{ __html: hero.heroTextAnimation }}
                    color={hero.color}
                  />
                </Box>
                {hero.title.secondary && <Box>{hero.title.secondary}</Box>}
              </Heading>
              <Text
                lineHeight="shorter"
                fontSize={hero.smallText ? 'md' : 'xl'}
                color="grey.600"
                maxW="30rem"
                dangerouslySetInnerHTML={{ __html: hero.text }}
              />
              {hero?.cta && (
                <>
                  <Flex flexDir={['column', 'row', 'row']} gap={4} mt={8}>
                    {(hero?.cta || [])?.map((eachCTA) => (
                      <StoryblokComponent blok={eachCTA} key={eachCTA._uid} page={page} />
                    ))}
                  </Flex>
                  {blok.hint && (
                    <Text lineHeight="base" fontSize="xs" mt={2}>
                      {blok.hint}
                    </Text>
                  )}
                </>
              )}
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
}
