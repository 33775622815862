import { Grid, GridItem, Flex } from '@chakra-ui/react';
import Image from 'next/image';

const Companies = ({
  companies,
  columns = 6,
}: {
  companies: {
    name: string;
    imageSrc: string;
    imageSize: {
      w: number;
      h: number;
    };
  }[];
  columns?: number;
}) => (
  <Grid
    mt={{ base: '7', lg: '9' }}
    templateColumns={{
      base: 'repeat(3, 1fr)',
      md: `repeat(${columns}, 1fr)`,
    }}
    gap={{ base: '2rem 1rem', lg: '20' }}
  >
    {companies.map((company) => (
      <GridItem key={company.name}>
        <Flex h="100%" alignItems="center" justifyContent="center">
          <Image
            src={company.imageSrc}
            alt={company.name}
            width={company.imageSize.w}
            height={company.imageSize.h}
          />
        </Flex>
      </GridItem>
    ))}
  </Grid>
);

export default Companies;
