import type { SelectAllJobsQuery } from './graphql/types/SelectAllJobs.query.generated';
import type { JobDetailDataProps } from './Job.types';
import { Employment_Type_Choices_Enum } from '../../../global/types/hasura-tables.generated.types';
import { uniqWith } from 'ramda';
import type { SelectAllJobsMarketingQuery } from '@_pages/engineering/job-openings/data';

const convertToEmploymentTypeCopy = (type?: Employment_Type_Choices_Enum | null) => {
  return (
    {
      [Employment_Type_Choices_Enum.Contract]: 'Contractor',
      [Employment_Type_Choices_Enum.FullTime]: 'Full-time',
      // This enum value is for Candidate preferences only, but in case we get this for a Job the fallback is FullTime
      [Employment_Type_Choices_Enum.FullTimeAndContract]: 'Full-time & Contractor',
    }[type as Employment_Type_Choices_Enum] || null
  );
};
export function serializeJobDetails({
  job,
}: // applicant_jobs,
// candidate_jobs,
{
  job: SelectAllJobsMarketingQuery['icims_job'][0];
  // applicant_jobs: SelectAllJobsQuery['applicant_job'];
  // candidate_jobs: SelectAllJobsQuery['candidate_job'];
}) {
  // Splits the string comma-separated skills into an array of skills
  // The regexp handles different comma-separated cases to be able to get rid of unnecessary spaces between skills, e.g. "a , b" or "a, b" or "a ,b"
  const techStacks = (job.tech_stack as string)?.split(/\s,\s|\s,|,\s|,/);
  const jobOrganization = job.icims_company?.organizations[0];

  return {
    id: job.profile_id,
    // TODO: this is the company name - CHANGE the key to name
    title: job.icims_company?.organizations[0]?.name || null,
    image: {
      src: job.icims_company?.organizations[0]?.logo || null,
      alt: job.icims_company?.organizations[0]?.name || null,
    },
    subtitle: job.jobtitle || null,
    skills: techStacks || [],
    // applied:
    //   applicant_jobs?.some(({ job: jobID }) => jobID === job.profile_id) ||
    //   candidate_jobs?.some(({ job: jobID }) => jobID === job.profile_id) ||
    //   false,
    jobDetail: {
      title: job.jobtitle || null,
      datePosted: job.created_at || null,
      logo: jobOrganization?.logo
        ? {
            src: jobOrganization?.logo,
            alt: job.jobtitle || '',
          }
        : null,
      role: job.family,
      techStacks: job.tech_stack?.split(',') || ([] as string[]),
      location: job.icims_job_locations.map((location) => location.icims_location?.value),
      descriptiveInfos: [
        {
          title: `About ${job.icims_company?.organizations[0]?.name}`,
          description: job.icims_company?.organizations[0]?.about,
        },
        {
          title: 'About The Role',
          description: job?.about,
        },
        {
          title: 'What You’ll Do',
          description: job?.what_youll_do,
        },
        {
          title: 'What You’ll Bring',
          description: job?.what_you_bring,
        },
      ].filter((info) => info.description) as JobDetailDataProps['descriptiveInfos'],
      tagInfos: [
        {
          title: 'Company Size',
          description: job.icims_company?.numofemps?.toString(),
        },
      ].filter((info) => info.description) as JobDetailDataProps['tagInfos'],
      requiredDegree: job?.degree_requirement || null,
      requiredYearsOfExperience: job?.years_experience || (null as string | null),
      // TODO: (TERM-579) Calculate these later
      // baseSalary: {
      //   fulltimeSalary:
      //     job?.min_salary || job?.max_salary
      //       ? {
      //           min: job?.min_salary,
      //           max: job?.max_salary,
      //         }
      //       : null,
      //   contractorSalary:
      //     job?.min_contract_rate || job?.max_contract_rate
      //       ? {
      //           min: job?.min_contract_rate,
      //           max: job?.max_contract_rate,
      //         }
      //       : null,
      // },
      companyName: job.icims_company?.organizations[0]?.name || '',
      organizationID: job.icims_company?.organizations[0]?.id || null,
      industry: jobOrganization?.salesforce_customer?.industry || null,
      employmentType: convertToEmploymentTypeCopy(job?.job?.employment_type) || null,
    },
  };
}

export const duplicatedTechStacks = [
  // mapTo should the same value as in the `skill` table
  {
    stacks: ['node', 'nodejs', 'node.js', 'node js'],
    mapTo: 'Node',
  },
  {
    stacks: ['js', 'javascript'],
    mapTo: 'JavaScript',
  },
  {
    stacks: ['postgres', 'postgresql'],
    mapTo: 'Postgres',
  },
  {
    stacks: ['graphql', 'graph ql'],
    mapTo: 'GraphQL',
  },
  {
    stacks: ['react', 'react js'],
    mapTo: 'React',
  },
  {
    stacks: ['express', 'express js'],
    mapTo: 'Express.js',
  },
  {
    stacks: ['cicd', 'ci/cd'],
    mapTo: 'CI/CD',
  },
];
// Tech stack + required skills
export const normalizedTechStackWithSkills = ({
  requiredSkills,
  techStack,
}: {
  requiredSkills: {
    skill1: SelectAllJobsQuery['icims_job'][0]['skillByRequiredSkill1'];
    skill2: SelectAllJobsQuery['icims_job'][0]['skillByRequiredSkill2'];
    skill3: SelectAllJobsQuery['icims_job'][0]['skillByRequiredSkill3'];
  };
  techStack: string | null;
}) =>
  uniqWith(
    (a, b) => a.toLowerCase() === b.toLowerCase(),
    [
      ...[
        requiredSkills.skill1?.name,
        requiredSkills.skill2?.name,
        requiredSkills.skill3?.name,
        // Empty skill returns 'N/A'
      ].filter((skill) => skill !== 'N/A' && skill),
      techStack?.split(','),
    ]
      .flat(Infinity)
      // Normalize data (e.g ['node.js', 'node'] => ['nodeJS'])
      .map((current: string) => {
        const skillDuplicateMap = duplicatedTechStacks.find(({ stacks }) => {
          return stacks.includes(current?.toLowerCase().trim());
        });
        return skillDuplicateMap ? skillDuplicateMap.mapTo : current?.trim();
      }),
  ).filter(Boolean);
