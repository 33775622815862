import getConfig from 'next/config';
import { Box, Heading, Text, Tag, Flex, UnorderedList, ListItem, GridItem } from '@chakra-ui/react';

import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import { TestimonialItem } from '@components/DemandLP/TestimonialsCarousel';
import { LocationIcon } from '../Icons';
import { PrimaryButton } from '@components/UI/Button';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;

export type ConfigType = {
  headingText: string;
  subHeadingText: string;
  name: string;
  meta?: {
    description: string;
  };
  faqWithSidebar?: boolean;
  faq?: {
    q: string;
    a: string;
  }[];
  candidates: {
    name: string;
    role?: string;
    experience: string;
    location: string;
    badges: string[];
    highlights: string[];
    skills: string[];
    status?: string;
  }[];
  testimonials?: TestimonialItem[];
};

export const CandidateCard = ({
  candidate,
  showCta = true,
}: {
  candidate: ConfigType['candidates'][number];
  showCta?: boolean;
}) => (
  <GridItem
    w="full"
    border="1px solid"
    borderColor="grey.300"
    bgColor="white"
    p={6}
    aria-label="Mock candidate information from Terminal Talent Hub app"
  >
    <Heading as="h3" fontSize="xl" mb={1}>
      {candidate.name}
    </Heading>
    {candidate.role && <Text fontSize="md">{candidate.role}</Text>}
    <Text fontSize="md" mb={1}>
      {candidate.experience}
    </Text>
    <Text fontSize="md" mb={4} display="flex" alignItems="center">
      <LocationIcon mr={2} w="10px" />
      {candidate.location}
    </Text>
    <Flex gridGap={2} flexWrap="wrap" mb={4}>
      {candidate.badges.map((el) => (
        <Tag
          size="md"
          borderRadius="full"
          variant="subtle"
          bg="green.100"
          color="green.800"
          fontWeight="bold"
          fontSize="lg"
          py={1}
          px={3}
          key={el}
        >
          {el}
        </Tag>
      ))}
    </Flex>
    <Box mb={4} p={3} bg="grey.100">
      <UnorderedList>
        {candidate.highlights.map((el) => (
          <ListItem key={el} fontSize="sm">
            {el}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
    <Flex gridGap={2} flexWrap="wrap" alignItems="center">
      {candidate.skills.map((el) => (
        <Tag
          key={el}
          size="sm"
          borderRadius="full"
          variant="subtle"
          colorScheme="gray"
          bg="grey.200"
          px={3}
          aria-label={`Candidate ${el} skill badge`}
        >
          {el}
        </Tag>
      ))}
      <Text fontSize="sm" aria-label="More skills badge">
        + more
      </Text>
    </Flex>
    {/* <Flex mb={4} alignItems="center" justifyContent="end">
      <ViewIcon w={3} mr={1} />
      <Text fontSize="xs">
        {candidate.status}
      </Text>
    </Flex> */}
    {showCta && (
      <>
        <Box h="1px" width="full" bg="grey.100" my={6} />
        <PrimaryButton
          href={talentHubOnboardingUrl}
          h={12}
          width="full"
          onClick={() =>
            trackCtaClicked({
              type: 'businesses',
              name: 'Hire Me',
              context: 'Candidate Cards',
              link: talentHubOnboardingUrl,
            })
          }
        >
          Hire Me
        </PrimaryButton>
      </>
    )}
  </GridItem>
);
