import { Container, Box, Grid, GridItem, Heading, Text, BoxProps } from '@chakra-ui/react';

import ImageShimmer from '../ImageShimmer/ImageShimmer';
import { PrimaryButton } from '../UI/Button';
import { trackCtaClicked } from '../../lib/segment/trackAnalytics';
import squaresLight from '../../public/images/ui/squares-light.svg';

interface HeroProps {
  hero: {
    longText: boolean;
    smallText: boolean;
    withPattern?: boolean;
    withAnimation?: boolean;
    title: {
      main: string;
      highlight?: string;
      color?: string;
      formatted?: string;
    };
    text: string;
    button?: {
      url: any;
      text: string;
      width?: string;
    };
    image?: {
      sourceUrl: string;
      altText: string;
      size?: {
        width: number;
        height: number;
      };
    };
  };
  children?: React.ReactNode;
  type?: string;
  parentBoxProps?: BoxProps;
}

export default function Hero({ hero, children, type, parentBoxProps = {} }: HeroProps) {
  return (
    <Box
      pt={{
        base: hero.withPattern ? 14 : 10,
        xl: hero.withPattern ? 24 : 20,
      }}
      pb={{
        base: hero.withPattern ? 14 : 20,
        xl: hero.withPattern ? 24 : 20,
      }}
      bgImage={{ lg: hero.withPattern && `url(${squaresLight.src})` }}
      bgRepeat="no-repeat"
      bgPosition={{
        lg: 'calc(50% + 350px) 0.5rem',
        xl: 'calc(50% + 350px) center',
      }}
      bgSize={{ lg: '41.875rem' }}
      as="section"
      {...parentBoxProps}
    >
      <Container position="relative" zIndex={1}>
        <Grid
          gridTemplateColumns={{
            lg: '1fr 1fr',
            xl: hero.longText ? '5.5fr 4.5fr' : '5fr 5.5fr',
          }}
          gap={{ base: '10', xl: hero.smallText ? '24' : '9' }}
        >
          {hero.image?.sourceUrl && (
            <GridItem order={{ lg: 1 }}>
              <ImageShimmer
                src={hero.image.sourceUrl}
                alt={hero.image.altText}
                width={hero.image.size?.width ? hero.image.size.width : 620}
                height={hero.image.size?.height ? hero.image.size.height : 430}
                style={{ objectFit: 'contain' }}
                layout="intrinsic"
                priority
              />
            </GridItem>
          )}
          {hero.withAnimation && <GridItem order={{ lg: 1 }}>{children}</GridItem>}
          <GridItem display={{ lg: 'flex' }} alignItems="center">
            <Box>
              <Heading
                as="h1"
                mb={{ base: 4, lg: hero.smallText ? 4 : 6 }}
                fontSize={{
                  base: hero.smallText ? '3xl' : '6xl',
                  xl: hero.smallText ? '4xl' : '7xl',
                }}
                lineHeight={{ base: 'none', xl: hero.smallText ? 'short' : 'none' }}
              >
                {hero.title.formatted && (
                  <Box as="span" dangerouslySetInnerHTML={{ __html: hero.title.formatted }} />
                )}
                {hero.title.main}{' '}
                {hero.title.highlight && (
                  <Box
                    display="inline-block"
                    as="span"
                    color={hero.title.color ? hero.title.color : 'green.500'}
                    position="relative"
                  >
                    {hero.title.highlight}
                  </Box>
                )}
              </Heading>
              {hero.text && (
                <Text
                  lineHeight="short"
                  fontSize={{ base: 'md', lg: hero.smallText ? 'md' : 'lg' }}
                >
                  {hero.text}
                </Text>
              )}
              {hero.button && Object.keys(hero.button).length !== 0 && (
                <PrimaryButton
                  href={hero.button.url}
                  w={{ base: '100%', md: 'auto', lg: hero.button.width }}
                  mt={{ base: 6, lg: hero.smallText ? 6 : 10 }}
                  px={{ lg: 5 }}
                  onClick={() =>
                    trackCtaClicked({
                      link: hero.button.url,
                      name: hero.button.text,
                      type,
                      context: 'Hero',
                    })
                  }
                >
                  {hero.button.text}
                </PrimaryButton>
              )}
            </Box>
          </GridItem>
        </Grid>
      </Container>
      {!hero.withAnimation && children}
    </Box>
  );
}
