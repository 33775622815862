import { Container, Heading, Text } from '@chakra-ui/react';
import VerticalTabs from './VerticalTabs';
import Carousel from './Carousel';

export default function TabsCarousel({
  tabsCarousel,
}: {
  tabsCarousel: {
    type: string;
    title: string;
    text?: string;
    tabs: {
      image: {
        sourceUrl: string;
      };
      altText: string;
      title: string;
      text: string;
    }[];
    defaultIndex?: number;
  };
}) {
  return (
    <Container mt={{ base: '14' }} mb={{ base: '14' }}>
      <Heading
        as="h2"
        fontSize={{ base: '2rem', lg: '5xl' }}
        lineHeight={{ base: '10', lg: '3.5rem' }}
        mb={{ base: !tabsCarousel.text && '8' }}
        maxW={{ lg: '30rem', xl: '36.25rem' }}
      >
        {tabsCarousel.title}
      </Heading>
      <VerticalTabs
        tabs={tabsCarousel.tabs}
        defaultIndex={tabsCarousel.defaultIndex}
        text={tabsCarousel.text}
        type={tabsCarousel.type}
        display={{ base: 'none', lg: 'block' }}
      />
      <Carousel
        carousel={tabsCarousel.tabs}
        defaultIndex={tabsCarousel.defaultIndex}
        text={tabsCarousel.text}
        display={{ lg: 'none' }}
      />
    </Container>
  );
}
