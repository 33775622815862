import { StoryblokComponent } from '@storyblok/react';
import type { EmptyPageStoryblok, Storyblok } from '../types';

export function EmptyPage({ blok, page }: Storyblok<EmptyPageStoryblok>) {
  return (
    <>
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} page={page} key={nestedBlok._uid} />
      ))}
    </>
  );
}
