/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState, useMemo, useEffect } from 'react';
import type { ReactNode, ChangeEvent, FocusEvent } from 'react';
import type { UseComboboxPropGetters } from 'downshift';
import { useCombobox, useMultipleSelection } from 'downshift';
import { LayoutProps, BorderProps, useBreakpointValue } from '@chakra-ui/react';
import { Spinner, useDisclosure, useStyleConfig, Box } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import { equals } from 'ramda';
import { Tag } from '../../Tag';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
} from '../../Modal';

import { List, ListItem } from '../../List';
import { Input } from '../Input';
import { Flex } from '../../layout';
import { Text } from '../../Text';
import { SelectIcon } from '../Select/Select';
import { CloseIcon } from '../../icons';

// TODO: [TAL-895] refactor - https://terminal.atlassian.net/browse/TAL-895
// TODO: [TAL-655] supports key/value structure - https://terminal.atlassian.net/browse/TAL-655

/**
 * Detects if the user is on desktop viewport the width of witch is greater than mobile and tablet viewport
 * @since On component mount it returns undefined, be cautious how you use this utility
 * @todo See if its possible to make this function return the correct return value on mount
 * @todo temporarily duplicate this function in order not to have to setup global for terminal.io
 * @returns boolean
 */
export function useIsViewPortDesktop(): boolean | undefined {
  // Had declare ssr: false because of flickering issue when app is not ssr
  // @see: https://chakra-ui.com/docs/hooks/use-breakpoint-value
  return useBreakpointValue({ base: false, md: true }, { ssr: false });
}

function determineListItemColors({ selectedItem, item, index, highlightedIndex }: any): {
  color?: string;
  bg?: string;
} {
  if (selectedItem === item) return { color: 'text.inverse', bg: 'accent.main' };

  return index === highlightedIndex ? { bg: 'bg.tertiary' } : {};
}

function TypeaheadList({
  name,
  inputOptions,
  loadingCopy,
  isLoading,
  noOptionsMatchedCopy,
  hasResult,
  highlightedIndex,
  getMenuProps,
  getItemProps,
  optionsClassName,
  selectedItem,
  customListItem,
  ...styleProps
}: {
  name: string;
  inputOptions: string[] | { value: string; label: string }[];
  loadingCopy: string;
  isLoading: boolean;
  noOptionsMatchedCopy: string;
  optionsClassName?: string;
  hasResult: boolean;
  highlightedIndex: number;
  getMenuProps: UseComboboxPropGetters<any>['getMenuProps'];
  getItemProps: UseComboboxPropGetters<any>['getItemProps'];
  selectedItem?: string | null;
  customListItem?: (item: any) => React.ReactNode;
} & LayoutProps &
  BorderProps) {
  return (
    <List variant="dropdown" {...getMenuProps()} {...styleProps}>
      {isLoading && (
        <ListItem _hover={{ bg: 'inherit', cursor: 'not-allowed' }}>
          <Flex>
            <Spinner color="text.link" mr={3} />
            <Text color="text.secondary">{loadingCopy}</Text>
          </Flex>
        </ListItem>
      )}
      {!isLoading &&
        inputOptions.map((item, index) => (
          <ListItem
            {...getItemProps({ item, index })}
            key={`input-${name}-${typeof item === 'string' ? item : item.value}`}
            variant="dropdown"
            className={optionsClassName}
            {...determineListItemColors({ index, highlightedIndex, selectedItem, item })}
          >
            {customListItem ? customListItem(item) : item}
          </ListItem>
        ))}

      {!isLoading && !inputOptions.length && (
        <ListItem
          zIndex="tooltip"
          _hover={{ bg: 'inherit', cursor: 'not-allowed' }}
          color="text.secondary"
        >
          {noOptionsMatchedCopy}
        </ListItem>
      )}
    </List>
  );
}

function TypeaheadInput({
  inputProps,
  showSelectIcon = false,
}: {
  inputProps: React.ComponentProps<typeof Input> & UseComboboxPropGetters<any>['getInputProps'];
  showSelectIcon?: boolean;
}) {
  return (
    <Flex
      direction="row"
      alignItems="baseline"
      width="100%"
      height="fit-content"
      position="relative"
    >
      <Input {...inputProps} />
      {showSelectIcon && (
        <SelectIcon
          width={6}
          height="100%"
          insetEnd={2}
          position="relative"
          color="currentColor"
          fontSize="1.25rem"
          _disabled={{
            opacity: 0.5,
          }}
          data-disabled={!!inputProps.isDisabled}
        />
      )}
    </Flex>
  );
}

type SharedTypeaheadProps = {
  containerProps?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  name: string;
  noOptionsMatchedCopy?: string;
  loadingCopy?: string;
  onBlur: (args: FocusEvent<HTMLInputElement>) => void;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectionChange: (field: string, value: string) => void;
  options: string[];
  /** It should allow items to be translated */
  shouldTranslate?: boolean;
  placeholder: string;
  renderBefore?: ({ getLabelProps }: any) => ReactNode;
  shouldOpenOnFocus?: boolean;
  showSelectIcon?: boolean;
  formInputValue?: string;
  isTypingAllowed?: boolean;
  /**
   * Props that will be utilize only when the options list is displayed in full screen.
   */
  fullScreen: {
    title: string;
    modalFinalFocusRef?: React.MutableRefObject<null>;
    renderBefore?: ({ getLabelProps }: any) => ReactNode;
  };
};

type TypeaheadProps = SharedTypeaheadProps & {
  /**
   * Do not use! Temporary prop for sole propose of using in storybook
   */
  defaultIsOpen?: boolean;
  initialValue?: string;
  shouldResetAfterSelect?: boolean;
  optionsToFilter?: string[];
};

type AsyncTypeaheadProps = SharedTypeaheadProps & {
  onInputValueChange: (values: string) => void;
  onInputValueChangeAfterSelection: (values: string) => void;
  inputValue: string;
  showNoResult?: boolean;
};

type ItemOption = { value: string; label: string };

export function AsyncTypeahead({
  containerProps,
  inputValue,
  isDisabled = false,
  isLoading = false,
  name,
  noOptionsMatchedCopy = 'This option is not available.',
  loadingCopy = 'Loading...',
  onBlur: onBlurProp,
  onSelectionChange,
  onInputValueChange,
  onInputValueChangeAfterSelection,
  options,
  shouldTranslate = false,
  placeholder,
  renderBefore,
  shouldOpenOnFocus = false,
  showNoResult = false,
  fullScreen,
  showSelectIcon = false,
  isTypingAllowed: isReadOnly,
}: AsyncTypeaheadProps) {
  const isDesktop = useIsViewPortDesktop();
  const { isOpen: isModalOpen, onClose: onModalClose, onOpen: onModalOpen } = useDisclosure();
  const initialRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    selectedItem,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    openMenu,
    closeMenu,
  } = useCombobox({
    items: options,
    inputValue,
    onInputValueChange: ({ inputValue: changedInputValue, type }) => {
      if (type === useCombobox.stateChangeTypes.InputChange) {
        onInputValueChange(changedInputValue || '');
      } else {
        onInputValueChangeAfterSelection(changedInputValue || '');
      }

      if (changedInputValue?.length === 0) {
        closeMenu();
      }
    },
    onSelectedItemChange: (changes: any) => {
      if (!isDesktop) onModalClose();
      onSelectionChange(name, changes.selectedItem || '');
    },
  });

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!isOpen) {
      onBlurProp(event);
    } else if (!shouldOpenOnFocus && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const inputProps = isDesktop
    ? {
        ...getInputProps({
          onFocus: () => {
            if (!isOpen && shouldOpenOnFocus) {
              openMenu();
            }
          },
          id: name,
          name,
          onBlur,
        }),
        isReadOnly,
      }
    : {
        value: getInputProps().value,
        onFocus: () => {
          onModalOpen();
        },
        id: name,
        name,
        onBlur,
        isDisabled,
        placeholder,
        isReadOnly,
      };

  return (
    <Flex {...containerProps}>
      {renderBefore && renderBefore({ getLabelProps })}
      <Flex {...getInputProps()} direction="column" flex="1 1 auto">
        <TypeaheadInput
          showSelectIcon={showSelectIcon}
          inputProps={{
            ...inputProps,
            isDisabled,
            placeholder,
            ref: inputRef,
            isReadOnly,
          }}
        />

        {isDesktop && (
          <Flex flex={1} position="relative">
            <List
              variant="dropdown"
              {...getMenuProps()}
              display={isOpen || showNoResult || isLoading ? undefined : 'none'}
            >
              {isLoading && (
                <ListItem _hover={{ bg: 'inherit', cursor: 'not-allowed' }}>
                  <Flex>
                    <Spinner color="text.link" mr={3} />
                    <Text color="text.secondary">{loadingCopy}</Text>
                  </Flex>
                </ListItem>
              )}
              {!isLoading &&
                options.map((item, index) => (
                  <ListItem
                    {...getItemProps({ item, index })}
                    key={`input-${name}-${item}`}
                    variant="dropdown"
                    className={cx(!shouldTranslate && 'notranslate')}
                    {...determineListItemColors({ index, highlightedIndex, selectedItem, item })}
                  >
                    {item}
                  </ListItem>
                ))}

              {!isLoading && showNoResult && (
                <ListItem
                  role="listitem"
                  _hover={{ bg: 'inherit', cursor: 'not-allowed' }}
                  color="text.secondary"
                >
                  {noOptionsMatchedCopy}
                </ListItem>
              )}
            </List>
          </Flex>
        )}
      </Flex>
      <Modal
        onClose={onModalClose}
        size="full"
        isOpen={isModalOpen}
        returnFocusOnClose={false}
        initialFocusRef={initialRef}
        motionPreset="scale"
        finalFocusRef={fullScreen.modalFinalFocusRef}
      >
        <ModalOverlay />
        <ModalContent h="100vh" height="fill-available">
          <ModalHeader>{fullScreen.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex">
            <Flex {...containerProps} flex="1">
              {fullScreen.renderBefore && fullScreen.renderBefore({ getLabelProps })}
              <Flex {...getInputProps()} direction="column" flex="1">
                <TypeaheadInput
                  showSelectIcon={showSelectIcon}
                  inputProps={{
                    ...getInputProps({
                      id: name,
                      name,
                      onBlur,
                    }),
                    isDisabled,
                    placeholder,
                    ref: initialRef,
                    isReadOnly,
                  }}
                />

                <Flex flex={1} position="relative" overflow="auto" mt={2}>
                  <TypeaheadList
                    border="none"
                    maxH="none"
                    display={isOpen || showNoResult || isLoading ? undefined : 'none'}
                    {...{
                      getItemProps,
                      getMenuProps,
                      hasResult: showNoResult,
                      highlightedIndex,
                      inputOptions: options,
                      isLoading,
                      loadingCopy,
                      name,
                      noOptionsMatchedCopy,
                      selectedItem,
                      optionsClassName: cx(!shouldTranslate && 'notranslate'),
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export function Typeahead({
  containerProps,
  defaultIsOpen = false,
  initialValue = '',
  isDisabled = false,
  isLoading = false,
  shouldResetAfterSelect = false,
  name,
  noOptionsMatchedCopy = 'This option is not available.',
  loadingCopy = 'Loading...',
  onBlur,
  onSelectionChange,
  onInputChange,
  options,
  optionsToFilter = [],
  shouldTranslate = false,
  placeholder,
  renderBefore,
  shouldOpenOnFocus = false,
  fullScreen,
  showSelectIcon = false,
  formInputValue,
  isTypingAllowed: isReadOnly,
}: TypeaheadProps) {
  const [inputOptions, setInputOption] = useState<string[]>(options);
  const isDesktop = useIsViewPortDesktop();
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure({
    defaultIsOpen,
  });
  const initialRef = useRef(null);

  const {
    selectedItem,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    openMenu,
    setInputValue,
    inputValue,
    reset,
    selectItem,
  } = useCombobox({
    items: inputOptions,
    initialInputValue: initialValue,
    defaultSelectedItem: initialValue || null,
    defaultIsOpen,
    onInputValueChange: ({ inputValue: currentInputValue }) => {
      setInputOption(
        options.filter(
          (item) =>
            optionsToFilter.every((optionToFilter) => optionToFilter !== item) &&
            item.toLowerCase().startsWith((currentInputValue || '').toLowerCase()),
        ),
      );
    },
    onIsOpenChange: (changes) => {
      if (changes.isOpen) {
        setInputOption(
          options.filter((item) =>
            optionsToFilter.every((optionToFilter) => optionToFilter !== item),
          ),
        );
      }
    },
    onSelectedItemChange: (changes) => {
      onSelectionChange(name, changes.selectedItem || '');
      if (shouldResetAfterSelect) {
        setInputValue('');
      }
    },
  });

  useEffect(() => {
    if (isReadOnly && formInputValue === inputValue) selectItem(inputValue);
    if (formInputValue === '' && inputValue === '') reset();
    if (typeof formInputValue === 'string' && formInputValue !== inputValue) {
      setInputValue(formInputValue);
    }
  }, [formInputValue, inputValue, setInputValue, reset, isReadOnly, selectItem]);

  return (
    <Flex {...containerProps}>
      {renderBefore && renderBefore({ getLabelProps })}
      <Flex {...getInputProps()} direction="column" flex="1 1 auto">
        <TypeaheadInput
          showSelectIcon={showSelectIcon}
          inputProps={{
            ...getInputProps({
              onFocus: () => {
                if (!isOpen && shouldOpenOnFocus) {
                  openMenu();
                  if (!isDesktop) onModalOpen();
                }
              },
              id: name,
              name,
              onBlur,
              onChange: onInputChange,
            }),
            isDisabled,
            placeholder,
            isReadOnly,
          }}
        />

        {isDesktop && (
          <Flex flex={1} position="relative">
            <TypeaheadList
              display={isOpen || isLoading ? undefined : 'none'}
              selectedItem={selectedItem}
              {...{
                getItemProps,
                getMenuProps,
                highlightedIndex,
                inputOptions,
                isLoading,
                loadingCopy,
                name,
                noOptionsMatchedCopy,
                hasResult: inputOptions.length > 0,
                optionsClassName: cx(!shouldTranslate && 'notranslate'),
              }}
            />
          </Flex>
        )}
      </Flex>
      <Modal
        onClose={onModalClose}
        size="full"
        isOpen={isModalOpen}
        returnFocusOnClose={false}
        initialFocusRef={initialRef}
        motionPreset="scale"
        finalFocusRef={fullScreen.modalFinalFocusRef}
      >
        <ModalOverlay />
        <ModalContent h="100vh" height="fill-available">
          <ModalHeader>{fullScreen.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex">
            <Flex {...containerProps} flexDir="column" flex="1">
              {fullScreen.renderBefore && fullScreen.renderBefore({ getLabelProps })}
              <Flex {...getInputProps()} direction="column" flex="1">
                <TypeaheadInput
                  showSelectIcon={showSelectIcon}
                  inputProps={{
                    ...getInputProps({
                      id: name,
                      name,
                    }),
                    isDisabled,
                    placeholder,
                    ref: initialRef,
                    isReadOnly,
                  }}
                />

                <Flex flex={1} position="relative" overflow="auto" mt={2}>
                  <TypeaheadList
                    border="none"
                    maxH="none"
                    {...{
                      getItemProps,
                      getMenuProps,
                      hasResult: inputOptions.length > 0,
                      highlightedIndex,
                      inputOptions,
                      isLoading,
                      loadingCopy,
                      name,
                      noOptionsMatchedCopy,
                      optionsClassName: cx(!shouldTranslate && 'notranslate'),
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export function MultiSelectTypeahead({
  shouldTranslate = false,
  shouldCreateOptions = false,
  containerProps,
  fullScreen,
  initialValue = '',
  isDisabled = false,
  isLoading = false,
  validateEntry,
  loadingCopy = 'Loading...',
  name,
  noOptionsMatchedCopy = 'This option is not available.',
  onBlur,
  options,
  initialSelectedOptions = [],
  placeholder,
  renderBefore,
  shouldOpenOnFocus = false,
  onSelectedItemsChange,
  formInputValue,
  showItemsValue,
  isInvalid,
  isTypingAllowed: isReadOnly,
  forceNewValues,
}: Omit<
  SharedTypeaheadProps,
  'options' | 'placeholder' | 'onSelectionChange' | 'formInputValue'
> & {
  /** Should allow the user to create new options */
  onSelectedItemsChange: (field: string, selectedItems: ItemOption[]) => void;
  shouldCreateOptions?: boolean;
  validateEntry?: (value: string, index: number) => boolean;
  options: ItemOption[];
  initialSelectedOptions?: ItemOption[];
  forceNewValues?: ItemOption[];
  initialValue?: string;
  placeholder?: string;
  formInputValue?: number;
  showItemsValue?: boolean;
  isInvalid?: boolean;
}) {
  const [externalInputValue, setExternalInputValue] = useState<string>(initialValue);
  const isDesktop = useIsViewPortDesktop();
  const { isOpen: isModalOpen, onClose: onModalClose, onOpen: onModalOpen } = useDisclosure();
  const initialRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { field: fieldStyles } = useStyleConfig('Input') as { field: Record<string, any> };

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setActiveIndex,
    setSelectedItems,
  } = useMultipleSelection<ItemOption>({
    initialSelectedItems: initialSelectedOptions,
    onSelectedItemsChange: (changes) => {
      onSelectedItemsChange(name, changes.selectedItems || []);
    },
  });

  const filteredOptions: ItemOption[] = useMemo(() => {
    return options?.filter((option) => {
      if (isLoading) return false;
      const isSelected = selectedItems.some((selectedItem) => selectedItem.value === option.value);
      if (!option.value || !option.label || isSelected) return false;
      return (
        option.value.toLowerCase().includes((externalInputValue || '').toLowerCase()) ||
        option.label.toLowerCase().includes((externalInputValue || '').toLowerCase())
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedItems, externalInputValue, selectedItems.length]);

  const {
    closeMenu,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    openMenu,
    reset,
    setInputValue,
    inputValue,
  } = useCombobox<ItemOption | null>({
    items: filteredOptions,
    initialInputValue: initialValue,
    onStateChange: ({ inputValue: newInputValue = '', type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          // eslint-disable-next-line no-case-declarations
          const values = newInputValue.split(',');
          // When the length is less than 2, no options has been completed.
          if (values.length < 2) break;

          values
            // Trim values to clear unexpected whitespaces
            .map((value) => value.trim())
            // Filter values to remove duplicated values at pasted items
            .filter((item, index, self) => self.indexOf(item.trim()) === index)
            // Check if value is selected to avoid duplicates
            .forEach((value: string) => {
              if (selectedItems.some((item) => item.value === value)) return;
              if (shouldCreateOptions && value)
                addSelectedItem(
                  filteredOptions.find((option) => option.value === value) || {
                    value,
                    label: value,
                  },
                );
            });
          setInputValue('');
          reset();
          closeMenu();
          break;
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
            reset();
            closeMenu();
            if (inputRef.current) inputRef.current.focus();
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (forceNewValues && !equals(selectedItems, forceNewValues)) {
      setSelectedItems(forceNewValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceNewValues]);

  useEffect(() => {
    setExternalInputValue(inputValue);
  }, [inputValue]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case ',':
      case 'Enter': {
        // When user types Enter or , should trigger select/create option
        const parsedValue = inputValue?.toLocaleLowerCase().trim();

        const selectedOption =
          (highlightedIndex >= 0 && filteredOptions[highlightedIndex]) ||
          filteredOptions.find(
            (option) =>
              option.value?.toLocaleLowerCase() === parsedValue ||
              option.label?.toLocaleLowerCase() === parsedValue,
          );

        const currentValue_isSelected = selectedItems.some(
          (item) =>
            item.value?.toLowerCase() === parsedValue || item.label?.toLowerCase() === parsedValue,
        );
        if (currentValue_isSelected) {
          setInputValue('');
          reset();
          closeMenu();
          return;
        }

        const newOption = shouldCreateOptions &&
          parsedValue && { label: inputValue.trim(), value: parsedValue };

        const option = selectedOption || newOption || undefined;
        if (option) addSelectedItem(option);
        closeMenu();
        reset();
        setInputValue('');
        if (inputRef.current) inputRef.current.focus();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (formInputValue === 0) {
      setSelectedItems([]);
      setInputValue('');
    }
    // ! Known issue: when does not have selected values, does not clear the input when trying to reset it
  }, [formInputValue, setSelectedItems, setInputValue]);

  const inputProps = isDesktop
    ? {
        ...getInputProps({
          ...getDropdownProps({
            ref: inputRef,
          }),
          onFocus: () => {
            if (!isOpen && shouldOpenOnFocus) {
              openMenu();
            }
          },
          value: getInputProps().value === '[object Object]' ? '' : getInputProps().value,
          id: name,
          name,
          onBlur,
        }),
        isReadOnly,
        isDisabled,
        placeholder,
        _focus: {
          boxShadow: 'none',
        },
        border: '0',
        height: 'auto',
        py: 1,
        my: 1,
      }
    : {
        ...getInputProps({
          ...getDropdownProps({
            ref: initialRef,
          }),
          id: name,
          name,
          onBlur,
          onFocus: () => {
            onModalOpen();
          },
        }),
        value: getInputProps().value === '[object Object]' ? '' : getInputProps().value,
        isDisabled,
        placeholder,
        _focus: {
          boxShadow: 'none',
        },
        border: '0',
        height: 'auto',
        py: 1,
        my: 1,
        isReadOnly,
      };

  return (
    <Flex {...containerProps} flexDirection="column">
      {renderBefore && renderBefore({ getLabelProps })}
      <Flex
        __css={{ ...fieldStyles, display: 'flex', flexWrap: 'wrap', height: 'auto' }}
        // eslint-disable-next-line @typescript-eslint/dot-notation
        _focusWithin={fieldStyles?.['_focus']}
        px={2}
        py={1}
        borderColor={isInvalid ? 'ui.error' : 'ui.secondary'}
      >
        {selectedItems.map((selectedItem, index) => {
          const hasErrors = validateEntry && !validateEntry(selectedItem.value, index);
          return (
            <Tag
              mr={1}
              mt={1}
              key={`selected-item-${selectedItem.value}`}
              colorScheme={hasErrors ? 'error' : 'disabled'}
              variant="filter"
              {...getSelectedItemProps({
                selectedItem,
                index,
                onFocus: () => setActiveIndex(index),
              })}
              sx={{
                '&:hover svg': { color: 'ui.primary' },
                userSelect: 'none',
              }}
            >
              {selectedItem.label || selectedItem.value}
              <Box
                as="hr"
                color="transparent"
                w="1px"
                h={5}
                ml={2}
                bg={hasErrors ? 'ui.primary' : 'ui.secondary'}
              />
              <CloseIcon
                onClick={() => {
                  if (inputRef.current) inputRef.current.focus();
                  removeSelectedItem(selectedItem);
                  closeMenu();
                }}
                ml={2}
                cursor="pointer"
                color={hasErrors ? 'ui.primary' : 'ui.darker.disabled'}
                w={2}
                h={2}
              />
            </Tag>
          );
        })}
        <Flex
          {...getInputProps()}
          display="inline-flex"
          direction="column"
          flex="1"
          w="full"
          minWidth="40%"
        >
          <TypeaheadInput
            inputProps={{
              ...inputProps,
              onKeyDown: (event: any) => {
                inputProps.onKeyDown(event);
                handleOnKeyDown(event);
              },
            }}
          />
        </Flex>
      </Flex>
      {isDesktop && (
        <Flex flex={1} position="relative">
          <TypeaheadList
            display={isOpen || isLoading ? undefined : 'none'}
            customListItem={(item) =>
              showItemsValue ? (
                <>
                  {item.label}
                  <Text variant="caption">{item.value}</Text>
                </>
              ) : (
                <>{item.label}</>
              )
            }
            {...{
              getItemProps,
              getMenuProps,
              hasResult: !!filteredOptions.length,
              highlightedIndex,
              inputOptions: filteredOptions,
              isLoading,
              loadingCopy,
              name,
              noOptionsMatchedCopy,
              selectedItem: selectedItems[0]?.value,
              optionsClassName: cx(!shouldTranslate && 'notranslate'),
            }}
          />
        </Flex>
      )}
      <Modal
        onClose={onModalClose}
        size="full"
        isOpen={isModalOpen}
        returnFocusOnClose={false}
        initialFocusRef={initialRef}
        motionPreset="scale"
        finalFocusRef={fullScreen.modalFinalFocusRef}
      >
        <ModalOverlay />
        <ModalContent h="100vh" height="fill-available">
          <ModalHeader>{fullScreen.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex">
            <Flex {...containerProps} flex="1" flexDirection="column">
              {fullScreen.renderBefore && fullScreen.renderBefore({ getLabelProps })}
              <Flex
                __css={{ ...fieldStyles, display: 'flex', flexWrap: 'wrap', height: 'auto' }}
                // eslint-disable-next-line @typescript-eslint/dot-notation
                _focusWithin={fieldStyles?.['_focus']}
                px={2}
                py={1}
              >
                {selectedItems.map((selectedItem, index) => {
                  const hasErrors = validateEntry && !validateEntry(selectedItem.value, index);
                  return (
                    <Tag
                      mr={1}
                      mt={1}
                      key={`selected-item-${selectedItem.value}`}
                      colorScheme={hasErrors ? 'error' : 'disabled'}
                      variant="filter"
                      {...getSelectedItemProps({
                        selectedItem,
                        index,
                        onFocus: () => setActiveIndex(index),
                      })}
                      sx={{
                        '&:hover svg': { color: 'ui.primary' },
                        userSelect: 'none',
                      }}
                    >
                      {selectedItem.label || selectedItem.value}
                      <Box
                        as="hr"
                        color="transparent"
                        w="1px"
                        h={5}
                        ml={2}
                        bg={hasErrors ? 'bg.primary' : 'ui.secondary'}
                      />
                      <CloseIcon
                        onClick={() => {
                          if (inputRef.current) inputRef.current.focus();
                          removeSelectedItem(selectedItem);
                          closeMenu();
                        }}
                        ml={2}
                        cursor="pointer"
                        color={hasErrors ? 'bg.primary' : 'ui.darker.disabled'}
                        w={2}
                        h={2}
                      />
                    </Tag>
                  );
                })}
                <Flex
                  {...getInputProps()}
                  display="inline-flex"
                  direction="column"
                  flex="1"
                  w="full"
                  minWidth="50%"
                >
                  <TypeaheadInput
                    inputProps={{
                      ...inputProps,
                      onKeyDown: (event: any) => {
                        inputProps.onKeyDown(event);
                        handleOnKeyDown(event);
                      },
                    }}
                  />
                </Flex>
              </Flex>
              <Flex flex={1} position="relative" overflow="auto" mt={2}>
                <TypeaheadList
                  maxH="none"
                  display={isModalOpen || inputValue || isLoading ? undefined : 'none'}
                  customListItem={(item) =>
                    showItemsValue ? (
                      <>
                        {item.label}
                        <Text variant="caption">{item.value}</Text>
                      </>
                    ) : (
                      <>{item.label}</>
                    )
                  }
                  {...{
                    getItemProps,
                    getMenuProps,
                    hasResult: !!filteredOptions.length,
                    highlightedIndex,
                    inputOptions: filteredOptions,
                    isLoading,
                    loadingCopy,
                    name,
                    noOptionsMatchedCopy,
                    selectedItem: selectedItems[0]?.value,
                    optionsClassName: cx(!shouldTranslate && 'notranslate'),
                  }}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
