import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Box } from '@chakra-ui/react';

export default function EngineersAnimationDesktop() {
  const [ref, inView] = useInView({
    rootMargin: '-40% 0%',
    triggerOnce: true,
  });
  const darkOrange = useAnimation();
  const boxWithBlueCircle = useAnimation();
  const blueCircle = useAnimation();
  const orangeBoxWithCircle = useAnimation();
  const blueBoxWithTriangle = useAnimation();
  const greenBoxWithBrackets = useAnimation();
  const greenBoxWithArrow = useAnimation();
  const dogTail = useAnimation();
  const leftFlower = useAnimation();
  const rightFlower = useAnimation();
  const middleFlower = useAnimation();
  const leg = useAnimation();

  if (inView) {
    darkOrange.start({
      y: 0,
      opacity: 1,
      transition: { duration: 1, delay: 0.5 },
    });
    boxWithBlueCircle.start({
      y: 0,
      opacity: 1,
      transition: { duration: 1, delay: 1 },
    });
    blueCircle.start({
      opacity: [0, 0, 1, 1, 1, 1],
      x: [0, 0, 25, 0, 25, 0],
      transition: { duration: 8 },
    });
    orangeBoxWithCircle.start({
      opacity: [1, 0, 1, 0, 1],
      transition: { duration: 6, delay: 1 },
    });
    blueBoxWithTriangle.start({
      opacity: [1, 0, 1, 0, 1],
      transition: { duration: 6, delay: 2 },
    });
    greenBoxWithBrackets.start({
      opacity: [1, 0, 1, 0, 1],
      transition: { duration: 6, delay: 4.5 },
    });
    greenBoxWithArrow.start({
      opacity: [1, 0, 1, 0, 1],
      transition: { duration: 6, delay: 6.5 },
    });
    dogTail.start({
      rotate: [-15, 5, -15, 5, -15, 5],
      transition: { duration: 8 },
    });
    leftFlower.start({
      rotate: [10, 0, 10, 0, 10, 0],
      transition: { duration: 10 },
    });
    middleFlower.start({
      rotate: [10, 0, 10, 0, 10, 0],
      transition: { duration: 10, delay: 0.5 },
    });
    rightFlower.start({
      rotate: [5, 0, 5, 0, 5, 0],
      transition: { duration: 10, delay: 0.7 },
    });
    leg.start({
      rotate: [1, 5, 1, 5, 1, 5, 1],
      transition: { duration: 10 },
    });
  }

  return (
    <Box ref={ref} pb={{ base: '1', lg: '0' }} mt={{ lg: '-6' }}>
      <svg viewBox="0 0 930 405" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref}>
        <title>Two engineers working remotely with Terminal</title>
        <g transform="translate(0, 30)">
          <motion.g animate={darkOrange} initial={{ y: -10, opacity: 0 }}>
            <path d="M142.7 63.6001H72.9004V130.6H142.7V63.6001Z" fill="#25393F" />
            <path d="M159.2 80H89.4004V147H159.2V80Z" fill="#F5AE2D" />
            <path
              d="M123.801 124.9C117.501 124.9 112.301 119.8 112.301 113.4C112.301 107 117.401 101.9 123.801 101.9C130.201 101.9 135.301 107 135.301 113.4C135.301 119.8 130.101 124.9 123.801 124.9ZM123.801 103.5C118.301 103.5 113.801 108 113.801 113.5C113.801 119 118.301 123.5 123.801 123.5C129.301 123.5 133.801 119 133.801 113.5C133.801 108 129.201 103.5 123.801 103.5Z"
              fill="white"
            />
          </motion.g>
        </g>
        <g transform="translate(550,150)">
          <path d="M336.001 138.6H269.301V206.8H336.001V138.6Z" fill="#90B667" />
          <path
            d="M287.96575,162.1845 C287.72075,161.9385 287.37675,161.9385 287.13075,162.1845 L286.14775,163.1185 C285.95075,163.3645 285.95075,163.7575 286.14775,163.9545 L295.04375,172.8505 L286.14775,181.7955 C285.95075,181.9915 285.95075,182.3845 286.14775,182.6305 L287.13075,183.5645 C287.37675,183.8105 287.72075,183.8105 287.96575,183.5645 L298.28775,173.2925 C298.48375,173.0465 298.48375,172.7025 298.28775,172.4565 L287.96575,162.1845 Z M317.40575,183.2695 L317.40575,181.8935 C317.40575,181.5985 317.11175,181.3035 316.81675,181.3035 L300.69575,181.3035 C300.35175,181.3035 300.10575,181.5985 300.10575,181.8935 L300.10575,183.2695 C300.10575,183.6135 300.35175,183.8595 300.69575,183.8595 L316.81675,183.8595 C317.11175,183.8595 317.40575,183.6135 317.40575,183.2695 Z"
            fill="#FFFFFF"
          />
        </g>
        <g transform="translate(150,0)">
          <motion.g
            animate={middleFlower}
            initial={{
              originY: 1,
            }}
          >
            <path
              d="M92.0013 269.8C89.9013 269.8 89.1013 269 88.8013 268.4C87.9013 266.2 91.7013 263.2 95.9013 261.4C100.401 259.5 105.201 260.7 105.401 260.8L106.301 261.1L105.801 261.9C105.701 262.1 103.201 266.3 98.7013 268.2C96.5013 269.1 94.3013 269.7 92.6013 269.8C92.4013 269.8 92.2013 269.8 92.0013 269.8ZM101.501 261.8C100.001 261.8 98.2013 262 96.5013 262.7C91.9013 264.6 89.9013 267 90.2013 267.7C90.3013 268 91.1013 268.3 92.6013 268.2C94.2013 268.1 96.2013 267.6 98.2013 266.7C101.101 265.5 103.001 263.2 104.001 261.9C103.301 261.9 102.501 261.8 101.501 261.8Z"
              fill="#90B667"
            />
            <path
              d="M95.5012 256.4C93.9012 256.4 93.2012 255.8 92.9012 255.3C91.7012 253.2 95.2012 249.8 99.3012 247.6C103.601 245.3 108.501 246 108.701 246L109.701 246.2L109.301 247.1C109.201 247.3 107.201 251.8 102.901 254.1C100.801 255.2 98.7012 256 97.0012 256.3C96.4012 256.3 95.9012 256.4 95.5012 256.4ZM100.001 248.8C95.6012 251.2 93.9012 253.8 94.2012 254.4C94.4012 254.7 95.2012 254.9 96.6012 254.7C98.2012 254.4 100.101 253.7 102.101 252.7C104.801 251.2 106.501 248.7 107.301 247.3C105.801 247.3 102.801 247.4 100.001 248.8Z"
              fill="#90B667"
            />
            <path
              d="M94.8001 243.2C94.2001 243.2 93.6001 243 93.2001 242.6C92.6001 242 92.2001 240.8 93.4001 238.5C94.2001 236.9 95.6001 235.1 97.3001 233.5C100.8 230.1 105.7 229.5 105.9 229.5L106.9 229.4L106.8 230.4C106.8 230.6 106 235.5 102.5 238.9C99.9001 241.3 96.9001 243.2 94.8001 243.2ZM105 231.2C103.5 231.5 100.5 232.4 98.3001 234.6C96.7001 236.1 95.5001 237.8 94.7001 239.2C94.0001 240.5 94.0001 241.3 94.2001 241.6C94.7001 242.1 97.7001 241.3 101.3 237.8C103.6 235.6 104.6 232.7 105 231.2Z"
              fill="#90B667"
            />
            <path
              d="M87.8 268.3C87.2 268.3 86.4 268.1 85.4 267.7C83.8 267 81.9 265.7 80.2 264.1C76.6 260.8 75.7 256 75.7 255.8L75.5 254.8L76.5 254.9C76.7 254.9 81.6 255.4 85.2 258.7C88.6 261.8 91.2 265.9 89.5 267.7C89.2 268 88.7 268.3 87.8 268.3ZM77.4 256.6C77.8 258.1 78.9 261 81.2 263.1C82.8 264.6 84.5 265.7 86 266.4C87.3 267 88.2 267 88.4 266.7C88.9 266.1 87.9 263.2 84.1 259.8C81.9 257.7 78.9 256.9 77.4 256.6Z"
              fill="#90B667"
            />
            <path
              d="M91.3016 256.1C90.8016 256.1 90.2016 256 89.4016 255.8C87.7016 255.4 85.6016 254.4 83.7016 253.1C79.6016 250.4 78.0016 245.8 77.9016 245.6L77.6016 244.7L78.6016 244.6C78.8016 244.6 83.7016 244.3 87.8016 247C89.8016 248.3 91.4016 249.8 92.5016 251.2C94.1016 253.3 93.9016 254.5 93.5016 255.2C93.2016 255.6 92.6016 256.1 91.3016 256.1ZM79.8016 246.1C80.5016 247.5 82.0016 250.2 84.6016 251.9C86.4016 253.1 88.3016 254 89.9016 254.4C91.3016 254.8 92.1016 254.6 92.3016 254.4C92.5016 254.1 92.3016 253.3 91.4016 252.2C90.4016 250.9 88.9016 249.5 87.0016 248.3C84.4016 246.5 81.4016 246.1 79.8016 246.1Z"
              fill="#90B667"
            />
            <path
              d="M91.3016 243.8C89.3016 243.8 86.3016 242.6 83.7016 240.8C79.6016 238.1 78.0016 233.5 77.9016 233.3L77.6016 232.4L78.6016 232.3C78.8016 232.3 83.7016 232 87.8016 234.7C89.8016 236 91.4016 237.5 92.5016 238.9C94.1016 241 93.9016 242.2 93.5016 242.9C93.1016 243.5 92.3016 243.8 91.3016 243.8ZM79.8016 233.8C80.5016 235.2 82.0016 237.9 84.6016 239.6C88.8016 242.4 91.9016 242.7 92.3016 242.1C92.5016 241.8 92.3016 241 91.4016 239.9C90.4016 238.6 88.9016 237.2 87.0016 236C84.4016 234.2 81.4016 233.8 79.8016 233.8Z"
              fill="#90B667"
            />
            <path
              d="M92.1004 233.2C91.4004 233.2 90.3004 232.8 89.1004 231.3C88.0004 229.9 86.9004 228 86.1004 225.7C84.4004 221.1 85.8004 216.4 85.9004 216.2L86.2004 215.3L87.0004 215.8C87.2004 215.9 91.3004 218.5 93.0004 223.1C93.8004 225.3 94.3004 227.5 94.3004 229.3C94.4004 231.9 93.5004 232.8 92.7004 233.1C92.5004 233.2 92.3004 233.2 92.1004 233.2ZM87.0004 217.7C86.7004 219.2 86.4004 222.3 87.4004 225.2C88.2004 227.3 89.2004 229.1 90.2004 230.3C91.1004 231.4 91.9004 231.8 92.2004 231.7C92.5004 231.6 92.8004 230.8 92.8004 229.4C92.8004 227.8 92.3004 225.8 91.5004 223.7C90.4004 220.7 88.2004 218.7 87.0004 217.7Z"
              fill="#90B667"
            />
            <path
              d="M82.501 315C82.301 314.4 77.0011 300.9 82.8011 282C84.3011 277 86.001 273 87.501 269.5C91.501 260.1 94.6011 252.6 91.9011 232.5L93.4011 232.3C96.2011 252.8 93.0011 260.5 88.9011 270.1C87.4011 273.8 85.8011 277.5 84.3011 282.5C78.7011 300.9 84.001 314.4 84.001 314.6L82.501 315Z"
              fill="#90B667"
            />
          </motion.g>
          <motion.g
            animate={leftFlower}
            initial={{
              originY: 1,
            }}
          >
            <path
              d="M78.1016 317.2C75.1016 310.3 73.8016 303.3 72.5016 296.6C70.2016 284.2 68.1016 273.5 55.6016 268.9C35.5016 261.6 18.7016 302.3 18.5016 302.7L17.1016 302.1C17.1016 302 21.5016 291.4 28.3016 282C37.5016 269.3 47.1016 264.3 56.1016 267.5C69.5016 272.4 71.6016 283.5 74.0016 296.3C75.2016 302.9 76.5016 309.8 79.5016 316.6L78.1016 317.2Z"
              fill="#90B667"
            />
            <path
              d="M31.7003 313.1L30.9003 312.6C30.7003 312.5 25.5003 309.3 23.2003 303.7C21.1003 298.4 20.4002 292.5 23.1002 291.4C25.8002 290.3 29.3003 295.1 31.4003 300.4C33.6003 306 32.0003 311.9 31.9003 312.2L31.7003 313.1ZM23.9003 292.7C23.8003 292.7 23.7003 292.7 23.7003 292.7C22.6003 293.1 22.4002 297.4 24.6002 303C26.1002 306.8 29.3003 309.5 30.8003 310.6C31.1003 308.7 31.6002 304.7 30.1002 300.8C27.9002 295.7 25.1003 292.7 23.9003 292.7Z"
              fill="#90B667"
            />
            <path
              d="M8.50076 299.9C4.70076 299.9 1.80078 298.8 1.70078 298.7L0.800781 298.4L1.40079 297.6C1.50079 297.4 5.10079 292.5 10.9008 290.7C16.3008 289.1 22.3008 288.8 23.1008 291.6C23.9008 294.4 18.9008 297.5 13.5008 299.2C11.7008 299.7 10.0008 299.9 8.50076 299.9ZM3.20078 297.6C5.00078 298.1 9.00076 298.9 13.0008 297.7C18.8008 295.9 22.0008 293.2 21.6008 292C21.3008 290.9 17.0008 290.3 11.3008 292.1C7.30078 293.3 4.40078 296.2 3.20078 297.6Z"
              fill="#90B667"
            />
            <path
              d="M20.3008 284.7C15.0008 284.7 10.8008 281.6 10.6008 281.5L9.80078 280.9L10.6008 280.3C10.8008 280.2 15.0008 276.8 20.4008 276.7C23.0008 276.7 25.4008 277 27.3008 277.6C30.0008 278.5 30.6008 279.7 30.6008 280.6C30.6008 283.2 25.6008 284.7 20.5008 284.8C20.4008 284.7 20.4008 284.7 20.3008 284.7ZM12.4008 280.8C13.9008 281.7 17.0008 283.2 20.5008 283.2C25.8008 283.1 29.1008 281.6 29.1008 280.6C29.1008 280.2 28.4008 279.6 26.8008 279C25.1008 278.4 22.8008 278.1 20.4008 278.2C16.9008 278.3 13.8008 279.9 12.4008 280.8Z"
              fill="#90B667"
            />
            <path
              d="M38.4017 299.1L37.6017 298.6C37.4017 298.5 32.7017 295.8 30.6017 290.9C29.6017 288.5 29.0017 286.1 28.8017 284.2C28.6017 281.4 29.5017 280.4 30.3017 280C31.1017 279.7 32.4017 279.7 34.3017 281.8C35.6017 283.3 36.9017 285.4 37.9017 287.8C40.0017 292.8 38.7017 298 38.6017 298.2L38.4017 299.1ZM31.0017 281.4C30.9017 281.4 30.9017 281.4 30.8017 281.4C30.4017 281.6 30.1017 282.4 30.2017 284.1C30.3017 285.9 30.9017 288.1 31.9017 290.3C33.3017 293.5 35.9017 295.7 37.3017 296.7C37.6017 295 37.8017 291.5 36.5017 288.3C35.6017 286.1 34.4017 284.1 33.2017 282.8C32.2017 281.8 31.5017 281.4 31.0017 281.4Z"
              fill="#90B667"
            />
            <path
              d="M46.7014 288.3L45.9014 287.8C45.7014 287.7 41.5014 285.3 39.5014 280.7C38.6014 278.5 38.0014 276.4 37.9014 274.6C37.7014 272 38.6014 271.1 39.3014 270.7C40.0014 270.4 41.3014 270.4 43.0014 272.4C44.2014 273.7 45.4014 275.6 46.3014 277.8C48.2014 282.3 47.0014 287.1 46.9014 287.3L46.7014 288.3ZM40.0014 272.1C39.9014 272.1 39.9014 272.1 39.9014 272.1C39.6014 272.2 39.3014 273 39.4014 274.5C39.5014 276.1 40.0014 278.1 40.9014 280.1C42.1014 282.9 44.4014 284.9 45.7014 285.8C45.9014 284.2 46.1014 281.2 44.9014 278.3C44.0014 276.3 43.0014 274.5 41.9014 273.3C41.0014 272.5 40.3014 272.1 40.0014 272.1Z"
              fill="#90B667"
            />
            <path
              d="M9.70042 323.7L9.40044 322.8C9.30044 322.6 7.60042 316.7 9.70042 311C10.7004 308.3 12.1004 305.8 13.5004 304.1C15.5004 301.7 17.0004 301.5 17.9004 301.9C20.6004 302.9 20.0004 308.8 18.1004 314.1C16.0004 319.8 10.8004 323 10.6004 323.2L9.70042 323.7ZM17.2004 303.2C16.7004 303.2 15.8004 303.7 14.7004 305C13.4004 306.6 12.1004 308.9 11.2004 311.5C9.70042 315.4 10.3004 319.4 10.7004 321.3C12.2004 320.2 15.3004 317.5 16.7004 313.6C18.8004 307.9 18.5004 303.7 17.4004 303.3C17.3004 303.2 17.2004 303.2 17.2004 303.2Z"
              fill="#90B667"
            />
            <path
              d="M34.5012 273.3C32.7012 273.3 30.6012 273.1 28.4012 272.6C22.7012 271.4 18.7012 267.6 18.5012 267.5L17.7012 266.7L18.8012 266.3C19.0012 266.2 24.2012 264.3 29.9012 265.4C32.7012 266 35.2012 266.8 37.0012 267.8C39.2012 268.9 40.2012 270.1 39.9012 271.3C39.6012 272.5 38.3012 273.1 35.9012 273.3C35.4012 273.3 35.0012 273.3 34.5012 273.3ZM20.6012 267.3C22.1012 268.5 25.0012 270.4 28.7012 271.2C31.3012 271.7 33.8012 271.9 35.8012 271.8C37.9012 271.6 38.5012 271.1 38.5012 271C38.5012 270.9 38.2012 270.1 36.4012 269.2C34.7012 268.3 32.3012 267.5 29.6012 267C25.9012 266.1 22.4012 266.8 20.6012 267.3Z"
              fill="#90B667"
            />
          </motion.g>
          <motion.g
            animate={rightFlower}
            initial={{
              originY: 1,
            }}
          >
            <path
              d="M96 317.3L96.2 316.3C96.2 316.1 97.2 311.2 100.9 307.8C102.7 306.2 104.6 304.9 106.3 304.1C108.8 303 109.9 303.5 110.5 304.1C111 304.7 111.4 305.9 110.1 308.2C109.2 309.8 107.7 311.6 105.9 313.2C102.2 316.6 97.2 317.1 97 317.1L96 317.3ZM108.8 305C108.4 305 107.8 305.1 106.9 305.5C105.4 306.2 103.6 307.4 101.9 308.9C99.5 311.1 98.4 314 97.9 315.5C99.5 315.2 102.5 314.3 104.9 312.1C106.6 310.6 107.9 308.9 108.8 307.5C109.6 306 109.6 305.3 109.4 305.1C109.3 305.1 109.1 305 108.8 305Z"
              fill="#90B667"
            />
            <path
              d="M103.4 332V330.9C103.4 330.7 103.6 325.5 106.9 321.2C109.9 317.2 114 313.9 116 315.3C116.6 315.8 117.2 316.9 116.3 319.6C115.6 321.4 114.4 323.6 112.9 325.6C109.7 329.9 104.7 331.5 104.5 331.6L103.4 332ZM114.8 316.4C113.8 316.4 111 318.2 108.1 322.1C106 324.8 105.3 328.1 105.1 329.8C106.7 329.1 109.6 327.5 111.6 324.8C113.1 322.9 114.2 320.9 114.8 319.2C115.4 317.6 115.3 316.8 115.1 316.6C115 316.5 114.9 316.4 114.8 316.4Z"
              fill="#90B667"
            />
            <path
              d="M109.001 345.2L108.901 344.1C108.901 343.9 108.601 338.7 111.501 334.1C114.201 329.8 117.901 326.1 120.001 327.4C120.701 327.8 121.401 328.9 120.601 331.7C120.101 333.6 119.101 335.8 117.701 338C114.901 342.6 110.101 344.6 109.901 344.7L109.001 345.2ZM119.001 328.7C118.101 328.7 115.401 330.8 112.801 334.9C111.001 337.8 110.601 341.1 110.501 342.8C112.001 342 114.801 340.1 116.601 337.2C117.901 335.1 118.801 333 119.301 331.3C119.701 329.6 119.501 328.8 119.301 328.7C119.201 328.7 119.101 328.7 119.001 328.7Z"
              fill="#90B667"
            />
            <path
              d="M126.901 359.5L126.101 358.7C125.901 358.5 122.301 354.9 121.301 349.6C120.801 347.1 120.701 344.6 121.001 342.7C121.401 339.9 122.401 339.1 123.201 339C125.601 338.6 127.701 343.4 128.601 348.3C129.601 353.6 127.401 358.3 127.301 358.5L126.901 359.5ZM123.501 340.4C123.201 340.5 122.701 341.1 122.501 342.8C122.301 344.6 122.401 346.9 122.801 349.3C123.401 352.7 125.301 355.4 126.401 356.7C126.901 355.1 127.801 351.9 127.201 348.5C126.201 343.4 124.301 340.4 123.501 340.4Z"
              fill="#90B667"
            />
            <path
              d="M121.1 308.5C120.8 308.5 120.5 308.5 120.3 308.5C115.2 308.2 109.8 306.8 109.9 304.3C110 301.8 115.6 300.9 120.6 301.2C126.2 301.5 130.5 304.7 130.7 304.8L131.6 305.5L130.6 306.1C130.5 306 126.3 308.5 121.1 308.5ZM118.9 302.5C114 302.5 111.5 303.7 111.4 304.3C111.4 305 114.4 306.7 120.3 307C123.9 307.2 127.1 306 128.7 305.2C127.2 304.3 124.1 302.8 120.5 302.6C120 302.5 119.4 302.5 118.9 302.5Z"
              fill="#90B667"
            />
            <path
              d="M127.602 321.8C126.502 321.8 125.202 321.7 124.002 321.4C119.302 320.2 114.802 317.8 115.402 315.5C115.602 314.7 116.402 313.7 119.102 313.5C121.002 313.4 123.302 313.6 125.802 314.2C130.802 315.4 134.202 319.3 134.302 319.5L135.002 320.3L134.002 320.7C133.802 320.7 131.102 321.8 127.602 321.8ZM120.202 314.9C119.902 314.9 119.602 314.9 119.302 314.9C117.702 315 117.002 315.5 116.902 315.7C116.702 316.4 119.202 318.5 124.402 319.8C127.602 320.6 130.702 319.9 132.402 319.5C131.202 318.4 128.702 316.3 125.502 315.5C123.602 315.1 121.702 314.9 120.202 314.9Z"
              fill="#90B667"
            />
            <path
              d="M134.001 336.2C132.101 336.2 129.601 335.9 127.201 334.8C122.801 332.8 118.801 329.6 119.801 327.4C120.101 326.7 121.101 325.9 123.801 326.2C125.701 326.4 127.901 327.1 130.201 328.1C134.901 330.2 137.501 334.6 137.601 334.8L138.101 335.7L137.101 335.9C137.101 336 135.801 336.2 134.001 336.2ZM122.801 327.7C121.801 327.7 121.301 327.9 121.201 328.1C120.901 328.8 123.001 331.3 127.901 333.5C130.901 334.9 134.101 334.8 135.801 334.6C134.801 333.3 132.701 330.8 129.701 329.5C127.601 328.5 125.401 327.9 123.701 327.7C123.401 327.7 123.101 327.7 122.801 327.7Z"
              fill="#90B667"
            />
            <path
              d="M122.501 340C117.101 316.6 109.101 300.3 101.101 296.4C99.0007 295.4 96.9007 295.3 95.0007 296.2C85.2007 300.8 87.5007 314.7 87.5007 314.8L86.0007 315.1C86.0007 314.9 83.6007 300 94.4007 294.9C96.7007 293.8 99.2007 293.9 101.801 295.1C110.401 299.2 118.501 315.5 124.001 339.7L122.501 340Z"
              fill="#90B667"
            />
          </motion.g>
          <path
            d="M99.5013 397.9H65.1013C65.1013 397.9 45.7013 389.9 55.5013 359C55.5013 359 66.0013 327 71.4013 314.3H93.2013C98.6013 327 109.101 359 109.101 359C118.901 389.9 99.5013 397.9 99.5013 397.9Z"
            fill="#F5AE2D"
          />
          <path
            d="M72.9008 388.4C72.0008 388.4 71.3008 388.2 70.9008 387.7C69.5008 386 70.3008 382.2 86.1008 365.8C100.401 351.1 100.201 348.7 100.101 348.3C98.4008 346.9 79.8008 357.3 52.7008 376.5L51.8008 375.3C51.9008 375.2 63.2008 367.2 74.8008 359.8C98.7008 344.4 100.401 346.4 101.201 347.3C102.401 348.7 101.501 352 87.2008 366.8C71.0008 383.5 71.7008 386.3 72.0008 386.7C73.9008 389 93.9008 378.3 111.401 367L112.201 368.3C104.401 373.4 80.6008 388.4 72.9008 388.4Z"
            fill="#FFDC81"
          />
          <path d="M98.9 326.8H66V328.3H98.9V326.8Z" fill="#FFDC81" />
          <path
            d="M99.7 398.6H64.9999L64.9 398.5C64.7 398.4 45 389.9 54.9 358.7C55 358.4 65.5 326.5 70.8 313.9L70.9999 313.4H93.7L93.9 313.9C99.2 326.5 109.7 358.3 109.8 358.7C119.7 389.9 100 398.5 99.8 398.5L99.7 398.6ZM65.3 397.1H99.4C101.2 396.3 117.6 388 108.4 359.2C108.3 358.9 98.1 327.9 92.7 315H71.9999C66.5999 327.8 56.4 358.9 56.3 359.2C47.1 388 63.5 396.3 65.3 397.1Z"
            fill="#25393F"
          />
          <path d="M233.8 312.5H145.4V398.1H233.8V312.5Z" fill="#6DA7BB" />
          <path
            d="M234.601 398.9H144.701V311.8H234.601V398.9ZM146.201 397.4H233.101V313.3H146.201V397.4Z"
            fill="#25393F"
          />
          <path
            d="M241.9 383.1C241.1 383.6 263.1 395.9 279.2 383L275.7 372.5L247.8 372L241.9 383.1Z"
            fill="#25383F"
          />
          <path
            d="M198.9 203.3V184.3C198.9 184.3 215 185.9 202.9 158.5L180.4 165.6L183.6 182.1L181 202.2L198.9 203.3Z"
            fill="white"
          />
          <path
            d="M199.701 204.1L180.301 203L183.001 182.2L179.701 165.2L203.401 157.6L203.701 158.2C208.701 169.5 209.601 177.4 206.401 181.8C204.401 184.6 201.201 185.1 199.701 185.1V204.1ZM182.001 201.6L198.201 202.5V183.5L199.001 183.6C199.001 183.6 203.001 183.9 205.101 180.9C207.001 178.3 208.101 172.4 202.501 159.5L181.301 166.2L184.401 182.1L182.001 201.6Z"
            fill="#25393F"
          />
          <path
            d="M191.601 169C191.601 169 189.401 164.8 186.801 167.6C185.701 168.8 185.601 172 189.701 173.4C189.701 173.4 190.401 180.7 183.001 181.4C183.001 181.4 173.701 172.1 176.901 161.5C176.901 161.5 164.201 164.7 162.101 153.1C161.501 149.6 163.701 143 171.301 142.4C175.101 142.1 182.001 143.9 182.101 153C182.101 153 198.001 144 205.201 160.5C205.201 160.7 199.501 168.7 191.601 169Z"
            fill="#25383F"
          />
          <path
            d="M151.602 279.2C151.602 279.2 151.602 291.6 194.902 282.7C194.902 282.7 207.702 279.4 210.502 265.6L158.602 272L151.602 279.2Z"
            fill="#F5AE2D"
          />
          <path
            d="M264.501 372.7C264.501 372.7 268.401 387.1 277.501 388.7C289.001 390.7 292.201 396.8 292.501 398.2H247.701L250.801 373.2L264.501 372.7Z"
            fill="white"
          />
          <path
            d="M293.5 398.9H246.9L250.2 372.5L265 372L265.2 372.6C265.2 372.7 269.1 386.6 277.6 388C289.1 390 292.8 396.1 293.2 398.1L293.5 398.9ZM248.6 397.4H291.5C290.4 395.3 286.7 391 277.4 389.4C268.8 387.9 264.8 376.2 263.9 373.5L251.5 374L248.6 397.4Z"
            fill="#25393F"
          />
          <path
            d="M242.901 381.3L243.001 379L246.901 303.5C238.001 305.5 195.701 314.7 174.501 310.8C169.001 309.8 164.701 307 161.601 302.5C153.201 290.2 157.001 269.4 157.101 268.5L157.301 267.5L282.801 268L278.501 381.2C278.501 381.2 270.501 376.5 261.501 376C253.001 375.5 244.901 380.1 244.801 380.2L242.901 381.3Z"
            fill="#DDE6E9"
          />
          <path
            d="M242.102 382.7L246.102 304.6C235.802 306.9 195.102 315.5 174.402 311.7C168.702 310.6 164.202 307.7 161.002 303C152.402 290.4 156.302 269.3 156.402 268.5L156.702 266.9L283.602 267.5L279.202 382.5L278.102 381.9C278.002 381.9 270.201 377.3 261.502 376.8C253.302 376.3 245.301 380.9 245.301 380.9L242.102 382.7ZM247.702 302.6L243.702 380L244.502 379.5C244.602 379.5 252.902 374.7 261.602 375.2C268.802 375.6 275.301 378.6 277.801 379.9L282.102 268.7L158.002 268.2L157.902 268.6C157.702 269.5 154.002 289.9 162.202 302C165.202 306.3 169.302 309 174.602 310C195.702 314 238.302 304.6 246.702 302.7L247.702 302.6Z"
            fill="#25393F"
          />
          <path
            d="M182.3 190.2C182.3 190.2 167.7 194.9 160.1 198.1C160.1 198.1 152.8 201.3 150.7 216.2C150.7 216.2 141.5 256.4 154.5 261.8L151.5 279.2C151.5 279.2 163.9 272.2 180.4 271.2C188 270.7 211.1 269.5 211.1 269.5L207.9 230.3L226.5 250L233.5 234.6L214.8 204.1C213.2 201.4 210.9 199.2 208.3 197.5L198.7 191.4C198.8 191.4 192.3 199.8 182.3 190.2Z"
            fill="#25383F"
          />
          <path
            d="M184.6 264.8C172.4 264.8 160.3 264.3 154.4 262.6C154.4 262.6 143.8 260.4 145.9 243.7C146 243.3 146.3 243 146.7 243C147.1 243.1 147.4 243.4 147.4 243.8C145.5 259 154.4 261 154.8 261.1C167.9 265 213.8 262.6 218.9 262.3L221.5 256.2L168.2 241.4L168.6 228.7C168.6 228.3 169 228 169.4 228C169.8 228 170.1 228.4 170.1 228.8L169.7 240.3L223.5 255.2L219.8 263.7H219.3C218.3 263.8 201.3 264.8 184.6 264.8Z"
            fill="white"
          />
          <path
            d="M209.701 251.5C209.301 251.5 209.001 251.2 209.001 250.8L207.201 222.9C207.201 222.5 207.501 222.1 207.901 222.1C208.301 222 208.701 222.4 208.701 222.8L210.501 250.7C210.501 251.1 210.201 251.5 209.701 251.5C209.801 251.5 209.701 251.5 209.701 251.5Z"
            fill="#DDE6E9"
          />
          <motion.g animate={leg} initial={{ originY: 0, originX: 0 }}>
            <path
              d="M306.9 353.7C306.9 353.7 315.6 356.8 326.4 349.6C330.3 347 334.9 340.5 334.4 334.6L324.3 329.8L304.9 342.2L306.9 353.7Z"
              fill="#25383F"
            />
            <path
              d="M324.699 332.9C324.699 332.9 335.299 342.9 345.299 339C356.099 334.7 362.099 337.7 363.099 338.7L325.599 363.1L312.199 338.7L324.699 332.9Z"
              fill="white"
            />
            <path
              d="M325.2 364.2L311 338.4L324.8 332L325.2 332.3C325.3 332.4 335.6 341.9 345 338.2C356 333.9 362.3 336.8 363.6 338.1L364.2 338.8L325.2 364.2ZM313.1 339.1L325.7 362.1L361.6 338.7C359.5 337.6 354.1 336.3 345.5 339.7C336.2 343.4 326.6 335.6 324.5 333.8L313.1 339.1Z"
              fill="#25393F"
            />
            <path
              d="M285.002 255.7L332.402 333.3C332.402 333.3 327.502 332.4 318.802 338C311.802 342.5 306.902 351.9 306.902 351.9C306.902 351.9 265.902 294.8 251.002 268.8L210.002 268.9L268.902 249.8C275.002 247.7 281.602 250.2 285.002 255.7Z"
              fill="#DDE6E9"
            />
            <path
              d="M307.001 353.3L306.301 352.3C305.901 351.7 265.501 295.5 250.501 269.5L205.201 269.6L268.601 249C275.001 246.9 282.001 249.5 285.601 255.3L333.901 334.3L332.201 334C332.201 334 327.501 333.2 319.201 338.6C312.501 342.9 307.601 352.2 307.501 352.3L307.001 353.3ZM251.401 268L251.601 268.4C265.301 292.3 301.401 342.9 306.801 350.5C308.301 347.9 312.701 341 318.401 337.3C324.601 333.3 328.901 332.6 331.001 332.5L284.301 256.1C281.101 250.9 274.901 248.6 269.101 250.5L214.701 268.1L251.401 268Z"
              fill="#25393F"
            />
          </motion.g>
          <path d="M278.501 231.1H219.201L201.301 268.9H260.701L278.501 231.1Z" fill="white" />
          <path
            d="M261.201 269.7H200.201L218.701 230.4H279.701L261.201 269.7ZM202.501 268.2H260.201L277.301 231.9H219.601L202.501 268.2Z"
            fill="#25393F"
          />
          <path
            d="M246.457 251.811C247.36 249.675 246.773 247.385 245.146 246.696C243.518 246.008 241.466 247.181 240.562 249.318C239.658 251.454 240.245 253.744 241.873 254.433C243.501 255.121 245.553 253.948 246.457 251.811Z"
            fill="#25383F"
          />
        </g>
        <g transform="translate(-200,250)">
          <motion.g animate={boxWithBlueCircle} initial={{ y: -10, opacity: 0 }}>
            <path d="M279.3 32.6001H204.6V107.3H279.3V32.6001Z" fill="white" />
            <path
              d="M279.3 108H274.3V106.5H279.3V108ZM269.3 108H264.3V106.5H269.3V108ZM259.3 108H254.3V106.5H259.3V108ZM249.3 108H244.3V106.5H249.3V108ZM239.3 108H234.3V106.5H239.3V108ZM229.3 108H224.3V106.5H229.3V108ZM219.3 108H214.3V106.5H219.3V108ZM209.3 108H203.9V106.9H204.7V106.5H209.4V108H209.3ZM280 103.6H278.5V98.6001H280V103.6ZM205.4 101.9H203.9V96.9H205.4V101.9ZM280 93.6001H278.5V88.6001H280V93.6001ZM205.4 91.9H203.9V86.9H205.4V91.9ZM280 83.6001H278.5V78.6001H280V83.6001ZM205.4 81.9H203.9V76.9H205.4V81.9ZM280 73.6001H278.5V68.6001H280V73.6001ZM205.4 71.9H203.9V66.9H205.4V71.9ZM280 63.6001H278.5V58.6001H280V63.6001ZM205.4 61.9H203.9V56.9H205.4V61.9ZM280 53.6001H278.5V48.6001H280V53.6001ZM205.4 51.9H203.9V46.9H205.4V51.9ZM280 43.6001H278.5V38.6001H280V43.6001ZM205.4 41.9H203.9V36.9H205.4V41.9ZM280 33.6001H278.5V33.3H275.3V31.8H280V33.6001ZM270.3 33.3H265.3V31.8H270.3V33.3ZM260.3 33.3H255.3V31.8H260.3V33.3ZM250.3 33.3H245.3V31.8H250.3V33.3ZM240.3 33.3H235.3V31.8H240.3V33.3ZM230.3 33.3H225.3V31.8H230.3V33.3ZM220.3 33.3H215.3V31.8H220.3V33.3ZM210.3 33.3H205.3V31.8H210.3V33.3Z"
              fill="#25393F"
            />
            <motion.path
              animate={blueCircle}
              initial={{ opacity: 0 }}
              d="M232.501 30.4C238.41 30.4 243.201 25.6094 243.201 19.7C243.201 13.7906 238.41 9 232.501 9C226.591 9 221.801 13.7906 221.801 19.7C221.801 25.6094 226.591 30.4 232.501 30.4Z"
              fill="#6DA8BB"
            />
          </motion.g>
        </g>
        <g transform="translate(390,0)">
          <motion.g animate={greenBoxWithArrow}>
            <path d="M537.802 63.7H487.102V112.4H537.802V63.7Z" fill="#90B667" />
            <path
              d="M500.556,80.1458 C500.361,79.9514 500.089,79.9514 499.895,80.1458 L499.117,80.8845 C498.961,81.0789 498.961,81.39 499.117,81.5455 L506.155,88.5831 L499.117,95.6595 C498.961,95.8151 498.961,96.1261 499.117,96.3205 L499.895,97.0593 C500.089,97.2537 500.361,97.2537 500.556,97.0593 L508.721,88.933 C508.876,88.7386 508.876,88.4664 508.721,88.272 L500.556,80.1458 Z M523.846,96.826 L523.846,95.7373 C523.846,95.504 523.612,95.2707 523.379,95.2707 L510.626,95.2707 C510.354,95.2707 510.159,95.504 510.159,95.7373 L510.159,96.826 C510.159,97.0982 510.354,97.2926 510.626,97.2926 L523.379,97.2926 C523.612,97.2926 523.846,97.0982 523.846,96.826 Z"
              fill="#FFFFFF"
            />
          </motion.g>
          <motion.g animate={orangeBoxWithCircle}>
            <path d="M464.1 63.7H413.4V112.4H464.1V63.7Z" fill="#F5AE2D" />
            <path
              d="M438.501 99.6C432.601 99.6 427.701 94.8 427.701 88.8C427.701 82.9 432.501 78 438.501 78C444.401 78 449.301 82.8 449.301 88.8C449.301 94.8 444.501 99.6 438.501 99.6ZM438.501 79.6C433.401 79.6 429.201 83.8 429.201 88.9C429.201 94 433.401 98.2 438.501 98.2C443.601 98.2 447.801 94 447.801 88.9C447.801 83.7 443.701 79.6 438.501 79.6Z"
              fill="white"
            />
          </motion.g>
          <motion.g animate={blueBoxWithTriangle}>
            <path d="M537.802 136.9H487.102V185.6H537.802V136.9Z" fill="#6DA7BB" />
            <path
              d="M524.5 172.1H501.9L513.2 152.2L524.5 172.1ZM504.5 170.6H522L513.3 155.2L504.5 170.6Z"
              fill="white"
            />
          </motion.g>
          <motion.g animate={greenBoxWithBrackets}>
            <path d="M464.1 136.9H413.4V185.6H464.1V136.9Z" fill="#90B667" />
            <path
              d="M433.565,150 L429.117,150 C427.55,150 426.286,151.314 426.286,152.831 L426.286,156.773 C426.286,157.228 425.983,157.885 425.679,158.188 L423.405,160.463 C423.203,160.665 423,161.07 423,161.322 C423,161.626 423.203,162.03 423.405,162.182 L425.679,164.507 C425.983,164.81 426.235,165.467 426.286,165.922 L426.286,169.814 C426.286,171.381 427.55,172.645 429.117,172.645 L433.565,172.645 C433.969,172.645 434.373,172.291 434.373,171.836 L434.373,171.027 C434.373,170.623 433.969,170.218 433.565,170.218 L429.117,170.218 C428.864,170.218 428.712,170.067 428.712,169.814 L428.712,165.922 C428.662,164.911 428.106,163.496 427.398,162.788 L425.932,161.322 L427.398,159.907 C428.106,159.199 428.662,157.784 428.712,156.773 L428.712,152.831 C428.712,152.628 428.864,152.426 429.117,152.426 L433.565,152.426 C433.969,152.426 434.373,152.072 434.373,151.617 L434.373,150.809 C434.373,150.404 433.969,150 433.565,150 Z M451.812,160.463 L449.487,158.188 C449.183,157.885 448.931,157.228 448.931,156.773 L448.931,152.831 C448.931,151.314 447.616,150 446.1,150 L441.652,150 C441.197,150 440.843,150.404 440.843,150.809 L440.843,151.617 C440.843,152.072 441.197,152.426 441.652,152.426 L446.1,152.426 C446.302,152.426 446.504,152.628 446.504,152.831 L446.504,156.773 C446.504,157.784 447.06,159.199 447.768,159.907 L449.234,161.322 L447.768,162.788 C447.06,163.496 446.504,164.911 446.504,165.922 L446.504,169.814 C446.504,170.067 446.302,170.218 446.1,170.218 L441.652,170.218 C441.197,170.218 440.843,170.623 440.843,171.027 L440.843,171.836 C440.843,172.291 441.197,172.645 441.652,172.645 L446.1,172.645 C447.616,172.645 448.931,171.381 448.931,169.814 L448.931,165.922 C448.931,165.467 449.183,164.81 449.487,164.507 L451.812,162.182 C451.963,162.03 452.166,161.626 452.166,161.322 C452.166,161.07 451.963,160.665 451.812,160.463 Z"
              fill="#FFFFFF"
            />
          </motion.g>
        </g>
        <g transform="translate(120,0)">
          <motion.path
            d="M612.251917,375.294097 C614.343242,363.263383 612.270905,360.844199 606.034904,368.036548 C602.56635,372.734342 603.531985,379.016572 608.93181,386.883239 C617.031547,398.683239 609.114928,393.340169 612.251917,375.294097 Z"
            fill="#F6AE2D"
            stroke="#25383F"
            animate={dogTail}
            initial={{
              originY: 1,
            }}
          />
          <path
            d="M675.401 375.9C657.801 359.6 624.201 361.4 610.301 377.4C591.201 399.4 603.801 395.2 579.701 385.9C579.701 385.9 588.501 398.1 609.301 398.1C630.101 398.1 639.801 398.1 639.801 398.1H693.001"
            fill="#F5AE2D"
          />
          <path
            d="M604.9 397.9C604.9 398.3 591.4 397.9 591.4 397.9H659.1L657.9 395C650.1 389.1 636.4 390.2 636.4 390.2C636.4 381.4 627.7 374.5 621.4 375.2"
            fill="#F5AE2D"
          />
          <path
            d="M600.901 398.8C598.901 398.8 595.801 398.8 591.301 398.6V397.1H657.901L657.201 395.4C649.701 389.9 636.501 390.9 636.401 390.9L635.601 391V390.2C635.601 386.3 633.801 382.4 630.601 379.6C627.801 377.1 624.301 375.7 621.501 376L621.301 374.5C624.601 374.2 628.401 375.7 631.601 378.5C634.901 381.5 636.901 385.4 637.101 389.5C640.201 389.4 651.301 389.2 658.301 394.5L658.501 394.6L660.201 398.8H604.901C604.401 398.8 603.401 398.8 600.901 398.8Z"
            fill="#FFDC81"
          />
          <path
            d="M677.402 381.3C675.302 399 682.602 397.6 682.602 397.6H692.802C696.402 398.4 685.502 367.1 666.502 369.8C659.902 370.7 658.102 374.6 656.502 376.9C655.902 377.8 651.902 382.6 651.002 383.3C650.402 383.7 642.602 387.1 642.602 389C642.602 393.5 645.902 398.3 651.502 398.2H670.102"
            fill="#F5AE2D"
          />
          <path
            d="M678.401 381.3C676.301 399 683.601 397.6 683.601 397.6H693.801C697.401 398.4 686.501 367.1 667.501 369.8C660.901 370.7 659.101 374.6 657.501 376.9C656.901 377.8 654.001 382.6 653.101 383.3C652.501 383.7 644.701 387.1 644.701 389C644.701 393.5 649.301 398.3 654.801 398.2H671.101"
            fill="#FFDC81"
          />
          <path
            d="M657.901 389.1C657.901 390.4 657.401 391.5 656.601 392.3C656.501 392.4 656.401 392.5 656.301 392.6C656.001 392 655.401 391.6 654.701 391.6C653.701 391.6 652.901 392.4 652.901 393.4C652.901 393.5 652.901 393.6 652.901 393.7H651.701C651.701 393.6 651.701 393.5 651.701 393.4C651.701 392.4 650.901 391.6 649.901 391.6C649.201 391.6 648.601 392 648.301 392.6C647.301 391.8 646.701 390.5 646.701 389.1H657.901Z"
            fill="#25393F"
          />
          <path
            d="M667.1 385.3C665.7 385.3 664.4 384.8 663.5 383.8C663.2 383.5 663.2 383 663.5 382.7C663.8 382.4 664.3 382.4 664.6 382.7C666 384.1 668.4 384.1 669.7 382.7C670 382.4 670.5 382.4 670.8 382.7C671.1 383 671.1 383.5 670.8 383.8C669.7 384.8 668.5 385.3 667.1 385.3Z"
            fill="#25393F"
          />
          <path
            d="M643.402 364.6C651.602 364.6 659.802 366.4 666.902 370C667.102 370 667.302 369.9 667.602 369.9C668.402 369.8 669.202 369.7 670.002 369.7C687.302 369.7 697.102 397.3 694.102 397.3H694.002L693.102 397.4H639.902C639.902 397.4 630.202 397.3 609.402 397.3L602.902 387.9C605.302 384.1 610.502 377.5 610.502 377.5C617.602 369.1 630.402 364.6 643.402 364.6ZM643.402 363.1C629.302 363.1 616.502 368.1 609.202 376.5C609.002 376.8 603.902 383.3 601.602 387.1L601.102 387.9L601.702 388.7L608.202 398.1L608.602 398.7H609.402C629.902 398.7 639.802 398.8 639.902 398.8H693.102H693.202H693.302H694.002C694.102 398.8 694.102 398.8 694.202 398.8C695.002 398.8 695.602 398.4 696.002 397.7C696.202 397.3 696.302 396.8 696.302 396.2C696.302 390.4 686.502 368.2 670.202 368.2C669.402 368.2 668.502 368.3 667.602 368.4C667.502 368.4 667.502 368.4 667.402 368.4C660.102 364.9 651.902 363.1 643.402 363.1Z"
            fill="#25393F"
          />
          <path
            d="M604.699 388V321.1H599.099V388C587.099 388.7 577.699 394.1 577.699 398.1H626.099C626.099 394.1 616.799 388.8 604.699 388Z"
            fill="#DDE6E9"
          />
          <path
            d="M626.9 398.9H577V398.1C577 393.7 586.1 388.2 598.4 387.3V320.3H605.5V387.3C617.8 388.2 626.9 393.7 626.9 398.1V398.9ZM578.6 397.4H625.2C624.1 394.2 616.2 389.5 604.6 388.8H603.9V321.9H599.8V388.8H599.1C587.7 389.5 579.7 394.2 578.6 397.4Z"
            fill="#25383F"
          />
          <path
            d="M496.8 374.8C496.8 374.8 499.2 382.4 493 388C488.8 391.8 483.3 392.5 483.3 392.5C482 392.5 472 398.2 473.3 398.2H522.4C523.5 398.2 524.4 397.3 524.4 396.2V392.3V374.6L496.8 374.8Z"
            fill="white"
          />
          <path
            d="M522.5 398.9H473.4C472.8 398.9 472.5 398.5 472.5 398.1C472.5 397.7 472.5 397.2 477.5 394.4C478.9 393.6 482.4 391.7 483.4 391.7C483.4 391.7 488.6 391 492.6 387.4C498.4 382.2 496.2 375.3 496.1 375L495.8 374L525.3 373.7V396.1C525.2 397.6 524 398.9 522.5 398.9ZM475.3 397.4H522.5C523.2 397.4 523.8 396.8 523.8 396.1V375.2L497.8 375.5C498.2 377.8 498.8 383.9 493.6 388.5C489.2 392.5 483.7 393.1 483.5 393.2C482.5 393.3 477.8 395.8 475.3 397.4Z"
            fill="#25393F"
          />
          <path
            d="M565.501 374.8C565.501 374.8 567.901 382.4 561.701 388C557.501 391.8 540.701 398.2 542.001 398.2H591.101C592.201 398.2 593.101 397.3 593.101 396.2V392.3L583.601 372.1L565.501 374.8Z"
            fill="white"
          />
          <path
            d="M592.302 375.5C592.302 375.5 594.702 376.5 586.102 380.6V374.4L592.302 375.5Z"
            fill="#F5AE2D"
          />
          <path
            d="M591.101 398.9H541.401L541.201 398.3C541.001 397.6 541.001 397.6 545.801 395.5C550.601 393.4 558.601 389.8 561.201 387.5C567.001 382.3 564.801 375.4 564.701 375.1L564.401 374.3L584.001 371.4L593.801 392.2V396.3C593.901 397.6 592.701 398.9 591.101 398.9ZM545.101 397.4H591.101C591.801 397.4 592.401 396.8 592.401 396.1V392.3L583.201 372.8L566.301 375.3C566.701 377.5 567.401 383.7 562.101 388.4C559.301 391 551.501 394.4 546.301 396.7C546.001 397 545.601 397.2 545.101 397.4Z"
            fill="#25393F"
          />
          <path
            d="M577.6 183.3C568.1 172.2 559.1 157.6 559.1 157.6L544.9 168.3C544.9 168.3 554.4 181.6 556.3 190.2C556.3 190.2 582.6 189.1 577.6 183.3Z"
            fill="white"
          />
          <path
            d="M555.7 191L555.6 190.4C553.7 182.1 544.4 168.9 544.3 168.8L543.9 168.2L559.4 156.6L559.8 157.3C559.9 157.4 568.9 171.9 578.3 182.9C579.3 184.1 579.2 185.1 578.9 185.7C577 190 559.9 190.9 556.4 191.1L555.7 191ZM545.9 168.5C547.7 171.1 554.9 181.8 556.9 189.4C564.5 189 576.3 187.5 577.4 185C577.5 184.9 577.6 184.5 577 183.8C568.7 174.2 560.7 161.8 558.8 158.8L545.9 168.5Z"
            fill="#25393F"
          />
          <path
            d="M557.702 151.6C556.602 149.2 554.802 147.1 552.602 145.8C548.902 143.7 541.302 140.2 537.202 141.5C532.202 143 534.002 152.8 535.502 157.8C536.702 161.9 538.902 166.8 542.702 169.4C547.102 172.4 554.302 168.5 557.002 164.7C559.602 161.1 559.602 155.8 557.702 151.6Z"
            fill="white"
          />
          <path
            d="M546.501 171.2C545.001 171.2 543.601 170.8 542.301 170C538.201 167.2 536.001 161.9 534.801 158C533.801 154.4 530.901 142.7 537.001 140.8C542.201 139.2 551.901 144.6 552.901 145.2C555.201 146.5 557.101 148.7 558.301 151.4C560.401 156.1 560.101 161.6 557.501 165.2C555.501 168.2 550.701 171.2 546.501 171.2ZM539.301 141.9C538.601 141.9 538.001 142 537.501 142.1C533.701 143.3 534.401 151.1 536.301 157.4C537.401 161.1 539.501 166.1 543.201 168.6C547.201 171.3 553.901 167.8 556.501 164.1C558.801 160.8 559.001 156 557.101 151.7C556.001 149.3 554.301 147.4 552.301 146.2C549.001 144.6 543.201 141.9 539.301 141.9Z"
            fill="#25393F"
          />
          <path
            d="M536.801 138.9C533.601 139.9 530.001 141.2 526.901 142.7C526.301 143 526.301 143.8 526.901 144.1C531.501 146.6 540.601 147.6 545.701 148C546.401 149.1 547.701 152.7 548.401 153.5C550.601 153.3 551.201 150.2 553.501 150.6C555.201 150.9 555.701 153.5 556.501 155.1C557.201 156.4 558.501 162.4 560.101 162.3C560.801 162.3 561.401 161.7 561.701 161.1C562.601 159.2 560.701 153.1 560.601 151.1C560.501 148.4 560.001 145.7 560.001 143.1C559.801 140.6 559.701 137.4 557.001 136.2C555.101 135.3 552.801 135.6 550.701 136C546.901 136.6 543.101 137.3 539.401 138.3C538.601 138.3 537.701 138.6 536.801 138.9Z"
            fill="#25393F"
          />
          <path
            d="M560.101 163C558.601 163 557.701 160.6 556.601 157.3C556.301 156.5 556.101 155.7 555.901 155.4C555.701 154.9 555.401 154.4 555.201 153.9C554.701 152.7 554.201 151.5 553.401 151.3C552.601 151.2 552.101 151.6 551.301 152.5C550.601 153.3 549.801 154.1 548.501 154.2H548.201L548.001 154C547.501 153.5 546.901 152.3 546.101 150.4C545.801 149.7 545.501 149.1 545.301 148.7C541.001 148.4 531.601 147.4 526.601 144.7C526.101 144.4 525.801 143.9 525.801 143.3C525.801 142.7 526.101 142.2 526.601 141.9C529.701 140.4 533.201 139.1 536.601 138C537.501 137.7 538.401 137.4 539.301 137.2C543.101 136.2 547.001 135.5 550.701 134.9C552.601 134.6 555.201 134.2 557.401 135.2C560.301 136.5 560.501 139.6 560.701 142.1L560.801 142.7C560.801 144.2 561.001 145.8 561.101 147.2C561.201 148.4 561.401 149.6 561.401 150.7C561.401 151.3 561.701 152.5 561.901 153.7C562.501 156.5 563.101 159.6 562.401 161.1C561.901 162.3 561.101 162.9 560.101 163ZM553.101 149.8C553.301 149.8 553.501 149.8 553.601 149.8C555.301 150.1 556.001 151.8 556.501 153.3C556.701 153.8 556.901 154.3 557.101 154.6C557.301 155 557.601 155.8 557.901 156.7C558.301 157.9 559.401 161.2 560.101 161.4C560.501 161.4 560.901 161 561.101 160.6C561.601 159.5 560.901 156.3 560.501 154.1C560.201 152.8 560.001 151.6 560.001 150.9C559.901 149.8 559.801 148.6 559.701 147.5C559.501 146 559.401 144.4 559.401 142.9L559.301 142.3C559.101 140 558.901 137.6 556.801 136.6C555.101 135.8 553.201 136.1 551.001 136.4C547.301 137 543.501 137.7 539.701 138.7C538.801 138.9 537.901 139.2 537.101 139.5C533.801 140.6 530.201 141.8 527.301 143.3C532.301 146 542.801 147 545.801 147.2H546.201L546.401 147.5C546.701 148 547.001 148.8 547.401 149.7C547.801 150.6 548.301 151.9 548.701 152.5C549.201 152.3 549.701 151.8 550.201 151.3C550.901 150.7 551.701 149.8 553.101 149.8Z"
            fill="#25393F"
          />
          <path
            d="M503.201 295.7L551.401 381.2C552.101 382.4 572.501 370.6 592.401 375.5C592.901 375.6 589.301 369.5 587.901 366.5L552.001 297.9L503.201 295.7Z"
            fill="#25383F"
          />
          <path
            d="M551.5 382C551.1 382 550.8 381.8 550.7 381.5L501.9 294.8L552.4 297.1L552.6 297.5L588.5 366.1C589 367.2 589.9 368.8 590.7 370.3C593.3 375.2 593.4 375.4 593 375.9L592.7 376.3L592.2 376.2C577.6 372.6 562.9 378.1 555.8 380.7C553.4 381.6 552.2 382 551.5 382ZM504.5 296.5L551.8 380.5C552.4 380.4 553.8 379.8 555.2 379.3C562.2 376.7 576.6 371.3 591.2 374.4C590.7 373.5 590 372.1 589.4 371C588.6 369.5 587.8 367.9 587.2 366.8L551.5 298.6L504.5 296.5Z"
            fill="#25383F"
          />
          <path
            d="M565.901 274.7C564.301 273.6 552.001 222.3 548.001 207.1C546.401 201.1 561.701 172.8 571.401 173.9C581.101 175 588.401 181.8 595.301 189.2C604.301 198.8 622.301 229.4 625.801 259.8C626.101 262.4 626.601 265.4 625.901 267.9C624.301 272.9 567.101 275.5 565.901 274.7Z"
            fill="#F5AE2D"
          />
          <path
            d="M570.101 275.6C567.501 275.6 565.801 275.5 565.501 275.3C564.401 274.6 561.801 264.8 552.501 228C550.301 219.3 548.401 211.8 547.201 207.3C546.001 202.8 552.801 188.5 560.101 180C563.001 176.6 567.401 172.7 571.301 173.1C581.401 174.3 588.901 181.4 595.601 188.7C604.501 198.3 622.701 228.9 626.301 259.8L626.401 260.6C626.701 263 627.001 265.8 626.301 268.2C626.101 269 625.201 269.7 623.401 270.3C615.101 273.2 582.401 275.6 570.101 275.6ZM566.301 274C568.201 274.2 581.601 273.9 596.201 272.7C618.201 270.9 624.601 268.7 624.901 267.7C625.501 265.6 625.201 263 624.901 260.7L624.801 259.9C621.301 229.4 603.401 199.1 594.501 189.7C587.901 182.7 580.701 175.7 571.101 174.6C568.501 174.3 565.001 176.6 561.201 181C553.801 189.6 547.601 203.3 548.601 206.9C549.801 211.4 551.701 218.9 553.901 227.6C558.301 245.1 564.901 271.4 566.301 274Z"
            fill="#25393F"
          />
          <path
            d="M585.002 263C597.002 261.8 612.702 260.8 625.002 259.8C626.702 271 626.202 266.3 626.202 272C626.402 288.3 623.202 300 616.802 304.2C610.002 308.7 595.502 307.3 587.202 307.6C568.502 308.3 550.002 308 531.402 309.3C531.502 327 531.002 367 530.902 379.2C530.902 381.4 489.102 381.7 489.102 379.2C489.302 365.3 489.802 320.2 489.302 299.5C489.102 291.9 492.402 283.9 498.702 279.2C502.802 276.2 507.902 275 512.902 273.9C536.602 268.8 557.402 266.3 585.002 263Z"
            fill="#25393F"
          />
          <path
            d="M509.901 381.7C500.301 381.7 490.601 381.2 488.901 380.2C488.401 379.9 488.301 379.5 488.301 379.2C488.401 369.7 489.001 321.4 488.501 299.5C488.301 291.1 492.001 283.1 498.201 278.6C502.301 275.6 507.201 274.4 512.701 273.2C536.601 268.1 557.901 265.5 584.901 262.3C592.501 261.5 601.601 260.9 610.401 260.2C615.501 259.8 620.401 259.5 624.901 259.1L625.601 259L625.701 259.7C626.001 261.7 626.201 263.2 626.401 264.3C627.001 267.9 627.001 268 626.901 269.3C626.901 269.8 626.901 270.6 626.901 272C627.101 289.2 623.701 300.5 617.201 304.8C611.701 308.4 601.601 308.3 593.501 308.3C591.201 308.3 589.001 308.3 587.201 308.3C580.001 308.6 572.701 308.7 565.701 308.8C554.701 309 543.301 309.2 532.101 310C532.201 330.6 531.601 377.2 531.601 379.2C531.601 379.5 531.501 379.8 531.001 380.1C529.101 381.2 519.501 381.7 509.901 381.7ZM489.801 378.9C492.901 380.6 526.701 380.6 530.101 378.9C530.201 374.5 530.701 329 530.601 309.3V308.6H531.301C542.801 307.8 554.401 307.6 565.701 307.4C572.701 307.3 580.001 307.1 587.201 306.9C589.101 306.8 591.301 306.8 593.601 306.9C601.501 307 611.301 307 616.401 303.7C622.401 299.7 625.601 288.5 625.401 272.1C625.401 270.6 625.401 269.9 625.401 269.3C625.401 268.2 625.401 268.2 624.901 264.6C624.701 263.6 624.501 262.3 624.301 260.7C620.001 261 615.401 261.4 610.501 261.7C601.701 262.3 592.601 263 585.001 263.8C558.101 267 536.901 269.6 513.001 274.7C507.601 275.9 502.901 277 499.001 279.8C493.201 284 489.801 291.6 489.901 299.4C490.501 321.1 489.901 368.9 489.801 378.9Z"
            fill="#25393F"
          />
          <path
            d="M531.399 234.2C523.599 240.5 503.599 246.3 493.599 243.6C491.299 243 488.899 242 486.599 242.6C484.499 243.1 482.799 244.8 481.899 246.7C480.899 248.6 480.499 250.8 480.099 252.9C479.699 254.8 484.199 252.8 484.699 252.4C486.099 250.8 485.699 247.9 488.699 248C490.199 248.1 491.499 249 492.799 249.8C500.099 254.2 511.199 252.8 519.899 253.1C528.699 253.4 543.999 252.8 551.499 248.1C564.999 239.6 576.999 217.7 579.199 202.1C579.299 201.1 557.899 189.1 552.899 191.9C548.199 194.5 546.299 203.6 544.299 210C541.499 218 536.699 229.9 531.399 234.2Z"
            fill="white"
          />
          <path
            d="M480.8 254.4C480.5 254.4 480.2 254.3 479.9 254.2C479.3 253.9 479.1 253.3 479.2 252.7C479.6 250.6 480.1 248.4 481.1 246.3C482.3 244 484.2 242.3 486.3 241.8C488.4 241.3 490.5 241.9 492.5 242.5C492.9 242.6 493.3 242.7 493.7 242.8C503.4 245.4 523.2 239.7 530.8 233.6C536.9 228.7 541.9 214.1 543.2 209.7C543.5 208.6 543.9 207.4 544.2 206.2C545.9 200.2 547.9 193.4 552.1 191.1C557.2 188.3 574.9 198.2 578.1 200.4C579.2 201.1 579.6 201.5 579.5 202.1C577.4 217.4 565.6 239.8 551.5 248.6C542.3 254.3 523.2 253.8 519.5 253.7C517.5 253.6 515.4 253.6 513.2 253.7C505.7 253.8 497.9 253.8 492.1 250.3L491.6 250C490.5 249.3 489.4 248.7 488.3 248.6C487 248.5 486.6 249.2 486.1 250.6C485.8 251.3 485.5 252.1 484.9 252.7C484.7 253.2 482.3 254.4 480.8 254.4ZM480.7 252.9C481.4 253 483.6 252.1 484 251.8C484.4 251.4 484.6 250.8 484.9 250.1C485.4 248.7 486.1 247.1 488.6 247.2C490.1 247.3 491.4 248.1 492.6 248.8L493.1 249.1C498.6 252.4 506.1 252.3 513.4 252.3C515.6 252.3 517.8 252.3 519.8 252.3C529.9 252.7 544.1 251.7 550.9 247.4C564.6 238.8 576 217.2 578.2 202.2C576.1 200.2 557.3 190.1 553 192.4C549.4 194.4 547.5 200.9 545.8 206.6C545.4 207.8 545.1 209 544.8 210.1C543.4 214.6 538.3 229.6 531.9 234.7C523.8 241.2 503.7 247 493.4 244.2C493 244.1 492.6 244 492.2 243.9C490.3 243.3 488.5 242.8 486.7 243.2C485 243.6 483.5 244.9 482.4 246.9C481.5 248.8 481.1 250.9 480.7 252.9Z"
            fill="#25393F"
          />
          <path
            d="M582.899 200.3L579.899 216.5C578.199 220.5 573.599 219.1 570.099 218.5C564.699 217.6 559.399 216.2 554.399 214.1C551.099 212.8 547.999 211.2 544.999 209.5C542.799 208.2 541.699 207.7 541.499 206.4C541.399 205.8 541.499 204.9 541.899 203.8C544.699 194.3 548.499 183.8 556.699 177.6C558.799 176.1 560.999 174.9 563.499 174.1C566.799 173.1 570.199 173.1 573.199 174.1"
            fill="#F5AE2D"
          />
          <path
            d="M575.3 220C573.9 220 572.5 219.7 571.2 219.5C570.8 219.4 570.4 219.3 570 219.3C564.4 218.4 559.1 216.9 554.1 214.9C550.8 213.6 547.6 212 544.6 210.2L544.1 209.9C542.2 208.8 541 208.1 540.8 206.6C540.7 205.9 540.8 205 541.2 203.7C544 194.2 547.8 183.5 556.3 177.1C558.5 175.5 560.8 174.3 563.3 173.5C566.7 172.4 570.2 172.4 573.5 173.5L573.1 174.9C570.1 174 566.9 174 563.8 174.9C561.5 175.6 559.2 176.8 557.2 178.3C549.1 184.4 545.4 194.8 542.7 204.1C542.4 205.2 542.3 205.9 542.3 206.4C542.4 207.2 543 207.6 544.9 208.7L545.4 209C548.4 210.7 551.5 212.3 554.7 213.6C559.6 215.6 564.8 217 570.3 217.9C570.7 218 571.1 218 571.6 218.1C575.3 218.8 578.1 219.1 579.3 216.4L582.2 200.3L583.7 200.6L580.7 217C579.5 219.3 577.5 220 575.3 220Z"
            fill="#25393F"
          />
          <path d="M456.7 251.3L447.2 210.8H442.5L453.1 255.9H505.6V251.3H456.7Z" fill="white" />
          <path
            d="M506.3 256.7H452.5L441.5 210.1H447.7L457.2 250.6H506.3V256.7ZM453.7 255.2H504.8V252H456L446.5 211.5H443.4L453.7 255.2Z"
            fill="#25393F"
          />
          <path
            d="M540.398 169.4C540.398 168.6 540.598 168 541.298 168C541.998 168 542.598 168.2 543.298 168.3C544.698 168.5 546.098 168.6 547.498 168.3C549.398 167.9 550.998 166.9 552.298 165.5C553.498 164.2 554.598 162.8 555.598 161.4C555.998 160.8 556.398 160.1 556.698 159.4C556.998 158.8 557.398 157.8 557.898 157.4C558.698 156.8 559.998 158 560.598 158.5C561.298 159.2 561.498 160.6 561.098 161.5C560.898 161.9 560.598 162.3 560.298 162.6C559.698 163.3 558.998 163.8 558.498 164.5C557.098 166.3 556.198 168.5 554.598 170.1C552.498 172.2 550.098 174.1 547.498 175.5C545.898 176.4 543.998 177 542.698 175.4C541.298 173.8 540.398 171.6 540.398 169.4Z"
            fill="#25393F"
          />
          <path
            d="M544.699 177.3C543.599 177.3 542.699 176.9 542.099 176C540.599 174.1 539.699 171.6 539.699 169.5C539.699 167.6 540.899 167.4 541.399 167.4C541.899 167.4 542.399 167.5 542.799 167.6C542.999 167.6 543.199 167.7 543.499 167.7C545.099 167.9 546.299 167.9 547.399 167.7C548.999 167.3 550.599 166.4 551.799 165.1C552.999 163.8 553.999 162.4 555.099 161C555.499 160.5 555.699 160 555.999 159.4L556.099 159.1C556.199 159 556.199 158.9 556.299 158.7C556.599 158.1 556.999 157.3 557.599 156.9C558.199 156.5 559.399 156.3 561.199 158.1C562.099 159 562.399 160.7 561.899 161.9C561.699 162.4 561.299 162.8 560.999 163.2C560.799 163.5 560.499 163.7 560.199 164C559.799 164.4 559.499 164.7 559.199 165.1C558.699 165.8 558.199 166.6 557.699 167.3C556.999 168.5 556.199 169.7 555.199 170.8C553.099 173 550.599 174.9 547.899 176.4C546.699 176.9 545.599 177.3 544.699 177.3ZM541.299 168.8C541.199 168.8 541.099 168.9 541.099 169.5C541.099 171.3 541.899 173.4 543.199 175.1C543.699 175.8 544.699 176.4 547.099 175.1C549.599 173.7 551.999 171.9 553.999 169.8C554.899 168.8 555.599 167.7 556.299 166.6C556.799 165.8 557.299 165 557.799 164.3C558.199 163.8 558.599 163.4 558.999 163C559.199 162.8 559.499 162.5 559.699 162.3C559.999 162 560.199 161.7 560.399 161.4C560.699 160.8 560.499 159.7 560.099 159.3C559.099 158.3 558.499 158.2 558.399 158.2C558.099 158.4 557.799 159 557.599 159.5C557.499 159.7 557.499 159.8 557.399 159.9L557.199 160C556.899 160.6 556.599 161.2 556.099 161.8C555.099 163.3 553.899 164.7 552.699 166C551.299 167.6 549.499 168.7 547.499 169.1C545.899 169.5 544.299 169.3 543.099 169.1C542.799 169.1 542.599 169 542.299 169C541.999 168.9 541.699 168.8 541.299 168.8Z"
            fill="#25393F"
          />
          <path
            d="M371.801 254.7H548.401V264.8H439.301V398.1H429.901V264.8H371.801"
            fill="#25393F"
          />
          <path
            d="M531.8 383.2H531.7H531V382.4V382.3C531.1 381.1 531.4 369.7 533.2 311.7V311C533.2 310.2 533.5 309.5 534.1 309C534.7 308.5 535.4 308.2 536.2 308.2C570.3 309.4 611.8 309.6 616.4 306.4C616.7 306.2 617.2 306.3 617.4 306.6C617.6 306.9 617.5 307.4 617.2 307.6C611 311.8 553.6 310.2 536.1 309.6C535.7 309.6 535.4 309.7 535.1 310C534.8 310.3 534.7 310.6 534.7 311V311.7C534.5 318.5 534 336.1 533.5 352.1C533.3 360.1 533 367.7 532.8 373.2C532.7 376 532.6 378.3 532.6 379.9C532.6 381 532.5 381.7 532.5 382.2V382.3C532.4 383.1 532.2 383.2 531.8 383.2Z"
            fill="white"
          />
          <path
            d="M668.302 182.6H661.702C659.502 182.6 657.502 184.1 656.902 186.3L623.502 311H556.002C552.202 311 549.102 314.1 549.102 317.9V330.4C549.102 334.2 552.202 337.3 556.002 337.3H645.902C649.402 337.3 652.302 334.5 652.302 330.9L673.202 188.2C673.602 185.2 671.302 182.6 668.302 182.6Z"
            fill="#DDE6E9"
          />
          <path
            d="M645.901 338H556.001C551.801 338 548.301 334.6 548.301 330.3V317.8C548.301 313.6 551.701 310.1 556.001 310.1H623.001L656.201 186C656.901 183.5 659.101 181.8 661.701 181.8H668.301C669.901 181.8 671.501 182.5 672.601 183.8C673.701 185 674.201 186.7 673.901 188.3L653.001 331C652.901 334.9 649.801 338 645.901 338ZM555.901 311.7C552.501 311.7 549.701 314.5 549.701 317.9V330.4C549.701 333.8 552.501 336.6 555.901 336.6H645.801C648.901 336.6 651.401 334.1 651.401 331V330.9L672.301 188.2C672.501 187 672.101 185.8 671.301 184.9C670.501 184 669.401 183.5 668.101 183.5H661.501C659.601 183.5 657.901 184.8 657.501 186.6L623.901 311.9H555.901V311.7Z"
            fill="#25383F"
          />
        </g>
      </svg>
    </Box>
  );
}
