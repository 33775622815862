import { Container, Box, Link, Grid, GridItem, Flex } from '@terminal/design-system';

import { useTranslation } from 'next-i18next';

const signUpUrl = 'https://app.terminal.io/onboarding';
import TerminalLogo from '../UI/TerminalLogo';

function FooterNav({
  navItems,
}: {
  navItems: {
    label: string;
    children: {
      label: string;
      href: string;
    }[];
  }[];
}) {
  if (!Array.isArray(navItems)) {
    return null;
  }

  return (
    <Grid
      mt={{ base: '8', md: '8', xl: '0' }}
      templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
      gap={{ base: '10', md: '14' }}
      flex={{ xl: '1' }}
    >
      {navItems.map((navItem) => (
        <GridItem
          key={navItem.label}
          pb={{ md: '14' }}
          position="relative"
          _before={{
            md: {
              content: '""',
              position: 'absolute',
              right: '0',
              top: '0',
              width: 'px',
              height: '100%',
              bgColor: 'grey.900',
              opacity: '0.2',
            },
          }}
          _last={{
            _before: {
              display: 'none',
            },
          }}
        >
          <Box
            mb={{ base: '6', lg: '8' }}
            color="blue.500"
            fontSize={{ base: 'md', md: 'sm' }}
            lineHeight={{ base: '4' }}
            fontWeight="700"
            textTransform="uppercase"
          >
            {navItem.label}
          </Box>
          {navItem.children?.length && (
            <Flex flexDir="column" alignItems="start" gap={4} mr={4}>
              {navItem.children.map((childItem) => (
                <Link
                  key={childItem.label}
                  href={childItem.href}
                  py="2"
                  fontSize={{ base: 'md', md: 'sm' }}
                  lineHeight="4"
                  fontWeight="700"
                  color="grey.900"
                  _hover={{
                    textDecoration: 'none',
                    '&::before': {
                      bgColor: 'green.500',
                    },
                  }}
                  position="relative"
                  target={childItem.href === '/work-for-terminal' ? '_blank' : '_self'}
                  _before={{
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    w: 'full',
                    h: '0.5',
                    transition: 'background-color 0.2s ease-in-out',
                    bgColor: 'transparent',
                  }}
                >
                  {childItem.label}
                </Link>
              ))}
            </Flex>
          )}
        </GridItem>
      ))}
    </Grid>
  );
}

export default function Footer() {
  const { t } = useTranslation('footer');

  // TODO: refactor this assertion cause is causing an error
  const NavItems = t('topMenu', { signUpUrl }) as {
    label: string;
    children: {
      label: string;
      href: string;
    }[];
  }[];

  return (
    <Box
      bgColor="blue.100"
      pt={{ base: '14', md: '10', xl: '20' }}
      pb={{ base: '10', md: '16' }}
      color="text.inverse"
    >
      <Container>
        <Box display={{ xl: 'flex' }} alignItems={{ xl: 'flex-start' }}>
          <Link
            href="/"
            display="flex"
            alignItems="center"
            mr={{ xl: '36' }}
            w="fit-content"
            aria-label="Terminal logo"
          >
            <TerminalLogo
              width={{ base: '8.75rem', xl: '9.8125rem' }}
              height={{ base: '2.5625rem', xl: '2.875rem' }}
            />
          </Link>
          <FooterNav navItems={NavItems} />
        </Box>
      </Container>
    </Box>
  );
}
