import FooterCTA from './FooterCTA';
import TopFooter from './TopFooter';
import BottomFooter from './BottomFooter';

export default function Footer({
  type,
  footer,
  withCTA,
  hideTopFooter,
}: {
  type: string;
  footer?: {
    longText: boolean;
    text: string;
    image?: string;
    button?: {
      url: string;
      text: string;
    };
    outlineButton?: {
      url: string;
      text: string;
    };
  };
  withCTA?: boolean;
  hideTopFooter?: boolean;
}) {
  return (
    <footer>
      {withCTA && <FooterCTA type={type} footer={footer} />}
      {!hideTopFooter && <TopFooter />}
      <BottomFooter />
    </footer>
  );
}
