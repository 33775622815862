import { Box } from '@chakra-ui/react';
import ImageShimmer from '../ImageShimmer/ImageShimmer';

export default function FooterCTAImage({ image, ...props }: { image: string }) {
  return (
    <Box
      w="100%"
      h={{ base: '250px', sm: '350px', md: '450px', lg: '400px' }}
      mb={{ base: '6', lg: '0' }}
      position="relative"
      {...props}
    >
      <ImageShimmer
        src={image}
        alt=""
        sizes="(max-width: 991px) 100vw, 500px"
        style={{
          objectFit: 'cover',
        }}
        fill
      />
    </Box>
  );
}
