import { Box, Container, Flex, Heading, Text, useBreakpointValue } from '@terminal/design-system';
import type { HeroWithBgImageStoryblok, Storyblok } from '../types';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { RichText } from '../primitive';
import {
  forceExtractDimensionsFormURL,
  selectBlokStyles,
  selectResponsiveStyle,
} from '@helpers/storyblok.utils';
import Image from 'next/image';
import { SquarePatternRoundedBG } from '@components/UI/SquarePattern';

function createImageStyleByPosition(
  bgPosition: HeroWithBgImageStoryblok['bgPosition'],
  isMobile: boolean,
): {
  position: 'relative' | 'absolute';
  bottom: number | string;
  left: number | string;
  right: number | 'initial';
  top: number | 'initial';
  margin: 'auto' | 'initial';
} {
  const position = isMobile ? 'relative' : 'absolute';

  if (bgPosition === 'bottom_left') {
    return {
      position,
      bottom: 0,
      left: 0,
      right: 'initial',
      top: 'initial',
      margin: 'initial',
    };
  }

  if (bgPosition === 'bottom_right') {
    return {
      position,
      bottom: 0,
      left: 'initial',
      right: 0,
      top: 'initial',
      margin: 'initial',
    };
  }

  if (bgPosition === 'center_left') {
    return {
      position,
      bottom: 0,
      left: 0,
      right: 'initial',
      top: 0,
      margin: 'auto',
    };
  }

  if (bgPosition === 'center_right') {
    return {
      position,
      bottom: 0,
      left: 'initial',
      right: 0,
      top: 0,
      margin: 'auto',
    };
  }

  // if  anyting else
  return {
    position,
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    margin: 'auto',
  };
}

export function HeroWithBGImage({ blok, page }: Storyblok<HeroWithBgImageStoryblok>) {
  const { paddingStyles, marginStyles } = selectBlokStyles(blok);
  const isMobile = useBreakpointValue([true, true, true, false]);

  const imageDimensions = forceExtractDimensionsFormURL(blok.heroImage?.filename, {
    width: 2000,
    height: 800,
  });

  const dotted = {
    width: selectResponsiveStyle(blok.dottedWidth),
    height: selectResponsiveStyle(blok.dottedHeight),
    show: blok.showDottedBackground,
    top: selectResponsiveStyle(blok.dottedTopPosition),
    right: selectResponsiveStyle(blok.dottedRightPosition),
    bottom: selectResponsiveStyle(blok.dottedBottomPosition),
    left: selectResponsiveStyle(blok.dottedLeftPosition),
    isWhite: blok.dottedBackgroundColor === 'white',
  };

  const heroTitle = (blok.titlePart1 || '')
    .replace('**', '<span style="color: #7AA550">')
    .replace('**', '</span>');

  const deprecatedTitlePart2 = blok.titlePart2
    ? ` <span style="color: #7AA550">${blok.titlePart2}</span>`
    : '';

  return (
    <Flex
      as="section"
      position="relative"
      bgColor={blok.bgColor?.value || 'blue.100'}
      {...marginStyles}
      {...storyblokEditable(blok)}
    >
      {blok?.showSquarePattern && (
        <Box
          zIndex={10}
          pos="absolute"
          w={selectResponsiveStyle(blok?.squareWidth) || ['60%', '60%', '60%', '40%']}
          h={selectResponsiveStyle(blok?.squareHeight) || ['20%', '20%', '20%', '40%']}
          display="inline-flex"
          bgColor={blok?.squareColor?.value || '#FEF8EA'}
          left={selectResponsiveStyle(blok?.squarePositionLeft) || 'unset'}
          right={selectResponsiveStyle(blok?.squarePositionRight) || 0}
          top={selectResponsiveStyle(blok?.squarePositionTop) || ['10%', '10%', '10%', 'unset']}
          bottom={
            selectResponsiveStyle(blok?.squarePositionBottom) || ['unset', 'unset', 'unset', '40%']
          }
          margin="auto"
        />
      )}

      {dotted.show && (
        <SquarePatternRoundedBG
          pos="absolute"
          top={dotted.top || ['5%', '5%', '5%', 0]}
          bottom={dotted.bottom || ['unset', 'unset', 'unset', 0]}
          left={dotted.left || 'unset'}
          right={dotted.right || 0}
          margin="auto"
          w={dotted.width || ['40%', '40%', '40%', '34%']}
          h={dotted.height || ['30%', '30%', '40%', '80%']}
          zIndex={9}
          isWhite={dotted.isWhite}
        />
      )}

      <Container
        zIndex={20}
        display="flex"
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
        alignItems="stretch"
        justifyContent="space-between"
        height="100%"
        my={0}
        py={0}
      >
        <Box
          flex="1"
          zIndex="1"
          paddingTop={paddingStyles.paddingTop || [10, 10, 10, 20]}
          paddingBottom={paddingStyles.paddingBottom || [10, 10, 10, 14]}
          paddingLeft={paddingStyles.paddingLeft || 0}
          paddingRight={paddingStyles.paddingRight || 0}
        >
          <Heading
            as="h1"
            mb={4}
            fontSize={['2xl', '2xl', '2xl', '4xl']}
            lineHeight="short"
            textTransform="capitalize"
            dangerouslySetInnerHTML={{
              __html: heroTitle + deprecatedTitlePart2,
            }}
          />
          {/* @ts-ignore */}
          <Box mt={4} className="text-font-lg">
            <RichText
              blok={{
                _uid: blok.description?._uid,
                component: 'richText',
                content: blok.description,
              }}
              page={page}
            />
          </Box>
          {blok?.cta && (
            <>
              <Box flexDir={['column', 'row', 'row']} gap={4} mt={8} className="large-button-child">
                {(blok?.cta || [])?.map((eachCTA) => (
                  <StoryblokComponent blok={eachCTA} key={eachCTA._uid} page={page} />
                ))}
              </Box>
              {blok.hint && (
                <Text lineHeight="base" mt={2}>
                  {blok.hint}
                </Text>
              )}
            </>
          )}
        </Box>

        <Flex
          flex={blok?.imageFlex ?? 1}
          position="relative"
          paddingTop={[10, 10, 10, 0]}
          marginLeft={selectResponsiveStyle(blok.heroImageLeftPosition) || 0}
        >
          <Image
            src={blok.heroImage?.filename}
            width={imageDimensions.width}
            height={imageDimensions.height}
            alt={blok.heroImage?.alt || 'Main Hero'}
            style={createImageStyleByPosition(blok.bgPosition, isMobile)}
          />
        </Flex>
      </Container>
    </Flex>
  );
}
