import { Link, Box, Flex, Heading, ArrowRightIcon, Button } from '@terminal/design-system';
import Image from 'next/image';
import type { CaseStudyCardStoryblok, Storyblok } from '../types';
import { forceExtractDimensionsFormURL } from '@helpers/storyblok.utils';
import { returnPathWithStartingSlash } from '@helpers/storyblok.utils';
import { storyblokEditable } from '@storyblok/react';

export function CaseStudyCard({ blok }: Storyblok<CaseStudyCardStoryblok>) {
  const logoDimensions = forceExtractDimensionsFormURL(blok.logo.filename, {
    width: 2000,
    height: 800,
  });

  return (
    <Box as="article" p="6" bgColor="green.100" borderRadius="0.5rem" {...storyblokEditable(blok)}>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        minH="18.5rem"
      >
        <Box
          py={1}
          px={2}
          fontSize="sm"
          lineHeight="shorter"
          mb={4}
          borderRadius="full"
          fontWeight="medium"
          bgColor="green.200"
          color="green.800"
        >
          Case Study
        </Box>
        <Heading as="h3" mb={5} variant="heading-2">
          {blok.title}
        </Heading>
        <Box>
          <Image
            src={blok.logo.filename}
            width={logoDimensions?.width}
            height={logoDimensions?.height}
            alt={blok.logo.alt}
            title={blok.logo.title}
            style={{
              width: '90px',
            }}
          />
          <Button
            variant="ghost"
            colorScheme="primary"
            as={Link}
            href={returnPathWithStartingSlash(blok?.link.url || blok.link?.cached_url)}
            mt={4}
            rightIcon={
              <ArrowRightIcon
                w={6}
                h={6}
                fill="green.700"
                aria-label="Arrow pointing to the right"
              />
            }
          >
            Read Case Study
          </Button>
          {/* <Link
            href={returnPathWithStartingSlash(blok?.link.url || blok.link?.cached_url)}
            display="flex"
            alignItems="center"
            color="green.500"
            mt={4}
          >
            <Box mr="3" fontWeight="bold">
              Read Case Study
            </Box>
            <ArrowRightIcon w={6} h={6} fill="green.700" aria-label="Arrow pointing to the right" />
          </Link> */}
        </Box>
      </Flex>
    </Box>
  );
}
