import { createIcon } from '@chakra-ui/icons';

export const LocationIcon = createIcon({
  displayName: 'LocationIcon',
  viewBox: '0 0 12 17',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M3.5 6.0979C3.5 6.76094 3.76339 7.39683 4.23223 7.86567C4.70107 8.33451 5.33696 8.5979 6 8.5979C6.66304 8.5979 7.29893 8.33451 7.76777 7.86567C8.23661 7.39683 8.5 6.76094 8.5 6.0979C8.5 5.43486 8.23661 4.79897 7.76777 4.33013C7.29893 3.86129 6.66304 3.5979 6 3.5979C5.33696 3.5979 4.70107 3.86129 4.23223 4.33013C3.76339 4.79897 3.5 5.43486 3.5 6.0979Z"
        stroke="#394125"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 1.0979C7.32608 1.0979 8.59785 1.62468 9.53553 2.56237C10.4732 3.50005 11 4.77182 11 6.0979C11 8.36923 7.61733 14.0646 6.41667 15.8746C6.37106 15.9433 6.30914 15.9997 6.23644 16.0387C6.16374 16.0778 6.08251 16.0982 6 16.0982C5.91749 16.0982 5.83626 16.0778 5.76356 16.0387C5.69086 15.9997 5.62894 15.9433 5.58333 15.8746C4.38267 14.0646 1 8.36923 1 6.0979C1 4.77182 1.52678 3.50005 2.46447 2.56237C3.40215 1.62468 4.67392 1.0979 6 1.0979V1.0979Z"
        stroke="#394125"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const ViewIcon = createIcon({
  displayName: 'ViewIcon',
  viewBox: '0 0 14 9',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.51856 3.58497C1.04915 4.02723 1.04915 4.73123 1.51856 5.17349C2.57611 6.14447 4.46687 7.66056 6.7913 7.66056C9.11572 7.66056 11.0065 6.14509 12.0647 5.17412C12.5338 4.73172 12.5338 4.02799 12.0647 3.58559C11.0072 2.61462 9.11368 1.0979 6.7913 1.0979C4.46892 1.0979 2.57679 2.61337 1.51856 3.58497Z"
        stroke="#637A83"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="6.79148"
        cy="4.37736"
        rx="1.53416"
        ry="1.40495"
        stroke="#637A83"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
