const OnboardingRolesArray: {
  icon: string;
  title: string;
  query: string;
}[] = [
  {
    icon: `<svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 29.4358C1.89543 29.4358 1 28.5404 1 27.4358V5.58912C1.00366 4.40139 1.9656 3.43945 3.15333 3.43579H28.8573C30.0392 3.43945 30.9963 4.39661 31 5.57846V27.2825C30.9963 28.4702 30.0344 29.4321 28.8467 29.4358H3Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M31 9.43579H1" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 19.4358H23" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 15.4358L13 19.4358L9 23.4358" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
    title: 'Frontend',
    query: 'FED',
  },
  {
    icon: `<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.83334 29.1025C2.72877 29.1025 1.83334 28.2071 1.83334 27.1025V5.25587C1.837 4.06814 2.79894 3.1062 3.98668 3.10254H29.6907C30.8725 3.1062 31.8297 4.06336 31.8333 5.24521V26.9492C31.8297 28.1369 30.8677 29.0989 29.68 29.1025H3.83334Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M31.8333 9.10254H1.83334" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.8333 15.1025L26.8333 19.1025L22.8333 23.1025" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.8333 15.1025L6.83334 19.1025L10.8333 23.1025" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.8333 15.1025L14.8333 23.1025" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_1407" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
              <rect x="0.5" y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_1407)" />
          </svg>`,
    title: 'Backend',
    query: 'BED',
  },
  {
    icon: `<svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5556 8.70248L16.9436 1.66515C16.3514 1.35934 15.6478 1.35934 15.0556 1.66515L1.44363 8.70248C1.1711 8.84362 1 9.12492 1 9.43182C1 9.73872 1.1711 10.02 1.44363 10.1612L15.0556 17.2025C15.6478 17.5083 16.3514 17.5083 16.9436 17.2025L30.5556 10.1692C30.8282 10.028 30.9993 9.74672 30.9993 9.43982C30.9993 9.13292 30.8282 8.85162 30.5556 8.71049V8.70248Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.79963 12.9358L1.44363 15.7065C1.1711 15.8476 1 16.1289 1 16.4358C1 16.7427 1.1711 17.024 1.44363 17.1651L15.0556 24.2065C15.6478 24.5123 16.3514 24.5123 16.9436 24.2065L30.5556 17.1651C30.8282 17.024 30.9993 16.7427 30.9993 16.4358C30.9993 16.1289 30.8282 15.8476 30.5556 15.7065L25.1996 12.9358" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.79963 19.9358L1.44363 22.7025C1.1711 22.8436 1 23.1249 1 23.4318C1 23.7387 1.1711 24.02 1.44363 24.1611L15.0556 31.2025C15.6478 31.5083 16.3514 31.5083 16.9436 31.2025L30.5556 24.1691C30.8282 24.028 30.9993 23.7467 30.9993 23.4398C30.9993 23.1329 30.8282 22.8516 30.5556 22.7105L25.1996 19.9358" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_875" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="33">
              <rect y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_875)" />
          </svg>`,
    title: 'Full Stack',
    query: 'FSD',
  },
  {
    icon: `<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5 13.6865C31.5005 22.4703 25.161 29.9718 16.5 31.4358C7.83899 29.9718 1.49947 22.4703 1.5 13.6865V3.43579C1.5 2.33122 2.39543 1.43579 3.5 1.43579H29.5C30.6046 1.43579 31.5 2.33122 31.5 3.43579V13.6865Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="16.5" cy="14.4358" r="2" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1987 6.70241L18.788 8.63974C18.9898 9.30696 19.674 9.70382 20.3533 9.54774L22.316 9.09307C23.0796 8.92099 23.8662 9.26636 24.2563 9.94497C24.6464 10.6236 24.549 11.4772 24.016 12.0504L22.6413 13.5277C22.1654 14.0395 22.1654 14.832 22.6413 15.3437L24.016 16.8251C24.549 17.3983 24.6464 18.2519 24.2563 18.9305C23.8662 19.6091 23.0796 19.9545 22.316 19.7824L20.3533 19.3277C19.674 19.1717 18.9898 19.5685 18.788 20.2357L18.1987 22.1691C17.9738 22.9194 17.2833 23.4332 16.5 23.4332C15.7167 23.4332 15.0262 22.9194 14.8013 22.1691L14.212 20.2317C14.0102 19.5645 13.326 19.1677 12.6467 19.3237L10.684 19.7784C9.92041 19.9505 9.13377 19.6051 8.74367 18.9265C8.35358 18.2479 8.45101 17.3943 8.984 16.8211L10.3587 15.3397C10.8346 14.828 10.8346 14.0355 10.3587 13.5237L8.984 12.0464C8.45101 11.4732 8.35358 10.6196 8.74367 9.94097C9.13377 9.26236 9.92041 8.91699 10.684 9.08907L12.6467 9.54374C13.326 9.69982 14.0102 9.30296 14.212 8.63574L14.8013 6.70241C15.0262 5.95211 15.7167 5.43823 16.5 5.43823C17.2833 5.43823 17.9738 5.95211 18.1987 6.70241Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_1006" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
              <rect x="0.5" y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_1006)" />
          </svg>`,
    title: 'DevOps',
    query: 'DEV',
  },
  {
    icon: `<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 20.4359L13.7307 18.7626C14.1908 18.4179 14.7724 18.2777 15.3391 18.3748C15.9057 18.4719 16.4075 18.7977 16.7267 19.2759V19.2759C17.1947 19.9784 17.1947 20.8934 16.7267 21.5959L14.5333 24.8852C13.86 25.8952 12.8963 26.6771 11.7693 27.1279L8.5 28.4359V31.4359" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 13.4358L7.7 15.4905C6.28405 16.0977 5.15593 17.2263 4.54933 18.6425L2.98533 22.3025C2.66494 23.0499 2.49982 23.8546 2.5 24.6678V31.4358" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 19.6865V5.43579C12.5 3.22665 14.2909 1.43579 16.5 1.43579H26.5C28.7091 1.43579 30.5 3.22665 30.5 5.43579V27.4358C30.5 29.6449 28.7091 31.4358 26.5 31.4358H16.5C14.2909 31.4358 12.5 29.6449 12.5 27.4358V26.7691" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1187 25.4358H30.5" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_1418" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
              <rect x="0.5" y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_1418)" />
          </svg>`,
    title: 'Mobile',
    query: 'MOD',
  },
  {
    icon: `<svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse rx="11.5271" ry="11.3611" transform="matrix(0.915497 -0.402324 0.380142 0.924928 15.8718 16.5815)" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.4851 25.1189L31.16 31.8059" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.0491 13.7275L9.1825 16.582L12.0491 19.4364" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.6935 13.7275L22.5601 16.582L19.6935 19.4364" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.8269 13.7275L14.9158 19.4364" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_990" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="33">
              <rect y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_990)"/>
          </svg>`,
    title: 'QA',
    query: 'MQA',
  },
  {
    icon: `<svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9588 20.583V30.1566" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.98627 30.1567H25.9313" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30.419 29.198C30.1436 29.198 29.9203 29.4123 29.9203 29.6767C29.9203 29.9411 30.1436 30.1554 30.419 30.1554C30.6944 30.1554 30.9176 29.9411 30.9176 29.6767C30.918 29.5496 30.8655 29.4277 30.7719 29.3378C30.6784 29.248 30.5513 29.1977 30.419 29.198V29.198" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.49863 29.198C1.22324 29.198 1 29.4123 1 29.6767C1 29.9411 1.22324 30.1554 1.49863 30.1554C1.77401 30.1554 1.99725 29.9411 1.99725 29.6767C1.99761 29.5496 1.94519 29.4277 1.8516 29.3378C1.75801 29.248 1.63098 29.1977 1.49863 29.198V29.198" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <ellipse cx="15.9588" cy="5.26524" rx="8.97527" ry="3.82945" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.98352 5.26514V11.0093C6.98352 13.1244 11.0018 14.8388 15.9588 14.8388C20.9158 14.8388 24.9341 13.1244 24.9341 11.0093V5.26514" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.98352 11.0095V16.7537C6.98352 18.8688 11.0018 20.5831 15.9588 20.5831C20.9158 20.5831 24.9341 18.8688 24.9341 16.7537V11.0095" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_971" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="33">
              <rect y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_971)" />
          </svg>`,
    title: 'Data Science',
    query: 'DSC',
  },
  {
    icon: `<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.5" cy="10.9358" r="5.5" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.5 27.4358C1.5 22.4652 5.52944 18.4358 10.5 18.4358C15.4706 18.4358 19.5 22.4652 19.5 27.4358" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="24.136" cy="13.9358" r="4.5" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.584 20.5264C23.8439 19.6935 26.3682 20.0177 28.3442 21.3946C30.3203 22.7715 31.4987 25.0273 31.5 27.4358" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <mask id="mask0_161_939" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
              <rect x="0.5" y="0.435791" width="32" height="32" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_161_939)" />
          </svg>`,
    title: 'Eng. Lead',
    query: 'ENL',
  },
  {
    icon: `<svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.759 1.56567C6.12821 4.6495 5.31831 3.40459 7.06641 4.55424C7.51265 4.8566 7.86474 5.28308 8.08106 5.78328C8.51366 6.75504 8.40349 7.88889 7.7923 8.75493C7.18112 9.62098 6.16251 10.0866 5.12266 9.97533C4.08282 9.86403 3.18095 9.19283 2.759 8.2162C1.747 6.11974 1.747 3.66214 2.759 1.56567Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.4375 9.97583L6.64394 13.4724" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3936 31.3324H9.56223C6.34167 31.3324 3.7309 28.667 3.7309 25.3791V13.4724H21.2249V25.3791C21.2249 28.667 18.6141 31.3324 15.3936 31.3324V31.3324Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1687 25.3791H21.2249V13.4724H23.1687C26.3892 13.4724 29 16.1378 29 19.4257V19.4257C29 22.7137 26.3892 25.3791 23.1687 25.3791V25.3791Z" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.0448 8.29712L11.1911 13.4725" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.4559 13.4724L15.0774 5.89053L19.4923 1.53931L20.253 7.75458L18.2768 13.4724" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.9485 8.63027C18.0089 9.25253 15.8999 8.4924 14.7728 6.76489" stroke="#25383F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
    title: 'Designer',
    query: 'DES',
  },
];

export default OnboardingRolesArray;
