import { Button } from '@chakra-ui/react';
import { Box, Flex, ButtonProps as ChakraButtonProps, Link, Text } from '@terminal/design-system';
import ArrowRight from '../Icons/ArrowRight';
import slugify from '@helpers/slugify';

interface ButtonProps extends ChakraButtonProps {
  hoverType?: string;
  href?: string;
}

export const PrimaryButton = ({ hoverType, ...props }: ButtonProps) => {
  const ButtonType = (props: any) => {
    if (props.href) {
      return (
        <Link
          display="inline-flex"
          href={props.href}
          alignItems="center"
          justifyContent="center"
          {...props}
        >
          {props.children}
        </Link>
      );
    } else {
      return <Button {...props}>{props.children}</Button>;
    }
  };

  return (
    <ButtonType
      fontWeight="bold"
      color="white"
      bgColor="green.500"
      minW="32"
      h="14"
      borderRadius="0"
      _hover={{
        backgroundColor: hoverType === 'onBlack' ? 'green.700' : 'grey.900',
      }}
      textDecoration="none"
      transition="all 0.3s ease-in-out"
      {...(typeof props.children === 'string' ? { className: slugify(props.children) } : {})}
      {...props}
    >
      {props.children}
    </ButtonType>
  );
};

export const OutlineButton = (props: any) => {
  return (
    <PrimaryButton
      color="green.500"
      borderWidth="0.125rem"
      borderColor="green.500"
      bgColor="transparent"
      _hover={{
        backgroundColor: 'transparent',
        borderColor: 'grey.900',
        color: 'grey.900',
      }}
      {...(typeof props.children === 'string' ? { className: slugify(props.children) } : {})}
      {...props}
    >
      {props.children}
    </PrimaryButton>
  );
};

export const GhostButton = (props: any) => {
  return (
    <PrimaryButton
      color="green.500"
      borderWidth="0.125rem"
      borderColor="transparent"
      bgColor="transparent"
      _hover={{
        backgroundColor: 'transparent',
        borderColor: 'green.500',
      }}
      {...(typeof props.children === 'string' ? { className: slugify(props.children) } : {})}
      {...props}
    >
      {props.children}
    </PrimaryButton>
  );
};

export const TextWithArrowButton = ({
  type,
  text,
  ...props
}: {
  type: string;
  text: {
    normal: string;
    strong: string;
  };
  [x: string]: any;
}) => {
  return (
    <PrimaryButton
      className={slugify(`${text.normal} ${text.strong}`)}
      {...props}
      as="a"
      aria-label={text.normal || text.strong}
      role="group"
    >
      <Flex>
        <Flex
          flexDirection={text.normal && text.strong ? 'column' : 'row'}
          py="4"
          pl="5"
          pr={{ base: '5', lg: '10' }}
          bgColor={type === 'dark' ? 'grey.800' : type === 'light' ? 'blue.100' : 'blue.200'}
          color={type === 'dark' ? 'white' : 'grey.900'}
          fontSize="xs"
          lineHeight="4"
          alignItems={text.normal && text.strong ? 'flex-start' : 'center'}
        >
          {text.normal && (
            <Text variant="caption" fontWeight="normal">
              {text.normal}
            </Text>
          )}
          {text.strong && (
            <Text variant="caption" fontWeight="bold">
              {text.strong}
            </Text>
          )}
        </Flex>
        <Flex
          w="16"
          minH="16"
          bgColor="green.500"
          alignItems="center"
          justifyContent="center"
          transition="all 0.3s ease-in-out"
          position="relative"
        >
          <Box
            position="absolute"
            w="0"
            h="100%"
            bgColor={type === 'dark' ? 'grey.800' : type === 'light' ? 'blue.100' : 'blue.200'}
            transition="all 0.3s ease-in-out"
            left="0"
            top="0"
            _groupHover={{ w: '100%' }}
            zIndex="1"
          />
          <ArrowRight
            _groupHover={{ color: type === 'dark' ? 'white' : 'grey.900' }}
            color="white"
          />
        </Flex>
      </Flex>
    </PrimaryButton>
  );
};
