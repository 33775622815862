import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { Container, Flex } from '@terminal/design-system';
import type { StackStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';
import * as CSS from 'csstype';

export function Stack({ blok, page }: Storyblok<StackStoryblok>) {
  const { marginStyles, textStyles, flexStyles } = selectBlokStyles(blok);

  const _Flex = (
    <Flex
      direction={(flexStyles?.direction as CSS.Property.FlexDirection) ?? ['column', 'row']}
      flexWrap="wrap"
      gap={flexStyles?.gap || 8}
      alignItems={flexStyles?.alignItems || 'stretch'}
      justifyContent={flexStyles?.justifyContent || 'flex-start'}
      // @ts-ignore
      textAlign={textStyles.textAlign || 'left'}
      justifyItems="center"
      {...storyblokEditable(blok)}
      {...marginStyles}
    >
      {blok.columns?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
      ))}
    </Flex>
  );

  if (blok.fullWidth) {
    return _Flex;
  }

  return <Container>{_Flex}</Container>;
}
