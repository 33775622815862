import { storyblokEditable } from '@storyblok/react';
import { Flex } from '@terminal/design-system';
import { OutlineButton } from '@components/UI/Button';
import { SkillsListStoryblok, Storyblok } from '../types';

export function SkillsList({ blok, page }: Storyblok<SkillsListStoryblok>) {
  return (
    <Flex justifyContent="center" flexWrap="wrap" gap={6} {...storyblokEditable(blok)}>
      {page?.extra?.skills?.map((el) => (
        <OutlineButton
          as="a"
          key={el.slug}
          href={el.full_slug}
          fontSize="sm"
          fontWeight={blok.whitePill ? '700' : 'normal'}
          borderRadius="6.25rem"
          color="grey.900"
          border="none"
          px={3}
          py={1}
          minW={{ sm: '10.625rem' }}
          h="2.25rem"
          textAlign="center"
          w={['auto', 'initial']}
          bgColor={blok.whitePill ? 'white' : 'bg.secondary'}
          _hover={{
            backgroundColor: 'blue.100',
            boxShadow: 'md',
          }}
        >
          {el.name}
        </OutlineButton>
      ))}
    </Flex>
  );
}
