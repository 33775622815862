import { Heading, Container, Text, Flex } from '@chakra-ui/react';

import { OutlineButton, PrimaryButton } from '../UI/Button';
import hire from '../../public/images/build-your-team/hire.svg';
import { trackCtaClicked } from '../../lib/segment/trackAnalytics';
import ImageShimmer from '../ImageShimmer/ImageShimmer';

const HeroDynamicSEO = ({
  headingText,
  subHeadingText,
  heroUrlImg = hire,
  ctas,
}: {
  headingText: string;
  subHeadingText: string;
  heroUrlImg?: string;
  ctas: {
    primary: {
      url: string;
      text: string;
    };
    secondary: {
      url: string;
      text: string;
    };
  };
}) => (
  <Container
    py={{ base: 6, lg: 8 }}
    display="flex"
    gap={{ base: 7, lg: 14 }}
    flexDir={{ base: 'column', lg: 'row' }}
  >
    <Flex justifyContent="center" flexDir="column">
      <Heading
        as="h1"
        fontSize={{ base: '2.5rem', lg: '3rem' }}
        mb={4}
        dangerouslySetInnerHTML={{
          __html: headingText,
        }}
      />
      <Text fontSize="lg" mb={{ base: 4, md: 6 }} lineHeight="shorter">
        {subHeadingText}
      </Text>
      <Flex flexDir={['column', 'column', 'row']} gridGap={4}>
        <Flex
          flexDir="column"
          minW={{ base: 'initial', lg: '240px' }}
          w="full"
          maxW={{ base: 'initial', lg: '220px' }}
        >
          <PrimaryButton
            href={ctas.primary.url}
            w="full"
            px={{ lg: 3 }}
            mb={2}
            onClick={() =>
              trackCtaClicked({
                name: ctas.primary.text,
                type: 'businesses',
                context: 'Hero',
                link: ctas.primary.url,
              })
            }
            textTransform="capitalize"
          >
            {ctas.primary.text}
          </PrimaryButton>
          <Text lineHeight="base" fontSize="xs" mb={1}>
            FREE to try! No cost to get started.
          </Text>
        </Flex>
        <OutlineButton
          href={ctas.secondary.url}
          w="full"
          maxW={{ base: 'initial', lg: '220px' }}
          px={{ lg: 3 }}
          onClick={() =>
            trackCtaClicked({
              type: 'businesses',
              name: ctas.secondary.text,
              context: 'Hero',
              link: ctas.secondary.url,
            })
          }
        >
          {ctas.secondary.text}
        </OutlineButton>
      </Flex>
    </Flex>
    <Flex minW={{ base: 'full', lg: '410px' }} minH={{ base: '190px', lg: '315px' }}>
      <ImageShimmer
        src={heroUrlImg}
        alt="Hire illustration"
        style={{ objectFit: 'contain' }}
        layout="intrinsic"
      />
    </Flex>
  </Container>
);

export default HeroDynamicSEO;
