import { textTheme } from '../Text';

type Dict = Record<string, any>;

const colorSchemaMaps = {
  primary: {
    bg: 'brand.lighter',
    color: 'text.primary',
  },
  'accent-lightest': {
    bg: 'accent.lightest',
    color: 'text.primary',
  },
  'accent-lighter': {
    bg: 'accent.lighter',
    color: 'text.primary',
  },
  success: {
    bg: 'ui.lighter.success',
    color: 'text.primary',
  },
  warning: {
    bg: 'ui.lighter.warning',
    color: 'text.primary',
  },
  info: {
    bg: 'ui.lighter.info',
    color: 'text.primary',
  },
  error: {
    bg: 'ui.lighter.error',
    color: 'text.primary',
  },
  disabled: {
    bg: 'ui.disabled',
    color: 'text.primary',
  },
  'disabled-dark': {
    bg: 'ui.darker.disabled',
    color: 'text.primary',
  },
};

export const badgeTheme = {
  variants: {
    subtle: (props: Dict) => ({
      // @ts-ignore
      ...(colorSchemaMaps[props.colorScheme as string] || colorSchemaMaps['brand-light']),
    }),
  },
  baseStyle: {
    ...textTheme.variants.body,
    height: 'fit-content',
    outline: 0,
    _focus: {
      boxShadow: 'outline',
    },
    bg: 'ui.lighter.info',
    textTransform: 'none',
  },
  sizes: {
    lg: {
      ...textTheme.variants.label,
      px: 2,
      borderRadius: 'base',
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'subtle',
    colorScheme: 'primary',
  },
};
