import { Container, Box, Grid, GridItem, Heading } from '@chakra-ui/react';
import getConfig from 'next/config';

import FooterCTASvg from './FooterCTASvg';
import FooterCTAImage from './FooterCTAImage';
import { OutlineButton, PrimaryButton } from '../UI/Button';
import { trackCtaClicked } from '../../lib/segment/trackAnalytics';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl, signUpUrl } = publicRuntimeConfig;

export default function FooterCTA({
  type,
  footer,
}: {
  type: string;
  footer: {
    longText: boolean;
    text: string;
    image?: string;
    button?: {
      url: string;
      text: string;
    };
    outlineButton?: {
      url: string;
      text: string;
    };
  };
}) {
  const customGetStartedLink = () => {
    if (type === 'businesses') {
      return footer.button?.url ?? talentHubOnboardingUrl;
    } else {
      return footer.button?.url ?? signUpUrl;
    }
  };

  return (
    <Box
      mt={{ base: 10, lg: 16 }}
      position="relative"
      _before={{
        base: {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '6.5rem',
          left: '0',
          bottom: '0',
          bgColor: 'blue.100',
          zIndex: '-1',
        },
        lg: {
          height: '11rem !important',
        },
      }}
    >
      <Container>
        <Box w={{ base: '100%' }} pb={{ base: '12', lg: '0' }} bgColor="grey.900">
          <Grid
            gridTemplateColumns={{
              lg: footer && footer.longText ? '2fr 1fr' : '1fr 1fr',
              xl: footer && footer.longText ? '1.5fr 1fr' : '1fr 1fr',
            }}
          >
            <GridItem order={{ lg: 1 }}>
              {!footer?.image && (
                <Box
                  px={{ base: '8', lg: '1.375rem' }}
                  mb={{ base: '8', lg: '0' }}
                  py={{ lg: '6' }}
                  display={{ lg: 'flex' }}
                  h={{ lg: '100%' }}
                  alignItems={{ lg: 'center' }}
                  justifyContent={{ lg: 'flex-end' }}
                >
                  <FooterCTASvg
                    w={{ base: '100%' }}
                    longText={footer && footer.longText ? footer.longText : null}
                  />
                </Box>
              )}
              {footer?.image && <FooterCTAImage image={footer.image} />}
            </GridItem>
            <GridItem
              display={{ lg: 'flex' }}
              alignItems={{ lg: 'center' }}
              py={{ lg: footer && footer.longText ? '10' : '0' }}
            >
              <Box px={{ base: '16', lg: '0' }} pl={{ lg: '16' }}>
                {footer && footer.text && (
                  <Heading
                    as="h3"
                    color="white"
                    fontSize={{ base: '1.75rem', lg: '2.625rem' }}
                    lineHeight={{ base: '8', lg: '3rem' }}
                    fontWeight="bold"
                  >
                    {footer.text}
                  </Heading>
                )}
                <PrimaryButton
                  hoverType="onBlack"
                  w={{ base: '100%', lg: 'auto' }}
                  mt={{ base: 6, lg: 8 }}
                  px={{ lg: 5 }}
                  href={customGetStartedLink()}
                  onClick={() =>
                    trackCtaClicked({
                      name: footer.button?.text ? footer.button.text : 'Get Started',
                      type,
                      context: 'Footer',
                      link: customGetStartedLink(),
                    })
                  }
                >
                  {footer.button?.text ? footer.button.text : 'Get Started'}
                </PrimaryButton>
                {footer.outlineButton?.url && (
                  <OutlineButton
                    href={footer.outlineButton.url}
                    w={{ base: '100%', lg: 'auto' }}
                    mt={{ base: 6, lg: 8 }}
                    px={{ lg: 5 }}
                    ml={{ base: 0, lg: 4 }}
                    onClick={() =>
                      trackCtaClicked({
                        name: footer.outlineButton.text,
                        type,
                        context: 'Footer',
                        link: footer.outlineButton.url,
                      })
                    }
                    _hover={{
                      borderColor: 'white',
                      color: 'white',
                    }}
                  >
                    {footer.outlineButton.text}
                  </OutlineButton>
                )}
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
