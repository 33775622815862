import { Box, Flex } from '@terminal/design-system';
import { DecorativeColorBoxesStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';
import { storyblokEditable } from '@storyblok/react';

export function DecorativeColorBoxes({ blok }: Storyblok<DecorativeColorBoxesStoryblok>) {
  const { flexStyles, sizesStyle, marginStyles } = selectBlokStyles(blok);

  return (
    <Flex {...flexStyles} {...sizesStyle} {...marginStyles} {...(storyblokEditable(blok) as any)}>
      <Box w={2} h={2} bgColor="ui.darker.info" />
      <Box w={2} h={2} bgColor="ui.warning" />
      <Box w={2} h={2} bgColor="ui.success" />
    </Flex>
  );
}
