import { gql, useQuery } from '@apollo/client';
import { EngineeringJobBoardStoryblok, Storyblok } from '../types';
import { SelectAllJobsMarketingQuery } from '@_pages/engineering/job-openings/data';
import JobBoard, { JobBoardLoading } from '@components/UI/JobBoard/JobBoard';
import { serializeJobDetails } from '@terminal/candidate/features/job/utils';
import getConfig from 'next/config';
import { storyblokEditable } from '@storyblok/react';
import { Box } from '@terminal/design-system';

const GET_JOBS = gql`
  query JobBoardJobs @cached(ttl: 600) {
    icims_job(
      where: {
        _and: [
          { icims_initial_data_transfer: { is_current: { _eq: true } } }
          { icims_profile_job: { active_on_job_portal_at: { _is_null: false } } }
          { icims_folder: { value: { _eq: "Approved" } } }
          { is_position: { _eq: false } }
          {
            icims_job_positions: {
              icims_folder: { value: { _eq: "Approved" } }
              is_position: { _eq: true }
            }
          }
          { icims_company: { organizations: { name: { _neq: "Terminal" } } } }
          { icims_company: { organizations: { logo: { _is_null: false, _neq: "" } } } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      profile_id
      created_at
      jobtitle
      tech_stack
      degree_requirement
      years_experience
      family
      icims_company {
        numofemps
        organizations {
          id
          salesforce_customer {
            industry
            website
          }
          logo
          name
          about
        }
      }
      about
      what_youll_do
      what_you_bring
      icims_job_locations {
        icims_location {
          value
        }
      }
      job {
        employment_type
      }
    }
    skill {
      name
    }
  }
`;

const { publicRuntimeConfig } = getConfig();

const signUpUrl = publicRuntimeConfig.signUpUrl;

export function EngineeringJobBoard({ blok }: Storyblok<EngineeringJobBoardStoryblok>) {
  const { data: hasuraData, loading: hasuraLoading } = useQuery<SelectAllJobsMarketingQuery>(
    GET_JOBS,
    {
      context: { clientName: 'hasura' },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
  );
  const jobs = (hasuraData?.icims_job || [])
    .filter((job, index, self) => {
      const companyName = job.icims_company.organizations[0].name;
      return index === self.findIndex((j) => j.icims_company.organizations[0].name === companyName);
    })
    .splice(0, 4)
    .map((job) => serializeJobDetails({ job }));
  return (
    <Box {...storyblokEditable(blok)}>
      {hasuraLoading ? (
        <JobBoardLoading signUpUrl={signUpUrl} bgColor="bg.tertiary" />
      ) : (
        <JobBoard jobs={jobs} signUpUrl={signUpUrl} bgColor="bg.tertiary" />
      )}
    </Box>
  );
}
