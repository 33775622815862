import { storyblokEditable } from '@storyblok/react';
import getConfig from 'next/config';

import {
  Box,
  Heading,
  Container,
  Text,
  UnorderedList,
  ListIcon,
  ListItem,
  Flex,
  Link,
} from '@terminal/design-system';
import { PrimaryButton, GhostButton } from '@components/UI/Button';
import ImageShimmer from '@components/ImageShimmer/ImageShimmer';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import SquarePattern from '@components/UI/SquarePattern';
import { CheckIcon } from '@components/Icons/Check';
import deskImg from '@public/images/hiring-plans/img-complete-desk.png';
import whiteSquares from '@public/images/ui/square-pattern-white.svg';
import { Storyblok, HiringPlansHeroStoryblok } from '../types';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;

export function HiringPlansHero({ blok }: Storyblok<HiringPlansHeroStoryblok>) {
  return (
    <Box
      as="section"
      bgColor="blue.100"
      pt={{ base: 10, lg: 16 }}
      pb={{ base: 6, lg: 8 }}
      pos="relative"
      overflow="hidden"
      {...storyblokEditable(blok)}
    >
      <Container display="flex" pos="relative" flexDir="column" zIndex={1}>
        <Heading as="h1" mb={10} variant="heading-1" textAlign="center" textTransform="capitalize">
          Hiring Options that{' '}
          <Box as="span" color="brand.main">
            Fit your business.
          </Box>
        </Heading>
        <Flex bgColor="white" flexDir={{ base: 'column', lg: 'row' }} mb={10}>
          <Box
            flex={1}
            px={{ base: 4, md: 10 }}
            py={14}
            textAlign="center"
            borderRight={{ lg: '1px solid' }}
            borderBottom={{ base: '1px solid', lg: 'none' }}
            borderRightColor={{ lg: 'ui.secondary' }}
            borderBottomColor={{ base: 'ui.secondary' }}
          >
            <Heading
              as="p"
              fontSize="1.75rem"
              lineHeight="short"
              fontWeight={700}
              color="brand.main"
              mb={2}
            >
              Hire
            </Heading>
            <Heading as="h2" variant="heading-4" mb={6}>
              Hire full-time or contract developers for your growing team. Terminal will employ your
              hires or you can self-manage.
            </Heading>
            <UnorderedList spacing={3} mb={6} w="fit-content" mx="auto">
              {[
                'Elite, vetted developers',
                'Comprehensive interview and hiring support',
                'Expert guidance every step of the way',
              ].map((item) => (
                <ListItem
                  key={item}
                  display="flex"
                  alignItems="center"
                  textAlign={{ base: 'left', md: 'center' }}
                  fontSize="md"
                >
                  <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                  {item}
                </ListItem>
              ))}
            </UnorderedList>
            <Text fontSize="sm" color="text.secondary" fontWeight={700} mb={3}>
              Manage employment the way you want
            </Text>
            <Flex alignItems="center" flexDir={{ base: 'column', md: 'row' }}>
              <Box bgColor="bg.secondary" p={4} textAlign="left">
                <Text fontSize="sm" fontWeight={700} mb={1}>
                  Hire + Employ
                </Text>
                <Text fontSize="sm">
                  Use Terminal for payroll, benefits & EOR services for your global hires
                </Text>
              </Box>
              <Text
                fontSize="sm"
                fontWeight={700}
                mx={3}
                my={{ base: 3, md: 0 }}
                color="ui.darker.success"
              >
                OR
              </Text>
              <Box bgColor="bg.secondary" p={4} textAlign="left">
                <Text fontSize="sm" fontWeight={700} mb={1}>
                  Hire Direct
                </Text>
                <Text fontSize="sm">
                  Manage employment through your own entity or employer of record (EOR)
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box flex={1} px={{ base: 4, md: 10 }} py={14} textAlign="center">
            <Heading
              as="p"
              fontSize="1.75rem"
              lineHeight="short"
              fontWeight={700}
              color="brand.main"
              mb={2}
            >
              Contract
            </Heading>
            <Heading as="h2" variant="heading-4" mb={6}>
              Onboard contract developers for fixed-term engagements. Terminal manages invoicing and
              payment.
            </Heading>
            <UnorderedList spacing={3} ml={0}>
              <ListItem
                key="Elite, vetted developers"
                display="flex"
                alignItems="center"
                textAlign={{ base: 'left', md: 'center' }}
                fontSize="md"
              >
                <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                Elite, vetted developers
              </ListItem>
              <ListItem
                key="Comprehensive interview and hiring support"
                display="flex"
                alignItems="center"
                textAlign={{ base: 'left', md: 'center' }}
                fontSize="md"
              >
                <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                Comprehensive interview and hiring support
              </ListItem>
              <ListItem
                key="Expert guidance every step of the way"
                display="flex"
                alignItems="center"
                textAlign={{ base: 'left', md: 'center' }}
                fontSize="md"
              >
                <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                Expert guidance every step of the way
              </ListItem>
              <ListItem
                key="Flexible engagements from 3 to 12 months"
                display="flex"
                alignItems="center"
                textAlign={{ base: 'left', md: 'center' }}
                fontSize="md"
              >
                <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                Flexible engagements from 3 to 12 months
              </ListItem>
              <ListItem
                key="Contract-to-hire options available"
                display="flex"
                alignItems="center"
                textAlign={{ base: 'left', md: 'center' }}
                fontSize="md"
              >
                <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                <Link
                  textDecoration="underline"
                  textUnderlineOffset="0.125rem"
                  href="/hiring-plans/contract-to-hire-developers"
                  mr={1}
                >
                  Contract-to-hire
                </Link>
                options available
              </ListItem>
              <ListItem
                key="Fully managed invoicing and payment"
                display="flex"
                alignItems="center"
                textAlign={{ base: 'left', md: 'center' }}
                fontSize="md"
              >
                <ListIcon as={CheckIcon} color="brand.main" w={4} h={4} mr={3} />
                Fully managed invoicing and payment
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
        <PrimaryButton
          href={talentHubOnboardingUrl}
          px={6}
          w={{ base: 'full', lg: '395px' }}
          mb={2}
          alignSelf="center"
          onClick={() =>
            trackCtaClicked({
              type: 'businesses',
              name: 'Browse Talent',
              context: 'Hiring Plans',
              link: talentHubOnboardingUrl,
            })
          }
        >
          Browse Talent
        </PrimaryButton>
        <GhostButton
          href="/contact?v=1"
          px={6}
          py={4}
          w={{ base: 'full', lg: '395px' }}
          alignSelf="center"
          color="accent.main"
          _hover={{
            borderColor: 'accent.main',
          }}
          onClick={() =>
            trackCtaClicked({
              type: 'businesses',
              name: 'Talk With Us About Your Needs',
              context: 'Hiring Plans',
              link: '/contact?v=1',
            })
          }
        >
          Talk With Us About Your Needs
        </GhostButton>
        <Box
          pos="absolute"
          bottom="-2rem"
          right={{ lg: '-1rem', xl: '-8%' }}
          display={{
            base: 'none',
            lg: 'initial',
          }}
        >
          <ImageShimmer src={deskImg} alt="Home office desk and plant" width={300} height={215} />
        </Box>
      </Container>
      <SquarePattern
        pos="absolute"
        right="0"
        top="25%"
        w="full"
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        bgImage={`url(${whiteSquares.src})`}
      />
    </Box>
  );
}
