import { Box, List, ListItem, Link } from '@terminal/design-system';
import ImageShimmer from '../../ImageShimmer/ImageShimmer';
import { serializeJobDetails } from '@terminal/candidate/features/job/utils';
import { useRouter } from 'next/router';
import { createSelectedJobRoute_basedOnFilters } from '@_pages/engineering/job-openings/utils';

export default function JobBoardItem({
  job: jobItem,
}: {
  job: ReturnType<typeof serializeJobDetails>;
}) {
  const router = useRouter();
  const marketingQueryparams = router.query;

  return (
    <Link
      href={`/engineers/job-openings/${createSelectedJobRoute_basedOnFilters(
        jobItem,
        {
          company: null,
          country: null,
          skills: null,
          role: null,
        },
        marketingQueryparams,
      )}`}
      display="block"
      maxW="34rem"
      minH={{ md: '100%' }}
      mx="auto"
      pt="7"
      px="8"
      pb="4"
      bgColor="white"
      boxShadow="0 0.125rem 0.5rem rgba(28, 40, 45, 0.2)"
      transition="box-shadow 0.2s ease-in-out"
      _hover={{
        boxShadow: '0 0.5rem 0.8rem rgba(28, 40, 45, 0.2)',
      }}
    >
      {!!jobItem.image.src && (
        <ImageShimmer
          src={jobItem.image.src}
          alt={jobItem.image.alt}
          width="137"
          height="32"
          style={{ objectFit: 'contain' }}
          layout="intrinsic"
        />
      )}
      <Box mt="2" fontSize="sm" lineHeight="4" fontWeight="medium" color="grey.900">
        {jobItem.jobDetail.location.join(', ')}
      </Box>
      <Box mt="2" fontSize="1.375rem" lineHeight="6" fontWeight="bold" color="grey.900">
        {jobItem.title}
      </Box>
      <Box mt="2" fontSize="sm" lineHeight="4" color="grey.900" fontWeight="medium">
        <Box h={{ md: '2rem' }} overflow="hidden">
          Industry:{' '}
          <Box as="span" color="green.500" fontWeight="medium">
            {jobItem.jobDetail.industry}
          </Box>
        </Box>
        <List display="flex" flexWrap="wrap" mt="4" maxHeight="9rem" overflow="hidden">
          {jobItem.skills.map((item: string, i: number) => (
            <ListItem
              mr="4"
              mb="4"
              py="2"
              px="4"
              key={i}
              color="blue.800"
              bgColor="blue.100"
              borderRadius="6.25rem"
              whiteSpace="nowrap"
              maxW="10rem"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {item}
            </ListItem>
          ))}
        </List>
      </Box>
    </Link>
  );
}
