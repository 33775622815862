export { LinkOverlay } from '@chakra-ui/react';
// design-system-diff
import { Link as ChakraNextLink } from '@chakra-ui/next-js';
import type { LinkProps } from '@chakra-ui/next-js';
import { forwardRef } from '@chakra-ui/react';
export { Link as NonNextLink } from '@chakra-ui/react';
export * from './Link.theme';

export const Link = forwardRef<LinkProps, 'a'>((props, ref) => {
  const { ...rest } = props;

  return <ChakraNextLink ref={ref} {...rest} prefetch={false} />;
});
