import { extendTheme, theme as defaultTheme } from '@terminal/design-system';

const old_theme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'grey.900',
        fontFamily: 'body',
      },
    },
  },
  components: {
    Container: {
      baseStyle: {
        maxW: ['100%', '540px', '720px', '960px', '1140px'],
      },
    },
    Heading: {
      baseStyle: {
        color: 'grey.900',
      },
    },
    Text: {
      baseStyle: {
        color: 'grey.900',
      },
    },
    Modal: {
      variants: {
        bottomModal: {
          dialogContainer: {
            height: 'auto',
            maxWidth: {
              base: '58.0625rem',
              lg: '39rem',
              xl: '54rem',
              '2xl': '62rem',
            },
            width: { base: '95%', '2xl': '100%' },
            '@supports(height: -webkit-fill-available)': {
              height: 'auto',
            },
            margin: {
              base: '0 auto',
              lg: '0 calc(50% - 490px) 0 auto',
              xl: '0 calc(50% - 600px) 0 auto',
              '2xl': '0 calc(50% - 680px) 0 auto',
            },
            top: 'auto',
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'visible',
            zIndex: '900',
          },
        },
      },
    },
  },
  fonts: {
    heading: 'var(--font-outfit), sans-serif',
    body: 'var(--font-outfit), sans-serif',
  },
  colors: {
    white: '#FFFFFF',
    grey: {
      100: '#F4F7F9',
      200: '#EEF0F1',
      300: '#D5DADC',
      400: '#ABB6BA',
      500: '#8398A0',
      600: '#637A83',
      700: '#49646E',
      800: '#344951',
      900: '#25383F',
      1000: '#1C282D',
    },
    blue: {
      100: '#E8EFF2',
      200: '#D9E3E7',
      400: '#8BBFD0',
      500: '#5E9CB0',
      600: '#5790A2',
      700: '#517E94',
      800: '#3D606C',
      900: '#334E57',
      1000: '#27393F',
    },
    green: {
      100: '#E7F5D6',
      200: '#CEE9AF',
      300: '#BFD897',
      400: '#96BA6E',
      500: '#7AA550',
      600: '#70954B',
      700: '#638245',
      800: '#546D3C',
      900: '#4C6138',
      1000: '#3F482E',
    },
    yellow: {
      100: '#FFECBD',
      200: '#FFDC82',
      300: '#F9C66C',
      400: '#F6AE2D',
      500: '#F0960F',
      600: '#DA7C10',
      700: '#C86A19',
      800: '#9A4B1D',
      900: '#7D391C',
      1000: '#5B260B',
    },
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1400px',
  },
});

const ui = {
  error: {
    lightest: '#FEF0EC',
    lighter: '#FEC9BD',
    main: '#F6623A',
    darker: '#842813',
    darkest: '#3A0F03',
  },
  warning: {
    lightest: '#FEF8EB',
    lighter: '#FFECBD',
    main: '#F6AE2D',
    darker: '#9A4B1D',
    darkest: '#3A2903',
  },
  success: {
    lightest: '#F5F8F1',
    lighter: '#CEE9AF',
    main: '#96BA6E',
    darker: '#546D3C',
    darkest: '#2B361B',
  },
  info: {
    lightest: '#E7F0F5',
    lighter: '#BADFF4',
    main: '#84CAF1',
    darker: '#48A1D3',
    darkest: '#177FB9',
  },
};

export const colors = {
  brand: {
    lightest: '#E7F5D6',
    lighter: '#BFD897',
    main: '#7AA550',
    darker: '#638245',
    darkest: '#4C6138',
  },
  accent: {
    lightest: '#E8EFF2',
    lighter: '#8BBFD0',
    main: '#5E9CB0',
    darker: '#517E94',
    darkest: '#25383F',
  },
  ui: {
    primary: '#25383F',
    secondary: '#D5DADC',
    disabled: '#EEF0F1',
    error: ui.error.main,
    success: ui.success.main,
    warning: ui.warning.main,
    info: ui.info.main,
    inverse: {
      primary: '#FFFFFF',
      secondary: '#F4F7F9',
    },
    lightest: {
      error: ui.error.lightest,
      success: ui.success.lightest,
      warning: ui.warning.lightest,
    },
    lighter: {
      error: ui.error.lighter,
      success: ui.success.lighter,
      warning: ui.warning.lighter,
      info: ui.info.lighter,
    },
    darker: {
      error: ui.error.darker,
      success: ui.success.darker,
      warning: ui.warning.darker,
      disabled: '#AFAFAF', // remove?
    },
    darkest: {
      error: ui.error.darkest,
      success: ui.success.darkest,
      warning: ui.warning.darkest,
    },
  },
  bg: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    tertiary: '#E8EFF2',
  },
  text: {
    primary: '#25383F',
    secondary: '#637A83',
    disabled: '#AFAFAF',
    inverse: '#FFFFFF',
    error: '#F6623A',
    success: '#96BA6E',
    link: '#5E9CB0',
    warning: ui.warning.lighter,
  },
};

type Dict = Record<string, any>;
const candidateTempButtonTheme = {
  variants: {
    // Temporarily overwrite the solid theme of the base theme, because the candidate app uses
    // accent.darkest for the bgHover of the solid:primary button instead of
    // accent.darkest
    solid: (props: Dict) => {
      const { colorScheme } = props;
      type ButtonColorScheme = 'primary' | 'accent' | 'error';

      const validColorScheme: ButtonColorScheme = ['primary', 'accent', 'error'].includes(
        colorScheme,
      )
        ? colorScheme
        : 'primary';

      const colorMap: {
        [key in ButtonColorScheme]: {
          bg: string;
          hoverBg: string;
          activeBg: string;
        };
      } = {
        primary: {
          bg: 'brand.main',
          hoverBg: 'accent.darkest',
          activeBg: 'accent.darkest',
        },
        accent: {
          bg: 'accent.main',
          hoverBg: 'accent.darker',
          activeBg: 'accent.darker',
        },
        error: {
          bg: 'ui.error',
          hoverBg: 'ui.darker.error',
          activeBg: 'ui.darker.error',
        },
      };

      return {
        bg: colorMap[validColorScheme].bg,
        color: 'text.inverse',
        _hover: {
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            bg: 'ui.disabled',
            color: 'text.disabled',
          },
        },
        _active: {
          bg: colorMap[validColorScheme].activeBg,
        },
        _disabled: {
          bg: 'ui.disabled',
          color: 'text.disabled',
        },
      };
    },
    /**
     * @deprecated
     * Variant:Solid ColorSchema:Primary should be used instead after Gabi accepts this change
     * TODO: Write ticket number
     */
    primary: (props: Dict) => {
      const { colorScheme } = props;
      type ButtonColorScheme = 'primary' | 'error';

      const validColorScheme: ButtonColorScheme = ['primary', 'error'].includes(colorScheme)
        ? colorScheme
        : 'primary';

      const colorMap: {
        [key in ButtonColorScheme]: {
          bg: string;
          hoverBg: string;
          activeBg: string;
        };
      } = {
        primary: {
          bg: 'brand.main',
          hoverBg: 'accent.darkest',
          activeBg: 'accent.darkest',
        },
        error: {
          bg: 'ui.error',
          hoverBg: 'ui.darker.error',
          activeBg: 'ui.darker.error',
        },
      };

      return {
        bg: colorMap[validColorScheme].bg,
        color: 'text.inverse',
        _hover: {
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            bg: 'ui.disabled',
            color: 'text.disabled',
          },
        },
        _active: {
          bg: colorMap[validColorScheme].activeBg,
        },
        _disabled: {
          bg: 'ui.disabled',
          color: 'text.disabled',
        },
      };
    },
    /**
     * @deprecated
     * Variant:Solid ColorSchema:Primary should be used instead
     * TODO: Write ticket number
     */
    secondary: {
      color: 'text.primary',
      border: '1px',
      borderColor: 'text.primary',
      _hover: {
        bg: 'bg.tertiary',
        _disabled: {
          borderColor: 'ui.disabled',
          color: 'text.disabled',
        },
      },
      _disabled: {
        borderColor: 'ui.disabled',
        color: 'text.disabled',
      },
    },
  },
};

export const theme = extendTheme(
  {
    colors: {
      ...colors,
      ...old_theme.colors,
    },
    components: {
      Button: candidateTempButtonTheme,
      Modal: old_theme.components.Modal,
      Container: old_theme.components.Container,
    },
    breakpoints: old_theme.breakpoints,
    styles: old_theme.styles,
    fonts: old_theme.fonts,
  },
  defaultTheme,
);

export default theme;
