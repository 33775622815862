import { SelectAllJobsMarketingQuery } from './data';
import { serializeJobDetails } from '@terminal/candidate/features/job/utils';
import slugify from '@helpers/slugify';

import sharecardImage from '@public/images/sharecard.jpg';
import { sitePath_withEnv } from '@helpers/general.utils';

function addMonths(date: string, months: number) {
  // Create a new Date object from the input date
  const newDate = new Date(date);

  // Add x months to the new date
  newDate.setMonth(newDate.getMonth() + months);

  // Return the new date
  return newDate;
}

export type JobOpeningsFilters = {
  role: string | null;
  company: string | null;
  country: string | null;
  skills: string | null;
};

export const supportedCountries = [
  'Canada',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Mexico',
  'Poland',
  'Romania',
  'Spain',
];

export function serializerForMarketingJobs(data: SelectAllJobsMarketingQuery) {
  return {
    supportedRoles: data.icims_job.flatMap((job) => job.family),
    allJobs: data.icims_job.map((job) =>
      serializeJobDetails({
        job,
      }),
    ),
  };
}

export function extractFirstRouteInLowerCase(route: string | string[]) {
  if (Array.isArray(route)) {
    return route[0]?.toLowerCase() || null;
  } else {
    return route?.toLowerCase() || null;
  }
}

function isASupportedCountry(value: string) {
  return supportedCountries
    .map((country) => slugify(country.toLowerCase()))
    .some((country) => country.toLowerCase() === value.toLowerCase());
}

/**
 * Given a job opening path, it extracts and return the job ID, job title and filter
 * When there there is job ID, it assumes that it does not extract filters from the path
 */
export function demystifyJobOpeningsRouteRaw({
  slugs,
  skills,
  supportedRoles,
}: {
  slugs: string | string[];
  skills: string | string[];
  supportedRoles: string[];
}): {
  jobID: string | null;
  jobTitle: string | null;
  filter: {
    role: string | null;
    company: string | null;
    country: string | null;
    skills: string | null;
  };
} {
  function isASupportedRole(value: string) {
    return supportedRoles
      .map((role) => slugify(role.toLowerCase()))
      .some((role) => role.toLowerCase() === value.toLowerCase());
  }

  if (!Array.isArray(slugs)) {
    return {
      jobID: null,
      jobTitle: null,
      filter: {
        company: null,
        role: null,
        country: null,
        skills: extractFirstRouteInLowerCase(skills),
      },
    };
  }

  const jobIDSlugIndex = slugs.findIndex((slug) => !isNaN(Number(slug)));
  const dynamicSlugs = jobIDSlugIndex !== -1 ? slugs.slice(0, jobIDSlugIndex) : slugs;
  const [jobID, jobTitle] = jobIDSlugIndex !== -1 ? slugs.slice(jobIDSlugIndex) : [null, null];

  let role: null | string = null;
  let company: null | string = null;
  let country: null | string = null;

  /**
   * Priopritize the order of the slugs to be country, role and company
   */
  function assignSlug(slug: string) {
    if (isASupportedCountry(slug)) return ['country', slug];
    if (isASupportedRole(slug)) return ['role', slug];
    return ['company', slug];
  }

  dynamicSlugs.forEach((slug) => {
    const [type, value] = assignSlug(slug);
    if (type === 'role') role = value;
    if (type === 'company') company = value;
    if (type === 'country') country = value;
  });

  return {
    jobID,
    jobTitle,
    filter: {
      role,
      company,
      country,
      skills: extractFirstRouteInLowerCase(skills),
    },
  };
}

/**
 * Creates job route while considering the filters that are nested in the route such that it retain does filters
 */
export function createSelectedJobRoute_basedOnFilters(
  job: ReturnType<typeof serializeJobDetails>,
  filters: JobOpeningsFilters,
  restOfQuerystring: {
    [key: string]: string | string[];
  } = {},
) {
  const company = job.jobDetail.companyName != null ? slugify(job.jobDetail.companyName) : '';
  const jobTitle = slugify(job.jobDetail.title);
  const role = slugify(job.jobDetail.role);
  const jobID = job.id.toString();

  // The dynamic route is the route the part of route is can change base on filters. It does
  // not inlclude the jobID and jobTitle
  const dynamicRoute = createJobOpeningDynamicRoute_basedOnFilters(filters, { company, role });

  const skills = slugify(
    job.jobDetail.techStacks
      .slice(0, 5)
      .map((skill) => skill.trim())
      .join('-')
      .toLowerCase(),
  );
  const locations =
    job.jobDetail.location.length <= 3
      ? slugify(job.jobDetail.location.join('-').toLowerCase())
      : '';

  const fullPath = `${dynamicRoute}/${jobID}/${jobTitle}`.toLowerCase();
  const queryString = createJobOpeningsQueryString(filters.skills, restOfQuerystring);

  // Initially we thought this needs to 120 for SEO, then I learnt that SEO doesn't care about the length of the title
  // In order to leave the logic of controlling the lenght of the title, I changed this value from 120 to the arbitrary
  // value 240 which is just going to make sure the title length is not oddly too long and allows us to keep the logic
  // in the code
  const maxTitleLength = 240;
  const titleAllowedLength =
    maxTitleLength - `https://www.terminal.io/engineers/job-openings/`.length;

  // We want to make sure the path is not longer than 120 characters for SEO purposes
  // -2 is to account for the '-' between the fullPath and locations and skills
  if (
    locations.length > 0 &&
    skills.length > 0 &&
    fullPath.length + skills.length + locations.length <= titleAllowedLength - 2
  ) {
    return `${fullPath}-${locations}-${skills}${queryString}`;
  }

  // -1 is to account for the '-' between the fullPath, skills
  if (skills.length > 0 && fullPath.length + skills.length <= titleAllowedLength - 1) {
    return `${fullPath}-${skills}${queryString}`;
  }

  if (locations.length > 0 && fullPath.length + locations.length <= titleAllowedLength - 1) {
    return `${fullPath}-${locations}${queryString}`;
  }

  return `${fullPath}${queryString}`;
}

/**
 * Based on the filters, and role and company of the job it returns a nested route that could have
 * all the 3 or less of {country}/{role}/{company} in the same order
 */
export function createJobOpeningDynamicRoute_basedOnFilters(
  filters: Omit<JobOpeningsFilters, 'skills'>,
  jobInfo: Pick<JobOpeningsFilters, 'role' | 'company'>,
): string {
  // Creates route by order of considered structure. If all the 3 country, role and compnay filter exists
  // it creates the route of {country}/{role}/{company}. If only role exists, it creates the route of {role}
  const availableFilters = [
    filters.country,
    !jobInfo ? filters.role : jobInfo.role || null,
    !jobInfo ? filters.company : jobInfo.company || null,
  ].filter((filter) => filter != null);

  return availableFilters.length > 0 ? `/${availableFilters.join('/')}` : '';
}

export function createJobOpeningsQueryString(
  skills: JobOpeningsFilters['skills'],
  restOfQuerystring: {
    [key: string]: string | string[];
  },
): string {
  const querryParamObjects = {
    ...(skills != null && { skills }),
    ...restOfQuerystring,
  };

  const params = Object.keys(querryParamObjects)
    .map((key) => `${key}=${querryParamObjects[key]}`)
    .join('&');

  return params.length > 0 ? `?${params}` : '';
}

/**
 * Creates list without job selection route while considering the filters that are nested in the route such that it retain does filters
 *
 */
export function createJobOpeningListFullRoute_basedOnFilters(
  { role, company, country, skills }: JobOpeningsFilters,
  restOfQuerystring: {
    [key: string]: string | string[];
  },
) {
  const queryString = createJobOpeningsQueryString(skills, restOfQuerystring);
  const dynamicRoute = createJobOpeningDynamicRoute_basedOnFilters(
    { role, company, country },
    null,
  );

  return `${dynamicRoute}${queryString}`;
}

/**
 * Transoforms role slugs to title case
 * @example transformRoleSlug_toTitleCase('qa-engineer') // returns 'QA Engineer'
 */
export function transformRoleSlug_toTitleCase(str: string) {
  const specialCases = {
    qa: 'QA',
    devops: 'DevOps',
  };

  return str
    .split('-')
    .map(
      (word) =>
        specialCases[word.toLowerCase()] ||
        `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`,
    )
    .join(' ');
}

export function createSEOData({
  allJobsData,
  selectedJobID,
  filter,
  siteEnv,
  jobOpeningsPath,
}: {
  allJobsData: SelectAllJobsMarketingQuery;
  selectedJobID: string;
  filter: JobOpeningsFilters;
  siteEnv: string;
  jobOpeningsPath: string;
}): {
  meta: {
    title: string;
    description: string;
    canonical: string;
  };
  openGraph: {
    url: string;
    type: 'website';
    images: {
      url: string;
      alt: string;
      width?: number;
      height?: number;
    }[];
    site_name: 'Terminal.io';
  };
  twitter: {
    card: string;
    site: string;
    image: string;
  };
  structuredData: {
    '@context': 'https://schema.org';
    '@type': 'JobPosting';
    identifier: {
      '@type': 'PropertyValue';
      name: string;
      value: string | number;
    };
    title: string;
    description: string;
    datePosted: string;
    validThrough: string;
    // Remote location requirements
    applicantLocationRequirements: {
      '@type': string;
      name: string;
    }[];
    jobLocationType: 'TELECOMMUTE';
    employmentType: 'FULL_TIME' | 'CONTRACTOR' | ['FULL_TIME', 'CONTRACTOR'];
    hiringOrganization: {
      '@type': 'Organization';
      name: string;
      sameAs: string;
      logo: string;
    };
    skills: string;

    // Beta Features
    educationRequirements?: {
      '@type': 'EducationalOccupationalCredential';
      credentialCategory: string;
    };
    experienceRequirements?: {
      '@type': 'OccupationalExperienceRequirements';
      monthsOfExperience: number;
    };
  } | null;
} {
  const { allJobs } = serializerForMarketingJobs(allJobsData);

  const selectedJob = allJobs.find((job) => `${job.id}` === selectedJobID);

  const roleTitlePart = filter.role ? `${transformRoleSlug_toTitleCase(filter.role)}` : 'Developer';

  const roleDescriptionPart = filter.role
    ? `full-time and contract ${transformRoleSlug_toTitleCase(filter.role)}`
    : 'full-time and contract remote developer';

  const countryTitleAndDescriptionPart = filter.country
    ? ` in ${transformRoleSlug_toTitleCase(filter.country)}`
    : '';

  if (selectedJob == null) {
    const noFilterListPage_meta = {
      title: `Remote ${roleTitlePart} Job Openings${countryTitleAndDescriptionPart} with Terminal | Terminal.io`,
      description: `Find ${roleDescriptionPart} roles${countryTitleAndDescriptionPart} with Terminal. Explore our frontend, backend, full stack, mobile jobs and more engineering roles available in Canada, Mexico, Colombia, and across LatAm and Europe.`,
      // TODO: get this from the utils to consider filter
      canonical: sitePath_withEnv({
        path: `${jobOpeningsPath}${createJobOpeningDynamicRoute_basedOnFilters(filter, null)}`,
        siteEnv,
      }),
    };

    return {
      meta: noFilterListPage_meta,
      openGraph: {
        site_name: 'Terminal.io',
        type: 'website',
        url: noFilterListPage_meta.canonical,
        images: [
          {
            url: sharecardImage.src,
            alt: 'Terminal.io jobs openings page image',
            width: sharecardImage.width,
            height: sharecardImage.height,
          },
        ],
      },
      twitter: {
        card: 'summary_large_image',
        site: '@terminal',
        image: sharecardImage.src,
      },
      structuredData: null,
    };
  }

  // countries for JobSchema
  const countries = selectedJob?.jobDetail?.location.map((loc) => {
    const country = loc.includes(',') ? loc.split(',').slice(1).join(',').trim() : loc;
    if (country === 'Kitchener') {
      return 'Canada';
    }
    return country;
  });

  const uniqueCountries = countries?.filter(function (item, pos, self) {
    return self.indexOf(item) === pos;
  });

  const selectedJobMeta = {
    title: `${selectedJob.jobDetail.title}${
      selectedJob.skills.length ? ` - ${selectedJob.skills.slice(0, 3).join(', ')}` : ''
    } | Terminal`,
    description: `Apply for the ${selectedJob.jobDetail.title} role with ${selectedJob.jobDetail.companyName} through Terminal.  Find your perfect remote engineer role today.`,
    canonical: sitePath_withEnv({
      path: `${jobOpeningsPath}${createSelectedJobRoute_basedOnFilters(selectedJob, {
        ...filter,
        // in canonical we want to always use the url structure that has {role}/{company}/{jobId}/{jobTitle+skills}
        // and we don't want country to be in that path
        country: null,
      })}`.split('?')[0],
      siteEnv,
    }),
  };

  const monthsOfExperience: number =
    {
      ZERO_TWO: 0,
      TWO_FIVE: 24,
      FIVE_TEN: 60,
      TEN_PLUS: 120,
    }[selectedJob.jobDetail.requiredYearsOfExperience] || null;

  const credentialCategory: string | null =
    {
      'High School': 'high school',
      Associate: 'associate degree',
      Bachelors: 'bachelor degree',
      'Postgraduate Degree': 'postgraduate degree',
      'Bootcamp / Certification': 'professional certificate',
      Other: null,
    }[selectedJob.jobDetail.requiredDegree] || null;

  const descriptionMain = selectedJob?.jobDetail?.descriptiveInfos
    ?.map((info) => {
      // Google only recognizes<p>, <ul>, and <li> tags and not headers and <strong>
      // @see https://developers.google.com/search/docs/appearance/structured-data/job-posting#job-posting-definition
      return `<p>${info.title}</p><br/>${info.description}`;
    })
    .join();

  const skilsDescription =
    selectedJob?.jobDetail?.techStacks &&
    `<br/><p>Skills:</p><br/><ul>${selectedJob.jobDetail.techStacks.map(
      (skill) => `<li>${skill}</li>`,
    )}</ul>`;

  const employmentTypeMapping = {
    'Full-Time': 'FULL_TIME',
    Contrator: 'CONTRACTOR',
    'Full-time & Contractor': ['FULL_TIME', 'CONTRACTOR'],
  } as const;

  const employmentType: 'FULL_TIME' | 'CONTRACTOR' | ['FULL_TIME', 'CONTRACTOR'] =
    employmentTypeMapping[selectedJob.jobDetail.employmentType] || 'FULL_TIME';

  return {
    meta: selectedJobMeta,
    openGraph: {
      url: selectedJobMeta.canonical,
      type: 'website',
      images: [
        {
          url: selectedJob.jobDetail.logo?.src,
          alt: selectedJob.jobDetail.logo?.alt,
        },
      ],
      site_name: 'Terminal.io',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@terminal',
      image: selectedJob.jobDetail.logo?.src,
    },
    structuredData: {
      '@context': 'https://schema.org',
      '@type': 'JobPosting',
      identifier: {
        '@type': 'PropertyValue',
        name: selectedJob.title,
        value: selectedJob.id,
      },
      title: `${selectedJob.jobDetail.title}`,
      description: `${descriptionMain}${skilsDescription}`,
      datePosted: selectedJob.jobDetail.datePosted,
      validThrough: addMonths(selectedJob.jobDetail.datePosted, 4).toISOString(),
      // Remote location requirements
      applicantLocationRequirements: uniqueCountries?.map((country) => ({
        '@type': 'Country',
        name: country,
      })),
      jobLocationType: 'TELECOMMUTE',
      employmentType,
      hiringOrganization: {
        '@type': 'Organization',
        name: selectedJob.jobDetail.companyName,
        sameAs: allJobsData?.icims_job?.find((job) => `${job.profile_id}` === selectedJobID)
          ?.icims_company?.organizations[0]?.salesforce_customer?.website,
        logo: selectedJob?.image.src,
      },
      skills: selectedJob.skills.join(', '),

      // Beta Features
      ...(credentialCategory
        ? {
            educationRequirements: {
              '@type': 'EducationalOccupationalCredential',
              credentialCategory,
            },
          }
        : {}),
      ...(monthsOfExperience
        ? {
            experienceRequirements: {
              '@type': 'OccupationalExperienceRequirements',
              monthsOfExperience,
            },
          }
        : {}),
    },
  };
}
