import Head from 'next/head';
import Layout from '../components/Layout';

import { Grid, GridItem, Container, Heading, Text } from '@terminal/design-system';
import { PrimaryButton } from '../components/UI/Button';
import Image404 from '../components/UI/Image404';
import { useRouter } from 'next/navigation';

export default function ErrorFallback({ resetError }) {
  const router = useRouter();

  const handleBackAction = () => {
    resetError();
    router.push('/');
  };

  return (
    <>
      <Head>
        <title>Something went wrong</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Layout type="businesses" withCTA={false}>
        <Container py={{ base: '14', lg: '24' }}>
          <Grid
            gridTemplateColumns={{ lg: '1fr 1fr' }}
            gap={{ base: '14', lg: '10', xl: '14' }}
            maxW={{ base: '30rem', lg: '90%' }}
            mx="auto"
          >
            <GridItem order={{ lg: 1 }}>
              <Image404 />
            </GridItem>
            <GridItem>
              <Heading
                as="h1"
                fontSize={{ base: '4.5rem' }}
                lineHeight={{ base: '4.5rem' }}
                fontWeight="700"
              >
                Oops!
                <br />
                <Text fontSize={{ base: '2.625rem' }} lineHeight={{ base: '3rem' }}>
                  Something went wrong
                </Text>
              </Heading>
              <Text mt="4" fontSize="lg" lineHeight="1.5rem">
                We&apos;re sorry. An error has occured, please try again
              </Text>
              <PrimaryButton onClick={handleBackAction} mt="6">
                Back to Home
              </PrimaryButton>
            </GridItem>
          </Grid>
        </Container>
      </Layout>
    </>
  );
}
