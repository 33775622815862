import Image, { ImageProps } from 'next/image';

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#E6E7E9" offset="20%" />
      <stop stop-color="#D6D8DB" offset="50%" />
      <stop stop-color="#E6E7E9" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#E6E7E9" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

const ImageShimmer = (imageProps: ImageProps) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <Image
    placeholder="blur"
    blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
    {...imageProps}
  />
);

export default ImageShimmer;
