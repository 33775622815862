import { Box, Flex, Button, Text, Heading, AspectRatio } from '@terminal/design-system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import ImageShimmer from '../ImageShimmer/ImageShimmer';

type VideoItem = {
  youtubeID: string;
  title: string;
  subtitle: string;
};

type ImageItem = {
  image: {
    sourceUrl: string;
    altText: string;
  };
  text: string;
  author: {
    name: string;
    jobTitle: string;
  };
};

export type TestimonialItem = VideoItem | ImageItem;

function isVideoContent(content: TestimonialItem): content is VideoItem {
  return Object.prototype.hasOwnProperty.call(content, 'youtubeID');
}

export default function TestimonialsCarousel({ slider }: { slider: TestimonialItem[] }) {
  const [prevEl, setPrevEl] = useState<HTMLElement>(null);
  const [nextEl, setNextEl] = useState<HTMLElement>(null);

  return (
    <Box>
      <Box
        as={Swiper}
        pl={{
          base: 'calc(50% - 180px)',
          md: 'calc(50% - 350px)',
          lg: 'calc(50% - 460px)',
          xl: 'calc(50% - 550px)',
        }}
        pb={12}
        slidesPerView="auto"
        navigation={{ prevEl, nextEl }}
        modules={[Navigation]}
        speed={1000}
        autoHeight={false}
        spaceBetween={32}
        simulateTouch={false}
      >
        {slider.map((slide, index) => (
          <SwiperSlide key={index} style={{ width: '100%', height: 'auto' }}>
            <Flex
              py={{ base: 6, lg: 10 }}
              px={{ base: 6, md: 18, lg: '6.25rem' }}
              bgColor="blue.100"
              borderRadius="lg"
              textAlign="center"
              flexDir="column"
              justifyContent="center"
              h="full"
            >
              {isVideoContent(slide) ? (
                <>
                  <AspectRatio
                    maxW="80%"
                    ratio={16 / 9}
                    mb={5}
                    left="50%"
                    transform="translateX(-50%)"
                  >
                    <iframe
                      src={`https://www.youtube.com/embed/${slide.youtubeID}`}
                      title="video"
                    />
                  </AspectRatio>
                  <Text fontSize="lg" fontWeight="bold" mb={1}>
                    {slide.title}
                  </Text>
                  <Text fontSize="lg">{slide.subtitle}</Text>
                </>
              ) : (
                <>
                  <Box
                    position="relative"
                    overflow="hidden"
                    w="6rem"
                    h="6rem"
                    borderRadius="full"
                    mx="auto"
                    mb={5}
                  >
                    <ImageShimmer
                      src={slide.image.sourceUrl}
                      alt={slide.image.altText}
                      style={{ objectFit: 'cover' }}
                      fill
                    />
                  </Box>
                  <Heading as="h6" fontSize="2xl" fontWeight="semibold" mb={{ base: 6, lg: 8 }}>
                    {slide.text}
                  </Heading>
                  <Text fontSize="lg" fontWeight="bold" mb={1}>
                    {slide.author.name}
                  </Text>
                  <Text fontSize="lg">{slide.author.jobTitle}</Text>
                </>
              )}
            </Flex>
          </SwiperSlide>
        ))}
      </Box>
      <Flex mx="auto" maxW="4.5rem" justifyContent="space-between">
        <Button
          bg="bg.primary"
          color="text.inverse"
          _hover={{ bg: 'bg.inverse' }}
          _disabled={{ color: 'text.disabled', bg: 'text.disabled', cursor: 'not-allowed' }}
          borderRadius="full"
          minW="6"
          h="6"
          p="0"
          ref={(node) => setPrevEl(node)}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00002 0.704961C6.81319 0.517709 6.55954 0.412476 6.29502 0.412476C6.0305 0.412476 5.77685 0.517709 5.59002 0.704961L1.00002 5.29496C0.61002 5.68496 0.61002 6.31496 1.00002 6.70496L5.59002 11.295C5.98002 11.685 6.61002 11.685 7.00002 11.295C7.39002 10.905 7.39002 10.275 7.00002 9.88496L3.12002 5.99496L7.00002 2.11496C7.39002 1.72496 7.38002 1.08496 7.00002 0.704961Z"
              fill="#25383F"
            />
          </svg>
        </Button>
        <Button
          bg="bg.primary"
          color="text.inverse"
          _hover={{ bg: 'bg.inverse' }}
          _disabled={{ color: 'text.disabled', bg: 'text.disabled', cursor: 'not-allowed' }}
          borderRadius="full"
          minW="6"
          h="6"
          p="0"
          ref={(node) => setNextEl(node)}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.00002 0.710632C0.61002 1.10063 0.61002 1.73063 1.00002 2.12063L4.88002 6.00063L1.00002 9.88063C0.61002 10.2706 0.61002 10.9006 1.00002 11.2906C1.39002 11.6806 2.02002 11.6806 2.41002 11.2906L7.00002 6.70063C7.39002 6.31063 7.39002 5.68063 7.00002 5.29063L2.41002 0.700632C2.03002 0.320632 1.39002 0.320632 1.00002 0.710632Z"
              fill="#25383F"
            />
          </svg>
        </Button>
      </Flex>
    </Box>
  );
}
