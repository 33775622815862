import { Box } from '@chakra-ui/react';

import patternImage from '@public/images/ui/square-pattern.svg';
import squaresWhite from '@public/images/ui/square-pattern-white.svg';

export default function SquarePattern(props: any) {
  return <Box w="100%" h="23rem" bgImage={`url(${patternImage.src})`} {...props} />;
}

export function SquarePatternRoundedBG(props: any) {
  return (
    <Box
      w="100%"
      h="23rem"
      bgRepeat="round"
      bgImage={`url(${props.isWhite ? squaresWhite.src : patternImage.src})`}
      {...props}
    />
  );
}
