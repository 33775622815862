import { cloneElement } from 'react';
import { createIcon as createChakraIcon } from '@chakra-ui/react';

/* eslint-disable react/no-array-index-key */
const createIcon = (
  displayName: string,
  elements: React.ReactElement[],
  useStrokeProps: boolean = true,
) => {
  const strokeProps = {
    stroke: 'currentColor',
    strokeWidth: '1.5',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  };
  return createChakraIcon({
    displayName,
    viewBox: '0 0 25 25',
    path: elements.map((el, ix) => {
      const newProps = useStrokeProps
        ? { key: `${displayName}-${ix}`, ...strokeProps }
        : { key: `${displayName}-${ix}` };

      return cloneElement(el, newProps);
    }),
  });
};

export const AddIcon = createIcon('AddIcon', [<path d="M.75 12h22.5M12 .75v22.5" />]);

export const ArrowLeftIcon = createIcon(
  'ArrowLeftIcon',
  [
    <path
      d="M10.72 23.03a.75.75 0 101.06-1.06l-9.22-9.22h20.69a.75.75 0 000-1.5H2.56l9.22-9.22A.75.75 0 0010.72.97L.22 11.47a.75.75 0 000 1.06l10.5 10.5z"
      fill="currentColor"
    />,
  ],
  false,
);

export const ChevronRightIcon = createChakraIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 8 12',
  path: [
    <path d="M1.00002 0.710632C0.61002 1.10063 0.61002 1.73063 1.00002 2.12063L4.88002 6.00063L1.00002 9.88063C0.61002 10.2706 0.61002 10.9006 1.00002 11.2906C1.39002 11.6806 2.02002 11.6806 2.41002 11.2906L7.00002 6.70063C7.39002 6.31063 7.39002 5.68063 7.00002 5.29063L2.41002 0.700632C2.03002 0.320632 1.39002 0.320632 1.00002 0.710632Z" />,
  ],
});

export const ArrowRightIcon = createIcon(
  'ArrowRightIcon',
  [
    <path
      d="M5.20898 13H16.379L11.499 17.88C11.109 18.27 11.109 18.91 11.499 19.3C11.889 19.69 12.519 19.69 12.909 19.3L19.499 12.71C19.889 12.32 19.889 11.69 19.499 11.3L12.919 4.69996C12.7322 4.5127 12.4785 4.40747 12.214 4.40747C11.9495 4.40747 11.6958 4.5127 11.509 4.69996C11.119 5.08996 11.119 5.71996 11.509 6.10996L16.379 11H5.20898C4.65898 11 4.20898 11.45 4.20898 12C4.20898 12.55 4.65898 13 5.20898 13Z"
      fill="currentColor"
    />,
  ],
  false,
);

export const AssessmentsIcon = createIcon('AssessmentsIcon', [
  <path d="M13.04 18.64l-3.7.53.52-3.72 9.55-9.54a2.25 2.25 0 113.18 3.18l-9.54 9.55z" />,
  <rect x="5.25" y=".75" width="7.5" height="3" rx=".75" />,
  <path d="M12.75 2.25h3c.83 0 1.5.67 1.5 1.5M17.25 18.75v3c0 .83-.67 1.5-1.5 1.5H2.25a1.5 1.5 0 01-1.5-1.5v-18c0-.83.67-1.5 1.5-1.5h3M5.25 8.25h7.5M5.25 12.75h3" />,
]);

export const BriefcaseIcon = createIcon(
  'BriefcaseIcon',
  [
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M14.396 3.25a.66.66 0 0 1 .652.552l.363 2.18H8.717l.364-2.18a.66.66 0 0 1 .651-.552h4.664zm-7.2 2.732.405-2.426a2.16 2.16 0 0 1 2.13-1.806h4.665a2.16 2.16 0 0 1 2.132 1.806l.403 2.426h3.597a3.571 3.571 0 0 1 3.571 3.572v9.875A3.571 3.571 0 0 1 20.528 23H3.599a3.571 3.571 0 0 1-3.571-3.571V9.554a3.571 3.571 0 0 1 3.571-3.572h3.597zm.633 1.5H3.6a2.071 2.071 0 0 0-2.071 2.072v2.071H22.599V9.554a2.071 2.071 0 0 0-2.071-2.072H7.829zm3.485 7.714v-2.071H1.528v6.304c0 1.144.927 2.071 2.071 2.071h16.929a2.071 2.071 0 0 0 2.071-2.071v-6.304h-9.785v2.071a.75.75 0 0 1-1.5 0z"
    />,
  ],
  false,
);

export const BriefcaseBoldIcon = createChakraIcon({
  displayName: 'BriefcaseBoldIcon',
  viewBox: '0 0 12 13',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#517E94"
    >
      <g clipPath="url(#clip0_2143_1313)">
        <path
          d="M10.2639 3.86609H1.79963C1.02051 3.86609 0.388916 4.49769 0.388916 5.2768V10.2143C0.388916 10.9934 1.02051 11.625 1.79963 11.625H10.2639C11.043 11.625 11.6746 10.9934 11.6746 10.2143V5.2768C11.6746 4.49769 11.043 3.86609 10.2639 3.86609Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.388916 6.6875H11.6746"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.03174 6.6875V8.09821"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.14791 3.86607H3.91577L4.17041 2.33968C4.19779 2.17498 4.28271 2.02533 4.41005 1.91735C4.5374 1.80938 4.69893 1.75008 4.86589 1.75H7.1978C7.36488 1.74991 7.52657 1.80913 7.65407 1.91713C7.78156 2.02512 7.86658 2.17486 7.89399 2.33968L8.14791 3.86607Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2143_1313">
          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  ),
});

export const BuildingIcon = createIcon('BuildingIcon', [
  <path d="M20.25 3.75v19.5M3.75 3.75h16.5M3.75 23.25V3.75M20.25 23.25H3.75M20.25 3.75H3.75l1.5-3h13.5l1.5 3zM.75 23.25h22.5M14.25 21a2.25 2.25 0 0 0-4.5 0v2.25h4.5V21z" />,
  <path d="M7.5 7.125a.375.375 0 1 1 0-.75M7.5 7.125a.375.375 0 1 0 0-.75M12 7.125a.375.375 0 0 1 0-.75M12 7.125a.375.375 0 0 0 0-.75M16.5 7.125a.375.375 0 0 1 0-.75M16.5 7.125a.375.375 0 0 0 0-.75M7.5 11.625a.375.375 0 0 1 0-.75M7.5 11.625a.375.375 0 0 0 0-.75M12 11.625a.375.375 0 0 1 0-.75M12 11.625a.375.375 0 0 0 0-.75M16.5 11.625a.375.375 0 0 1 0-.75M16.5 11.625a.375.375 0 0 0 0-.75M7.5 16.125a.375.375 0 0 1 0-.75M7.5 16.125a.375.375 0 0 0 0-.75M12 16.125a.375.375 0 0 1 0-.75M12 16.125a.375.375 0 0 0 0-.75M16.5 16.125a.375.375 0 0 1 0-.75M16.5 16.125a.375.375 0 0 0 0-.75" />,
]);

export const CheckIcon = createIcon('CheckIcon', [
  <path d="M23.25.75L8.15 22.3a2.2 2.2 0 01-3.56.06L.75 17.25" />,
]);

export const CheckCircleIcon = createIcon('CheckCircleIcon', [
  <path d="M6 13.22l2.45 3.48a1.05 1.05 0 001.7.05L18 6.83" />,
  <circle cx="12" cy="12" r="11.25" />,
]);

export const CheckCircleSolidIcon = createIcon(
  'CheckCircleSolidIcon',
  [
    <circle cx="12" cy="11.749" r="11" fill="currentColor" stroke="currentColor" />,
    <path
      d="m6.133 12.946 2.396 3.4a1.026 1.026 0 0 0 1.669.05l7.669-9.703"
      fill="none"
      stroke="#fff"
    />,
  ],
  false,
);

export const CloseIcon = createIcon('CloseIcon', [
  <path d="M.75 23.25L23.25.75M23.25 23.25L.75.75" />,
]);

export const DeleteIcon = createIcon('DeleteIcon', [
  <path d="M1.5 4.5h21" />,
  <path d="M14.25 1.5h-4.5c-.83 0-1.5.67-1.5 1.5v1.5h7.5V3c0-.83-.67-1.5-1.5-1.5z" />,
  <path d="M9.75 17.25v-7.5M14.25 17.25v-7.5" />,
  <path d="M18.86 21.12a1.5 1.5 0 01-1.49 1.38H6.63a1.5 1.5 0 01-1.5-1.38L3.76 4.5h16.5l-1.39 16.62z" />,
]);

export const DotMenuIcon = createIcon('DotMenuIcon', [
  <path d="M12.38 6a.38.38 0 100 .75.38.38 0 000-.75v0M12.38 11a.38.38 0 100 .75.38.38 0 000-.75v0M12.38 16a.38.38 0 100 .75.38.38 0 000-.75v0" />,
]);

export const DropdownIcon = createIcon('DropdownIcon', [
  <path d="M18 9l-5.72 5.72a.4.4 0 01-.56 0L6 9" />,
]);

export const ArrowUpwardIcon = createIcon('ArrowUpwardIcon', [
  <path
    d="M 18.6 9.5 L 12.883 15.217 C 12.808 15.292 12.706 15.334 12.6 15.334 C 12.494 15.334 12.392 15.292 12.317 15.217 L 6.6 9.5"
    transform="matrix(-1, 0, 0, -1, 25.2, 24.834)"
  />,
]);

export const MinusIcon = createIcon('MinusIcon', [<line x1="20%" y1="50%" x2="75%" y2="50%" />]);

export const DurationIcon = createIcon('DurationIcon', [
  <circle cx="12" cy="14.25" r="9" />,
  <path d="M18 7.5l1.88-1.88M19.5 5.25l.75.75M12 5.25V.75M14.25.75h-4.5M12 15l-3.75-4.15" />,
]);

export const EducationIcon = createIcon('EducationIcon', [
  <path d="M18.6001 6.6C18.6001 8.08521 18.0101 9.5096 16.9599 10.5598C15.9097 11.61 14.4854 12.2 13.0001 12.2C11.5149 12.2 10.0906 11.61 9.04035 10.5598C7.99014 9.5096 7.40015 8.08521 7.40015 6.6V1H18.6001V6.6Z" />,
  <path d="M2.59961 25C2.59961 22.2418 3.69532 19.5965 5.6457 17.6461C7.59608 15.6957 10.2414 14.6 12.9996 14.6C15.7579 14.6 18.4031 15.6957 20.3535 17.6461C22.3039 19.5965 23.3996 22.2418 23.3996 25" />,
  <path d="M1 1H25" />,
  <path d="M7.40015 5.79999H18.6001" />,
  <path d="M2.59961 1V9" />,
  <path d="M7.68091 16.0624L13.0004 20.2L18.3198 16.0624" />,
]);

export const EditIcon = createIcon('EditIcon', [
  <path d="M12.67 14.14l-3.71.53.53-3.72 9.54-9.54a2.25 2.25 0 013.18 3.18l-9.54 9.55z" />,
  <path d="M19.12 14.25v7.5c0 .83-.67 1.5-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5v-15c0-.83.67-1.5 1.5-1.5h7.5" />,
]);

export const EmailVerificationIcon = createIcon('EmailVerificationIcon', [
  <path d="M2.76 16.63a1.66 1.66 0 01-.88-3.08L20.36 2a1.66 1.66 0 012.52 1.72l-3.6 19.17a1.66 1.66 0 01-2.97.7l-5.21-6.96H2.76z" />,
  <path d="M11.1 16.63H8.6v5.17a1.66 1.66 0 002.6 1.37l3.18-2.16-3.28-4.38z" />,
  <path d="M11.1 16.63l11.08-14.6" />,
]);

export const ErrorCircleIcon = createIcon('ErrorCircleIcon', [
  <circle cx="12" cy="12" r="11.25" />,
  <path d="M7.5 16.5l9-9M16.5 16.5l-9-9" />,
]);

export const ErrorCircleSolidIcon = createIcon(
  'ErrorCircleSolidIcon',
  [
    <circle cx="12" cy="12" r="12" fill="currentColor" />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73034 6.66968C7.43745 6.37679 6.96258 6.37679 6.66968 6.66968C6.37679 6.96258 6.37679 7.43745 6.66968 7.73034L10.9394 12L6.66968 16.2697C6.37679 16.5626 6.37679 17.0374 6.66968 17.3303C6.96258 17.6232 7.43745 17.6232 7.73034 17.3303L12 13.0607L16.2697 17.3303C16.5626 17.6232 17.0374 17.6232 17.3303 17.3303C17.6232 17.0374 17.6232 16.5626 17.3303 16.2697L13.0607 12L17.3303 7.73034C17.6232 7.43745 17.6232 6.96258 17.3303 6.66968C17.0374 6.37679 16.5626 6.37679 16.2697 6.66968L12 10.9394L7.73034 6.66968Z"
      fill="#25383F"
    />,
  ],
  false,
);

export const FileIcon = createIcon('FileIcon', [
  <path d="M20.3025 5.811C20.5489 6.09228 20.6874 6.47396 20.6875 6.872V21.251C20.687 22.4929 19.8063 23.4994 18.7196 23.5H2.96963C2.44748 23.5003 1.94663 23.2635 1.57725 22.8417C1.20788 22.4199 1.00023 21.8477 1 21.251V3.251C0.999768 2.65392 1.20721 2.08121 1.57663 1.65901C1.94606 1.23681 2.44718 0.999735 2.96963 1H15.5495C15.8978 1.00009 16.2318 1.15837 16.4779 1.44L20.3025 5.811Z" />,
  <path d="M15.4375 1V5.5C15.4375 6.32843 16.0251 7 16.75 7H20.6875" />,
]);

export const GoogleIcon = createIcon(
  'GoogleIcon',
  [
    <path
      d="M19.1 15.7l.69.3a.75.75 0 00-.69-1.05v.75zm-4.63 0h-.75c0 .41.34.75.75.75v-.75zm0-4.17v-.75a.75.75 0 00-.75.75h.75zm9.78 0H25a.75.75 0 00-.75-.75v.75zm0 2.06l.75.04a.8.8 0 000-.04h-.75zm-3-8.29l.5.55a.75.75 0 00.05-1.06l-.55.51zm-3.4 3.13l-.55.51c.28.3.75.32 1.06.04l-.51-.55zM13 6.33v.75-.75zm6.1 8.62h-4.63v1.5h4.63v-1.5zm-3.88.75v-4.17h-1.5v4.17h1.5zm-.75-3.42h9.78v-1.5h-9.78v1.5zm9.03-.75v2.06H25v-2.06h-1.5zm0 2.02c-.27 5-4.02 9.1-8.97 9.83l.22 1.49A12.02 12.02 0 0025 13.63l-1.5-.08zm-8.97 9.83A10.52 10.52 0 013.1 16.55l-1.4.51a12.02 12.02 0 0013.05 7.8l-.22-1.48zM3.1 16.55C1.4 11.85 3.25 6.6 7.51 4l-.78-1.28A12.02 12.02 0 001.7 17.06l1.41-.5zM7.51 4a10.52 10.52 0 0113.2 1.81L21.8 4.8A12.02 12.02 0 006.73 2.72L7.51 4zm13.23.75l-3.4 3.13 1.02 1.1 3.4-3.13-1.02-1.1zM18.4 7.92A7.36 7.36 0 0013 5.58v1.5c1.63 0 3.19.67 4.3 1.86l1.1-1.02zM13 5.58a7.42 7.42 0 00-7.35 6.4l1.49.2A5.92 5.92 0 0113 7.08v-1.5zm-7.35 6.4a7.42 7.42 0 005.32 8.16l.4-1.45a5.92 5.92 0 01-4.23-6.5l-1.49-.22zm5.32 8.16A7.42 7.42 0 0019.79 16l-1.38-.6a5.92 5.92 0 01-7.03 3.3l-.41 1.44z"
      fill="currentColor"
    />,
    <path
      d="M7.18 9.71L3.59 6.84M7.19 16.32l-3.44 3.1M16.92 18.39l3.07 3.45"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
  false,
);

export const HomeIcon = createIcon('HomeIcon', [
  <path
    d="M0.915985 12.1937L10.838 2.26325C11.1348 1.96818 11.5368 1.80317 11.9553 1.80455C12.3738 1.80593 12.7747 1.97359 13.0696 2.27061L23 12.1937"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M19.8451 9.04093V21.6583H4.07085V9.03568"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M15.1128 21.6583V16.926C15.1128 15.1836 13.7004 13.7711 11.958 13.7711C10.2156 13.7711 8.80313 15.1836 8.80313 16.926V21.6583"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
]);

export const InfoIcon = createIcon('InfoIcon', [
  <path d="M14.25 16.5h-.75A1.5 1.5 0 0112 15v-3.75a.75.75 0 00-.75-.75h-.75M11.63 6.75a.38.38 0 100 .75.38.38 0 000-.75v0" />,
  <circle cx="12" cy="12" r="11.25" />,
]);

export const InfoIconBold = createIcon(
  'InfoIcon',
  [
    <path
      strokeWidth="2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      d="M14.25 16.5h-.75A1.5 1.5 0 0112 15v-3.75a.75.75 0 00-.75-.75h-.75M11.63 6.75a.38.38 0 100 .75.38.38 0 000-.75v0"
    />,
    <circle
      strokeWidth="2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      cx="12"
      cy="12"
      r="11.25"
    />,
  ],
  false,
);

export const LinkoutIcon = createIcon('LinkoutIcon', [
  <path d="M23.25 8.5V1.75H16.5M23.25 1.75l-15 15M11.25 6.25h-9c-.83 0-1.5.67-1.5 1.5v15c0 .83.67 1.5 1.5 1.5h15c.83 0 1.5-.67 1.5-1.5v-9" />,
]);

export const LogoutIcon = createIcon('LogoutIcon', [
  <path d="M8.5 12h15.75M20.5 15.75L24.25 12 20.5 8.25M16.75 16.5V21c.04.79-.57 1.46-1.36 1.5H3.1A1.44 1.44 0 011.75 21V3a1.44 1.44 0 011.36-1.5H15.4c.79.04 1.4.71 1.36 1.5v4.5" />,
]);

export const UnfilledLoveIcon = createIcon('UnfilledLoveIcon', [
  <path d="M12 21.84l-9.59-10A5.67 5.67 0 011.35 5.3v0a5.67 5.67 0 019.08-1.47L12 5.38l1.57-1.56a5.67 5.67 0 019.08 1.47v0a5.67 5.67 0 01-1.06 6.55l-9.59 10z" />,
]);

export const FilledLoveIcon = createIcon(
  'FilledLoveIcon',
  [
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M12 21.84l-9.59-10A5.67 5.67 0 011.35 5.3v0a5.67 5.67 0 019.08-1.47L12 5.38l1.57-1.56a5.67 5.67 0 019.08 1.47v0a5.67 5.67 0 01-1.06 6.55l-9.59 10z"
    />,
  ],
  false,
);

export const MenuIcon = createIcon('MenuIcon', [
  <path d="M2.25 18h19.5M2.25 12h19.5M2.25 6h19.5" />,
]);

export const NotificationIcon = createIcon('NotificationIcon', [
  <path d="M10 21.75a2.09 2.09 0 004 0M12 3V.75" />,
  <path d="M12 3a7.5 7.5 0 017.5 7.5c0 7.05 1.5 8.25 1.5 8.25H3s1.5-1.92 1.5-8.25A7.5 7.5 0 0112 3z" />,
]);

export const PDF = createIcon('PDFIcon', [
  <path d="M6.25 24.25h-3a1.5 1.5 0 01-1.5-1.5V3.25c0-.83.67-1.5 1.5-1.5h10.63c.4 0 .77.16 1.06.44l5.87 5.87c.28.28.44.66.44 1.06v4.63" />,
  <path d="M21.25 9.25h-6a1.5 1.5 0 01-1.5-1.5v-6M9.25 24.25v-7.5M9.25 16.75H10a2.25 2.25 0 010 4.5h-.75" />,
  <path d="M15.25 24.25a3 3 0 003-3v-1.5a3 3 0 00-3-3v7.5z" />,
  <path d="M21.25 24.25v-6c0-.83.67-1.5 1.5-1.5h1.5M21.25 21.25h2.25" />,
]);

export const PendingIcon = createIcon('PendingIcon', [
  <path d="M6.04 6.26c.55 3.08 2.5 5.19 5.96 6.26 3.45-1.07 5.4-3.18 5.96-6.26a1.4 1.4 0 00-.34-1.16c-.3-.32-.71-.5-1.15-.5H7.53c-.44 0-.86.18-1.15.5a1.4 1.4 0 00-.34 1.16z" />,
  <path d="M3.75 1.72h16.5" />,
  <path d="M7.53 20.44c-.44 0-.86-.18-1.14-.5a1.4 1.4 0 01-.35-1.16c.55-3.08 2.5-5.19 5.96-6.26 3.45 1.07 5.4 3.18 5.96 6.26.07.42-.06.84-.35 1.16-.28.32-.7.5-1.14.5H7.53z" />,
  <path d="M3.75 23.32h16.5" />,
]);

export const PreferredIcon = createIcon('PreferredIcon', [
  <path d="M13.17 2.7l2.57 6.8h4.99a1.22 1.22 0 01.86 2.13l-4.32 3.03 2.4 5.53a1.31 1.31 0 01-1.87 1.63L12 18.55l-5.8 3.27a1.3 1.3 0 01-1.86-1.63l2.4-5.53-4.33-3.03a1.23 1.23 0 01.86-2.14h4.99l2.57-6.79a1.33 1.33 0 012.34 0z" />,
]);

export const PreferredFilledIcon = createIcon(
  'PreferredFilledIcon',
  [
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M13.17 2.7l2.57 6.8h4.99a1.22 1.22 0 01.86 2.13l-4.32 3.03 2.4 5.53a1.31 1.31 0 01-1.87 1.63L12 18.55l-5.8 3.27a1.3 1.3 0 01-1.86-1.63l2.4-5.53-4.33-3.03a1.23 1.23 0 01.86-2.14h4.99l2.57-6.79a1.33 1.33 0 012.34 0z"
    />,
  ],
  false,
);

export const ProgrammingIcon = createIcon('ProgrammingIcon', [
  <path d="M2.25 21.75a1.5 1.5 0 01-1.5-1.5V3.87c0-.9.72-1.62 1.62-1.62h19.27c.89 0 1.6.72 1.61 1.6v16.29c0 .89-.72 1.6-1.61 1.61H2.25z" />,
  <path d="M23.25 6.75H.75M16.5 11.25l3 3-3 3M7.5 11.25l-3 3 3 3M13.5 11.25l-3 6" />,
]);

export const RefreshIcon = createIcon('RefreshIcon', [
  <path d="M5.64 7a8.25 8.25 0 0114.61 5.25v2.25M18 17.9a8.25 8.25 0 01-14.25-5.65v-1.5" />,
  <path d="M23.25 11.5l-3 3-3-3M.75 13.75l3-3 3 3" />,
]);

export const SearchIcon = createIcon('SearchIcon', [
  <circle cx="12.39" cy="12.39" r="8.06" transform="rotate(-23.02 12.39 12.39)" />,
  <path d="M18.09 18.08l6.24 6.25" />,
]);

export const SettingsIcon = createIcon('SettingsIcon', [
  <path d="M10.55 2.44a1.96 1.96 0 002.9 0l.95-1.04a1.96 1.96 0 013.41 1.41l-.07 1.4a1.96 1.96 0 002.05 2.06l1.4-.07a1.96 1.96 0 011.41 3.4l-1.04.95a1.96 1.96 0 000 2.9l1.04.95a1.96 1.96 0 01-1.41 3.4l-1.4-.07a1.96 1.96 0 00-2.06 2.06l.07 1.4a1.96 1.96 0 01-3.4 1.41l-.94-1.04a1.96 1.96 0 00-2.9 0L9.6 22.6a1.96 1.96 0 01-3.41-1.4l.07-1.4a1.96 1.96 0 00-2.05-2.06l-1.4.07A1.96 1.96 0 011.4 14.4l1.04-.94a1.96 1.96 0 000-2.9L1.4 9.6A1.96 1.96 0 012.8 6.2l1.4.07a1.96 1.96 0 002.07-2.06l-.07-1.4A1.96 1.96 0 019.6 1.4l.95 1.04z" />,
  <circle cx="12" cy="12" r="4.5" />,
]);

export const SolidCircleIcon = createIcon(
  'SolidCircleIcon',
  [<circle cx="12" cy="12" r="11.25" fill="currentColor" />],
  false,
);
export const DashedCircleIcon = createIcon(
  'DashedCircleIcon',
  [
    <circle
      cx="12"
      cy="12.499"
      r="11.25"
      fill="#e6eef2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 5"
    />,
  ],
  false,
);

export const TooltipIcon = createIcon('TooltipIcon', [
  <path d="M9 9a3 3 0 114 2.83c-.6.21-1 .78-1 1.41v1.01M12 17.25a.38.38 0 100 .75.38.38 0 000-.75v0" />,
  <circle cx="12" cy="12" r="11.25" />,
]);

export const UserIcon = createIcon('UserIcon', [
  <circle cx="12" cy="6" r="5.25" />,
  <path d="M2.25 23.25a9.75 9.75 0 1119.5 0" />,
]);

export const ViewOffIcon = createIcon('ViewOffIcon', [
  <path d="M2.78 21L21.53 3M8.96 19.05c.99.3 2.01.46 3.04.45 4.1.07 8.26-2.81 10.82-5.64.57-.63.57-1.59 0-2.22a20.57 20.57 0 00-3.07-2.76M14.41 6.28C13.62 6.08 12.81 6 12 6c-4.03-.07-8.2 2.75-10.82 5.63-.57.64-.57 1.6 0 2.23.8.87 1.67 1.68 2.6 2.4M8.25 12.75A3.75 3.75 0 0112 9" />,
  <path d="M15.75 12.75v0A3.75 3.75 0 0112 16.5" />,
]);

export const ViewOnIcon = createIcon('ViewOnIcon', [
  <path d="M1.7 9.97c-.93.89-.93 2.3 0 3.18 2.12 1.94 5.9 4.98 10.55 4.98s8.43-3.04 10.55-4.98c.93-.88.93-2.29 0-3.17C20.68 8.03 16.9 5 12.25 5S3.82 8.03 1.7 9.97z" />,
  <ellipse cx="12.25" cy="11.56" rx="3.07" ry="2.81" />,
]);

export const WarningIcon = createIcon('WarningIcon', [
  <path d="M12 18.75a.38.38 0 100 .75.38.38 0 000-.75v0" />,
  <path d="M12 15.75v-7.5" />,
  <path d="M13.62 1.76a1.8 1.8 0 00-3.24 0L.9 21.06a1.52 1.52 0 001.36 2.19h19.46a1.52 1.52 0 001.36-2.2L13.62 1.77z" />,
]);

export const WarningCircleIcon = createIcon('WarningCircleIcon', [
  <path d="M12 16.5a.38.38 0 100 .75.38.38 0 000-.75v0" />,
  <path d="M12 13.5V5.25" />,
  <circle cx="12" cy="12" r="11.25" />,
]);

export const WarningCircleSolidIcon = createIcon(
  'WarningCircleSolidIcon',
  [
    <circle
      cx="12"
      cy="12"
      r="11.25"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />,
    <path
      d="M12.75 5.25C12.75 4.83579 12.4142 4.5 12 4.5C11.5858 4.5 11.25 4.83579 11.25 5.25V13.5C11.25 13.9142 11.5858 14.25 12 14.25C12.4142 14.25 12.75 13.9142 12.75 13.5V5.25ZM12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75Z"
      fill="white"
    />,
  ],
  false,
);

export const CalendarIcon = createIcon('CalendarIcon', [
  <path
    d="m2.5715 4.2857c-0.45465 0-0.89069 0.18061-1.2122 0.50211-0.32149 0.32149-0.5021 0.75752-0.5021 1.2122v15.429c0 0.4546 0.18061 0.8907 0.5021 1.2122 0.32149 0.3214 0.75753 0.5021 1.2122 0.5021h18.857c0.4547 0 0.8907-0.1807 1.2122-0.5021 0.3215-0.3215 0.5021-0.7576 0.5021-1.2122v-15.429c0-0.45466-0.1806-0.89069-0.5021-1.2122-0.3215-0.3215-0.7575-0.50211-1.2122-0.50211h-3.4286"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m6 0.85714v6.8571"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m18 0.85714v6.8571"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m6 4.2857h8.5714"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m6 13.714c0.47338 0 0.85714-0.3838 0.85714-0.8572 0-0.4733-0.38376-0.8571-0.85714-0.8571-0.47339 0-0.85715 0.3838-0.85715 0.8571 0 0.4734 0.38376 0.8572 0.85715 0.8572z"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m12 13.714c0.4734 0 0.8571-0.3838 0.8571-0.8572 0-0.4733-0.3837-0.8571-0.8571-0.8571s-0.8572 0.3838-0.8572 0.8571c0 0.4734 0.3838 0.8572 0.8572 0.8572z"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m18 13.714c0.4734 0 0.8571-0.3838 0.8571-0.8572 0-0.4733-0.3837-0.8571-0.8571-0.8571s-0.8572 0.3838-0.8572 0.8571c0 0.4734 0.3838 0.8572 0.8572 0.8572z"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
  <path
    d="m6 18.857c0.47338 0 0.85714-0.3837 0.85714-0.8571s-0.38376-0.8571-0.85714-0.8571c-0.47339 0-0.85715 0.3837-0.85715 0.8571s0.38376 0.8571 0.85715 0.8571z"
    stroke="#25383F"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.71429"
  />,
]);

export const LocationIcon = createIcon('LocationIcon', [
  <path
    d="m8.25 8.25c0 0.99456 0.39509 1.9484 1.0984 2.6517 0.70325 0.7032 1.657 1.0983 2.6516 1.0983s1.9484-0.3951 2.6517-1.0983c0.7032-0.7033 1.0983-1.6571 1.0983-2.6517s-0.3951-1.9484-1.0983-2.6516c-0.7033-0.70326-1.6571-1.0984-2.6517-1.0984s-1.9484 0.39509-2.6516 1.0984c-0.70326 0.70326-1.0984 1.6571-1.0984 2.6516z"
    stroke="#637A83"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />,
  <path
    d="m12 0.75c1.9891 0 3.8968 0.79018 5.3033 2.1967s2.1967 3.3142 2.1967 5.3033c0 3.407-5.074 11.95-6.875 14.665-0.0684 0.1031-0.1613 0.1877-0.2703 0.2463-0.1091 0.0585-0.2309 0.0891-0.3547 0.0891s-0.2456-0.0306-0.3547-0.0891c-0.109-0.0586-0.2019-0.1432-0.2703-0.2463-1.801-2.715-6.875-11.258-6.875-14.665 0-1.9891 0.79018-3.8968 2.1967-5.3033s3.3142-2.1967 5.3033-2.1967v0z"
    stroke="#637A83"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />,
]);

export const ClockIcon = createIcon('ClockIcon', [
  <path
    d="m12.143 23.286c6.154 0 11.143-4.9888 11.143-11.143 0-6.1541-4.9888-11.143-11.143-11.143-6.1541 0-11.143 4.9888-11.143 11.143 0 6.154 4.9888 11.143 11.143 11.143z"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.7143"
  />,
  <path
    d="m12.143 7.8569v4.2857l4.3543 5.0743"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.7143"
  />,
]);

export const PhoneIcon = createIcon('PhoneIcon', [
  <path
    d="m15.017 22.286c1.0557 0.6811 2.3137 0.9779 3.5625 0.8406 1.2488-0.1374 2.4122-0.7006 3.2946-1.5948l0.7715-0.7543c0.3382-0.346 0.5275-0.8105 0.5275-1.2943s-0.1893-0.9484-0.5275-1.2943l-3.2743-3.24c-0.343-0.3371-0.8048-0.526-1.2857-0.526-0.481 0-0.9427 0.1889-1.2857 0.526v0c-0.346 0.3382-0.8105 0.5276-1.2943 0.5276s-0.9484-0.1894-1.2943-0.5276l-5.1429-5.1428c-0.17156-0.16907-0.3078-0.37056-0.40079-0.59276-0.09299-0.22219-0.14088-0.46066-0.14088-0.70153s0.04789-0.47933 0.14088-0.70153c0.09299-0.22219 0.22923-0.42368 0.40079-0.59275v0c0.33713-0.34304 0.52603-0.80476 0.52603-1.2857s-0.1889-0.94268-0.52603-1.2857l-3.2571-3.2571c-0.34593-0.33821-0.8105-0.52757-1.2943-0.52757-0.48378 0-0.94835 0.18936-1.2943 0.52757l-0.75429 0.77142c-0.89426 0.88242-1.4574 2.0458-1.5948 3.2946-0.13737 1.2488 0.15944 2.5068 0.84051 3.5625 3.5486 5.2298 8.0639 9.7334 13.303 13.269v0z"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.7143"
  />,
]);

export const MailIcon = createIcon('MailIcon', [
  <path
    d="m21.723 3h-18.857c-0.94678 0-1.7143 0.76751-1.7143 1.7143v14.571c0 0.9468 0.76751 1.7143 1.7143 1.7143h18.857c0.9468 0 1.7143-0.7675 1.7143-1.7143v-14.571c0-0.94678-0.7675-1.7143-1.7143-1.7143z"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.7143"
  />,
  <path
    d="m1.1516 5.1431 10.046 8.5714c0.3081 0.2566 0.6963 0.3971 1.0972 0.3971s0.7891-0.1405 1.0971-0.3971l10.046-8.5714"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.7143"
  />,
]);

export const LinkedinIcon = createIcon('LinkedinIcon', [
  <path
    d="m5.67 22.63h-4.86v-13.93h4.86v13.93zm10.09-9.39c-0.5676 0-1.1119 0.2254-1.5132 0.6268-0.4013 0.4013-0.6268 0.9456-0.6268 1.5132v7.25h-5.11v-13.93h5.11v1.59c1.1977-1.014 2.7109-1.5796 4.28-1.6 3.17 0 5.37 2.35 5.37 6.81v7.13h-5.37v-7.25c0.0013-0.2819-0.0531-0.5612-0.16-0.822-0.107-0.2608-0.2644-0.4979-0.4632-0.6977-0.1989-0.1998-0.4352-0.3583-0.6955-0.4665-0.2603-0.1081-0.5394-0.1638-0.8213-0.1638v0.01zm-10-9.36c0 0.49446-0.14662 0.97781-0.42132 1.3889-0.27471 0.41112-0.66516 0.73155-1.122 0.92077-0.45682 0.18922-0.95948 0.23873-1.4444 0.14227-0.48496-0.09647-0.93041-0.33457-1.28-0.6842-0.34963-0.34963-0.58773-0.79509-0.68419-1.28s-0.046955-0.98762 0.14226-1.4444c0.18922-0.45681 0.50965-0.84726 0.92078-1.122 0.41112-0.2747 0.89447-0.42133 1.3889-0.42133 0.65957 0.00528 1.2903 0.271 1.7548 0.73927 0.46453 0.46827 0.72517 1.1011 0.72515 1.7607h0.02z"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />,
]);

export const PlayIcon = createIcon('PlayIcon', [
  <path
    d="m12 23.25c6.2132 0 11.25-5.0368 11.25-11.25s-5.0368-11.25-11.25-11.25-11.25 5.0368-11.25 11.25 5.0368 11.25 11.25 11.25z"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />,
  <path
    d="m9 15.61c-0.00177 0.3147 0.08758 0.6231 0.25728 0.8881 0.16969 0.2651 0.41247 0.4753 0.69902 0.6053 0.2865 0.1301 0.6046 0.1744 0.9158 0.1277 0.3112-0.0468 0.6022-0.1826 0.8379-0.3911l4.68-4.09c0.1064-0.0938 0.1916-0.2093 0.25-0.3386s0.0886-0.2695 0.0886-0.4114-0.0302-0.2821-0.0886-0.4114-0.1436-0.2448-0.25-0.3386l-4.68-4.1c-0.2371-0.20413-0.5276-0.33619-0.8373-0.38059-0.3097-0.04439-0.6257 7.2e-4 -0.91056 0.13003-0.28489 0.1293-0.52686 0.3374-0.69734 0.59974s-0.26236 0.56796-0.2648 0.88082v7.23z"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />,
]);

export const CoffeeCupIcon = createChakraIcon({
  displayName: 'CoffeeCupIcon',
  viewBox: '0 0 25 55',
  path: (
    <>
      <path
        d="M16.89 34.7002H1V53.8702H16.89V34.7002Z"
        fill="#A0CFDA"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M16.8901 38.4102H20.1701C21.0188 38.4102 21.8328 38.7473 22.4329 39.3474C23.033 39.9475 23.3701 40.7615 23.3701 41.6102V46.1002C23.3701 46.5204 23.2874 46.9365 23.1265 47.3247C22.9657 47.713 22.73 48.0657 22.4329 48.3629C22.1357 48.66 21.783 48.8958 21.3947 49.0566C21.0065 49.2174 20.5904 49.3002 20.1701 49.3002H16.8901V38.4102Z"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M14.3499 20.36C14.3499 20.36 13.1399 27.69 10.2599 27.18C7.37994 26.67 10.14 13.74 12.41 11.69C13.13 11.04 13.7999 11.69 13.0299 14.05C12.1699 16.74 9.69994 21.3 7.70994 21.65C3.16994 22.46 6.19995 8.76 11.8899 1"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      ,
    </>
  ),
  defaultProps: {
    fill: 'none',
  },
});

export const WebsiteIcon = createIcon('WebsiteIcon', [
  <path
    d="M1 4.5V5.84467C1.00011 6.60788 1.14582 7.36407 1.42933 8.07267L2 9.5L3 6L4 9.5L4.57067 8.07267C4.85418 7.36407 4.99989 6.60788 5 5.84467V4.5"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M6.5 4.5V5.84467C6.50011 6.60788 6.64582 7.36407 6.92933 8.07267L7.5 9.5L8.5 6L9.5 9.5L10.0707 8.07267C10.3542 7.36407 10.4999 6.60788 10.5 5.84467V4.5"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M12 4.5V5.84467C12.0001 6.60788 12.1458 7.36407 12.4293 8.07267L13 9.5L14 6L15 9.5L15.5707 8.07267C15.8542 7.36407 15.9999 6.60788 16 5.84467V4.5"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M10.5 15.5H6.5L7 12.5H10L10.5 15.5Z"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M5 15.5H12"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M1 0.5H16"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M1 12.5H16"
    stroke="#25383F"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
]);

export const LeadershipIcon = createIcon('LeadershipIcon', [
  <path
    d="M4.5 11.25H19.5"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M7.8 14.25L7.5 11.25H16.5L16.2 14.25"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M9.375 3.375C9.375 4.07119 9.65156 4.73887 10.1438 5.23116C10.6361 5.72344 11.3038 6 12 6C12.6962 6 13.3639 5.72344 13.8562 5.23116C14.3484 4.73887 14.625 4.07119 14.625 3.375C14.625 2.67881 14.3484 2.01113 13.8562 1.51884C13.3639 1.02656 12.6962 0.75 12 0.75C11.3038 0.75 10.6361 1.02656 10.1438 1.51884C9.65156 2.01113 9.375 2.67881 9.375 3.375V3.375Z"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M15.249 8.25C14.8482 7.77945 14.3501 7.40154 13.7889 7.14238C13.2278 6.88322 12.6171 6.74901 11.999 6.74901C11.3809 6.74901 10.7702 6.88322 10.2091 7.14238C9.64793 7.40154 9.14976 7.77945 8.74899 8.25"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M1.37401 18.375C1.37401 18.7197 1.44191 19.0611 1.57382 19.3795C1.70574 19.698 1.8991 19.9874 2.14285 20.2312C2.38661 20.4749 2.67598 20.6683 2.99446 20.8002C3.31294 20.9321 3.65429 21 3.99901 21C4.34373 21 4.68507 20.9321 5.00355 20.8002C5.32203 20.6683 5.61141 20.4749 5.85516 20.2312C6.09892 19.9874 6.29227 19.698 6.42419 19.3795C6.55611 19.0611 6.62401 18.7197 6.62401 18.375C6.62401 18.0303 6.55611 17.6889 6.42419 17.3705C6.29227 17.052 6.09892 16.7626 5.85516 16.5188C5.61141 16.2751 5.32203 16.0817 5.00355 15.9498C4.68507 15.8179 4.34373 15.75 3.99901 15.75C3.65429 15.75 3.31294 15.8179 2.99446 15.9498C2.67598 16.0817 2.38661 16.2751 2.14285 16.5188C1.8991 16.7626 1.70574 17.052 1.57382 17.3705C1.44191 17.6889 1.37401 18.0303 1.37401 18.375V18.375Z"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M7.248 23.25C6.84724 22.7795 6.34907 22.4015 5.78794 22.1424C5.2268 21.8832 4.61609 21.749 3.998 21.749C3.37991 21.749 2.7692 21.8832 2.20807 22.1424C1.64693 22.4015 1.14877 22.7795 0.748001 23.25"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M9.375 18.375C9.375 19.0712 9.65156 19.7389 10.1438 20.2312C10.6361 20.7234 11.3038 21 12 21C12.6962 21 13.3639 20.7234 13.8562 20.2312C14.3484 19.7389 14.625 19.0712 14.625 18.375C14.625 17.6788 14.3484 17.0111 13.8562 16.5188C13.3639 16.0266 12.6962 15.75 12 15.75C11.3038 15.75 10.6361 16.0266 10.1438 16.5188C9.65156 17.0111 9.375 17.6788 9.375 18.375V18.375Z"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M15.249 23.25C14.8482 22.7795 14.3501 22.4015 13.7889 22.1424C13.2278 21.8832 12.6171 21.749 11.999 21.749C11.3809 21.749 10.7702 21.8832 10.2091 22.1424C9.64793 22.4015 9.14976 22.7795 8.74899 23.25"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M17.376 18.375C17.376 19.0712 17.6526 19.7389 18.1449 20.2312C18.6371 20.7234 19.3048 21 20.001 21C20.6972 21 21.3649 20.7234 21.8572 20.2312C22.3494 19.7389 22.626 19.0712 22.626 18.375C22.626 17.6788 22.3494 17.0111 21.8572 16.5188C21.3649 16.0266 20.6972 15.75 20.001 15.75C19.3048 15.75 18.6371 16.0266 18.1449 16.5188C17.6526 17.0111 17.376 17.6788 17.376 18.375V18.375Z"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  <path
    d="M23.25 23.25C22.8492 22.7795 22.3511 22.4015 21.7899 22.1424C21.2288 21.8832 20.6181 21.749 20 21.749C19.3819 21.749 18.7712 21.8832 18.2101 22.1424C17.6489 22.4015 17.1508 22.7795 16.75 23.25"
    stroke="#8BBFD0"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
]);

export const DashboardIcon = createChakraIcon({
  displayName: 'DashboardIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M7.71426 0.857178H2.5714C1.62463 0.857178 0.857117 1.62469 0.857117 2.57146V7.71432C0.857117 8.66109 1.62463 9.42861 2.5714 9.42861H7.71426C8.66103 9.42861 9.42854 8.66109 9.42854 7.71432V2.57146C9.42854 1.62469 8.66103 0.857178 7.71426 0.857178Z"
        fill="#EFD58B"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4286 0.857178H16.2857C15.3389 0.857178 14.5714 1.62469 14.5714 2.57146V7.71432C14.5714 8.66109 15.3389 9.42861 16.2857 9.42861H21.4286C22.3753 9.42861 23.1428 8.66109 23.1428 7.71432V2.57146C23.1428 1.62469 22.3753 0.857178 21.4286 0.857178Z"
        fill="#FEF8EB"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71426 14.5714H2.5714C1.62463 14.5714 0.857117 15.3389 0.857117 16.2857V21.4286C0.857117 22.3753 1.62463 23.1428 2.5714 23.1428H7.71426C8.66103 23.1428 9.42854 22.3753 9.42854 21.4286V16.2857C9.42854 15.3389 8.66103 14.5714 7.71426 14.5714Z"
        fill="#FEF8EB"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4286 14.5714H16.2857C15.3389 14.5714 14.5714 15.3389 14.5714 16.2857V21.4286C14.5714 22.3753 15.3389 23.1428 16.2857 23.1428H21.4286C22.3753 23.1428 23.1428 22.3753 23.1428 21.4286V16.2857C23.1428 15.3389 22.3753 14.5714 21.4286 14.5714Z"
        fill="#EFD58B"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const SearchUserIcon = createChakraIcon({
  displayName: 'SearchUserIcon',
  viewBox: '0 0 26 26',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M1 11.4348C1 14.2022 2.09938 16.8564 4.05628 18.8133C6.01318 20.7702 8.66731 21.8695 11.4348 21.8695C14.2023 21.8695 16.8564 20.7702 18.8133 18.8133C20.7702 16.8564 21.8696 14.2022 21.8696 11.4348C21.8696 8.66729 20.7702 6.01317 18.8133 4.05627C16.8564 2.09937 14.2023 1 11.4348 1C8.66731 1 6.01318 2.09937 4.05628 4.05627C2.09938 6.01317 1 8.66729 1 11.4348V11.4348Z"
        fill="#FEF8EB"
        stroke="#25383F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04346 8.56513C8.04346 9.46455 8.40075 10.3271 9.03675 10.9631C9.67274 11.5991 10.5353 11.9564 11.4348 11.9564C12.3342 11.9564 13.1968 11.5991 13.8328 10.9631C14.4688 10.3271 14.8261 9.46455 14.8261 8.56513C14.8261 7.6657 14.4688 6.80311 13.8328 6.16712C13.1968 5.53112 12.3342 5.17383 11.4348 5.17383C10.5353 5.17383 9.67274 5.53112 9.03675 6.16712C8.40075 6.80311 8.04346 7.6657 8.04346 8.56513V8.56513Z"
        fill="#EFD58B"
        stroke="#25383F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7107 17.174C16.3209 16.1034 15.6112 15.1785 14.6778 14.525C13.7445 13.8715 12.6327 13.521 11.4933 13.521C10.3539 13.521 9.24206 13.8715 8.30871 14.525C7.37535 15.1785 6.66564 16.1034 6.27588 17.174"
        fill="#EFD58B"
      />
      <path
        d="M16.7107 17.174C16.3209 16.1034 15.6112 15.1785 14.6778 14.525C13.7445 13.8715 12.6327 13.521 11.4933 13.521C10.3539 13.521 9.24206 13.8715 8.30871 14.525C7.37535 15.1785 6.66564 16.1034 6.27588 17.174"
        stroke="#25383F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 24.9998L18.8132 18.813"
        stroke="#25383F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const JobsIcon = createChakraIcon({
  displayName: 'JobsIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M20.528 6.73218H3.59944C2.04121 6.73218 0.778015 7.99537 0.778015 9.55361V19.4286C0.778015 20.9868 2.04121 22.25 3.59944 22.25H20.528C22.0862 22.25 23.3494 20.9868 23.3494 19.4286V9.55361C23.3494 7.99537 22.0862 6.73218 20.528 6.73218Z"
        fill="#EFD58B"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.778015 12.375H23.3494"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0637 12.375V15.1964"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2959 6.73214H7.8316L8.34087 3.67936C8.39564 3.34995 8.56547 3.05066 8.82017 2.83471C9.07487 2.61876 9.39791 2.50015 9.73184 2.5H14.3957C14.7298 2.49982 15.0532 2.61827 15.3082 2.83425C15.5632 3.05023 15.7332 3.34972 15.788 3.67936L16.2959 6.73214Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const MembersIcon = createChakraIcon({
  displayName: 'MembersIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M13.978 22.9129L14.637 18.9569H17.275L15.3 13.0229C14.64 11.0449 13.824 9.7229 12 9.7229C10.176 9.7229 9.36298 11.0409 8.69998 13.0229L6.72498 18.9569H9.36298L10.022 22.9129H13.978Z"
        fill="#EFD58B"
        stroke="#27393F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.40803C13.4564 8.40803 14.637 7.22741 14.637 5.77103C14.637 4.31466 13.4564 3.13403 12 3.13403C10.5436 3.13403 9.36298 4.31466 9.36298 5.77103C9.36298 7.22741 10.5436 8.40803 12 8.40803Z"
        fill="#EFD58B"
        stroke="#27393F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 14.5869H21L21.45 11.8869H23.25L21.9 7.83691C21.45 6.48691 20.893 5.58691 19.65 5.58691C18.407 5.58691 17.85 6.48691 17.4 7.83691L17.133 8.63691"
        fill="#FEF8EB"
      />
      <path
        d="M19.25 14.5869H21L21.45 11.8869H23.25L21.9 7.83691C21.45 6.48691 20.893 5.58691 19.65 5.58691C18.407 5.58691 17.85 6.48691 17.4 7.83691L17.133 8.63691"
        stroke="#27393F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.65 4.68691C20.6441 4.68691 21.45 3.88103 21.45 2.88691C21.45 1.8928 20.6441 1.08691 19.65 1.08691C18.6559 1.08691 17.85 1.8928 17.85 2.88691C17.85 3.88103 18.6559 4.68691 19.65 4.68691Z"
        fill="#FEF8EB"
        stroke="#27393F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 14.5869H3L2.55 11.8869H0.75L2.1 7.83691C2.55 6.48691 3.107 5.58691 4.35 5.58691C5.593 5.58691 6.15 6.48691 6.6 7.83691L6.867 8.63691"
        fill="#FEF8EB"
      />
      <path
        d="M4.75 14.5869H3L2.55 11.8869H0.75L2.1 7.83691C2.55 6.48691 3.107 5.58691 4.35 5.58691C5.593 5.58691 6.15 6.48691 6.6 7.83691L6.867 8.63691"
        stroke="#27393F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.34999 4.68691C5.3441 4.68691 6.14999 3.88103 6.14999 2.88691C6.14999 1.8928 5.3441 1.08691 4.34999 1.08691C3.35588 1.08691 2.54999 1.8928 2.54999 2.88691C2.54999 3.88103 3.35588 4.68691 4.34999 4.68691Z"
        fill="#FEF8EB"
        stroke="#27393F"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const UserGroupIcon = createChakraIcon({
  displayName: 'UserGroupIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M8.57138 10.2858C10.7016 10.2858 12.4285 8.55892 12.4285 6.42868C12.4285 4.29843 10.7016 2.57153 8.57138 2.57153C6.44114 2.57153 4.71423 4.29843 4.71423 6.42868C4.71423 8.55892 6.44114 10.2858 8.57138 10.2858Z"
        fill="#EFD58B"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2857 23.1429H0.857117V21.4286C0.857117 19.3827 1.66987 17.4205 3.11658 15.9738C4.56329 14.5271 6.52545 13.7144 8.5714 13.7144C10.6174 13.7144 12.5795 14.5271 14.0262 15.9738C15.4729 17.4205 16.2857 19.3827 16.2857 21.4286V23.1429Z"
        fill="#EFD58B"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4285 2.57153C16.4515 2.57153 17.4326 2.97791 18.1559 3.70126C18.8793 4.42462 19.2857 5.4057 19.2857 6.42868C19.2857 7.45165 18.8793 8.43273 18.1559 9.15609C17.4326 9.87944 16.4515 10.2858 15.4285 10.2858"
        fill="#FEF8EB"
      />
      <path
        d="M15.4285 2.57153C16.4515 2.57153 17.4326 2.97791 18.1559 3.70126C18.8793 4.42462 19.2857 5.4057 19.2857 6.42868C19.2857 7.45165 18.8793 8.43273 18.1559 9.15609C17.4326 9.87944 16.4515 10.2858 15.4285 10.2858"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1714 14.04C19.6317 14.5956 20.8889 15.5814 21.7766 16.8671C22.6643 18.1527 23.1407 19.6777 23.1428 21.24V23.1429H20.5714"
        fill="#FEF8EB"
      />
      <path
        d="M18.1714 14.04C19.6317 14.5956 20.8889 15.5814 21.7766 16.8671C22.6643 18.1527 23.1407 19.6777 23.1428 21.24V23.1429H20.5714"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const OrganizationsIcon = createChakraIcon({
  displayName: 'OrganizationsIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <defs />
      <circle
        cx="12"
        cy="3.75"
        r="3"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="19"
        cy="20.25"
        r="3"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="5"
        cy="20.25"
        r="3"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12,6.75V8.5A3.5,3.5,0,0,1,8.5,12,3.5,3.5,0,0,0,5,15.5v1.75"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12,6.75V8.5A3.5,3.5,0,0,0,15.5,12,3.5,3.5,0,0,1,19,15.5v1.75"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const WalletIcon = createChakraIcon({
  displayName: 'WalletIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <g clipPath="url(#clip0_3153_44301)">
        <path
          d="M21.75 18.5V22C21.75 22.3978 21.592 22.7794 21.3107 23.0607C21.0294 23.342 20.6478 23.5 20.25 23.5H3C2.40326 23.5 1.83097 23.2629 1.40901 22.841C0.987053 22.419 0.75 21.8467 0.75 21.25V3.75C0.75 3.15326 0.987053 2.58097 1.40901 2.15901C1.83097 1.73705 2.40326 1.5 3 1.5H18.75C19.1478 1.5 19.5294 1.65804 19.8107 1.93934C20.092 2.22064 20.25 2.60218 20.25 3V6.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.75 18.5C22.1478 18.5 22.5294 18.342 22.8107 18.0607C23.092 17.7794 23.25 17.3978 23.25 17V12.5C23.25 12.1022 23.092 11.7206 22.8107 11.4393C22.5294 11.158 22.1478 11 21.75 11H17.5C16.5054 11 15.5516 11.3951 14.8483 12.0983C14.1451 12.8016 13.75 13.7554 13.75 14.75C13.75 15.7446 14.1451 16.6984 14.8483 17.4017C15.5516 18.1049 16.5054 18.5 17.5 18.5H21.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9951 15.1201C17.788 15.1201 17.6201 14.9523 17.6201 14.7451C17.6201 14.538 17.788 14.3701 17.9951 14.3701"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M17.9951 15.1201C18.2022 15.1201 18.3701 14.9523 18.3701 14.7451C18.3701 14.538 18.2022 14.3701 17.9951 14.3701"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M21.75 11V7.75004C21.75 7.35221 21.592 6.97068 21.3107 6.68938C21.0294 6.40807 20.6478 6.25004 20.25 6.25004H5.63C5.30133 6.26744 4.97437 6.19233 4.68624 6.03324C4.39811 5.87415 4.16036 5.63746 4 5.35004"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3153_44301">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const ChevronIcon = createChakraIcon({
  displayName: 'ChevronIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M12.362 7.87539L8.00262 5.10073L3.64329 7.87539C3.19862 8.15806 2.50262 7.92873 2.50262 7.49939V6.31873C2.51025 6.164 2.55708 6.01376 2.63871 5.8821C2.72034 5.75044 2.8341 5.64169 2.96929 5.56606L7.54729 2.65273C7.68444 2.57187 7.84075 2.52923 7.99996 2.52923C8.15917 2.52923 8.31547 2.57187 8.45262 2.65273L13.0306 5.56606C13.1658 5.64169 13.2796 5.75044 13.3612 5.8821C13.4428 6.01376 13.4897 6.164 13.4973 6.31873V7.49939C13.5026 7.92873 12.8066 8.15806 12.362 7.87539Z"
        fill="#96BA6E"
      />
      <path
        d="M12.362 13.3462L8.00262 10.5715L3.64329 13.3462C3.19862 13.6288 2.50262 13.3995 2.50262 12.9702V11.7895C2.51025 11.6348 2.55708 11.4845 2.63871 11.3529C2.72034 11.2212 2.8341 11.1125 2.96929 11.0368L7.54729 8.1235C7.68444 8.04264 7.84075 8 7.99996 8C8.15917 8 8.31547 8.04264 8.45262 8.1235L13.0306 11.0368C13.1658 11.1125 13.2796 11.2212 13.3612 11.3529C13.4428 11.4845 13.4897 11.6348 13.4973 11.7895V12.9702C13.5026 13.3995 12.8066 13.6288 12.362 13.3462Z"
        fill="#96BA6E"
      />
    </>
  ),
});

export const StopwatchIcon = createChakraIcon({
  displayName: 'StopwatchIcon',
  viewBox: '0 0 14 17',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="7"
        cy="10"
        r="6"
        stroke="#5E9CB0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5.5L12.25 4.25"
        stroke="#5E9CB0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4L12.5 4.5"
        stroke="#5E9CB0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4V1"
        stroke="#5E9CB0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 1H5.5"
        stroke="#5E9CB0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.5L4.5 7.73267"
        stroke="#5E9CB0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export const ShareIcon = createChakraIcon({
  displayName: 'ShareIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M1.5 11.25C1.5 12.2446 1.89509 13.1984 2.59835 13.9017C3.30161 14.6049 4.25544 15 5.25 15C6.24456 15 7.19839 14.6049 7.90165 13.9017C8.60491 13.1984 9 12.2446 9 11.25C9 10.2554 8.60491 9.30161 7.90165 8.59835C7.19839 7.89509 6.24456 7.5 5.25 7.5C4.25544 7.5 3.30161 7.89509 2.59835 8.59835C1.89509 9.30161 1.5 10.2554 1.5 11.25V11.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6C15 6.99456 15.3951 7.94839 16.0983 8.65165C16.8016 9.35491 17.7554 9.75 18.75 9.75C19.7446 9.75 20.6984 9.35491 21.4017 8.65165C22.1049 7.94839 22.5 6.99456 22.5 6C22.5 5.00544 22.1049 4.05161 21.4017 3.34835C20.6984 2.64509 19.7446 2.25 18.75 2.25C17.7554 2.25 16.8016 2.64509 16.0983 3.34835C15.3951 4.05161 15 5.00544 15 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 18C15 18.9946 15.3951 19.9484 16.0983 20.6517C16.8016 21.3549 17.7554 21.75 18.75 21.75C19.7446 21.75 20.6984 21.3549 21.4017 20.6517C22.1049 19.9484 22.5 18.9946 22.5 18C22.5 17.0054 22.1049 16.0516 21.4017 15.3483C20.6984 14.6451 19.7446 14.25 18.75 14.25C17.7554 14.25 16.8016 14.6451 16.0983 15.3483C15.3951 16.0516 15 17.0054 15 18Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74597 9.891L15.254 7.36"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60498 12.928L15.395 16.323"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const DownloadIcon = createChakraIcon({
  displayName: 'DownloadIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.168 15C16.1088 16.8553 14.1363 18.0004 12 18.0004C9.86366 18.0004 7.89124 16.8553 6.832 15H3C2.17157 15 1.5 15.6716 1.5 16.5V21C1.5 21.8284 2.17157 22.5 3 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V16.5C22.5 15.6716 21.8284 15 21 15H17.168Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 9L12 12L15.75 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const MonitorIcon = createChakraIcon({
  displayName: 'MonitorIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <g clipPath="url(#clip0_3989_5570)">
        <path
          d="M22.5714 3.42857H2C1.52661 3.42857 1.14285 3.81233 1.14285 4.28572V18C1.14285 18.4734 1.52661 18.8571 2 18.8571H22.5714C23.0448 18.8571 23.4286 18.4734 23.4286 18V4.28572C23.4286 3.81233 23.0448 3.42857 22.5714 3.42857Z"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5714 18.8571L8.85713 23.1429"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 18.8571L15.7143 23.1429"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.14285 23.1429H17.4286"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3989_5570">
          <rect width="24" height="24" fill="white" transform="translate(0.285706)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const ActiveIcon = createChakraIcon({
  displayName: 'ActiveIcon',
  viewBox: '0 0 25 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <circle
        cx="12"
        cy="12"
        r="8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="4" fill="currentColor" />
    </>
  ),
});

export const LockEmptyIcon = createChakraIcon({
  displayName: 'LockEmptyIcon',
  viewBox: '0 0 24 25',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <g clipPath="url(#clip0_245_39326)">
        <path
          d="M18.75 10.25H5.25C4.42157 10.25 3.75 10.9216 3.75 11.75V22.25C3.75 23.0784 4.42157 23.75 5.25 23.75H18.75C19.5784 23.75 20.25 23.0784 20.25 22.25V11.75C20.25 10.9216 19.5784 10.25 18.75 10.25Z"
          stroke="#AFAFAF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 10.25V6.5C6.75 5.10761 7.30312 3.77226 8.28769 2.78769C9.27226 1.80312 10.6076 1.25 12 1.25C13.3924 1.25 14.7277 1.80312 15.7123 2.78769C16.6969 3.77226 17.25 5.10761 17.25 6.5V10.25"
          stroke="#AFAFAF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15.5V18.5"
          stroke="#AFAFAF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_245_39326">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const ChatBubble = createChakraIcon({
  displayName: 'ChatBubble',
  viewBox: '0 0 81 80',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5655 66.4326L45.9233 59.1002L58.4379 65.4926L56.6194 56.8175L62.5068 55.5611C63.2845 55.3951 63.9642 54.9204 64.3965 54.2413C64.8287 53.5623 64.9779 52.7346 64.8114 51.9403L57.434 16.7468C57.3515 16.3535 57.194 15.9802 56.9705 15.6483C56.747 15.3164 56.4618 15.0323 56.1312 14.8123C55.8006 14.5923 55.431 14.4407 55.0437 14.3661C54.6564 14.2916 54.2588 14.2954 53.8737 14.3776L2.93246 25.2492C2.54736 25.3314 2.18227 25.4902 1.85805 25.7167C1.53382 25.9431 1.25679 26.2327 1.04279 26.5689C0.828785 26.9051 0.681975 27.2814 0.610776 27.6762C0.539577 28.071 0.545369 28.4766 0.627815 28.87L8.00524 64.0635C8.17175 64.8578 8.6404 65.5536 9.30808 65.9979C9.97576 66.4422 10.7878 66.5986 11.5655 66.4326Z"
        fill="#B1CDD7"
      />
      <path
        d="M23.6146 47.4635L29.905 48.0359L28.9811 58.3739L40.5873 49.0079L72.234 51.8874C73.0105 51.958 73.7834 51.7107 74.3826 51.1999C74.9819 50.689 75.3583 49.9565 75.4292 49.1633L78.5727 13.9907C78.6436 13.1976 78.4031 12.4089 77.9041 11.7981C77.4052 11.1874 76.6886 10.8045 75.912 10.7339L27.3498 6.31526C26.965 6.27869 26.5768 6.32028 26.2075 6.43763C25.8382 6.55498 25.4951 6.74578 25.1981 6.99901C24.9011 7.25224 24.656 7.56289 24.4769 7.91307C24.2978 8.26324 24.1883 8.646 24.1546 9.03928L21.0111 44.2119C20.976 44.6046 21.017 45.0004 21.1317 45.3766C21.2464 45.7528 21.4326 46.1021 21.6797 46.4045C21.9268 46.7069 22.2299 46.9565 22.5717 47.1391C22.9135 47.3218 23.2873 47.4337 23.6718 47.4687"
        fill="#25383F"
      />
      <path
        d="M23.6146 47.4635L29.905 48.0359L28.9811 58.3739L40.5873 49.0079L72.234 51.8874C73.0105 51.958 73.7834 51.7107 74.3826 51.1999C74.9819 50.689 75.3583 49.9565 75.4292 49.1633L78.5727 13.9907C78.6436 13.1976 78.4031 12.4089 77.9041 11.7981C77.4052 11.1874 76.6886 10.8045 75.912 10.7339L27.3498 6.31526C26.965 6.27869 26.5768 6.32028 26.2075 6.43763C25.8382 6.55498 25.4951 6.74578 25.1981 6.99901C24.9011 7.25224 24.656 7.56289 24.4769 7.91307C24.2978 8.26324 24.1883 8.646 24.1546 9.03928L21.0111 44.2119C20.941 44.995 21.1744 45.7742 21.6612 46.3822C22.148 46.9903 22.8494 47.3785 23.6146 47.4635Z"
        stroke="#25383F"
        strokeWidth="2.46847"
        strokeLinejoin="round"
      />
      <path
        d="M24.8282 46.4196L31.119 46.992L30.194 57.3416L41.8018 47.964L73.393 50.8385C74.1696 50.9091 74.9425 50.6618 75.5417 50.151C76.141 49.6401 76.5174 48.9076 76.5883 48.1145L79.7318 12.9421C79.8027 12.149 79.5622 11.3603 79.0632 10.7495C78.5642 10.1388 77.8476 9.75594 77.071 9.68528L28.5062 5.26642C27.7296 5.19576 26.9567 5.44305 26.3575 5.9539C25.7582 6.46475 25.3817 7.1973 25.3109 7.99041L22.1674 43.1628C22.0965 43.9559 22.337 44.7446 22.836 45.3554C23.335 45.9661 24.0516 46.3489 24.8282 46.4196Z"
        fill="white"
      />
      <path
        d="M24.8282 46.4196L31.119 46.992L30.194 57.3416L41.8018 47.964L73.393 50.8385C74.1696 50.9091 74.9425 50.6618 75.5417 50.151C76.141 49.6401 76.5174 48.9076 76.5883 48.1145L79.7318 12.9421C79.8027 12.149 79.5622 11.3603 79.0632 10.7495C78.5642 10.1388 77.8476 9.75594 77.071 9.68528L28.5062 5.26642C27.7296 5.19576 26.9567 5.44305 26.3575 5.9539C25.7582 6.46475 25.3817 7.1973 25.3109 7.99041L22.1674 43.1628C22.0965 43.9559 22.337 44.7446 22.836 45.3554C23.335 45.9661 24.0516 46.3489 24.8282 46.4196Z"
        stroke="#25383F"
        strokeWidth="1.85135"
        strokeLinejoin="round"
      />
      <path
        d="M40.1341 24.0351L35.3915 28.0787L33.4886 25.7622"
        stroke="#82A35A"
        strokeWidth="2.46847"
        strokeMiterlimit="10"
      />
      <path
        d="M38.3041 33.3138L32.8594 32.8184L32.3624 38.3792L37.8071 38.8746L38.3041 33.3138Z"
        fill="white"
      />
      <path
        d="M38.3041 33.3138L32.8594 32.8184L32.3624 38.3792L37.8071 38.8746L38.3041 33.3138Z"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M45.6445 17.625L70.5554 19.8916"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.8086 26.9805L69.7194 29.2471"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.9453 36.6309L68.8562 38.8975"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9231 15.2148L36.1805 19.2582L34.2787 16.9301"
        stroke="#82A35A"
        strokeWidth="2.46847"
        strokeMiterlimit="10"
      />
    </svg>
  ),
});
export const LockIcon = createChakraIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M18.75 9.75H5.25C4.42157 9.75 3.75 10.4216 3.75 11.25V21.75C3.75 22.5784 4.42157 23.25 5.25 23.25H18.75C19.5784 23.25 20.25 22.5784 20.25 21.75V11.25C20.25 10.4216 19.5784 9.75 18.75 9.75Z"
        fill="#EFD58B"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 9.75V6C6.75 4.60761 7.30312 3.27226 8.28769 2.28769C9.27226 1.30312 10.6076 0.75 12 0.75C13.3924 0.75 14.7277 1.30312 15.7123 2.28769C16.6969 3.27226 17.25 4.60761 17.25 6V9.75"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15V18"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const BrowseIcon = createChakraIcon({
  displayName: 'BrowseIcon',
  viewBox: '0 0 18 18',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M1.5 9C1.5 10.9891 2.29018 12.8968 3.6967 14.3033C5.10322 15.7098 7.01088 16.5 9 16.5C10.9891 16.5 12.8968 15.7098 14.3033 14.3033C15.7098 12.8968 16.5 10.9891 16.5 9C16.5 7.01088 15.7098 5.10322 14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5C7.01088 1.5 5.10322 2.29018 3.6967 3.6967C2.29018 5.10322 1.5 7.01088 1.5 9Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 8.41667C5.5 9.19021 5.80729 9.93208 6.35427 10.4791C6.90125 11.026 7.64312 11.3333 8.41667 11.3333C9.19021 11.3333 9.93208 11.026 10.4791 10.4791C11.026 9.93208 11.3333 9.19021 11.3333 8.41667C11.3333 7.64312 11.026 6.90125 10.4791 6.35427C9.93208 5.80729 9.19021 5.5 8.41667 5.5C7.64312 5.5 6.90125 5.80729 6.35427 6.35427C5.80729 6.90125 5.5 7.64312 5.5 8.41667Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4793 10.4787L12.5 12.5"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const EmptyCircleIcon = createChakraIcon({
  displayName: 'EmptyCircleIcon',
  viewBox: '0 0 26 26',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <circle
      cx="13"
      cy="12.999"
      r="11.25"
      stroke="#D5DADC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export const DocumentDisabledIcon = createChakraIcon({
  displayName: 'EmptyCircleIcon',
  viewBox: '0 0 80 80',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <rect width="80" height="80" rx="40" fill="#EBF0F5" />
      <g clipPath="url(#clip0_2079_1291)">
        <path
          d="M21.25 26C21.25 25.4477 21.6977 25 22.25 25H51.75C52.3023 25 52.75 25.4477 52.75 26V62.25C52.75 62.8023 52.3023 63.25 51.75 63.25H28.25C24.384 63.25 21.25 60.116 21.25 56.25V26Z"
          fill="white"
          stroke="#D5DADC"
          strokeWidth="2"
        />
        <circle cx="28.75" cy="41.5" r="1" fill="#ABB6BA" stroke="#D5DADC" />
        <circle cx="28.75" cy="48.25" r="1" fill="#ABB6BA" stroke="#D5DADC" />
        <circle cx="28.75" cy="34.75" r="1" fill="#ABB6BA" stroke="#D5DADC" />
        <path
          d="M52.7074 63.25C55.2162 63.25 57.25 61.3792 57.25 59.0714V58H31.0426V58.7074C31.0426 61.2162 29.0088 63.25 26.5 63.25H52.7074Z"
          fill="#D5DADC"
          stroke="#D5DADC"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <line x1="32.5" y1="41.25" x2="46.75" y2="41.25" stroke="#D5DADC" strokeWidth="2" />
        <line x1="32.5" y1="48" x2="46.75" y2="48" stroke="#D5DADC" strokeWidth="2" />
        <line x1="32.5" y1="34.5" x2="40" y2="34.5" stroke="#D5DADC" strokeWidth="2" />
        <path
          d="M50.7317 29.6954L53.7851 26.8716L62.1579 35.5692C62.9655 36.4081 62.9266 37.7468 62.0716 38.5375C61.2405 39.3061 59.9473 39.2685 59.1622 38.453L50.7317 29.6954Z"
          fill="#ABB6BA"
        />
        <path
          d="M59.5224 38.1062L51.4457 29.7162L53.7648 27.5714L61.7976 35.9159C62.411 36.5531 62.3815 37.5699 61.7322 38.1704C61.1009 38.7542 60.1187 38.7256 59.5224 38.1062Z"
          stroke="#D5DADC"
        />
        <circle cx="49" cy="24.25" r="7.25" fill="white" stroke="#D5DADC" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_2079_1291">
          <rect width="48" height="48" fill="white" transform="translate(16 16)" />
        </clipPath>
      </defs>
    </>
  ),
});
