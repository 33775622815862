import { Container, Box, Grid, GridItem, Center, Skeleton } from '@terminal/design-system';
import JobBoardItem from './JobBoardItem';
import { PrimaryButton } from '../Button';
import { trackGetStarted } from '../../../lib/segment/trackGetStarted';
import { serializeJobDetails } from '@terminal/candidate/features/job/utils';
import { Link } from '@terminal/design-system';

export default function JobBoard({
  jobs,
  signUpUrl,
  bgColor,
}: {
  jobs: ReturnType<typeof serializeJobDetails>[];
  signUpUrl: string;
  bgColor: string;
}) {
  return (
    <Box
      bgColor={bgColor ? bgColor : 'transparent'}
      pt={{ base: '14', lg: '12' }}
      pb={{ base: '14', lg: '28' }}
    >
      <Container>
        <Grid
          gridTemplateColumns={{
            md: `repeat(${jobs.length <= 2 ? 1 : 2}, 1fr)`,
            xl: `repeat(${jobs.length <= 3 ? jobs.length : 4}, 1fr)`,
          }}
          gap={{ base: '6', md: '5' }}
        >
          {jobs.map((job) => (
            <GridItem key={job.id}>
              <JobBoardItem job={job} />
            </GridItem>
          ))}
        </Grid>
        {signUpUrl && (
          <Center flexDirection="column">
            <PrimaryButton
              as={Link}
              w={{ base: '100%', md: 'auto' }}
              mt={{ base: '12' }}
              href={signUpUrl}
              onClick={() => trackGetStarted({ type: 'engineers', context: 'JobBoard' })}
            >
              Get Started
            </PrimaryButton>
            <Link href="/engineers/job-openings" mt={4}>
              See more job openings
            </Link>
          </Center>
        )}
      </Container>
    </Box>
  );
}

export function JobBoardLoading({ signUpUrl, bgColor }: { signUpUrl?: string; bgColor: string }) {
  return (
    <Box
      bgColor={bgColor ? bgColor : 'transparent'}
      pt={{ base: '14', lg: '12' }}
      pb={{ base: '14', lg: '28' }}
    >
      <Container>
        <Grid
          gridTemplateColumns={{
            base: `repeat(1, 1fr)`,
            sm: `repeat(2, 1fr)`,
            md: `repeat(4, 1fr)`,
          }}
          gap={{ base: '6', md: '5' }}
        >
          {Array(4)
            .fill(null)
            .map((_, index) => (
              <GridItem key={`loading-${index}`}>
                <Skeleton w="262px" height="346px" />
              </GridItem>
            ))}
        </Grid>
        {signUpUrl && (
          <Center flexDirection="column">
            <PrimaryButton
              as={Link}
              w={{ base: '100%', md: 'auto' }}
              mt={{ base: '12' }}
              href={signUpUrl}
              onClick={() => trackGetStarted({ type: 'engineers', context: 'JobBoard' })}
            >
              Get Started
            </PrimaryButton>
            <Link href="/engineers/job-openings" mt={4}>
              See more job openings
            </Link>
          </Center>
        )}
      </Container>
    </Box>
  );
}
