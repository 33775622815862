import { selectBlokStyles } from '@helpers/storyblok.utils';
import { storyblokEditable } from '@storyblok/react';
import { AspectRatio, Box } from '@terminal/design-system';
import { Storyblok, YoutubeStoryblok } from '../types';

const video_id = (youtubeUrl) => {
  if (!youtubeUrl) return '';
  if (youtubeUrl.startsWith('https://www.youtube.com/watch?v=')) {
    return youtubeUrl.split('=')[1];
  } else if (youtubeUrl.startsWith('https://youtu.be/')) {
    return youtubeUrl.split('/')[3];
  }
};
function getVideoOptions(blok: YoutubeStoryblok) {
  let optionsString = '';
  blok.enableFullscreen ? (optionsString += '&fs=1') : (optionsString += '&fs=0');
  if (blok.autoPlay) optionsString += '&autoplay=1&mute=1';
  if (blok.hideControls) optionsString += '&controls=0';
  if (blok.disableRelatedVideos) optionsString += '&rel=0';
  if (blok.startAt) optionsString += `&start=${blok.startAt}`;
  return optionsString;
}

export function Youtube({ blok }: Storyblok<YoutubeStoryblok>) {
  const { marginStyles, sizesStyle } = selectBlokStyles(blok);

  return (
    <Box
      {...marginStyles}
      {...storyblokEditable(blok)}
      {...(blok.attributeID && { id: blok.attributeID })}
    >
      <AspectRatio
        maxW="80%"
        {...sizesStyle}
        ratio={+blok.aspectRatio}
        left="50%"
        transform="translateX(-50%)"
      >
        <iframe
          loading="lazy"
          src={`https://www.youtube.com/embed/${video_id(
            blok.link,
          )}?modestbranding=1${getVideoOptions(blok)}`}
          title={'youtube-video: ' + blok.title || ''}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
        />
      </AspectRatio>
    </Box>
  );
}
