import {
  Container,
  Box,
  Heading,
  HeadingProps,
  BoxProps,
  List,
  ListItem,
  Link,
  Text,
  Flex,
} from '@terminal/design-system';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  AccordionPanelProps,
} from '@chakra-ui/react';

export default function Faq({
  faq,
  customStyles,
  children,
  parentBoxProps = {},
  title = 'FAQ',
  showSidebar,
}: {
  faq: {
    q: string;
    a: string | JSX.Element;
  }[];
  customStyles?: {
    heading?: HeadingProps;
    questionHeading?: HeadingProps;
    content?: AccordionPanelProps;
  };
  children?: React.ReactChild;
  parentBoxProps?: BoxProps;
  title?: string;
  showSidebar?: boolean;
}) {
  if (showSidebar) {
    return (
      <Container pb={12} id="faqs" as="section" {...parentBoxProps}>
        <Flex gap={8} flexDirection={['column', 'row']}>
          <Box flex={1} position={['static', 'sticky']} top={0} alignSelf="flex-start">
            <Heading as="h2" variant="heading-2">
              {title}
            </Heading>
            <List spacing={4} pt={4}>
              {faq.map((item, index) => (
                <ListItem key={index} borderBottom="0.0625rem solid #ABB6BA" pb={2}>
                  <Link href={`#faq-${index}`}>{item.q}</Link>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box flex={3}>
            {faq.map((item, index) => (
              <Box key={index} id={`faq-${index}`}>
                <Heading as="h3" variant="heading-3">
                  {item.q}
                </Heading>
                {typeof item.a === 'string' ? (
                  <Text
                    as="div"
                    variant="body"
                    ml="0"
                    pl={{ base: '0' }}
                    pr={{ base: '2rem' }}
                    pb={4}
                    pt={3}
                    sx={{
                      ul: {
                        pl: 8,
                      },
                      li: {
                        pb: 4,
                      },
                      a: {
                        textDecoration: 'underline',
                        color: 'text.link',
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.a,
                    }}
                  />
                ) : (
                  <Box
                    ml="0"
                    pl={{ base: '0' }}
                    pr={{ base: '2rem' }}
                    pb={4}
                    pt={3}
                    sx={{
                      ul: {
                        pl: 8,
                      },
                      li: {
                        pb: 4,
                      },
                      '& > a': {
                        textDecoration: 'underline',
                        color: 'text.link',
                        display: 'inline-block',
                      },
                    }}
                  >
                    {item.a}
                  </Box>
                )}
              </Box>
            ))}
            {children}
          </Box>
        </Flex>
      </Container>
    );
  }
  return (
    <Box
      py={{ base: '12', lg: '24' }}
      maxW={{ lg: '61.125rem' }}
      mx="auto"
      id="faqs"
      as="section"
      {...parentBoxProps}
    >
      <Container>
        <Heading
          as="h2"
          textAlign={{ base: 'center', lg: 'left' }}
          fontSize={{ base: '2.5rem', lg: '3rem' }}
          lineHeight={{ base: '3rem', lg: '3.5rem' }}
          mb={{ base: '4', lg: '6' }}
          {...customStyles?.heading}
        >
          {title}
        </Heading>
        <Accordion allowToggle>
          {faq.map((item, index) => (
            <AccordionItem
              key={index}
              borderBottom="0.0625rem solid #ABB6BA"
              borderTop="none"
              id={`${index}`}
              sx={{
                ':last-of-type': {
                  border: 'none',
                },
              }}
            >
              <Heading as="h2">
                <AccordionButton
                  px={0}
                  py={6}
                  _expanded={{ bg: 'transparent' }}
                  _hover={{
                    bgColor: 'transparent',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    fontWeight="semibold"
                    fontSize={{ base: '1.25rem', lg: '1.75rem' }}
                    lineHeight={{ base: '1.5rem', lg: '2.125rem' }}
                    pr={{ base: '2.75rem' }}
                    {...customStyles?.questionHeading}
                  >
                    {item.q}
                  </Box>
                  <AccordionIcon color="grey.900" fontSize="2rem" />
                </AccordionButton>
              </Heading>
              <Box bgColor="transparent">
                <AccordionPanel
                  ml="0"
                  pl={{ base: '0' }}
                  pr={{ base: '2rem' }}
                  pb="2rem"
                  fontSize="md"
                  {...customStyles?.content}
                  sx={{
                    ul: {
                      pl: 8,
                    },
                    li: {
                      pb: 4,
                    },
                    '& > a': {
                      textDecoration: 'underline',
                      color: 'text.link',
                      display: 'inline-block',
                    },
                  }}
                >
                  {item.a}
                </AccordionPanel>
              </Box>
            </AccordionItem>
          ))}
        </Accordion>
        {children}
      </Container>
    </Box>
  );
}
