'use client';
import {
  Table as TableComponent,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useBreakpointValue,
} from '@terminal/design-system';

import { Storyblok, SimpleTableStoryblok } from '../types';
import { storyblokEditable } from '@storyblok/react';
import { selectBlokStyles } from '@helpers/storyblok.utils';

export function Table({ blok }: Storyblok<SimpleTableStoryblok>) {
  const { marginStyles, sizesStyle } = selectBlokStyles(blok);

  const bold_columns = (
    blok.boldColumns?.includes(',') ? blok.boldColumns.split(',') : [blok.boldColumns]
  ).map(Number);
  const bold_rows = (blok.boldRows?.includes(',') ? blok.boldRows.split(',') : [blok.boldRows]).map(
    Number,
  );

  const responsive_size = useBreakpointValue(
    { base: 'sm', md: 'md', lg: 'lg' },
    { ssr: typeof window !== 'undefined' ? false : true, fallback: 'md' },
  );

  return (
    <TableContainer {...storyblokEditable(blok)} {...marginStyles} {...sizesStyle}>
      <TableComponent variant={blok.Variant} size={blok.Size || responsive_size}>
        {blok.caption && <TableCaption>{blok.caption}</TableCaption>}

        {blok.content.thead && (
          <Thead>
            <Tr>
              {blok.content.thead.map((heading) => (
                <Th key={heading._uid}>{heading.value}</Th>
              ))}
            </Tr>
          </Thead>
        )}

        {blok.content.tbody && (
          <Tbody>
            {blok.content.tbody.map((row, row_index) => (
              <Tr key={row._uid}>
                {row.body.map((cell, column_index) => {
                  const is_bold_row = bold_rows.includes(row_index + 1);
                  const is_bold_column = bold_columns.includes(column_index + 1);

                  return (
                    <Td key={cell._uid} isNumeric={!isNaN(Number(cell.value))}>
                      {is_bold_row || is_bold_column ? <b>{cell.value}</b> : cell.value}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        )}
      </TableComponent>
    </TableContainer>
  );
}
