import {
  Box,
  Container,
  Grid,
  GridItem,
  Flex,
  Heading,
  Text,
  VStack,
  HeadingProps,
  UnorderedList,
  ListItem,
} from '@terminal/design-system';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import squaresWhite from '../public/images/ui/square-pattern-white.svg';
import homeOfficeChair from '../public/images/hiring-markets/home-office-perks.svg';
import { trackCtaClicked } from '../lib/segment/trackAnalytics';

import Hero from './Home/Hero';
import SquarePattern from './UI/SquarePattern';
import ImageShimmer from './ImageShimmer/ImageShimmer';
import { PrimaryButton, GhostButton } from './UI/Button';

export const CardWithIcon = ({
  title,
  image,
  text,
  headingProps = {},
}: {
  title: string;
  image: {
    src: string;
    alt: string;
  };
  text: string;
  headingProps?: HeadingProps;
}) => (
  <VStack textAlign="center" flex={1}>
    <ImageShimmer src={image.src} alt={image.alt} width={80} height={80} />
    <Heading as="h3" fontSize="3xl" variant="heading-3" mt={4} mb={1} {...headingProps}>
      {title}
    </Heading>
    <Text variant="body">{text}</Text>
  </VStack>
);

export function HiringMarketsLayout({
  hero,
  regionContent,
  stats,
  benefits,
  hiringCountries,
  testimonial,
}: {
  hero: {
    longText: boolean;
    smallText: boolean;
    title: {
      formatted: string;
      main: string;
    };
    text: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
    button: {
      url: string;
      text: string;
      width: string;
    };
  };
  regionContent: {
    image: {
      src: string;
      alt: string;
    };
    content: {
      title: string;
      description: string;
      items?: string[];
    }[];
    button: {
      url: string;
      text: string;
    };
    title?: string;
    countries?: {
      image: {
        sourceUrl: string;
        altText: string;
      };
      title: string;
      text: string;
      button?: {
        text: string;
        url: string;
      };
    }[];
  };
  stats: {
    title1: string;
    items: {
      image: {
        src: string;
        alt: string;
      };
      title: string;
      text: string;
    }[];
    title2: string;
    image: {
      src: string;
      alt: string;
    };
  };
  benefits: {
    title: string;
    items: {
      image: {
        src: string;
        alt: string;
      };
      title: string;
      text: string;
    }[];
  };
  hiringCountries: {
    title: string;
    items: {
      title: string;
      text: string;
      link: {
        url: string;
        text: string;
      };
      image: {
        src: string;
        alt: string;
        height: string;
      };
    }[];
  };
  testimonial: {
    image: {
      sourceUrl: string;
      altText: string;
    };
    text: string;
    author: {
      name: string;
      jobTitle: string;
    };
  };
}) {
  return (
    <>
      {/* Hero section */}
      <Hero
        hero={hero}
        parentBoxProps={{
          bgColor: 'blue.100',
          position: 'relative',
        }}
      >
        <Box
          position="absolute"
          w={{
            base: '40%',
            lg: '24%',
          }}
          top={{
            base: 2,
            lg: 16,
          }}
          bottom={{
            base: 'initial',
            lg: 16,
          }}
          height={{
            base: '50%',
            lg: 'initial',
          }}
          right={0}
          bgImage={`url(${squaresWhite.src})`}
        />
      </Hero>
      {/* Region section */}
      <Box as="section" py={{ base: 10, lg: 16 }} pos="relative">
        <SquarePattern
          pos="absolute"
          left={0}
          top={{
            base: 0,
            lg: 'calc(var(--chakra-space-16) + 5rem)',
          }}
          height={{
            base: '300px',
            lg: '400px',
          }}
          width={{
            base: '50%',
            lg: '25%',
          }}
        />
        <Container>
          <Flex
            flexWrap="wrap"
            flexDir={{
              base: 'column',
              lg: 'row',
            }}
            gap={{ base: 7, lg: 20 }}
            mb={{ base: regionContent.title ? 20 : 0, lg: regionContent.title ? 32 : 0 }}
          >
            <Flex flex={1} alignItems="center" justifyContent="center">
              <Box
                pos="relative"
                h={{
                  base: '255px',
                  md: '364px',
                }}
                w={{
                  base: '280px',
                  md: '400px',
                }}
              >
                <ImageShimmer src={regionContent.image.src} alt={regionContent.image.alt} fill />
              </Box>
            </Flex>
            <Flex flex={1} flexDir="column">
              <Flex mb={8}>
                <Box w={2} h={2} mr={2} bgColor="blue.500" />
                <Box w={2} h={2} mr={2} bgColor="yellow.400" />
                <Box w={2} h={2} bgColor="green.500" />
              </Flex>
              {regionContent.content.map(({ title, description, items }) => (
                <>
                  <Heading as="h2" variant="heading-2" mb={1}>
                    {title}
                  </Heading>
                  <Text variant="body" mb={items ? 0 : 8}>
                    {description}
                  </Text>
                  {items && (
                    <UnorderedList mb={8} aria-label={`${title} list`} ml={4}>
                      {items.map((item) => (
                        <ListItem key={item} fontSize="base" lineHeight="short">
                          {item}
                        </ListItem>
                      ))}
                    </UnorderedList>
                  )}
                </>
              ))}
              <PrimaryButton
                href={regionContent.button.url}
                w={{ base: '100%', md: 'fit-content' }}
                px={5}
                onClick={() =>
                  trackCtaClicked({
                    link: regionContent.button.url,
                    name: regionContent.button.text,
                    type: 'businesses',
                    context: 'Region Section',
                  })
                }
              >
                {regionContent.button.text}
                <ArrowForwardIcon color="white" w={4} h={4} ml={3} />
              </PrimaryButton>
            </Flex>
          </Flex>
          {regionContent.title && (
            <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
              {regionContent.title}
            </Heading>
          )}
          {regionContent.countries && (
            <Grid
              gap={{ base: 8, lg: 6 }}
              gridTemplateColumns={{
                md: 'repeat(2, 1fr)',
                lg: `repeat(${
                  regionContent.countries.length > 3 ? 3 : regionContent.countries.length
                }, 1fr)`,
                xl: `repeat(${
                  regionContent.countries.length > 4 ? 4 : regionContent.countries.length
                }, 1fr)`,
              }}
            >
              {regionContent.countries.map((country) => (
                <GridItem
                  key={country.title}
                  textAlign="center"
                  maxW={{ md: '15rem' }}
                  display="flex"
                  flexDir="column"
                >
                  <Box pos="relative" mx="auto" mb={4}>
                    <ImageShimmer
                      src={country.image.sourceUrl}
                      alt={country.image.altText}
                      width={204}
                      height={110}
                    />
                  </Box>
                  <Heading as="h3" fontSize="2xl" lineHeight="short" mb={1}>
                    {country.title}
                  </Heading>
                  <Text variant="body" color="text.secondary" mb={4} flex={1}>
                    {country.text}
                  </Text>
                  {country.button && (
                    <GhostButton
                      href={country.button.url}
                      color="blue.500"
                      _hover={{
                        borderColor: 'blue.500',
                      }}
                      px={3}
                      onClick={() =>
                        trackCtaClicked({
                          link: country.button.url,
                          name: country.button.text,
                          type: 'businesses',
                          context: 'Region Section',
                        })
                      }
                    >
                      {country.button.text}
                      <ArrowForwardIcon color="blue.500" w={4} h={4} ml={3} />
                    </GhostButton>
                  )}
                </GridItem>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
      {/* Highlights section */}
      <Box as="section" py={{ base: 10, lg: 16 }} bgColor="bg.secondary">
        <Container>
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
            {stats.title1}
          </Heading>
          <Flex
            flexDir={{
              base: 'column',
              lg: 'row',
            }}
            gap={10}
          >
            {stats.items.map(({ title, image, text }) => (
              <CardWithIcon key={title} title={title} image={image} text={text} />
            ))}
          </Flex>
        </Container>
      </Box>
      {/* Market stats graphic section */}
      <Box as="section" pos="relative">
        <SquarePattern
          pos="absolute"
          left={0}
          bottom={0}
          height="360px"
          width="30%"
          display={{
            base: 'none',
            lg: 'initial',
          }}
        />
        <SquarePattern
          pos="absolute"
          right={0}
          top={0}
          height="360px"
          width="20%"
          display={{
            base: 'none',
            lg: 'initial',
          }}
        />
        <Box
          py={{ base: 10, lg: 16 }}
          px={4}
          bgColor="white"
          w="39.875rem"
          maxW="full"
          mx="auto"
          pos="relative"
        >
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
            {stats.title2}
          </Heading>
          <Box
            pos="relative"
            width={{
              base: 'full',
              md: 'calc(39.875rem - (var(--chakra-space-4) * 2))',
            }}
            height={{
              base: '16rem',
              md: '25rem',
            }}
          >
            <ImageShimmer src={stats.image.src} alt={stats.image.alt} fill />
          </Box>
        </Box>
      </Box>
      {/* Testimonial section */}
      <Box as="section">
        <Container px={{ base: 0, md: 4 }}>
          <Box bgColor="grey.900" px={{ base: 4, md: 20 }} py={16} pos="relative">
            <Box
              pos="absolute"
              width="185px"
              height="195px"
              top="-11rem"
              right="-2rem"
              display={{
                base: 'none',
                lg: 'initial',
              }}
            >
              <ImageShimmer
                src={homeOfficeChair}
                alt="Home office chair and desktop"
                style={{ objectFit: 'cover' }}
                fill
              />
            </Box>
            <Box pos="relative" overflow="hidden" w="5rem" h="5rem" borderRadius="full" mb={5}>
              <ImageShimmer
                src={testimonial.image.sourceUrl}
                alt={testimonial.image.altText}
                style={{ objectFit: 'cover' }}
                fill
              />
            </Box>
            <Heading as="h6" variant="heading-3" fontWeight="bold" color="text.inverse" mb={5}>
              {testimonial.text}
            </Heading>
            <Text variant="label" color="text.inverse">
              {testimonial.author.name}
            </Text>
            <Text variant="body" color="text.inverse">
              {testimonial.author.jobTitle}
            </Text>
          </Box>
        </Container>
      </Box>
      {/* Where we hire section */}
      <Box as="section" py={{ base: 10, lg: 16 }}>
        <Container>
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
            {hiringCountries.title}
          </Heading>
          <Flex
            flexDir={{
              base: 'column',
              lg: 'row',
            }}
            gap={10}
          >
            {hiringCountries.items.map(({ image, link, text, title }) => (
              <Flex key={title} flexDir={{ base: 'column', md: 'row' }} gap={6} flex={1}>
                <Box alignSelf="flex-start" pos="relative" minW="10rem" h={image.height}>
                  <ImageShimmer src={image.src} alt={image.alt} fill />
                </Box>
                <Flex flexDir="column">
                  <Heading as="h3" variant="heading-2" mb={1}>
                    {title}
                  </Heading>
                  <Text variant="body" mb={5}>
                    {text}
                  </Text>
                  <PrimaryButton
                    as="a"
                    aria-label={`Go to ${link.text} about ${title}`}
                    title={`Go to ${link.text} about ${title}`}
                    href={link.url}
                    w={{ base: '100%', md: '15rem' }}
                    px={5}
                    onClick={() =>
                      trackCtaClicked({
                        link: link.url,
                        name: link.text,
                        type: 'businesses',
                        context: 'Where do we Hire',
                      })
                    }
                  >
                    {link.text}
                    <ArrowForwardIcon color="white" w={4} h={4} ml={3} />
                  </PrimaryButton>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Container>
      </Box>
      {/* Benefits section */}
      <Box
        as="section"
        pt={{ base: 10, lg: 16 }}
        pb={{ base: 4, lg: 16 }}
        bgColor="bg.secondary"
        pos="relative"
        _after={{
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '25rem',
          left: '0',
          top: '100%',
          bgColor: 'bg.secondary',
          zIndex: '-1',
        }}
      >
        <Container>
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
            {benefits.title}
          </Heading>
          <Grid
            gridTemplateColumns={{
              md: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
            gap={10}
          >
            {benefits.items.map(({ title, image, text }) => (
              <CardWithIcon
                key={title}
                title={title}
                image={image}
                text={text}
                headingProps={{ fontSize: 'xl' }}
              />
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
