import { Box, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import ArrowRight from '../Icons/ArrowRight';
import { PrimaryButton } from './Button';
import ImageShimmer from '../ImageShimmer/ImageShimmer';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export default function Carousel({
  text,
  carousel,
  defaultIndex,
  ...props
}: {
  text: string;
  carousel: {
    image: {
      sourceUrl: string;
    };
    altText: string;
    title: string;
    text: string;
  }[];
  defaultIndex?: number;
  [x: string]: any;
}) {
  const [[page, direction], setPage] = useState<[number, number]>([defaultIndex ?? 0, 0]);

  const imageIndex = wrap(0, carousel.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <Box {...props}>
      {text && (
        <Text mt="3" mb="6" fontSize="md" lineHeight="6">
          {text}
        </Text>
      )}
      <Box
        overflow="hidden"
        position="relative"
        height={{ base: '39.375rem', sm: '36.5625rem', md: '35.3125rem' }}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            style={{
              position: 'absolute',
              left: '0',
              right: '0',
              margin: '0 auto',
              maxWidth: '100%',
            }}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            <Box position="relative" w="100%" h="365px">
              <ImageShimmer
                src={carousel[imageIndex].image.sourceUrl}
                alt={carousel[imageIndex].altText ? carousel[imageIndex].altText : ''}
                style={{ objectFit: 'contain' }}
                fill
              />
            </Box>
            <Box mt="10" px="16" py="10" bgColor="blue.100">
              <Box fontSize="lg" lineHeight="6" fontWeight="bold">
                {carousel[imageIndex].title}
              </Box>
              <Box mt="4" lineHeight="5">
                {carousel[imageIndex].text}
              </Box>
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
      <Flex w="16rem" mx="auto" mt="8" alignItems="center" justifyContent="space-between">
        <PrimaryButton minW="14" h="14" bgColor="green.500" onClick={() => paginate(-1)}>
          <ArrowRight
            color="white"
            transform="rotate(180deg)"
            aria-label="Arrow icon pointing to the right"
          />
        </PrimaryButton>
        <Box>
          {imageIndex + 1} of {carousel.length}
        </Box>
        <PrimaryButton minW="14" h="14" bgColor="green.500" onClick={() => paginate(1)}>
          <ArrowRight color="white" aria-label="Arrow icon pointing to the right" />
        </PrimaryButton>
      </Flex>
    </Box>
  );
}
