import { Icon } from '@chakra-ui/react';
export default function LinkedIn({ color, ...props }: { color?: string; [x: string]: any }) {
  return (
    <Icon
      w="6"
      h="6"
      viewBox="0 0 24 24"
      transition="color 0.3s ease-in-out"
      color={color}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8599 20.5875H16.544V13.8998C16.544 12.3052 16.5153 10.2543 14.3007 10.2543C12.0549 10.2543 11.7124 11.992 11.7124 13.7864V20.5875H7.40039V6.83574H11.538V8.71648H11.5979C12.1733 7.63496 13.582 6.49414 15.6821 6.49414C20.0527 6.49414 20.8599 9.34168 20.8599 13.0452V20.5875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53375 4.95773C1.14718 4.95773 0.0288086 3.84656 0.0288086 2.47887C0.0288086 1.11117 1.14718 0 2.53375 0C3.91382 0 5.03479 1.11117 5.03479 2.47887C5.03479 3.84656 3.91382 4.95773 2.53375 4.95773Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69226 20.5877H0.371094V6.83594H4.69226V20.5877Z"
        fill="currentColor"
      />
    </Icon>
  );
}
