import { selectBlokStyles } from '@helpers/storyblok.utils';
import { SvgAnimationStoryblok, Storyblok } from '../types';
import EngineersAnimationDesktop from '@components/Animations/Hero/EngineersAnimationDesktop';
import { Box } from '@terminal/design-system';
import { storyblokEditable } from '@storyblok/react';

const animationMapping = {
  'engineering-desktop': <EngineersAnimationDesktop />,
};

export function SVGAnimation({ blok }: Storyblok<SvgAnimationStoryblok>) {
  const { sizesStyle } = selectBlokStyles(blok);

  const animation = animationMapping[blok.animation];
  return animation ? (
    <Box {...sizesStyle} {...storyblokEditable(blok)}>
      {animation}
    </Box>
  ) : null;
}
