import { Icon } from '@chakra-ui/react';
export default function Twitter({ color, ...props }: { color?: string }) {
  return (
    <Icon
      w="6"
      h="6"
      viewBox="0 0 24 24"
      transition="color 0.3s ease-in-out"
      color={color}
      {...props}
    >
      <path
        d="M22 5.79603C21.2511 6.128 20.4565 6.34576 19.643 6.44203C20.5002 5.92948 21.1417 5.12267 21.448 4.17203C20.6426 4.6505 19.7612 4.98738 18.842 5.16803C18.2788 4.56845 17.5484 4.15192 16.7456 3.97244C15.9429 3.79295 15.1046 3.85881 14.3397 4.16145C13.5748 4.4641 12.9185 4.98958 12.4558 5.66975C11.9931 6.34993 11.7455 7.15341 11.745 7.97603C11.7444 8.29067 11.7797 8.60435 11.85 8.91103C10.2188 8.82913 8.62297 8.40515 7.16619 7.6666C5.70942 6.92805 4.42422 5.89143 3.394 4.62403C2.86926 5.52738 2.7085 6.59676 2.94444 7.61447C3.18038 8.63218 3.79529 9.52172 4.664 10.102C4.01193 10.082 3.37413 9.90613 2.804 9.58903V9.64103C2.80416 10.5885 3.13206 11.5067 3.73208 12.24C4.3321 12.9733 5.1673 13.4764 6.096 13.664C5.74368 13.7598 5.38011 13.8079 5.015 13.807C4.75565 13.8071 4.49686 13.783 4.242 13.735C4.50405 14.5502 5.01426 15.2631 5.70132 15.7741C6.38837 16.2851 7.21792 16.5686 8.074 16.585C6.35342 17.9317 4.16942 18.5419 2 18.282C3.87605 19.488 6.05975 20.1282 8.29 20.126C9.82577 20.1364 11.3483 19.8415 12.7692 19.2586C14.19 18.6756 15.4809 17.8162 16.5668 16.7301C17.6527 15.6441 18.5121 14.3532 19.0949 12.9323C19.6778 11.5113 19.9725 9.98879 19.962 8.45303C19.962 8.27503 19.9577 8.09836 19.949 7.92303C20.7535 7.34143 21.448 6.62118 22 5.79603Z"
        fill="currentColor"
      />
    </Icon>
  );
}
