import { storyblokEditable } from '@storyblok/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@terminal/design-system';
import { OutlineButton } from '@components/UI/Button';
import ImageShimmer from '@components/ImageShimmer/ImageShimmer';
import { Storyblok, RolesListStoryblok } from '../types';

export function RolesList({ blok, page }: Storyblok<RolesListStoryblok>) {
  const roles =
    blok.order?.map((uuid) => {
      if (typeof uuid === 'string') {
        return page?.extra?.roles[uuid];
      }
    }) || Object.values(page?.extra?.roles);

  if (blok.bigPills) {
    return (
      <Flex justifyContent="center" flexWrap="wrap" gap={6}>
        {roles.map((el) => (
          <OutlineButton
            key={el.slug}
            href={el.full_slug}
            fontSize="md"
            fontWeight="bold"
            borderRadius="6.25rem"
            border="none"
            color="grey.900"
            bg="white"
            px={3}
            py={2}
            _hover={{
              backgroundColor: 'green.100',
              '& > svg': {
                display: 'inherit',
              },
            }}
            minW={{ base: 'full', sm: '16rem' }}
            h={12}
            justifyContent="start"
          >
            {!!el.icon && <ImageShimmer width={32} height={32} src={el.icon} alt={el.name} />}
            <Text as="span" flex={1} variant="label" ml={2}>
              {el.name}
            </Text>
            <ArrowForwardIcon display="none" color="green.500" w={5} h={5} ml={1} />
          </OutlineButton>
        ))}
      </Flex>
    );
  }
  return (
    <Flex justifyContent="center" flexWrap="wrap" gap={6} {...storyblokEditable(blok)}>
      {roles.map((el) => (
        <OutlineButton
          as="a"
          key={el.slug}
          href={el.full_slug}
          fontSize="sm"
          fontWeight={blok.whitePill ? '700' : 'normal'}
          borderRadius="6.25rem"
          color="grey.900"
          border="none"
          px={3}
          py={1}
          minW={{ base: 'calc(50% - 1rem)', md: '13rem' }}
          h="2.25rem"
          textAlign="center"
          bgColor={blok.whitePill ? 'white' : 'bg.secondary'}
          _hover={{
            backgroundColor: 'blue.100',
            boxShadow: 'md',
          }}
        >
          {el.name}
        </OutlineButton>
      ))}
    </Flex>
  );
}
