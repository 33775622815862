import { Button as ChakraButton } from '@terminal/design-system';
import { storyblokEditable } from '@storyblok/react';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import Link from 'next/link';
import type { ButtonStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';

export function Button({ blok }: Storyblok<ButtonStoryblok>) {
  const { marginStyles } = selectBlokStyles(blok);

  const setting = {
    variant:
      {
        solidBrand: 'solid',
        outlineBrand: 'outline',
        ghostBrand: 'ghost',
        solidAccent: 'solid',
        outlineAccent: 'outline',
        ghostAccent: 'ghost',
      }[blok.variant] || 'solid',
    colorScheme:
      {
        solidBrand: 'primary',
        outlineBrand: 'primary',
        ghostBrand: 'primary',
        solidAccent: 'accent',
        outlineAccent: 'accent',
        ghostAccent: 'accent',
      }[blok.variant] || 'primary',
  } || {
    variant: 'solid',
    colorScheme: 'primary',
  };

  // TODO: Ask why start with two slashes ? this brokes the build
  const href = `${
    blok?.href?.cached_url.startsWith('#') ||
    blok?.href?.cached_url.startsWith('/') ||
    blok?.href?.cached_url.startsWith('http')
      ? ''
      : '/'
  }${blok?.href?.url || blok?.href?.cached_url || ''}`;

  const attr = {
    id: blok.id,
    className: blok.className,
    'aria-label': blok.ariaLabel || blok.title,
  };

  //   Filter object by eliminating undefined values and then spread
  const filteredAttr = Object.fromEntries(Object.entries(attr).filter(([_, v]) => v));

  return (
    <ChakraButton
      as={Link}
      {...filteredAttr}
      {...storyblokEditable(blok)}
      href={href}
      {...(blok.fullWidth ? { width: 'full' } : {})}
      variant={setting.variant}
      colorScheme={setting.colorScheme}
      {...marginStyles}
      onClick={() => {
        if (blok?.enableOnClickTracking) {
          trackCtaClicked({
            name: blok.title,
            type: blok.trackingParamType,
            context: blok.trackingParamContext,
            link: href,
          });
        }
      }}
      size={blok.size}
    >
      {blok.title}
    </ChakraButton>
  );
}
