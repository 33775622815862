import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export default function metaRobots({
  translated,
  locale,
  post,
}: {
  translated: boolean;
  locale: string;
  post?: {
    seo: {
      metaRobotsNoindex: string;
      metaRobotsNofollow: string;
    };
  };
}) {
  if (publicRuntimeConfig.seoIndexDisabled === 'true') {
    return 'noindex, nofollow';
  }

  if (publicRuntimeConfig.siteEnv === 'dev') {
    return 'noindex, nofollow';
  }

  if (post) {
    return `${post.seo.metaRobotsNoindex}, ${post.seo.metaRobotsNofollow}`;
  }

  return 'index, follow';
}

export function determineMetaRobots({
  translated,
  locale,
  post,
}: {
  translated: boolean;
  locale: string;
  post?: {
    seo: {
      metaRobotsNoindex: string;
      metaRobotsNofollow: string;
    };
  };
}) {
  if (publicRuntimeConfig.siteEnv === 'dev') {
    return { nofollow: true, noindex: true };
  }

  if (post) {
    return {
      nofollow: post.seo.metaRobotsNofollow === 'nofollow',
      noindex: post.seo.metaRobotsNoindex === 'noindex',
    };
  }

  return { nofollow: false, noindex: false };
}

export function selectMetaRobots_forStoryblok(storyblokRobots?: string): string {
  if (publicRuntimeConfig.siteEnv === 'dev') {
    return 'nofollow noindex';
  }

  if (storyblokRobots) {
    return storyblokRobots;
  }

  return 'index, follow';
}
