import { Container, Box, Link, Grid, GridItem } from '@terminal/design-system';
import { openConsentManager } from '@segment/consent-manager';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { MouseEvent } from 'react';

import Facebook from '../Icons/Facebook';
import Twitter from '../Icons/Twitter';
import LinkedIn from '../Icons/LinkedIn';
import Instagram from '../Icons/Instagram';

export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const router = useRouter();

  const { t } = useTranslation('footer');

  // TODO: refactor this assertion cause is causing an error
  const NavItems = t('bottomMenu') as {
    label: string;
    href: string;
  }[];

  const handleLinkClick = (e: MouseEvent<HTMLElement>, url: string, i: number, length: number) => {
    e.preventDefault();
    if (length - 1 === i) {
      openConsentManager();
    } else {
      router.push(url);
    }
  };

  return (
    <>
      <Box
        bgColor="grey.900"
        pt={{ base: '12', lg: '10' }}
        pb={{ base: '12', lg: '10' }}
        color="text.inverse"
      >
        <Container
          display={{ lg: 'flex' }}
          alignItems={{ lg: 'center' }}
          justifyContent={{ lg: 'space-between' }}
        >
          <Grid
            templateColumns="repeat(4, 1.3125rem)"
            gap={8}
            sx={{
              '& a, a:hover': {
                color: 'text.inverse',
              },
            }}
          >
            <Link
              href="https://www.facebook.com/jointerminal"
              target="_blank"
              rel="noreferrer noopener"
              w="full"
            >
              <Facebook aria-label="Facebook logo" />
            </Link>
            <Link
              href="https://twitter.com/JoinTerminal"
              target="_blank"
              rel="noreferrer noopener"
              w="full"
            >
              <Twitter aria-label="Twitter logo" />
            </Link>
            <Link
              href="https://www.linkedin.com/company/jointerminal"
              target="_blank"
              rel="noreferrer noopener"
              w="full"
            >
              <LinkedIn aria-label="LinkedIn logo" />
            </Link>
            <Link
              href="https://www.instagram.com/jointerminal/"
              target="_blank"
              rel="noreferrer noopener"
              w="full"
            >
              <Instagram aria-label="Instagram logo" />
            </Link>
          </Grid>
          <Grid
            templateColumns={{ lg: 'repeat(5, auto)' }}
            gap="6"
            mt={{ base: '7', lg: '0' }}
            ml={{ lg: '2rem' }}
            fontSize={{ base: 'md', lg: 'xs' }}
            lineHeight="1.5rem"
          >
            {Array.isArray(NavItems) &&
              NavItems.map((navItem, i, { length }) => {
                return (
                  <Link
                    key={navItem.label}
                    href={navItem.href}
                    onClick={(e) => handleLinkClick(e, navItem.href, i, length)}
                    textDecoration="underline"
                    color="text.inverse"
                    _hover={{ color: 'text.inverse' }}
                    fontSize="sm"
                  >
                    {navItem.label}
                  </Link>
                );
              })}
            <GridItem>
              <Box>&copy; {getCurrentYear()} Terminal Inc.</Box>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
