import TabsCarousel from '@components/UI/TabsCarousel';
import { Storyblok, VerticalCarouselStoryblok } from '../types';
import { storyblokEditable } from '@storyblok/react';
import { Box } from '@terminal/design-system';

export function VerticalCarousel({ blok }: Storyblok<VerticalCarouselStoryblok>) {
  const tabs = blok?.tabs?.map((tabItem) => ({
    image: {
      sourceUrl: tabItem.image.filename,
    },
    altText: tabItem.imageAltText,
    title: tabItem.title,
    text: tabItem.description,
  }));
  const tabsCarousel = {
    type: blok.type ?? 'pin',
    defaultIndex: 0,
    title: blok.title,
    text: blok.description,
    tabs: tabs,
  };
  return (
    <Box {...storyblokEditable(blok)}>
      <TabsCarousel tabsCarousel={tabsCarousel} />
    </Box>
  );
}
