import {
  StyleFlexStoryblok,
  StyleGridStoryblok,
  StyleMarginsStoryblok,
  StylePaddingsStoryblok,
  StyleSizesStoryblok,
  StyleStoryblok,
  StylesTextStoryblok,
} from '@components/storyblok/types';

export function extractDimensionsFromURL(url: string): { width: number; height: number } | null {
  const regex = /(\d+)x(\d+)/;
  const match = url?.match(regex);

  if (match) {
    return { width: parseInt(match[1]), height: parseInt(match[2]) };
  } else {
    return null;
  }
}

export function forceExtractDimensionsFormURL(
  url: string,
  fallback: { width: number; height: number } = { width: 1200, height: 800 },
): { width: number; height: number } {
  let width = fallback?.width;
  let height = fallback?.height;

  if (!url) return fallback;

  try {
    const dimensions = extractDimensionsFromURL(url);
    width = dimensions?.width;
    height = dimensions?.height;
  } catch (e) {
    console.error('^^^ RichText NODE_IMAGE', e);
  }

  return { width, height };
}

export function returnPathWithStartingSlash(path: string): string {
  return path.startsWith('/') ? path : `/${path}`;
}

/**
 * If a component can have the style blok, it fetches it and returns the style props
 * @deprecated use selectBlokStyles instead
 */
export function selectStoryblokStyleBlok<T extends { style?: StyleStoryblok[] }>(blok: T) {
  const blokStyle: StyleStoryblok = blok?.style?.find(
    (eachStyle) => eachStyle?.component?.toLowerCase() === 'style',
  );

  return {
    marginTop: blokStyle?.marginTop,
    marginBottom: blokStyle?.marginBottom,
    marginRight: blokStyle?.marginRight,
    marginLeft: blokStyle?.marginLeft,
    textAlign: blokStyle?.textAlign,
    alignItems: blokStyle?.alignItems,
    justifyContent: blokStyle?.justifyContent,
  };
}

export function selectBlokStyles<
  T extends {
    style?: (
      | StyleMarginsStoryblok
      | StyleFlexStoryblok
      | StyleSizesStoryblok
      | StylesTextStoryblok
      | StyleGridStoryblok
      | StylePaddingsStoryblok
    )[];
  },
>(blok: T) {
  const marginComponent = blok.style?.find(
    (eachStyle) => eachStyle.component === 'styleMargins',
  ) as StyleMarginsStoryblok;

  const marginStyles = marginComponent
    ? {
        marginTop: selectResponsiveStyle(marginComponent.marginTop),
        marginBottom: selectResponsiveStyle(marginComponent.marginBottom),
        marginLeft: selectResponsiveStyle(marginComponent.marginLeft),
        marginRight: selectResponsiveStyle(marginComponent.marginRight),
      }
    : {};

  const paddingComponent = blok.style?.find(
    (eachStyle) => eachStyle.component === 'stylePaddings',
  ) as StylePaddingsStoryblok;

  const paddingStyles = paddingComponent
    ? {
        paddingTop: selectResponsiveStyle(paddingComponent.paddingTop),
        paddingBottom: selectResponsiveStyle(paddingComponent.paddingBottom),
        paddingLeft: selectResponsiveStyle(paddingComponent.paddingLeft),
        paddingRight: selectResponsiveStyle(paddingComponent.paddingRight),
      }
    : {};

  const sizesStyleComponent = blok.style?.find(
    (eachStyle) => eachStyle.component === 'styleSizes',
  ) as StyleSizesStoryblok;

  const sizesStyle = sizesStyleComponent
    ? {
        height: selectResponsiveStyle(sizesStyleComponent.height),
        maxHeight: selectResponsiveStyle(sizesStyleComponent.maxHeight),
        minHeight: selectResponsiveStyle(sizesStyleComponent.minHeight),
        width: selectResponsiveStyle(sizesStyleComponent.width),
        maxWidth: selectResponsiveStyle(sizesStyleComponent.maxWidth),
        minWidth: selectResponsiveStyle(sizesStyleComponent.minWidth),
      }
    : {};

  const textStyleComponent = blok.style?.find(
    (eachStyle) => eachStyle.component === 'stylesText',
  ) as StylesTextStoryblok;

  const textStyles = textStyleComponent
    ? {
        textAlign: selectResponsiveStyle(textStyleComponent.textAlign),
      }
    : {};

  const flexComponent = blok.style?.find(
    (eachStyle) => eachStyle.component === 'styleFlex',
  ) as StyleFlexStoryblok;

  const flexStyles = flexComponent
    ? {
        gap: selectResponsiveStyle(flexComponent.gap),
        alignItems: selectResponsiveStyle(flexComponent.alignItems),
        justifyContent: selectResponsiveStyle(flexComponent.justifyContent),
        direction: selectResponsiveStyle(flexComponent.direction),
      }
    : {};

  const gridComponent = blok.style?.find(
    (eachStyle) => eachStyle.component === 'styleGrid',
  ) as StyleGridStoryblok;

  const gridStyles = gridComponent
    ? {
        gap: selectResponsiveStyle(gridComponent.gap),
        alignItems: selectResponsiveStyle(gridComponent.alignItems),
        columns: selectResponsiveStyle(gridComponent.columns),
      }
    : {};

  return {
    marginStyles,
    sizesStyle,
    flexStyles,
    textStyles,
    gridStyles,
    paddingStyles,
  };
}

export function selectResponsiveStyle(value?: string): string | string[] {
  if (!value) {
    return undefined;
  }
  return value?.includes(',') ? value.split(',') : value;
}
