import { Container, Box, Heading, Text } from '@terminal/design-system';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { FaqWithoutSidebarStoryblok, Storyblok } from '../types';
import { selectBlokStyles, selectResponsiveStyle } from '@helpers/storyblok.utils';
import { storyblokEditable } from '@storyblok/react';

export function FaqWithoutSidebar({ blok }: Storyblok<FaqWithoutSidebarStoryblok>) {
  const { marginStyles } = selectBlokStyles(blok);

  return (
    <Box
      py={{ base: '12', lg: '24' }}
      {...marginStyles}
      maxW={{ lg: '61.125rem' }}
      mx="auto"
      id="faqs"
      as="section"
      {...storyblokEditable(blok)}
    >
      <Container>
        {blok.title && (
          <Heading
            as="h2"
            variant="heading-2"
            // @ts-ignore
            textAlign={selectResponsiveStyle(blok.titleAlignment) || { base: 'center', lg: 'left' }}
            mb={{ base: '4', lg: '6' }}
          >
            {blok.title}
          </Heading>
        )}
        <Accordion allowToggle>
          {blok.QAs.map((item) => (
            <AccordionItem
              key={item._uid}
              borderBottom="0.0625rem solid #ABB6BA"
              borderTop="none"
              id={`${item._uid}`}
              sx={{
                ':last-of-type': {
                  border: 'none',
                },
              }}
            >
              <Box>
                <AccordionButton
                  px={0}
                  py={4}
                  _expanded={{ bg: 'transparent' }}
                  _hover={{
                    bgColor: 'transparent',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                >
                  <Text
                    flex="1"
                    textAlign="left"
                    fontWeight="semibold"
                    fontSize={{ base: '1rem', lg: '1.25rem' }}
                    lineHeight={{ base: '1.5rem', lg: '2.125rem' }}
                    pr={{ base: '2.75rem' }}
                  >
                    {item.question}
                  </Text>
                  <AccordionIcon color="grey.900" fontSize="2rem" />
                </AccordionButton>
              </Box>
              <Box bgColor="transparent">
                <AccordionPanel
                  ml="0"
                  pl={{ base: '0' }}
                  pr={{ base: '2rem' }}
                  pb="2rem"
                  fontSize="md"
                >
                  <Text>{item.answer}</Text>
                </AccordionPanel>
              </Box>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Box>
  );
}
