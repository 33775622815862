import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { Container, SimpleGrid } from '@terminal/design-system';
import type { GridStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';

export function Grid({ blok, page }: Storyblok<GridStoryblok>) {
  const { marginStyles, gridStyles, textStyles } = selectBlokStyles(blok);

  return (
    <Container {...marginStyles}>
      <SimpleGrid
        // @ts-ignore
        columns={gridStyles.columns || [1, 2, 3, 4]}
        alignItems={gridStyles.alignItems || 'stretch'}
        // @ts-ignore
        textAlign={textStyles.textAlign || 'left'}
        justifyItems="center"
        spacing={gridStyles.gap || 8}
        {...storyblokEditable(blok)}
      >
        {blok.columns?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
        ))}
      </SimpleGrid>
    </Container>
  );
}
