const OnboardingYearsOfExperience: {
  title: string;
  value: string;
}[] = [
  {
    title: '0 to 2 years',
    value: 'ZERO_TWO',
  },
  {
    title: '2 to 5 years',
    value: 'TWO_FIVE',
  },
  {
    title: '5 to 10 years',
    value: 'FIVE_TEN',
  },
  {
    title: 'more than 10 years',
    value: 'TEN_PLUS',
  },
];

export default OnboardingYearsOfExperience;
