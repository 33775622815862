import { Box, Heading, Container, Grid } from '@terminal/design-system';
import { CandidateCard } from '@components/UI/CandidateCard';

import type { CandidateCardsSectionStoryblok, Storyblok } from '../types';
import { storyblokEditable } from '@storyblok/react';
import { selectBlokStyles } from '@helpers/storyblok.utils';

export function CandidateCardsSection({ blok }: Storyblok<CandidateCardsSectionStoryblok>) {
  const { marginStyles, paddingStyles } = selectBlokStyles(blok);

  const heroTitle = (blok.title || 'Instant access to top **developers** for hire')
    .replace('**', '<span style="color: #7AA550">')
    .replace('**', '</span>');

  const candidates =
    blok.candidates?.map((candidate) => {
      // TODO: improve this implementation
      const skills = [];
      const highlights = [];
      candidate.highlight1 && highlights.push(candidate.highlight1);
      candidate.highlight2 && highlights.push(candidate.highlight2);
      candidate.highlight3 && highlights.push(candidate.highlight3);
      candidate.highlight4 && highlights.push(candidate.highlight4);

      candidate.skill1 && skills.push(candidate.skill1);
      candidate.skill2 && skills.push(candidate.skill2);
      candidate.skill3 && skills.push(candidate.skill3);
      return {
        name: candidate.name,
        role: candidate.role,
        experience: candidate.experience,
        location: candidate.location,
        badges: candidate.badges,
        highlights,
        skills,
      };
    }) || [];

  return (
    <Box
      paddingTop={paddingStyles.paddingTop || { base: 12, lg: 20 }}
      paddingBottom={paddingStyles.paddingBottom || { base: 12, lg: 20 }}
      paddingLeft={paddingStyles.paddingLeft || 0}
      paddingRight={paddingStyles.paddingRight || 0}
      {...marginStyles}
      as="section"
      {...storyblokEditable(blok)}
    >
      <Container>
        <Heading
          as="h2"
          fontSize={{ base: '4xl', md: '2.625rem' }}
          textAlign="center"
          mb={12}
          dangerouslySetInnerHTML={{
            __html: heroTitle,
          }}
        />
        <Grid
          templateColumns={{
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={{ base: 4, lg: 6 }}
        >
          {candidates.map((candidate, index) => (
            <CandidateCard key={`${candidate.name}-${index}`} candidate={candidate} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
