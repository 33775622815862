import {
  Link,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Heading,
  Text,
  Button,
} from '@terminal/design-system';

import type { DeveloperStoryCardStoryblok, Storyblok } from '../types';
import Image from 'next/image';
import { storyblokEditable } from '@storyblok/react';
import { forceExtractDimensionsFormURL } from '@helpers/storyblok.utils';
import { radii } from '@terminal/global/design-system/theme/design-tokens';

export function DeveloperStoryCard({ blok }: Storyblok<DeveloperStoryCardStoryblok>) {
  switch (blok.cardType) {
    case undefined: // This is the default for cards that might not have been updated in the UI
    case 'quote':
      return <DeveloperStoryQuoteCard blok={blok} />;
    case 'image':
      return <DeveloperStoryCardImage blok={blok} />;
    default:
      return null;
  }
}

function DeveloperStoryQuoteCard({ blok }: { blok: DeveloperStoryCardStoryblok }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = (e) => {
    e.preventDefault();
    onOpen();
  };
  return (
    <Box as="article" p="6" bgColor="bg.primary" borderRadius="0.5rem" {...storyblokEditable(blok)}>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        minH="18.5rem"
      >
        <Box
          py={1}
          px={2}
          fontSize="sm"
          lineHeight="shorter"
          mb="5"
          bgColor="blue.100"
          color="blue.800"
          borderRadius="full"
          fontWeight="medium"
        >
          Developer Story
        </Box>
        <Heading as="blockquote" mb="5" fontSize="2xl" lineHeight="shorter" fontWeight="medium">
          {blok.title} ;
        </Heading>
        <Flex alignItems="center">
          <Box overflow="hidden" borderRadius="full" flexShrink={0}>
            <Image
              src={blok?.profilePicture?.filename}
              alt={blok?.profileTitle}
              width={56}
              height={56}
            />
          </Box>
          <Box ml={4}>
            <Heading as="cite" variant="heading-4" fontStyle="normal">
              {blok?.profileTitle}
            </Heading>
            <Text fontSize="sm" lineHeight="shorter" fontWeight="medium">
              {blok?.profileInfo}
            </Text>
          </Box>
        </Flex>
        <Button
          variant="ghost"
          colorScheme="primary"
          mt={4}
          onClick={handleClick}
          leftIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Play video icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10.8 15.9L15.47 12.4C15.74 12.2 15.74 11.8 15.47 11.6L10.8 8.1C10.47 7.85 10 8.09 10 8.5V15.5C10 15.91 10.47 16.15 10.8 15.9ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
                fill="#7AA550"
              />
            </svg>
          }
        >
          Watch Video
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent bgColor="grey.100" borderRadius="0">
          <ModalCloseButton />
          <ModalBody p={{ base: '10', md: '12' }}>
            <AspectRatio maxW="100%" ratio={16 / 9}>
              <iframe src={`https://www.youtube.com/embed/${blok.youtubeVideoID}`} title="video" />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

function DeveloperStoryCardImage({ blok }: { blok: DeveloperStoryCardStoryblok }) {
  const imageDimensions = forceExtractDimensionsFormURL(blok.profilePicture.filename, {
    width: 1200,
    height: 800,
  });

  return (
    <Box overflow="hidden" {...storyblokEditable(blok)} minH="18.5rem">
      <Box zIndex={'hide'} position={'relative'} width={'100%'} height={'100%'}>
        <Image
          src={blok.profilePicture.filename}
          width={imageDimensions.width}
          height={imageDimensions.height}
          alt={blok.profilePicture.alt}
          style={{ borderRadius: radii['lg'], objectFit: 'cover' }}
        />
      </Box>
      <Box position={'absolute'} width={'100%'} bottom={0} left={0} p={6}>
        <Heading as="h3" variant="heading-3" color={'text.inverse'}>
          {blok.profileTitle}
        </Heading>
        <Text color={'text.inverse'}>{blok.profileInfo}</Text>
        <Flex w="100%" alignItems="center" justifyContent="space-between" mt="2">
          <Link
            href={blok?.linkToStory.url ?? 'https://www.terminal.io/engineers/developer-stories'}
            display="flex"
            alignItems="center"
            color="white"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10.8 15.9L15.47 12.4C15.74 12.2 15.74 11.8 15.47 11.6L10.8 8.1C10.47 7.85 10 8.09 10 8.5V15.5C10 15.91 10.47 16.15 10.8 15.9ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
                fill="white"
              />
            </svg>

            <Box ml="2" fontWeight="bold">
              {'Their Story'}
            </Box>
          </Link>
          <Box w="1.5rem" h="1.5rem" position="relative">
            <Image
              src={blok.countryPicture.filename}
              alt={blok.countryPicture.name}
              style={{ objectFit: 'contain' }}
              fill
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
