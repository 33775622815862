import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Storyblok, RichTextWithCopyStoryblok } from '../types';
import { Box, Button, Flex, Heading } from '@terminal/design-system';
import Image from 'next/image';
import { MouseEventHandler } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { render } from 'storyblok-rich-text-react-renderer';
import { selectBlokStyles } from '@helpers/storyblok.utils';

export function RichTextWithCopy({ blok, page }: Storyblok<RichTextWithCopyStoryblok>) {
  const { title } = blok;
  const { marginStyles, sizesStyle } = selectBlokStyles(blok);
  const editor = blok.editor[0].content;

  const html_content_to_copy = renderToStaticMarkup(render(editor));

  const handleCopy: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    void navigator.clipboard.write([
      new ClipboardItem({
        'text/html': Promise.resolve(new Blob([html_content_to_copy], { type: 'text/html' })),
        'text/plain': Promise.resolve(new Blob([html_content_to_copy], { type: 'text/plain' })),
      }),
    ]);
  };

  return (
    <Flex
      flexDirection={'column'}
      backgroundColor={'bg.primary'}
      borderRadius={'base'}
      borderColor={'ui.secondary !important'}
      border={'1px solid'}
      {...marginStyles}
      {...sizesStyle}
      {...storyblokEditable(blok)}
    >
      <Flex
        justifyContent={'space-between'}
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        backgroundColor={'bg.tertiary'}
        borderBottom={'1px solid'}
        borderColor={'ui.secondary'}
        padding={4}
      >
        <Heading as="h2" variant="heading-2">
          {title}
        </Heading>
        <Button
          variant={'ghost'}
          leftIcon={<Image src="/images/ui/copy.svg" alt="copy" width={20} height={20} />}
          onClick={handleCopy}
          paddingLeft={[0, 0, 2]} // left align with the title text
        >
          Copy
        </Button>
      </Flex>
      {blok.editor.map((nestedBlok) => (
        <Box padding={4}>
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
        </Box>
      ))}
    </Flex>
  );
}
