import Image from 'next/image';
import * as CSS from 'csstype';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Box, Flex, Heading, Text } from '@terminal/design-system';
import {
  forceExtractDimensionsFormURL,
  selectBlokStyles,
  selectResponsiveStyle,
} from '@helpers/storyblok.utils';
import type { FeatureDescriptionCardStoryblok, Storyblok } from '../types';

export function FeatureDescriptionCard(props: Storyblok<FeatureDescriptionCardStoryblok>) {
  const { blok, page } = props;

  const imageDimensions = forceExtractDimensionsFormURL(blok.image?.filename, {
    width: 240,
    height: 200,
  });

  const gap = selectResponsiveStyle(blok.gap || '0');
  const { sizesStyle } = selectBlokStyles(blok);

  imageDimensions.width =
    (blok.imageWidth && !Number.isNaN(Number(blok?.imageWidth))
      ? Number(blok?.imageWidth)
      : imageDimensions.width) ?? imageDimensions.width;

  imageDimensions.height =
    (blok.imageHeight && !Number.isNaN(Number(blok?.imageHeight))
      ? Number(blok?.imageHeight)
      : imageDimensions.height) ?? imageDimensions.height;

  return (
    <Flex
      flexDir={selectResponsiveStyle(blok.cardDirection || 'column') as CSS.Property.FlexDirection}
      alignItems={blok.imageAlignment || 'center'}
      flex="1"
      {...storyblokEditable(blok)}
      gap={gap}
      {...sizesStyle}
    >
      <Image
        src={blok.image?.filename}
        alt={blok.image.alt as string}
        width={imageDimensions.width}
        height={imageDimensions.height}
      />

      <Flex
        flexDir="column"
        mx={6}
        textAlign={blok.textAlignment || 'left'}
        justifyContent="space-between"
      >
        <Heading as="h4" variant="heading-2" mt={4}>
          {blok.title}
        </Heading>
        <Text variant="body" mt={2}>
          {blok.description}
        </Text>
        <Flex my={5} justifyContent={blok.buttonsAlignment || 'left'}>
          {(blok.cta || [])?.map((eachCTA) => (
            <StoryblokComponent blok={eachCTA} key={eachCTA._uid} page={page} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
