import { Box } from '@chakra-ui/react';

export default function ChevronRight(props) {
  return (
    <Box {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.29289 5.79289C8.68342 5.40237 9.31658 5.40237 9.70711 5.79289L15.9142 12L9.70711 18.2071C9.31658 18.5976 8.68342 18.5976 8.29289 18.2071C7.90237 17.8166 7.90237 17.1834 8.29289 16.7929L13.0858 12L8.29289 7.20711C7.90237 6.81658 7.90237 6.18342 8.29289 5.79289Z"
          fill="#25383F"
        />
      </svg>
    </Box>
  );
}
