import { Box } from '@chakra-ui/layout';

export default function Image404() {
  return (
    <Box w="100%" maxW="25rem">
      <svg viewBox="0 0 440 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M357.233 222.836L366.235 296.811L310.063 295.305C304.662 295.949 298.711 283.537 296.781 267.569C294.85 251.601 297.651 238.15 303.052 237.506L357.233 222.836Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M361.614 219.745C354.843 220.557 351.492 238.912 354.142 260.744C356.793 282.576 364.435 299.624 371.216 298.821L381.728 297.563L372.136 218.497L361.614 219.745Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M88.6381 0.950928H373.696C376.15 0.951812 378.567 1.54883 380.734 2.68964C382.9 3.83046 384.752 5.48024 386.125 7.49438C387.498 9.50852 388.352 11.8255 388.61 14.2423C388.869 16.6591 388.525 19.1018 387.609 21.3564L311.724 207.829C310.605 210.569 308.687 212.917 306.214 214.573C303.741 216.228 300.826 217.115 297.841 217.12H19.8441C17.4376 217.113 15.0681 216.533 12.9355 215.429C10.8029 214.325 8.96983 212.729 7.59093 210.776C6.21203 208.822 5.32778 206.569 5.01281 204.207C4.69783 201.844 4.96137 199.441 5.78118 197.2L74.5753 10.7277C75.6262 7.86794 77.5385 5.39673 80.0536 3.64819C82.5686 1.89965 85.5652 0.958133 88.6381 0.950928Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M84.7773 0.950928H362.004C364.455 0.956664 366.868 1.55723 369.031 2.70008C371.194 3.84293 373.041 5.49325 374.411 7.50667C375.781 9.52009 376.631 11.8353 376.889 14.2497C377.146 16.6641 376.802 19.1041 375.887 21.3564L299.991 207.829C298.873 210.569 296.955 212.917 294.482 214.573C292.009 216.228 289.093 217.115 286.109 217.12H16.0033C13.5951 217.117 11.2232 216.539 9.08803 215.436C6.95284 214.333 5.11709 212.737 3.7359 210.784C2.35472 208.83 1.46869 206.576 1.15269 204.212C0.836693 201.847 1.10001 199.442 1.92039 197.2L70.7245 10.7277C71.7748 7.86953 73.6856 5.39942 76.1987 3.65102C78.7118 1.90262 81.7061 0.960159 84.7773 0.950928Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M16.0033 217.12H281.147C285.601 217.114 289.951 215.791 293.64 213.32C297.328 210.849 300.188 207.343 301.852 203.252L316.175 168.038H12.6726L5.53113 187.433C0.210044 201.836 0.470097 217.12 16.0033 217.12Z"
          fill="#25383F"
        />
        <path
          d="M259.723 285.835H168.965L140.369 217.12H231.127L259.723 285.835Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M122.405 299.01H255.582C259.493 299.01 262.664 295.87 262.664 291.996C262.664 288.123 259.493 284.983 255.582 284.983H122.405C118.494 284.983 115.324 288.123 115.324 291.996C115.324 295.87 118.494 299.01 122.405 299.01Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M227.046 145.622H122.485C120.92 145.62 119.378 145.246 117.988 144.533C116.599 143.819 115.402 142.786 114.499 141.52C113.596 140.254 113.012 138.792 112.797 137.256C112.582 135.721 112.742 134.157 113.263 132.695L147.49 37.8689C148.171 35.9866 149.422 34.3586 151.073 33.2078C152.724 32.057 154.693 31.4396 156.712 31.4402H261.283C262.852 31.4403 264.398 31.8137 265.791 32.529C267.184 33.2444 268.382 34.2806 269.286 35.5506C270.19 36.8205 270.773 38.2869 270.985 39.8264C271.197 41.3659 271.033 42.9333 270.505 44.3967L236.278 139.233C235.591 141.109 234.335 142.73 232.683 143.873C231.03 145.017 229.062 145.627 227.046 145.622Z"
          fill="#25383F"
        />
        <path
          d="M221.275 138.747H116.704C115.131 138.749 113.582 138.376 112.186 137.659C110.79 136.942 109.589 135.902 108.684 134.628C107.78 133.355 107.198 131.884 106.989 130.34C106.78 128.797 106.949 127.226 107.482 125.761L141.699 30.9352C142.381 29.0511 143.634 27.4214 145.286 26.269C146.938 25.1165 148.91 24.4976 150.931 24.4966H255.492C257.063 24.4945 258.612 24.8668 260.007 25.582C261.402 26.2972 262.603 27.3343 263.508 28.6058C264.413 29.8772 264.996 31.3457 265.208 32.8873C265.42 34.4289 265.254 35.9983 264.724 37.463L230.497 132.289C229.822 134.177 228.572 135.811 226.921 136.968C225.27 138.124 223.298 138.746 221.275 138.747Z"
          fill="#FBDF8F"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M202.351 109.05L164.174 47.7349"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M226.086 47.7349L140.439 109.05"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M381.73 297.582C388.509 296.775 391.857 278.421 389.208 256.585C386.56 234.75 378.917 217.703 372.138 218.509C365.359 219.316 362.011 237.671 364.66 259.506C367.308 281.341 374.951 298.388 381.73 297.582Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M381.139 292.793C385.817 292.237 387.72 276.211 385.39 257C383.06 237.788 377.378 222.665 372.7 223.221C368.022 223.778 366.118 239.803 368.448 259.015C370.779 278.227 376.46 293.35 381.139 292.793Z"
          fill="#25383F"
        />
        <path
          d="M370.646 265.608C370.646 265.608 383.108 259.278 387.599 270.62C387.599 270.62 398.131 268.708 399.032 276.474C399.032 276.474 405.463 276.474 406.623 281.318C406.623 281.318 424.487 279.337 424.747 296.83C424.747 296.83 399.022 302.159 379.668 291.233C374.475 283.607 371.367 274.778 370.646 265.608Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M415.364 288.827L413.044 291.125"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M380.358 273.552L381.868 275.038"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path d="M399.062 280.991V283.289" stroke="#25383F" strokeWidth="2" strokeMiterlimit="10" />
        <path
          d="M386.719 283.289L388.599 285.152"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M394.211 275.048C394.211 275.048 402.052 252.265 439.09 242.478"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M411.184 256.099C410.994 255.613 426.737 249.224 438.44 261.963C438.44 261.963 418.086 274.057 411.184 256.099Z"
          fill="#BDD796"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M404.623 259.843C404.623 259.843 419.926 244.895 404.623 226.689C404.623 226.689 383.799 245.113 404.623 259.843Z"
          fill="#BDD796"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M98.3501 188.711H60.5624L53.2909 229.789H95.4395L98.3501 188.711Z"
          fill="#A0CFDA"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M50.3003 245.618L75.8155 264.162C75.8155 264.162 54.7012 291.174 34.8071 287.598L13.9328 262.319C13.9328 262.319 35.9374 261.745 50.3003 245.618Z"
          fill="#FBDF8F"
          stroke="#25383F"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
}
