import { storyblokEditable } from '@storyblok/react';
import { Box, Container, Heading } from '@terminal/design-system';
import TestimonialsCarousel from '@components/DemandLP/TestimonialsCarousel';
import { Storyblok, TestimonialsSectionStoryblok, VideoTestimonyStoryblok } from '../types';

const testimonials = [
  {
    image: {
      sourceUrl: '/images/testimonials/andrew-backes.jpg',
      altText: 'Andrew Backes',
    },
    text: '“With Terminal, we have recruiting and on-the-ground expertise in the markets where we want to hire. We needed a group of people who were experts in the laws in these markets, who could set up payment structures, who would provide an office where engineers could work, and who could handle all the other details for us. Having all that bundled together, that was game-changing.”',
    author: {
      name: 'Andrew Backes',
      jobTitle: 'Head of Engineering, Armory',
    },
  },
  {
    image: {
      sourceUrl: '/images/testimonials/melissa-baird.jpg',
      altText: 'Melissa Baird',
    },
    text: '“To bring Hims to new heights, we know we are going to need more happy, invested team members – and we’ll continue to look to Terminal to find and support them.”',
    author: {
      name: 'Melissa Baird',
      jobTitle: 'COO & Head of Technology, Hims',
    },
  },
  {
    image: {
      sourceUrl: '/images/testimonials/russ-greenspan.jpg',
      altText: 'Russ Greenspan',
    },
    text: '“Turning to Latin America has significantly expanded the candidate pool for us. There’s so much good talent. I studied in Latin America myself, so I know firsthand the kind of creative thinking and quality engineers that you’ll find there.”',
    author: {
      name: 'Russ Greenspan',
      jobTitle: 'CTO, PresenceLearning',
    },
  },
];

export function TestimonialsSection({ blok }: Storyblok<TestimonialsSectionStoryblok>) {
  const testimonialsSerialized =
    blok.testimonials?.map((testimony) => {
      if (testimony.youtubeID) return testimony as VideoTestimonyStoryblok;
      return {
        image: {
          sourceUrl: testimony.image?.filename,
          altText: testimony.author,
        },
        text: testimony.text,
        author: {
          name: testimony.authorName,
          jobTitle: testimony.authorJobTitle,
        },
      };
    }) || [];
  return (
    <Box py={{ base: 12, lg: 20 }} bgColor="grey.900" as="section" {...storyblokEditable(blok)}>
      <Container>
        <Heading
          as="h2"
          color="text.inverse"
          fontSize={{ base: '4xl', lg: '2.625rem' }}
          lineHeight="shorter"
          textAlign="center"
          mb={{ base: 7, lg: 12 }}
        >
          {blok.title}
        </Heading>
        <TestimonialsCarousel slider={[...testimonialsSerialized, ...testimonials]} />
      </Container>
    </Box>
  );
}
