import { extendTheme } from '@chakra-ui/react';
import { designTokens } from './design-tokens';

// npm run gen:theme-typings fails when importing from ../components
import { AccordionTheme } from '../components/Accordion/Accordion.theme';
import { alertsTheme } from '../components/alerts/alerts.theme';
import { buttonTheme } from '../components/Button/Button.theme';
import { formLabelTheme } from '../components/form/FormLabel';
import { formErrorTheme } from '../components/form/FormError';
import { checkboxTheme } from '../components/form/Checkbox';
import { selectTheme } from '../components/form/Select';
import { headingTheme } from '../components/Heading/Heading.theme';
import { inputTheme } from '../components/form/Input/Input.theme';
import { linkTheme } from '../components/Link';
import { textTheme } from '../components/Text/Text.theme';
import { menuTheme } from '../components/Menu/Menu.theme';
import { drawerTheme } from '../components/Drawer/Drawer.theme';
import { tabsTheme } from '../components/Tabs/Tabs.theme';
import { tagTheme } from '../components/Tag/Tag.theme';
import { popoverTheme } from '../components/Popover';
import { containerTheme } from '../components/layout';
import { dividerTheme } from '../components/Divider/Divider.theme';
import { modalTheme } from '../components/Modal';
import { listTheme } from '../components/List';
import { stepTheme } from '../components/Steps';
import { textareaTheme } from '../components/Textarea';
import { switchTheme } from '../components/Switch';
import { tooltipTheme } from '../components/Tooltip';
import { avatarTheme } from '../components/Avatar/Avatar.theme';
import { formControlTheme } from '../components/form/FormControl/FormControl.theme';
import { tableTheme } from '../components/Table';
import { progressTheme } from '../components/Progress';
import { badgeTheme } from '../components/Badge';

// NOTE: Make sure `npm run gen:theme-typings` can run after you add a component theme

export const components = {
  Accordion: AccordionTheme,
  Alert: alertsTheme,
  Badge: badgeTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  Container: containerTheme,
  Divider: dividerTheme,
  Drawer: drawerTheme,
  FormError: formErrorTheme,
  FormLabel: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Link: linkTheme,
  Menu: menuTheme,
  Popover: popoverTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Text: textTheme,
  Select: selectTheme,
  Modal: modalTheme,
  List: listTheme,
  Step: stepTheme,
  Textarea: textareaTheme,
  Switch: switchTheme,
  Tooltip: tooltipTheme,
  Avatar: avatarTheme,
  Form: formControlTheme,
  Table: tableTheme,
  Progress: progressTheme,
};

export const styles = {
  global: {
    '[data-consent-manager-dialog]': {
      zIndex: 'tooltip',
    },
    // TODO: Remove non default-theme styles - (Cannot be removed now, because they are required for the global-auth stories).
    html: {
      h: 'fill-available',
      'scroll-behavior': 'smooth',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      minH: '100vh',
      minHeight: 'fill-available',
    },
    '#root': {
      flex: 1,
      display: 'flex',
    },
  },
};

const { colors, ...designTokensRest } = designTokens;

const themeExtendedOver_chakraUITheme = extendTheme({
  ...designTokensRest,
  colors,
  components,
});

export const theme = {
  ...themeExtendedOver_chakraUITheme,
  styles,
  components: {
    ...themeExtendedOver_chakraUITheme.components,
    Tabs: tabsTheme,
  },
};
