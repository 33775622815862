import { Icon } from '@chakra-ui/react';
export default function Facebook({ color, ...props }: { color?: string; [x: string]: any }) {
  return (
    <Icon
      w="6"
      h="6"
      viewBox="0 0 24 24"
      transition="color 0.3s ease-in-out"
      color={color}
      {...props}
    >
      <path
        d="M11 7.00028C11 4.66012 13.0001 2.81975 15.3322 3.01409L17 3.15308V6.15328H15C14.4477 6.15328 14 6.601 14 7.15328V10.1531H17L16.5 13.1531H14V21.1533H11V13.1531H8V10.1531H11V7.00028Z"
        fill="currentColor"
      />
    </Icon>
  );
}
