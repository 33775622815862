import OnboardingRolesForm from '@components/UI/OnboardingRolesForm';

import onboardingRoles from '@components/LightLP/OnboardingRolesArray';
import onboardingYears from '@components/LightLP/OnboardingYearsOfExperience';
import getConfig from 'next/config';
import { FormStoryblok, Storyblok } from '../types';

const { publicRuntimeConfig } = getConfig();

const signUpUrl = publicRuntimeConfig.signUpUrl;

const forms = {
  'engineering-intake': (
    <OnboardingRolesForm
      onboardingRoles={onboardingRoles}
      onboardingYears={onboardingYears}
      signUpUrl={signUpUrl}
    />
  ),
};
export function Form({ blok }: Storyblok<FormStoryblok>) {
  const form = forms[blok.form];
  return form ? form : null;
}
