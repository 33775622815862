import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export default function sitePath(
  path: string,
  lang?: string,
  { excludeParams }: { excludeParams: boolean } = { excludeParams: true },
) {
  const langString = lang ? `/${lang}` : '';
  const relativePath = excludeParams && path.split('?').length ? path.split('?')[0] : path;

  if (publicRuntimeConfig.siteEnv === 'dev') {
    return `https://dev.terminal.io${langString + relativePath}`;
  } else {
    return `https://www.terminal.io${langString + relativePath}`;
  }
}
