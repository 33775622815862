import { Box, BoxProps } from '@chakra-ui/react';

export function CheckIcon(props: BoxProps) {
  return (
    <Box {...props}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33337 8.93014L6.35164 14.0468L14.7154 3.81348"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}
