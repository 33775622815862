import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import getConfig from 'next/config';

import { Box, Link, NonNextLink } from '@terminal/design-system';
import { Button } from '@chakra-ui/react'; // TODO: import from design-system

import {
  ConsentManager,
  loadPreferences,
  onPreferencesSaved as onSegmentPreferencesSaved,
} from '@segment/consent-manager';
import inEU from '@segment/in-eu';

const { publicRuntimeConfig } = getConfig();

export async function requiresConsent() {
  return (await inEU()) || document.location.search.includes('requireConsent');
}

export const SegmentConsent = ({ onReadyToInitializeTracking }) => {
  const router = useRouter();

  useEffect(() => {
    onReadyToInitializeTracking(loadPreferences());

    // Allows for subscribing to changes in preferences.
    onSegmentPreferencesSaved((preferences) => {
      onReadyToInitializeTracking(preferences);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!publicRuntimeConfig.segmentWriteKey) {
    console.log('No segment key');
    return null;
  }

  return (
    <Box position="fixed" zIndex="999" w="100%" bottom="0" bgColor="#517E94">
      <Box
        mx="auto"
        sx={{
          '@media (min-width:768px)': { maxW: '720px' },
          '@media (min-width:992px)': { maxW: '960px' },
          '@media (min-width:1200px)': { maxW: '1140px' },
        }}
      >
        <ConsentManager
          writeKey={publicRuntimeConfig.segmentWriteKey}
          otherWriteKeys={publicRuntimeConfig.segmentOtherWriteKeys}
          bannerContent={
            <Box pt="1rem" as="span" display="inline-block" fontSize="0.875rem" fontFamily="body">
              We use various cookies that analyze your interaction with this website. Learn more
              about our{' '}
              <Link href="https://www.terminal.io/privacy-policy" target="_blank">
                Privacy Policy.
              </Link>
            </Box>
          }
          bannerActionsBlock={({ acceptAll, changePreferences }) => {
            const handleAccept = () => {
              acceptAll();
              router.reload();
            };
            return (
              <Box
                py="1rem"
                mt="-1.5rem"
                sx={{
                  '@media (min-width:768px)': { mt: '0' },
                }}
                fontFamily="body"
              >
                <Button
                  onClick={handleAccept}
                  mr="1rem"
                  h="2rem"
                  px="2.5rem"
                  w="full"
                  fontWeight="bold"
                  fontSize="0.75rem"
                  bgColor="#96BA6E"
                  transition="all 0.3s ease-in-out"
                  _hover={{
                    bgColor: '#25383F',
                  }}
                >
                  Accept All
                </Button>
                <NonNextLink
                  as="button"
                  onClick={changePreferences}
                  mt="0.5rem"
                  fontSize="0.75rem"
                  textDecoration="underline"
                >
                  Manage Preferences
                </NonNextLink>
              </Box>
            );
          }}
          bannerBackgroundColor="transparent"
          bannerSubContent=""
          bannerHideCloseButton
          shouldRequireConsent={requiresConsent}
          defaultDestinationBehavior="imply"
          preferencesDialogTitle="Website Data Collection Preferences"
          preferencesDialogContent="We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site."
          cancelDialogTitle="Are you sure you want to cancel?"
          cancelDialogContent="Your preferences have not been saved. By continuing to use our website, you are agreeing to our Website Data Collection Policy."
        />
      </Box>
    </Box>
  );
};
