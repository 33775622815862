import { Box } from '@chakra-ui/react';

export default function FooterCTASvg({
  longText,
  ...props
}: {
  longText: boolean;
  [x: string]: any;
}) {
  return (
    <Box {...props}>
      <Box display={{ lg: 'none' }}>
        <svg viewBox="0 0 432 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 288 93)"
            fill="#5E9CB0"
            stroke="#5E9CB0"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 288 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 288 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 96 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 96 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 96 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 384 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 384 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 384 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 192 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 192 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 192 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 0 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 0 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 0 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 256 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 256 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 256 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 64 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 64 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 64 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 352 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 352 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 352 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 160 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 160 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 160 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 416 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 416 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 416 29)"
            fill="white"
            stroke="white"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 224 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 224 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 224 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 32 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 32 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 32 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 320 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 320 61)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 320 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 128 93)"
            stroke="#49646E"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 128 61)"
            fill="#F6AE2D"
            stroke="#F6AE2D"
            strokeWidth="3"
          />
          <rect
            x="1.5"
            y="-1.5"
            width="13"
            height="13"
            transform="matrix(1 0 0 -1 128 29)"
            stroke="#49646E"
            strokeWidth="3"
          />
        </svg>
      </Box>
      <Box display={{ base: 'none', lg: 'block' }}>
        {!longText && (
          <svg viewBox="0 0 528 304" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect
              x="97.5"
              y="129.5"
              width="13"
              height="13"
              fill="#F6AE2D"
              stroke="#F6AE2D"
              strokeWidth="3"
            />
            <rect x="97.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="353.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="385.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect
              x="385.5"
              y="257.5"
              width="13"
              height="13"
              fill="#5E9CB0"
              stroke="#5E9CB0"
              strokeWidth="3"
            />
            <rect x="385.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="417.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="449.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="481.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect
              x="514"
              y="2"
              width="12"
              height="12"
              fill="white"
              stroke="white"
              strokeWidth="4"
            />
            <rect x="513.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="513.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
          </svg>
        )}
        {longText && (
          <svg viewBox="0 0 336 304" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="1.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="33.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect
              x="65.5"
              y="129.5"
              width="13"
              height="13"
              fill="#F6AE2D"
              stroke="#F6AE2D"
              strokeWidth="3"
            />
            <rect x="65.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="65.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="97.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="129.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="161.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="193.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect
              x="193.5"
              y="257.5"
              width="13"
              height="13"
              fill="#5E9CB0"
              stroke="#5E9CB0"
              strokeWidth="3"
            />
            <rect x="193.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="225.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="257.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="1.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="289.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect
              x="322"
              y="2"
              width="12"
              height="12"
              fill="white"
              stroke="white"
              strokeWidth="4"
            />
            <rect x="321.5" y="33.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="65.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="97.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="129.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="161.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="193.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="225.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="257.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
            <rect x="321.5" y="289.5" width="13" height="13" stroke="#49646E" strokeWidth="3" />
          </svg>
        )}
      </Box>
    </Box>
  );
}
