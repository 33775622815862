import { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { omit } from 'ramda';
import { SentryLink } from 'apollo-link-sentry';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const isSentryEnabled = new Set(['dev', 'prod', 'production']).has(
  publicRuntimeConfig.sentryEnvironment,
);

let apolloClient;

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const wpLink = new HttpLink({
  uri: publicRuntimeConfig.wpGraphqlUrl,
  credentials: 'same-origin',
});

const hasuraLink = new HttpLink({
  uri: publicRuntimeConfig.hasuraEndpoint,
  headers: {
    'x-hasura-role': 'anonymous',
  },
});

const externalCandidateLink = new HttpLink({
  uri: publicRuntimeConfig.externalCandidateEndpoint,
});

const otherLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'externalCandidate',
  externalCandidateLink,
  wpLink,
);

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from(
      [
        isSentryEnabled &&
          new SentryLink({
            setFingerprint: true,
            setTransaction: true,
          }),
        onError(({ graphQLErrors, networkError, operation }) => {
          // Sets the query variables and context into sentry extra field
          const { variables, getContext } = operation;
          const context = getContext();

          Sentry.setExtra('variables', JSON.stringify(variables, null, 2));
          // So that we see mostly the context thats set programatically and headers
          Sentry.setExtra('context', omit(['getCacheKey', 'cache', 'clientAwareness'], context));

          if (graphQLErrors) {
            // eslint-disable-next-line no-restricted-syntax
            for (const graphQLError of graphQLErrors) {
              const { message, locations, path } = graphQLError;
              if (!message.includes('Could not verify JWT: JWTExpired')) {
                // eslint-disable-next-line no-console
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
              }
            }
          }
          // eslint-disable-next-line no-console
          if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
      ].filter(Boolean),
    ).split((operation) => operation.getContext().clientName === 'hasura', hasuraLink, otherLinks),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
        // @ts-ignore, TODO: fix apollo types
        nextFetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient: ReturnType<typeof createApolloClient> = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
