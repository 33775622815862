import { useEffect, useState } from 'react';
import { getStoryblokApi } from '@storyblok/react';
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  Flex,
  List,
  ListItem,
  ListIcon,
  useBreakpointValue,
} from '@terminal/design-system';
import getConfig from 'next/config';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { CandidateCard } from '@components/UI/CandidateCard';
import blobPattern from '@public/images/hiring-developers/blob-pattern.svg';
import hiringMarkets from '@public/images/hiring-developers/hiring-markets.svg';
import { PrimaryButton } from '@components/UI/Button';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import ImageShimmer from '@components/ImageShimmer/ImageShimmer';
import { CheckIcon } from '@components/Icons/Check';
import SquarePattern from '@components/UI/SquarePattern';
import { fiveCompaniesLogos } from '@helpers/constants';
import { SkillsList } from '../staticComponents';
import { GlobalComponent } from '../contentPages';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;

const offeringsCards = [
  {
    image: {
      url: '/images/hiring-developers/vetted-candidates.png',
      altText: 'Women looking for vetted developers',
      width: '560px',
      height: '400px',
      responsiveWidth: '460px',
      responsiveHeight: '300px',
      smallWidth: '277px',
      marginLeft: '-5rem',
    },
    title: 'Vetted developers seeking new positions',
    description:
      'Our curated pool of software engineers are screened by talent experts for their skills, experience, salary and English proficiency.',
    items: [
      '1 on 1 screening with talent expert',
      'Elite talent: only the top 7% pass screening',
      'Video clips of each candidate for review',
    ],
    square: {
      color: '#FFF7E2',
      top: '2rem',
    },
    marginBottom: 16,
  },
  {
    image: {
      url: '/images/hiring-developers/match.png',
      altText: 'Man looking for developers with different roles',
      width: '348px',
      height: '350px',
      responsiveWidth: '248px',
      responsiveHeight: '250px',
      smallWidth: '200px',
      marginLeft: '-1rem',
    },
    title: 'Match with developers perfect for your roles',
    description:
      'Hire 40% faster as our talent experts and smart technology surface the right software engineers for your roles.',
    items: [
      'Aligned to your time zone and team culture',
      '4 in 5 candidate matches are accepted to interview',
    ],
    square: {
      color: '#E7F5D6',
      top: '0.5rem',
    },
    marginBottom: '7.4rem',
  },
  {
    image: {
      url: '/images/hiring-developers/machine.png',
      altText: 'Women reading a paper with a machine behind her',
      width: '518px',
      height: '330px',
      responsiveWidth: '388px',
      responsiveHeight: '230px',
      smallWidth: '310px',
      marginLeft: '-3.5rem',
    },
    title: 'Hire & employ talent with flexible options',
    description:
      'Hire full-time employees or onboard contract talent with the option to contract-to-hire. Flexibility that works for you.',
    items: [
      'Full-time employees or contract talent',
      'Benefits, equity & HR support for full-time hires',
      'Option to contract-to-hire',
    ],
    square: {
      color: '#E8EFF2',
      top: '-1rem',
      topResponsive: '2rem',
      height: '21.25rem',
      responsiveHeight: '17rem',
    },
    button: {
      url: talentHubOnboardingUrl,
      text: 'Hiring Options',
    },
    marginBottom: 0,
  },
];

const hiringMarketsCards = [
  {
    image: {
      url: '/images/hiring-developers/canada.png',
      altText: 'Globe with pin in Canada',
    },
    title: 'Canada',
    description:
      "A premier global market for hiring top-tier engineers, Canada's innovative tech culture thrives, especially in key talent ecosystems Vancouver, Toronto, and Kitchener-Waterloo.",
    link: {
      url: '/hire-developers/canada',
      text: 'Canadian Developers',
    },
  },
  {
    image: {
      url: '/images/hiring-developers/latam.png',
      altText: 'Globe with pin in some Latin America countries',
      height: '8.75rem',
    },
    title: 'Latin America',
    description:
      'Software developers in Latin America are perfectly aligned with U.S. time zones, LatAm developers in Costa Rica, Colombia, and Mexico are elite talents ready for U.S. companies.',
    link: {
      url: '/hire-developers/latam-latin-america',
      text: 'LatAm Developers',
    },
  },
  {
    image: {
      url: '/images/hiring-developers/europe.png',
      altText: 'Globe with pin in Canada',
    },
    title: 'Europe',
    description:
      'With talented senior engineers, dynamic startups, and strong English proficiency, Poland, Spain, Romania, and Hungary are perfect hiring markets for your tech team.',
    link: {
      url: '/hire-developers/europe',
      text: 'European Developers',
    },
  },
];

export function HireDevelopersContent({ blok, page }) {
  // #region Refactor this please
  const [roleListStory, setRoleListStory] = useState(null);
  const storyblokApi = getStoryblokApi();

  useEffect(() => {
    if (!storyblokApi) return;
    async function getGlobalComponentRoleListWithIcon() {
      const { data } = await storyblokApi.get(
        `cdn/stories/private-global-components/roles-with-icons`,
        {
          version: 'published',
        },
      );
      setRoleListStory(data.story);
    }
    getGlobalComponentRoleListWithIcon();
  }, [storyblokApi]);
  // #endregion

  const candidates =
    blok.candidates?.map((candidate) => {
      // TODO: improve this implementation
      const skills = [];
      const highlights = [];
      candidate.highlight1 && highlights.push(candidate.highlight1);
      candidate.highlight2 && highlights.push(candidate.highlight2);
      candidate.highlight3 && highlights.push(candidate.highlight3);
      candidate.highlight4 && highlights.push(candidate.highlight4);

      candidate.skill1 && skills.push(candidate.skill1);
      candidate.skill2 && skills.push(candidate.skill2);
      candidate.skill3 && skills.push(candidate.skill3);
      return {
        name: candidate.name,
        role: candidate.role,
        experience: candidate.experience,
        location: candidate.location,
        badges: candidate.badges,
        highlights,
        skills,
      };
    }) || [];

  const containerBgImage = useBreakpointValue(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    { base: 'none', md: `url(${hiringMarkets.src}), url(${blobPattern.src})` },
    { ssr: true },
  );

  const heroTitle = (
    blok.heroTitle || 'Hire Software Developers From A **Deep Global Talent Pool**'
  ).split(/\*\*/);

  const heroDescription =
    blok.heroDescription ||
    'Tap into top software engineers, data, devops, and mobile pros looking for their next job. Hire dedicated software developers that cost 40% less than U.S. talent.';

  return (
    <>
      {/* Hero section */}
      {/* TODO: (performance-optimization) switch to using Image component */}
      <Box
        as="section"
        bgColor="blue.100"
        pt={{ base: 0, xl: 32 }}
        pb={{ base: 6, xl: 32 }}
        style={{ backgroundImage: containerBgImage }}
        bgRepeat="no-repeat"
        bgPosition={{
          md: 'calc(50% + 280px) center, calc(50% + 400px) center',
        }}
        bgSize={{ md: '345px, 40rem', xl: '445px, 45rem' }}
      >
        <Box
          p={4}
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          bgImage={`url(${blobPattern.src})`}
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="contain"
          mb={6}
          display={{ md: 'none' }}
        >
          <Box w="15rem" h="12.5rem" mx="auto">
            <ImageShimmer
              src={hiringMarkets}
              alt="Hiring markets flags inside of globe"
              objectFit="fill"
              priority={true}
            />
          </Box>
        </Box>
        <Container>
          <Box maxW="32.875rem" pr={{ md: 3, lg: 0 }}>
            <Heading as="h1" mb={4} fontSize="4xl" lineHeight="short" textTransform="capitalize">
              {heroTitle[0]}
              {!!heroTitle[1] && (
                <Box as="span" color="green.500">
                  {heroTitle[1]}
                </Box>
              )}
            </Heading>
            <Text lineHeight="short" fontSize="xl" mb={{ base: 4, lg: 7 }}>
              {heroDescription}
            </Text>
            <PrimaryButton
              href={talentHubOnboardingUrl}
              w={{ base: '100%', md: 'auto', lg: '15rem' }}
              px={{ lg: 5 }}
              onClick={() =>
                trackCtaClicked({
                  link: talentHubOnboardingUrl,
                  name: 'Browse Talent',
                  type: 'businesses',
                  context: 'Hire Developers',
                })
              }
            >
              Browse Talent
            </PrimaryButton>
          </Box>
        </Container>
      </Box>
      {/* Offerings section */}
      <Box
        as="section"
        bgColor="white"
        pt={{ base: 10, lg: 20 }}
        pb={{ base: 4, lg: 20 }}
        pos="relative"
        overflow="hidden"
      >
        <Container pos="relative" zIndex={1} bgColor="white">
          {offeringsCards.map((content) => (
            <Grid
              key={content.title}
              gridTemplateColumns={{ lg: '1fr 420px', xl: '1fr 500px' }}
              gap={{ base: 10, lg: 14, xl: 20 }}
              mb={{
                base: 14,
                lg: content.marginBottom,
              }}
            >
              <GridItem display="flex" flexDir="column" justifyContent="center">
                <Flex mb={8} gridGap={2}>
                  <Box w={2} h={2} bgColor="blue.500" />
                  <Box w={2} h={2} bgColor="yellow.400" />
                  <Box w={2} h={2} bgColor="green.500" />
                </Flex>
                <Heading as="h2" fontSize={{ base: 'xl', lg: '2xl' }} lineHeight="short" mb={1}>
                  {content.title}
                </Heading>
                <Text
                  fontWeight="bold"
                  fontSize="md"
                  color="text.secondary"
                  lineHeight="short"
                  mb={3}
                >
                  {content.description}
                </Text>
                <List spacing={2} mb={content.button ? 8 : 0} aria-label={`${content.title} list`}>
                  {content.items.map((item) => (
                    <ListItem
                      key={item}
                      display="flex"
                      fontSize="md"
                      alignItems="center"
                      lineHeight="short"
                    >
                      <ListIcon as={CheckIcon} color="green.500" w={4} h={4} mr={3} />
                      {item}
                    </ListItem>
                  ))}
                </List>
                {content.button && (
                  <PrimaryButton
                    href={content.button.url}
                    px={6}
                    w={{ base: 'full', md: '15rem' }}
                    onClick={() =>
                      trackCtaClicked({
                        type: 'businesses',
                        name: content.button.text,
                        context: 'Hire Developers',
                        link: content.button.url,
                      })
                    }
                  >
                    {content.button.text}
                    <ArrowForwardIcon color="white" w={4} h={4} ml={3} />
                  </PrimaryButton>
                )}
              </GridItem>
              <GridItem
                pos="relative"
                display="flex"
                alignItems="center"
                justifyContent={{
                  base: 'center',
                  lg: 'start',
                }}
                order={{
                  base: -1,
                  lg: 0,
                }}
              >
                <Box pos="relative" zIndex={1}>
                  <Box
                    pos="relative"
                    h={{
                      base: '12.5rem',
                      lg: content.image.responsiveHeight,
                      xl: content.image.height,
                    }}
                    w={{
                      base: content.image.smallWidth,
                      lg: content.image.responsiveWidth,
                      xl: content.image?.width,
                    }}
                    ml={{
                      lg: '-1rem',
                      xl: content.image.marginLeft,
                    }}
                  >
                    <ImageShimmer
                      src={content.image.url}
                      alt={content.image.altText}
                      fill
                      priority={true}
                    />
                  </Box>
                </Box>
                <Box
                  pos="absolute"
                  w="100vw"
                  bgColor={content.square.color}
                  left={{
                    base: '20%',
                    lg: 0,
                  }}
                  top={{
                    base: 0,
                    lg: content.square.topResponsive || 0,
                    xl: content.square.top,
                  }}
                  h={{
                    base: 'full',
                    lg: content.square.responsiveHeight || 'full',
                    xl: content.square.height || '352px',
                  }}
                />
              </GridItem>
            </Grid>
          ))}
        </Container>
        <Box
          pos="absolute"
          left={0}
          top={0}
          bottom={0}
          w="8rem"
          display={{
            base: 'none',
            lg: 'initial',
          }}
        >
          <SquarePattern minH="full" />
        </Box>
      </Box>
      {/* Roles seo section */}
      <Box bgColor="grey.100" as="section" py={{ base: 10, md: 16 }}>
        <Container>
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
            Hire Software Developers For Core Roles
          </Heading>
          <GlobalComponent
            blok={{
              ...blok,
              story: roleListStory,
              component: 'globalComponent',
            }}
            page={page}
          />
        </Container>
      </Box>
      {/* Profiles section */}
      <Box bgColor="grey.900" as="section" py={{ base: 10, md: 16 }}>
        <Container>
          <Heading
            as="h2"
            textAlign="center"
            fontSize="1.75rem"
            lineHeight="short"
            color="text.inverse"
          >
            Recent Hires For Top-Tier Startups
          </Heading>
          <Flex
            mt={10}
            gap={{ base: 7, lg: 8 }}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            {fiveCompaniesLogos.map((company) => (
              <Flex key={company.name} pos="relative">
                <ImageShimmer
                  src={company.imageSrc}
                  alt={company.name}
                  width={company.imageSize.w}
                  height={company.imageSize.h}
                />
              </Flex>
            ))}
          </Flex>
          <Grid
            templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
            gap={{ base: 4, lg: 6 }}
            mt={10}
          >
            {candidates.map((candidate) => (
              <CandidateCard key={candidate.name} candidate={candidate} showCta={false} />
            ))}
          </Grid>
          <PrimaryButton
            href={talentHubOnboardingUrl}
            w={{ base: '100%', md: '25rem' }}
            px={{ lg: 5 }}
            mx="auto"
            display="flex"
            my={{ base: 6, md: 10 }}
            _hover={{
              backgroundColor: 'green.700',
            }}
            onClick={() =>
              trackCtaClicked({
                link: talentHubOnboardingUrl,
                name: 'Hire Developers',
                type: 'businesses',
                context: 'Hire Developers',
              })
            }
          >
            Hire Developers
          </PrimaryButton>
        </Container>
      </Box>
      {/* Hiring markets section */}
      <Box bgColor="white" as="section" py={{ base: 10, md: 16 }}>
        <Container>
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={10}>
            Hire Dedicated Software Developers From Our Global Talent Pools
          </Heading>
          <Grid
            templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
            gap={{ base: 4, lg: 6 }}
            mt={10}
          >
            {hiringMarketsCards.map((market) => (
              <GridItem key={market.title} display="flex" flexDir="column" alignItems="start">
                <Flex flex={1} alignItems="end" alignSelf={{ base: 'center', lg: 'initial' }}>
                  <Box pos="relative" w="11.25rem" h={market.image.height || '10.625rem'}>
                    <ImageShimmer src={market.image.url} alt={market.image.altText} fill />
                  </Box>
                </Flex>
                <Heading as="h3" fontSize="xl" lineHeight="short" mt={4}>
                  {market.title}
                </Heading>
                <Text fontSize="md" lineHeight="short" color="text.secondary" mt={1} mb={7}>
                  {market.description}
                </Text>
                <PrimaryButton
                  href={market.link.url}
                  px={4}
                  py={3}
                  h="3rem"
                  w={{ base: 'full', lg: 'initial' }}
                  onClick={() =>
                    trackCtaClicked({
                      type: 'businesses',
                      name: market.link.text,
                      context: 'Hire Developers',
                      link: market.link.url,
                    })
                  }
                >
                  {market.link.text}
                  <ArrowForwardIcon color="white" w={4} h={4} ml={3} />
                </PrimaryButton>
              </GridItem>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* Skills SEO section */}
      <Box
        bgColor="bg.secondary"
        as="section"
        pt={{ base: 10, lg: 16 }}
        pos="relative"
        _after={{
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '25rem',
          left: '0',
          top: '100%',
          bgColor: 'bg.secondary',
          zIndex: '-1',
        }}
      >
        <Container>
          <Heading as="h2" textAlign="center" fontSize="1.75rem" lineHeight="short" mb={2}>
            Hire From A Skilled Engineering Pool
          </Heading>
          <Text fontSize="lg" lineHeight="short" textAlign="center" mb={10}>
            Our software engineers and developers have the core skills you need.
          </Text>
          <SkillsList
            blok={{
              ...blok,
              component: 'skillsList',
              whitePill: true,
            }}
            page={page}
          />
        </Container>
      </Box>
    </>
  );
}
