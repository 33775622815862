import getConfig from 'next/config';
import { storyblokEditable } from '@storyblok/react';
import { Box, Heading, GridItem } from '@terminal/design-system';
import VerticalTabs from '@components/UI/VerticalTabs';
import { PrimaryButton } from '@components/UI/Button';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import { Storyblok, CandidatesAndHiringProcessStoryblok } from '../types';

const tabsCarousel = (skillName: string) => [
  {
    title: 'Attract',
    text: "We're here to help you attract ready-to-work, top tier talent engineers from around the globe.",
  },
  {
    title: 'Hire',
    text: `Terminal manages the full ${skillName} developer hiring process, including all paperwork and administrative duties.`,
  },
  {
    title: 'Retain',
    text: "We're your partner in retention, offering team member localized HR support, stipends and tools they need to thrive.",
  },
];

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;
export function CandidatesAndHiringProcess({
  blok,
}: Storyblok<CandidatesAndHiringProcessStoryblok>) {
  const titleSplit = blok.title?.split('%STORYNAME%') || [];
  const items = blok.items?.length ? blok.items : tabsCarousel(blok.storyName);

  return (
    <Box
      // pb={{ base: 6, lg: 6 }}
      minH={{ base: '46rem', lg: '33rem' }}
      as="section"
      {...storyblokEditable(blok)}
    >
      <VerticalTabs tabs={items} type="number" shouldHideImage={true}>
        <GridItem
          gridArea={{ base: '1/1/1/3', lg: '1 / 1 / 3 / 2' }}
          alignSelf="center"
          mb={{ base: 4, lg: 0 }}
        >
          <Heading as="h2" fontSize={{ base: '2.5rem', lg: '3rem' }} mb={{ base: 4, lg: 10 }}>
            {titleSplit[0]}
            <Box as="span" color="green.500">
              {' '}
              {blok.storyName}{' '}
            </Box>
            {titleSplit[1]}
          </Heading>
          <PrimaryButton
            href={talentHubOnboardingUrl}
            px={5}
            py={4}
            onClick={() =>
              trackCtaClicked({
                name: `Hire ${blok.storyName} Developers Today`,
                type: 'businesses',
                context: 'Hiring Process',
                link: talentHubOnboardingUrl,
              })
            }
          >
            Hire Developers Today
          </PrimaryButton>
        </GridItem>
      </VerticalTabs>
    </Box>
  );
}
