import { extendTheme } from '@chakra-ui/react';
import { components as baseComponents, designTokens as baseDesignTokens } from '../theme';

export {
  ChakraProvider,
  createIcon,
  extendTheme,
  forwardRef,
  useBreakpointValue,
  useClipboard,
  useDisclosure,
  useMediaQuery,
  useRadio,
  useTheme,
  useToast as nativeToastHook,
  useToken,
} from '@chakra-ui/react';

// TODO: Update this icon to remove `@chakra-ui/icons`
export { CheckCircleIcon as ChakraCheckCircleIcon } from '@chakra-ui/icons';

// type Theme = Parameters<typeof extendTheme>[number];
const { colors: baseColors, ...baseDesignTokensRest } = baseDesignTokens;

// TODO: update the type from any to specific type
/**
 * Extending base theme
 * @todo add more description
 */
export const extendBaseTheme = (overwrite: {
  designTokens: any;
  styles?: any;
  components?: any;
}) => {
  const {
    // @ts-ignore
    designTokens: { colors, ...designTokensRest },
    styles,
    components,
  } = overwrite;

  return {
    ...extendTheme({
      ...baseDesignTokensRest,
      ...designTokensRest,
      components: {
        ...baseComponents,
        ...components,
      },
    }),
    styles,
    colors: {
      ...baseColors,
      ...colors,
    },
  };
};
